import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import * as InstagramAPI from '@/apis/instagram';
import { useSocketStore } from './socket';

export const CSV_TYPE_INSTAGRAM_RIGHTS_REQUEST_MEDIA = 'instagramRightsRequestMedia';

export const useInstagramRightsRequestStore = defineStore(
  'instagramRightsRequest',
  () => {
    const authStore = useAuthStore();
    const socketStore = useSocketStore();

    const counts = ref({
      ALL: 0,
      REQUESTED: 0,
      APPROVED: 0,
      LAPSED: 0,
    });
    const requestUsers = ref(null);
    const filteredRequestUsers = ref(null);
    const rightsRequestComments = ref([]);
    const pending = ref({
      rightsRequestCommentsStatus: false,
      rightsRequestUsers: false,
    });

    async function getRightsRequestAllUsers() {
      const brandId = authStore.currentBrand?.id;
      pending.value.rightsRequestUsers = true;
      try {
        const response = await InstagramAPI.getInstagramRightsRequestMediaCounts({
          brandId,
          contentRightsType: 'ALL',
        });
        requestUsers.value = response?.data?.users ?? null;
      } finally {
        pending.value.rightsRequestUsers = false;
      }
    }

    async function getRightsRequestMediaCounts({ startDate, endDate, userId }) {
      const brandId = authStore.currentBrand?.id;
      const response = await InstagramAPI.getInstagramRightsRequestMediaCounts({
        brandId,
        startDate,
        endDate,
        userId,
        contentRightsType: 'ALL',
      });
      const payload = response?.data;
      counts.value = payload?.counts;
      filteredRequestUsers.value = payload?.users;
      return payload;
    }

    async function getRightsRequestMediaCSV({ startDate, endDate, userId, contentRightsType }) {
      const brandId = authStore.currentBrand?.id;
      const socketId = socketStore.id;
      const type = CSV_TYPE_INSTAGRAM_RIGHTS_REQUEST_MEDIA;

      const response = await InstagramAPI.getInstagramRightsRequestMediaCsv({
        brandId,
        startDate,
        endDate,
        userId,
        contentRightsType,
        socketId,
        type,
      });
      const payload = response?.data;

      return payload;
    }

    async function postFacebookComment({ postId, message }) {
      const brandId = authStore.currentBrand?.id;
      const response = await InstagramAPI.addRightsRequestFacebookComment({
        brandId,
        postId,
        message,
      });
      const payload = response?.data;

      return payload;
    }

    async function getRightsRequestComments() {
      try {
        pending.value.rightsRequestCommentsStatus = true;
        const brandId = authStore.currentBrand?.id;
        const response = await InstagramAPI.getRightsRequestComments({
          brandId,
        });
        const payload = response?.data;
        rightsRequestComments.value = payload?.data;
        return payload;
      } finally {
        pending.value.rightsRequestCommentsStatus = false;
      }
    }

    async function addRightsRequestComment({ comment, requestDocumentId, requestHashtagId }) {
      const brandId = authStore.currentBrand?.id;
      const response = await InstagramAPI.addRightsRequestComment({
        brandId,
        comment,
        requestDocumentId,
        requestHashtagId,
      });
      const payload = response?.data;
      rightsRequestComments.value.unshift(payload);
      return payload;
    }

    async function updateRightsRequestComment({
      commentId,
      comment,
      requestDocumentId,
      requestHashtagId,
    }) {
      const brandId = authStore.currentBrand?.id;
      const response = await InstagramAPI.updateRightsRequestComment({
        brandId,
        commentId,
        comment,
        requestDocumentId,
        requestHashtagId,
      });
      const payload = response?.data;
      const index = rightsRequestComments.value.findIndex(
        (requestComment) => payload.id === requestComment.id,
      );
      rightsRequestComments.value.splice(index, 1, payload);
      return payload;
    }

    async function deleteRightsRequestComment({ commentId }) {
      const brandId = authStore.currentBrand?.id;
      const response = await InstagramAPI.deleteRightsRequestComment({
        brandId,
        commentId,
      });
      const payload = response?.data;

      return payload;
    }

    function clearRightsRequestComments() {
      rightsRequestComments.value = [];
    }

    return {
      pending,
      counts,
      requestUsers,
      rightsRequestComments,
      getRightsRequestAllUsers,
      getRightsRequestMediaCounts,
      getRightsRequestMediaCSV,
      getRightsRequestComments,
      addRightsRequestComment,
      updateRightsRequestComment,
      deleteRightsRequestComment,
      postFacebookComment,
      clearRightsRequestComments,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
