import { defineStore } from 'pinia';
import humps from 'humps';
import { v4 as uuidv4 } from 'uuid';
import axios, { HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import communityApi from '@/apis/community';
import {
  blockConfirmationPopup,
  COMMUNITY_BLOCK_TOAST,
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_GENERIC_TYPES,
  COMMUNITY_UNBLOCK_TOAST,
  conversationMessageTypes,
  UNREAD_COMMUNITY_INTERACTIONS_CAP,
  UNREAD_QUERY_FILTER_KEYS,
} from '@/app/community/constants';
import { parseTimestamps } from '@/app/community/utils/time';
import {
  applyReversibleCommunityInteractionUpdate,
  filterCommunityInteractions,
  sortCommunityInteractions,
} from '@/app/community/utils/store-utils';
import { reverseUpdate } from '@/utils/optimism';
import { LibraryAPI } from '@/apis/index';
import { useAuthStore } from '@/stores/auth';
import { applyBulkUpdateToCollection } from '@/app/community/utils/websockets';
import { useNotificationStore } from '@/stores/notification';
import { generateDistinctRandomColor } from '@/utils/color';
import { useIdentityStore } from '@/stores/identity';
import { useEnabledTypes } from '@/app/community/composables/useEnabledTypes';
import { refreshCancelToken } from '@/apis/axios.utils';
import * as CollaborationAPI from '@/apis/collaboration';
import { userBlockedUpdatedEvent } from '@/app/community/utils/mixpanel-events';
import { isInteractionMediaCommentType } from '@/app/community/utils/community-interactions';

const notifyError = (message) => {
  const notificationStore = useNotificationStore();
  notificationStore.setToast({
    message,
    type: 'error',
  });
};

const emptyCountResponse = {
  ALL_MESSAGES: 0,
  INBOX: 0,
  ASSIGNED_TO_YOU: 0,
};

function getUnreadCountQueryParams(currentQuery) {
  return UNREAD_QUERY_FILTER_KEYS.reduce((queryParams, filterKey) => {
    queryParams[filterKey] = currentQuery[filterKey];
    return queryParams;
  }, {});
}

export const useCommunityInteractionStore = defineStore('communityInteraction', {
  resetOnBrandChange: true,
  state: () => ({
    tags: [],
    communityInteractions: [],
    communityInteractionUnreadCountsByType: {},
    communityInteractionUnreadCounts: {},
    selectedCommunityInteractionId: null,
    finalCommunityInteractionLoaded: false,
    communityInteractionsNextPage: null,
    hasFetchedCommunityInteractions: false,
    selectedCommunityInteractionMedia: null,
    currentQuery: {},
    internalNotes: [],
    selectedInternalNoteId: null,
    finalInternalNoteLoaded: false,
    internalNoteNextPage: null,
    hasFetchedInternalNotes: false,
    pending: {
      communityInteraction: false,
      updateCommunityInteraction: false,
      communityInteractions: false,
      internalNotes: false,
    },
    communityInteractionsCancelToken: null,
    prevAssigneeId: null,
    interactionTypes: null,
    blockActionClicked: false,
  }),
  getters: {
    sortedTags: (state) => [...state.tags].sort((a, b) => a.name.localeCompare(b.name)),
    filteredCommunityInteractions: (state) => {
      return filterCommunityInteractions(state.communityInteractions, state.currentQuery);
    },
    selectedCommunityInteraction: (state) => {
      return filterCommunityInteractions(state.communityInteractions, state.currentQuery).find(
        (communityInteraction) =>
          communityInteraction?.id === state.selectedCommunityInteractionId &&
          state.selectedCommunityInteractionId !== null,
      );
    },
    selectedCommunityInteractionTags() {
      if (!this.selectedCommunityInteraction?.tagIds?.length) {
        return [];
      }
      return this.selectedCommunityInteraction?.tagIds.flatMap(
        (tagID) => this.sortedTags.find((tag) => tag.id === tagID) || [],
      );
    },
    selectedInternalNote: (state) => {
      return state.internalNotes.find(
        (internalNote) => internalNote.id === state.selectedInternalNoteId,
      );
    },
    selectedInteractionGenericType() {
      const interactionType = this.selectedCommunityInteraction?.type;
      if (isInteractionMediaCommentType(this.selectedCommunityInteraction)) {
        return COMMUNITY_GENERIC_TYPES.COMMENT;
      }
      if (conversationMessageTypes().includes(interactionType)) {
        return COMMUNITY_GENERIC_TYPES.CONVERSATION;
      }

      return undefined;
    },
  },
  actions: {
    async toggleBlockState(interaction, afterUpdateHook = null, toBlocked = true) {
      const { title, confirmMessage, confirmAlias, cancelAlias } =
        blockConfirmationPopup(toBlocked);
      const notificationStore = useNotificationStore();
      const confirmed = await notificationStore.confirm(title, confirmMessage, {
        confirmAlias,
        cancelAlias,
      });

      if (confirmed) {
        this.blockActionClicked = true;
        const toast = toBlocked ? COMMUNITY_BLOCK_TOAST : COMMUNITY_UNBLOCK_TOAST;
        try {
          const res = await this.updateCommunityInteraction(
            interaction.id,
            {
              isAuthorBlocked: toBlocked,
            },
            false,
            null,
            afterUpdateHook,
          );
          if (res.data.data) {
            const [channel, type] = interaction.type.split('_');
            userBlockedUpdatedEvent(toBlocked, channel, type);
            notificationStore.setToast({
              message: toast.SUCCESS,
              type: 'success',
            });
          }
        } catch (error) {
          if (error.response && error.response.status === HttpStatusCode.Conflict) {
            notifyError(toast.ERROR.RECENTLY_BLOCKED);
          } else {
            notifyError(toBlocked ? toast.ERROR.DEFAULT : toast.ERROR);
          }
        }
        this.blockActionClicked = false;
      }
    },
    async getCommunityInteraction({ brandId, id, types }) {
      this.pending.communityInteraction = true;
      try {
        const res = await communityApi.getCommunityInteractions({ brandId, id, types });
        this.pending.communityInteraction = false;
        return res.data.data.map(parseTimestamps)[0];
      } catch {
        this.pending.communityInteraction = false;
        return {};
      }
    },
    async getCommunityInteractions(params, skipFilter = false) {
      this.pending.communityInteractions = true;
      const { next } = params;
      if (!next) {
        this.communityInteractions = [];
        this.communityInteractionsNextPage = null;
      }

      const cancelToken = refreshCancelToken(this, 'communityInteractionsCancelToken');
      try {
        const res = await communityApi.getCommunityInteractions(params, { cancelToken });
        const data = res.data.data.map(parseTimestamps);
        const newCommunityInteractionIds = data.map(
          (communityInteraction) => communityInteraction.id,
        );

        this.communityInteractionsNextPage = res.data.paging.next;
        this.finalCommunityInteractionLoaded =
          data.length === 0 || !this.communityInteractionsNextPage;

        this.communityInteractions = skipFilter
          ? [...this.communityInteractions, ...data]
          : filterCommunityInteractions(
              sortCommunityInteractions(
                [
                  ...this.communityInteractions.filter(
                    (communityInteraction) =>
                      !newCommunityInteractionIds.includes(communityInteraction.id),
                  ),
                  ...data,
                ],
                this.currentQuery,
              ),
              this.currentQuery,
              true,
            );
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
      }
      this.pending.communityInteractions = false;
    },
    async updateCommunityInteraction(
      id,
      payload,
      flagChange = false,
      interaction = null,
      afterUpdateHook = null,
      throwError = false,
    ) {
      const communityInteraction =
        interaction || this.communityInteractions.find((ci) => ci.id === id);
      const { assigneeId, isRead, isArchived } = communityInteraction;
      const identityStore = useIdentityStore();
      const currentUserId = identityStore.identity?.id;
      this.calculateCountChanges(payload, isRead, isArchived, assigneeId, currentUserId);
      if (flagChange) {
        this.pending.updateCommunityInteraction = true;
      }
      applyReversibleCommunityInteractionUpdate(
        this.communityInteractions,
        id,
        humps.camelizeKeys(payload),
      );

      if (afterUpdateHook) afterUpdateHook({ commentId: id, payload });

      try {
        const res = await communityApi.updateCommunityInteraction(id, payload);
        const communityInteractionData = parseTimestamps(res.data.data);

        this.communityInteractions = this.communityInteractions.map((ci) => {
          if (ci.id === id) {
            communityInteractionData.searchResultSnippet = ci.searchResultSnippet;
            return communityInteractionData;
          }
          return ci;
        });

        if (payload?.isRead != null) {
          const count = this.communityInteractionUnreadCountsByType[communityInteraction?.type];
          if (count >= UNREAD_COMMUNITY_INTERACTIONS_CAP && communityInteraction?.type) {
            this.getUnreadInteractionCounts();
          }
        }
        if (flagChange) {
          this.pending.updateCommunityInteraction = false;
        }
        return res;
      } catch (err) {
        reverseUpdate(this.communityInteractions, id);
        if (afterUpdateHook) afterUpdateHook({ commentId: id, payload, undo: true });
        this.communityInteractions = filterCommunityInteractions(
          this.communityInteractions,
          this.currentQuery,
        );
        this.calculateCountChanges(payload, isRead, isArchived, assigneeId, currentUserId, true);
        if (flagChange) {
          this.pending.updateCommunityInteraction = false;
        }
        // bubble up errors related to blocking users to handle specific errors
        if (payload.isAuthorBlocked !== undefined || throwError) {
          throw err;
        }
      }

      return {};
    },
    async bulkUpdateCommunityInteractions(brandId, interactionIds, payload) {
      const ids = interactionIds.split(',').map((id) => Number(id));
      const preUpdateInteractions = this.communityInteractions.filter((interaction) =>
        ids.includes(interaction.id),
      );
      const res = await communityApi.bulkUpdateCommunityInteractions(
        { brandId, ids: interactionIds },
        payload,
      );
      if (
        Object.prototype.hasOwnProperty.call(payload, 'isRead') ||
        Object.prototype.hasOwnProperty.call(payload, 'isArchived')
      ) {
        this.calculateBulkCountChanges(preUpdateInteractions, payload);
      }

      return res;
    },
    applyBulkUpdateResponse(interactions) {
      // use the response from the bulk update to update the state of community interactions
      interactions.forEach((interaction) => {
        const index = this.communityInteractions.findIndex((item) => item.id === interaction.id);
        if (index >= 0) {
          this.communityInteractions.splice(index, 1, parseTimestamps(interaction));
        }
      });
    },
    async getUnreadInteractionCounts() {
      const authStore = useAuthStore();
      const queryParams = getUnreadCountQueryParams(
        humps.camelizeKeys({
          ...this.currentQuery,
          sourceUpdatedAfter: this.currentQuery.startDate
            ? dayjs(this.currentQuery.startDate).startOf('date').format()
            : null,
          sourceUpdatedBefore: this.currentQuery.endDate
            ? dayjs(this.currentQuery.endDate).endOf('date').format()
            : null,
        }),
      );
      const res = await communityApi.getUnreadCommunityInteractionCounts(
        authStore.currentBrand?.id,
        queryParams,
      );
      this.communityInteractionUnreadCountsByType = res.data;
    },
    async getUnreadInteractionCountsV2() {
      // skip get unread count if no enabledTypes
      const { enabledTypes } = useEnabledTypes();
      if (!enabledTypes.value?.length) {
        this.communityInteractionUnreadCounts = emptyCountResponse;
        return;
      }
      const cancelToken = refreshCancelToken(this, 'unreadInteractionCountsCancelToken');
      const authStore = useAuthStore();
      // We don't need the isArchived and isRead query param for this endpoint
      const { isArchived, isRead, assigneeId, ...query } = this.currentQuery;
      const assignment = { assigneeId: this.prevAssigneeId ?? assigneeId };
      const queryParams = {
        ...getUnreadCountQueryParams({ ...query, ...assignment }),
      };
      let counts;
      try {
        const res = await communityApi.getUnreadCommunityInteractionCountsV2(
          authStore.currentBrand?.id,
          queryParams,
          { cancelToken },
        );
        counts = res.data;

        // the response from the backend is not consistent with the front end, ASSIGNED_TO_YOU not the same as ASSIGNED_TO_ME
        counts = {
          ALL_MESSAGES: counts.ALL_MESSAGES,
          ASSIGNED_TO_YOU: counts.ASSIGNED_TO_ME ?? counts.ASSIGNED_TO_YOU,
          INBOX: counts.INBOX,
        };
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
      }
      this.communityInteractionUnreadCounts = counts;
    },
    setSelectedCommunityInteraction(communityInteraction) {
      this.selectedCommunityInteractionId = communityInteraction?.id || null;
    },
    async clearCommunityInteractions() {
      this.selectedCommunityInteractionId = null;
      this.communityInteractions = [];
      this.finalCommunityInteractionLoaded = false;
      this.communityInteractionsNextPage = null;
    },
    async setCommunityInteractionMedia({ brandId, mediaId }) {
      const res = await LibraryAPI.getInteractionMediaById(
        {
          brandId,
          mediaId,
        },
        {},
        { useCache: true },
      );
      this.selectedCommunityInteractionMedia = res.data;
    },
    clearCommunityInteractionMedia() {
      this.selectedCommunityInteractionMedia = null;
    },
    updateSelectedInteraction({ payload: value, undo = false }) {
      if (undo) {
        Object.keys(
          Object.fromEntries(
            Object.entries(value).filter((item) => typeof item?.[1] === 'boolean'),
          ),
        ).forEach((key) => {
          value[key] = !value[key];
        });
      }
      const interaction = this.communityInteractions.find(
        (communityInteraction) =>
          communityInteraction.id === this.selectedCommunityInteractionId &&
          this.selectedCommunityInteractionId !== null,
      );
      if (interaction) {
        this.communityInteractions = this.communityInteractions.map((item) =>
          item.id !== interaction.id ? item : { ...interaction, ...value },
        );
      }
    },
    setCommentDisabled(payload) {
      return new Promise((resolve, reject) => {
        communityApi
          .updatePostEnabled(payload)
          .then(() => {
            this.selectedCommunityInteractionMedia.sourceData.commentDisabled =
              payload.commentDisabled;
            resolve();
          })
          .catch(() => reject());
      });
    },
    setCurrentQuery(newQuery) {
      this.currentQuery = newQuery;
    },
    bulkUpdatePlatformConversations({ payload }) {
      payload.forEach((update) => {
        applyBulkUpdateToCollection(this.communityInteractions, 'id', update);
      });
      this.communityInteractions = filterCommunityInteractions(
        sortCommunityInteractions(this.communityInteractions, this.currentQuery),
        this.currentQuery,
      );
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.getUnreadInteractionCountsV2();
      }, 3000);
    },
    removeCommunityInteractionsFromStore({ interactionIds }) {
      const currentId = this.selectedCommunityInteractionId;
      const currentIndex = this.communityInteractions.map((item) => item.id).indexOf(currentId);

      // if selected interaction is removed, select next interaction
      if (interactionIds.includes(currentId)) {
        const interactions = this.communityInteractions;
        const notRemoved = (interaction) => !interactionIds.includes(interaction.id);

        // find first interaction that is not removed
        const nextInteraction =
          interactions.slice(currentIndex + 1).find(notRemoved) ||
          interactions.slice(0, currentIndex).reverse().find(notRemoved);
        this.selectedCommunityInteractionId = nextInteraction?.id || null;
      }
      this.communityInteractions = this.communityInteractions.filter((interaction) => {
        return !interactionIds.includes(interaction.id);
      });
      // eslint-disable-next-line no-unused-expressions
      this.getUnreadInteractionCountsV2();
    },

    generateTagColor() {
      const existingColors = this.tags.map((t) => t.color);
      return generateDistinctRandomColor(existingColors);
    },
    async createTag({ brandId, tag }) {
      try {
        const authStore = useAuthStore();
        const color = tag.color ?? this.generateTagColor();
        const res = await communityApi.createTag({
          brandId: brandId ?? authStore.currentBrand?.id,
          name: tag.name,
          color,
        });
        const newTag = res?.data?.data;
        this.tags.push(newTag);
        return newTag;
      } catch (e) {
        notifyError('There was an error creating your tag');
        throw e;
      }
    },
    async getTags({ brandId }) {
      this.tags = [];
      const res = await communityApi.getTags({ brandId });
      this.tags = res.data.data;
    },
    async deleteTag(tagId) {
      const tagToDeleteIndex = this.tags.findIndex((tag) => tag.id === tagId);
      const deletedTag = this.tags.splice(tagToDeleteIndex, 1);
      try {
        await communityApi.deleteTag(tagId);
      } catch (e) {
        notifyError('There was an error deleting your tag');
        this.tags.push(deletedTag);
        throw e;
      }
    },
    async assignTag({ brandId, tag: assignedTag, isNew }) {
      let tag = { ...assignedTag };
      if (isNew) {
        const temporaryID = uuidv4();
        try {
          // Create a temporary tag, and assign it to the interaction
          tag.color = this.generateTagColor();
          tag.id = temporaryID;
          this.tags.push(tag);
          applyReversibleCommunityInteractionUpdate(
            this.communityInteractions,
            this.selectedCommunityInteractionId,
            {
              tagIds: { add: [tag.id] },
            },
          );

          // Send request to backend to create the tag
          tag = await this.createTag({ brandId, tag });

          // Remove temporary tag
          reverseUpdate(this.communityInteractions, this.selectedCommunityInteractionId);
          this.tags = this.tags.filter((t) => t.id !== temporaryID);
        } catch (e) {
          this.tags = this.tags.filter((t) => t.id !== temporaryID);
          throw e;
        }
      }

      try {
        // Send request to backend to assign the real tag to the interaction.
        await this.updateCommunityInteraction(this.selectedCommunityInteractionId, {
          tagIds: { add: [tag.id] },
        });
      } catch (e) {
        notifyError('There was an error assigning your tag');
        throw e;
      }
    },
    async unAssignTag(tag) {
      try {
        await this.updateCommunityInteraction(this.selectedCommunityInteractionId, {
          tagIds: { remove: [tag.id] },
        });
      } catch (e) {
        notifyError('There was an error removing your tag');
        throw e;
      }
    },
    formatInternalNotePreview(internalNote, users) {
      const authorUser = users[internalNote.userId];
      const noteItem = {
        id: internalNote.id,
        resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE,
        resourceId: Number(internalNote.resourceId),
        type: `${internalNote.meta?.channel}_${internalNote.meta?.messageType}`,
        text: internalNote.text,
        createdAt: internalNote.createdAt,
        isRead: internalNote.meta?.isRead,
        isAdComment: internalNote.meta?.isAdComment || false,
        authorUser,
        users,
      };
      return noteItem;
    },
    async getInternalNotesPreview({
      brandId,
      commentId,
      mentionedUserIds,
      meta,
      createdBefore,
      createdAfter,
      limit,
      offset,
      sort,
      next,
    }) {
      const cancelToken = refreshCancelToken(this, 'internalNotesCancelToken');
      this.pending.internalNotes = true;
      try {
        const payload = next
          ? { next }
          : {
              brandId,
              commentId,
              resourceTypes: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE,
              mentionedUserIds,
              createdBefore,
              createdAfter,
              meta,
              limit,
              offset,
              sort,
            };
        const response = await CollaborationAPI.getBrandComments(payload, { cancelToken });

        const { data, paging, referencedObjects } = response.data;
        const notes = [];

        data.forEach((item) => {
          if (item.meta?.channel && item.meta?.messageType) {
            notes.push(this.formatInternalNotePreview(item, referencedObjects?.user));
          }
        });
        this.internalNotes = [...this.internalNotes, ...notes];
        this.internalNoteNextPage = notes.length !== 0 ? paging.next : null;
        this.finalInternalNoteLoaded = notes.length === 0 || !this.internalNoteNextPage;
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        notifyError('Failed to load internal notes. Please try again.');
      } finally {
        this.pending.internalNotes = false;
      }
    },
    setSelectedInternalNote(internalNote) {
      this.selectedInternalNoteId = internalNote?.id || null;
    },
    clearInternalNotes() {
      if (this.communityInteractions.length) {
        this.clearCommunityInteractions();
      }
      this.selectedInternalNoteId = null;
      this.internalNotes = [];
      this.finalInternalNoteLoaded = false;
      this.internalNoteNextPage = null;
      this.selectedCommunityInteractionId = null;
      this.communityInteractions = [];
    },
    calculateAssigneeCountChanges(
      newAssigneeId,
      oldAssigneeId,
      communityInteraction,
      reverse = false,
    ) {
      const identityStore = useIdentityStore();
      const currentUserId = identityStore.identity.id;
      const allMessagesChange = 0;
      let assignedToMeChange = 0;
      let inboxChange = 0;

      if (newAssigneeId === currentUserId) {
        if (!communityInteraction.isRead) {
          assignedToMeChange += 1;
        }
      } else if (oldAssigneeId === currentUserId) {
        if (!communityInteraction.isRead) {
          assignedToMeChange -= 1;
        }
      }

      if (reverse) {
        assignedToMeChange *= -1;
        inboxChange *= -1;
      }

      return { allMessagesChange, assignedToMeChange, inboxChange };
    },
    calculateCountChanges(payload, isRead, isArchived, assigneeId, currentUserId, reverse = false) {
      let allMessagesCountChange = 0;
      let assignedToMeCountChange = 0;
      let inboxCountChange = 0;

      // reverse will only be true when we need to undo an optimistic update
      // either add or subtract a count change in the event we need to undo an optimistic update
      const countOperation = (change) => {
        return reverse ? -change : change;
      };

      if (payload.isRead !== undefined) {
        if (payload.isRead) {
          if (isArchived) {
            // only the all message inbox shows archived posts as well
            allMessagesCountChange += countOperation(-1);
          } else {
            inboxCountChange += countOperation(-1);
            allMessagesCountChange += countOperation(-1);
          }
          if (assigneeId === currentUserId) assignedToMeCountChange += countOperation(-1);
        } else {
          if (isArchived) {
            allMessagesCountChange += countOperation(1);
          } else {
            inboxCountChange += countOperation(1);
            allMessagesCountChange += countOperation(1);
          }
          if (assigneeId === currentUserId) assignedToMeCountChange += countOperation(1);
        }
      }

      if (payload.isArchived !== undefined) {
        if (payload.isArchived) {
          if (!isRead) {
            inboxCountChange += countOperation(-1);

            if (assigneeId === currentUserId) {
              assignedToMeCountChange += countOperation(-1);
            }
          }
        } else if (!isRead) {
          inboxCountChange += countOperation(1);

          if (assigneeId === currentUserId) {
            assignedToMeCountChange += countOperation(1);
          }
        }
      }
      this.updateInteractionCounts(
        allMessagesCountChange,
        assignedToMeCountChange,
        inboxCountChange,
      );
    },
    calculateBulkCountChanges(communityInteractions, payload) {
      const identityStore = useIdentityStore();
      const currentUserId = identityStore.identity.id;
      let allMessagesCountChange = 0;
      let assignedToMeCountChange = 0;
      let inboxCountChange = 0;

      if (payload.isRead !== undefined) {
        communityInteractions.forEach(({ assigneeId, isRead }) => {
          if (payload.isRead) {
            // only update counts if the interaction is unread
            if (!isRead) {
              inboxCountChange -= 1;
              allMessagesCountChange -= 1;
              if (assigneeId === currentUserId) assignedToMeCountChange -= 1;
            }
          } else {
            inboxCountChange += 1;
            allMessagesCountChange += 1;
            if (assigneeId === currentUserId) assignedToMeCountChange += 1;
          }
        });
      }

      if (payload.isArchived !== undefined) {
        communityInteractions.forEach(({ assigneeId, isRead }) => {
          const change = payload.isArchived ? -1 : 1;

          if (!isRead) {
            // only update counts if interaction is unread
            inboxCountChange += change;
            if (assigneeId === currentUserId) {
              assignedToMeCountChange += change;
            }
          }
        });
      }
      this.updateInteractionCounts(
        allMessagesCountChange,
        assignedToMeCountChange,
        inboxCountChange,
      );
    },
    updateInteractionCounts(allMessagesChange, assignedToMeChange, inboxCountChange) {
      this.communityInteractionUnreadCounts = {
        ALL_MESSAGES: Math.max(
          0,
          (this.communityInteractionUnreadCounts?.ALL_MESSAGES ?? 0) + allMessagesChange,
        ),
        ASSIGNED_TO_YOU: Math.max(
          0,
          (this.communityInteractionUnreadCounts?.ASSIGNED_TO_YOU ?? 0) + assignedToMeChange,
        ),
        INBOX: Math.max(0, (this.communityInteractionUnreadCounts.INBOX ?? 0) + inboxCountChange),
      };
    },

    executeRuleActionOnInteraction(interactionToUpdate) {
      const storeInteractions = this.communityInteractions;
      const indexToUpdate = storeInteractions.findIndex(
        (storeInteraction) => storeInteraction.id === interactionToUpdate.id,
      );

      if (indexToUpdate === -1) {
        return;
      }

      const updatedInteraction = {
        ...storeInteractions[indexToUpdate],
        isArchived: interactionToUpdate.isArchived,
        tagIds: interactionToUpdate.tagIds,
      };

      this.communityInteractions = [
        ...storeInteractions.slice(0, indexToUpdate),
        updatedInteraction,
        ...storeInteractions.slice(indexToUpdate + 1),
      ];
    },
  },
});
