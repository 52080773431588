import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useSocialListeningStore } from '@/stores/social-listening';
import {
  CHANNEL_FILTER_OPTIONS,
  TRENDS_DEFAULT_FILTERS,
} from '@/app/socialListening/constants/listeningFilters';
import {
  getKeywordORString,
  getStartAndEndDate,
} from '@/app/socialListening/utils/api-request-utils';
import cloneDeep from 'lodash/cloneDeep';
import { getFormattedSentimentChartData } from '@/app/socialListening/utils/formatters';
import { useListeningPermissions } from '@/app/socialListening/composables/useListeningPermissions';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

export const useSocialListeningTrendsStore = defineStore('socialListeningTrends', () => {
  const socialListeningStore = useSocialListeningStore();
  const { canAccessYoutubeCompliance } = useListeningPermissions();

  const filters = ref({ ...cloneDeep(TRENDS_DEFAULT_FILTERS) });
  const temporaryFilters = ref({});

  const pending = ref({
    sentimentTrendTimeSeries: false,
  });
  const sentimentTrendTimeSeries = ref(null);
  const queryKeyword = ref('');

  const searchFilters = computed(() => {
    return { ...(filters.value || {}), ...(temporaryFilters.value || {}) };
  });

  const sourceCreated = computed(() => {
    const dateRange = getStartAndEndDate(searchFilters.value.dateRange);
    return { onOrAfter: dateRange.startDate, onOrBefore: dateRange.endDate };
  });

  function getChannels(channels = []) {
    if (canAccessYoutubeCompliance.value) {
      return channels;
    }
    return channels.filter((c) => c !== CHANNEL_FILTER_OPTIONS.YOUTUBE.value);
  }

  const selectedChannels = computed(() => {
    const channels = filters.value.channels?.length
      ? filters.value.channels
      : TRENDS_DEFAULT_FILTERS.channels;
    return getChannels(channels);
  });

  const formattedFilters = computed(() => {
    const formattedPageFilters = {
      sources: getChannels(filters.value.channels),
      mediaTypes: { includes: filters.value.mediaTypes },
      keywordsAndHashtags: filters.value.keyword,
      sourceCreated: sourceCreated.value,
      sentiment: null,
      ...temporaryFilters.value,
    };
    // Filter out null values and return
    return Object.fromEntries(
      Object.entries(formattedPageFilters).filter(
        ([, value]) => value !== null && value !== undefined,
      ),
    );
  });

  const drawerSerializedFilters = computed(() => {
    const drawerFilters = {
      ...omitBy(searchFilters.value, isEmpty),
      keywordsAndHashtags: getKeywordORString(filters.value, socialListeningStore.topKeywords),
    };
    return mapValues(drawerFilters, (v, k) => {
      if (k === 'channels') {
        return selectedChannels.value?.join(',');
      }
      return Array.isArray(v) ? v?.join(',') : v;
    });
  });

  const scale = computed(() => {
    return socialListeningStore.calculateScale(formattedFilters.value);
  });

  function setFilters({
    channels,
    sentiments,
    mediaTypes,
    dateRange,
    keyword,
    keywordTypes,
    customDateRange,
  }) {
    filters.value = {
      channels: channels ? getChannels(channels) : channels,
      sentiments,
      mediaTypes,
      dateRange,
      keyword,
      keywordTypes,
      customDateRange,
    };
  }

  function resetFilters() {
    filters.value = { ...cloneDeep(TRENDS_DEFAULT_FILTERS) };
    queryKeyword.value = '';
  }

  function setKeywordFilter(keyword) {
    filters.value = {
      ...filters.value,
      keyword,
    };
  }

  function removeKeywordFilter() {
    filters.value = {
      ...filters.value,
      keyword: '',
    };
    socialListeningStore.enableTopLevelFilters();
  }

  function clearTempFilters() {
    temporaryFilters.value = {};
  }

  function setTempFilters(filtersObj) {
    temporaryFilters.value = { ...filtersObj };
  }

  async function getSentimentTrend() {
    try {
      sentimentTrendTimeSeries.value = null;
      pending.value.sentimentTrendTimeSeries = true;
      const result = await socialListeningStore.getSentimentTrendTimeSeries({
        searchFilters: formattedFilters.value,
        scale: scale.value,
      });
      sentimentTrendTimeSeries.value = getFormattedSentimentChartData(result, filters.value);
    } finally {
      pending.value.sentimentTrendTimeSeries = false;
    }
  }

  return {
    filters,
    temporaryFilters,
    searchFilters,
    drawerSerializedFilters,
    queryKeyword,
    removeKeywordFilter,
    setFilters,
    resetFilters,
    setKeywordFilter,
    clearTempFilters,
    setTempFilters,
    pending,
    getSentimentTrend,
    sentimentTrendTimeSeries,
    sourceCreated,
    getChannels,
    selectedChannels,
    formattedFilters,
    scale,
  };
});
