<template>
  <main class="insights" data-cy="data-insights">
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon :name="headerIcon" />
        <span class="insights-title">{{ headerTitle }}</span>
        <slot name="title"></slot>
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <div :class="['insights-list', { open: dropdownOpen }]">
      <div class="insights-wrap">
        <slot></slot>
      </div>
    </div>
    <div :class="['insights-list', { open: dropdownOpen }]">
      <slot name="custom-metrics-insights"></slot>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'InsightsBase',
  components: {
    Icon,
  },
  props: {
    headerIcon: { type: String, default: null },
    headerTitle: { type: String, default: 'Insights' },
  },
  data() {
    return {
      dropdownOpen: true,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    .insights-title {
      margin-right: var(--space-12);
    }

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-12);
        cursor: help;
      }
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .insights-list {
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.3s;
    padding-top: var(--space-8);

    .insights-wrap {
      margin-left: var(--space-32);
      padding-left: var(--space-4);
    }

    &.open {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }
}
</style>
