import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import dayjs from 'dayjs';
import { GRAPH_SCALES } from '@/models/dashboards/graph-scales.enum';
import { GRAPH_STATS } from '@/models/dashboards/graph-stats.enum';
import { METRICS, getMetricText } from '@/models/dashboards/metrics';
import { METRIC_FORMATS } from '@/models/dashboards/metrics.constants';
import { getChannelText, getChannelConfigProp } from '@/models/dashboards/channels.enum';
import { getChartColour } from '@/ux/colours';
import {
  REPORTS,
  REPORT_LABEL_TYPES,
  METRIC_TYPE_REPORT_KEYS,
} from '@/app/dashboards/utils/reports.enum';
import { TOTAL_METRIC_GROUPS } from '@/app/dashboards/constants';
import {
  extractBrandIdsFromReport,
  extractBrandTagObjectsFromReport,
  extractCompetitorTagsFromReport,
  uniqueSourceAccountIdsFromReport,
} from '@/app/dashboards/utils/tagging.utils';
import { getAllAvailableCompetitorIdsByChannel } from '@/app/dashboards/utils/competitive.utils';
import { getMetricDetails } from '@/utils/metrics';
import { xssEscapeHtml } from '@/utils/xss';
import { getAdsPlatformController } from '@/app/dashboards/utils/getAdsPlatformController';
/**
 * Returns a date object.
 * The date string MUST match the format argument.
 * @param {string} [value] - The date string
 * @param {string} [format] - The date format
 * */
export function reportParseDate(value, format = 'YYYY-MM-DD') {
  return dayjs(value, format);
}

export function getGraphReportDataToMap(data, brandId, channel, metric, aggregateBy) {
  if (aggregateBy === TOTAL_METRIC_GROUPS.CHANNEL) {
    return data?.[channel]?.metrics;
  }
  if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
    return data?.[brandId]?.metrics;
  }
  return data?.[brandId]?.[channel]?.[metric] || data?.[brandId]?.metrics?.[metric];
}

export function extractReportGraphDatasetData(
  data,
  brandId,
  channel,
  metric,
  dateFormat,
  aggregateBy = null,
) {
  const metricOpts = METRICS?.[channel]?.[metric];
  let emptyValue = null;
  if (metricOpts?.emptyValue === 0) {
    emptyValue = 0;
  } else if (!isNil(metricOpts?.emptyValue)) {
    emptyValue = String(metricOpts.emptyValue);
  }
  const roundValue = metricOpts?.formats?.NORMAL === METRIC_FORMATS.INTEGER;
  const dataToMap = getGraphReportDataToMap(data, brandId, channel, metric, aggregateBy);

  return map(dataToMap, (value, date) => {
    const formattedValue = roundValue ? Math.round(value) : value;
    return {
      x: reportParseDate(date, dateFormat).valueOf(),
      y: isObject(value) ? formattedValue.value : formattedValue ?? emptyValue,
    };
  }).sort((left, right) => left.x - right.x);
}

export function differenceInMonths(startDate, endDate) {
  const monthDiffs = dayjs(endDate).month() - dayjs(startDate).month() + 1;
  const yearDiffs = dayjs(endDate).year() - dayjs(startDate).year();

  return monthDiffs + yearDiffs * 12;
}
export function graphStatValues(startDate, endDate, graphScale, datasetData) {
  let totalPoints;
  if (graphScale === GRAPH_SCALES.DAILY.value) {
    totalPoints = dayjs(endDate).diff(dayjs(startDate), 'days') + 1;
  } else if (graphScale === GRAPH_SCALES.MONTHLY.value) {
    totalPoints = differenceInMonths(startDate, endDate);
  }
  return {
    [GRAPH_STATS.CURRENT.value]: datasetData?.[datasetData.length - 1]?.y,
    [GRAPH_STATS.SUM.value]:
      datasetData?.length > 0 ? sumBy(datasetData, (point) => point.y) : undefined,
    [GRAPH_STATS.MEAN.value]:
      datasetData.length > 0
        ? meanBy(
            filter(datasetData, (point) => point.y),
            (point) => point.y,
          )
        : undefined,
    [GRAPH_STATS.MEAN_BY_DAY.value]:
      datasetData.length > 0 ? sumBy(datasetData, (point) => point.y) / totalPoints : undefined,
  };
}

export function extractBarMetricChartDataByChannel(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;

  const channelNames = reportMeta?.channels?.map((channel) => {
    return {
      name: getChannelText(channel),
      id: channel,
      type: REPORT_LABEL_TYPES.BRAND,
    };
  });
  const channelColours = reportMeta?.channels?.map((channel) =>
    getChannelConfigProp(channel, 'chartColor'),
  );
  const firstMetricName = reportMeta?.metrics?.[0];
  const data = reportMeta?.channels?.map(
    (channel) => reportData?.[channel]?.metrics?.[firstMetricName]?.value,
  );
  const metricDetails = getMetricDetails(
    firstMetricName,
    reportMeta?.channels,
    METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  );
  return {
    labels: channelNames,
    datasets: [
      {
        label:
          metricDetails?.displayName ??
          getMetricText(reportMeta?.channels[0], firstMetricName, reportMeta?.channels),
        data,
        backgroundColor: channelColours,
      },
    ],
  };
}

export function extractPermittedAccountIdsFromReport(report) {
  const reportBrandIds = extractBrandIdsFromReport(report);
  return reportBrandIds.filter(
    (id) => report?.data[id]?.user_has_access && !report?.data[id]?.hidden_metrics,
  );
}

export function extractBarMetricChartDataByBrand(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const brandIds = extractPermittedAccountIdsFromReport(report);

  let brandData = brandIds?.map((brandId) => {
    return {
      name: reportData?.[brandId]?.name,
      type: REPORT_LABEL_TYPES.BRAND,
      id: brandId.toString(),
    };
  });

  const firstMetricName = reportMeta?.metrics?.[0] ?? reportMeta?.metric;
  let sourceAccountIds = uniqueSourceAccountIdsFromReport(report);
  const reportChannels = reportMeta?.channels || [].concat(reportMeta?.channel || []);
  const allAvailableCompetitorIdsByChannel = getAllAvailableCompetitorIdsByChannel(reportChannels);
  const allAvailableCompetitorIds = Object.values(allAvailableCompetitorIdsByChannel).flat();
  sourceAccountIds = sourceAccountIds.filter((sourceAccountId) =>
    allAvailableCompetitorIds.includes(sourceAccountId),
  );

  let data = brandIds?.map((brandId) => reportData?.[brandId]?.metrics?.[firstMetricName]?.value);

  if (sourceAccountIds) {
    const competitiveData = sourceAccountIds?.map(
      (competitorId) => reportData?.[competitorId]?.metrics?.[firstMetricName]?.value,
    );
    const competitorData = sourceAccountIds?.map((competitorId) => {
      return {
        name: reportData?.[competitorId]?.name,
        type: REPORT_LABEL_TYPES.COMPETITOR,
        id: competitorId,
      };
    });

    brandData = [...brandData, ...competitorData];
    data = [...data, ...competitiveData];
  }
  const brandChartColours = brandData.map((_, brandIndex) => getChartColour(brandIndex));

  const dhDataMeta = [...brandIds, ...sourceAccountIds]?.map((id) => {
    const dataType = reportData?.[id]?.data_type;
    const name = reportData?.[id]?.name;
    return {
      id,
      dataType,
      name,
    };
  });
  const metricDetails = getMetricDetails(
    firstMetricName,
    reportMeta?.channels,
    METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  );

  return {
    labels: brandData,
    datasets: [
      {
        label:
          metricDetails?.displayName ??
          getMetricText(reportMeta?.channels[0], firstMetricName, reportMeta?.channels),
        data,
        dhDataMeta,
        backgroundColor: brandChartColours,
      },
    ],
  };
}

export function extractBarMetricChartDataByTag(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const reportBrandTags = extractBrandTagObjectsFromReport(report);
  const competitorTags = extractCompetitorTagsFromReport(report);
  const tags = [...reportBrandTags, ...competitorTags];

  const tagData = tags?.map((tag) => {
    return {
      name: tag.name,
      id: tag.id,
      type: tag.dataType,
    };
  });
  const firstMetricName = reportMeta?.metrics?.[0] ?? reportMeta?.metric;
  const data = tags?.map(
    (tag) => reportData?.[`${tag.id}_${tag.dataType}`]?.metrics?.[firstMetricName]?.value,
  );
  const brandChartColours = tagData.map((brandTagName, brandIndex) => getChartColour(brandIndex));
  const dhDataMeta = tags?.map((tag) => {
    return {
      id: tag.id,
      dataType: tag.dataType,
      brandId: tag?.brand_id,
      competitors: tag?.competitors,
      brands: tag?.brands,
      name: tag.name,
    };
  });
  const metricDetails = getMetricDetails(
    firstMetricName,
    reportMeta?.channels,
    METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  );

  return {
    labels: tagData,
    datasets: [
      {
        label:
          metricDetails?.displayName ??
          getMetricText(reportMeta?.channels[0], firstMetricName, reportMeta?.channels),
        data,
        dhDataMeta,
        backgroundColor: brandChartColours,
      },
    ],
  };
}

/**
 * This function is currently not being used. In order for this to properly report data in the
 * future we'll need to structure the label channelNames to contain an array of objects containing
 * a name, id, and type.
 * @param {*} report
 * @returns {object}
 */
export function extractStackedBarMetricChartDataByChannel(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const brandIds = extractPermittedAccountIdsFromReport(report);

  const channelNames = reportMeta?.channels?.map((channel) => getChannelText(channel));
  const firstMetricName = reportMeta?.metrics?.[0];
  const brandDatasets = brandIds?.map((brandId, brandIndex) => {
    const brandName = reportData?.[brandId]?.name;
    const data = reportMeta?.channels?.map(
      (channel) => reportData?.[channel]?.metrics?.[firstMetricName]?.value,
    );
    return {
      label: brandName,
      data,
      backgroundColor: getChartColour(brandIndex),
    };
  });
  return {
    labels: channelNames,
    datasets: brandDatasets,
  };
}

export function extractStackedBarMetricChartData(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const brandIds = extractPermittedAccountIdsFromReport(report);

  const brandData = brandIds?.map((brandId) => {
    return {
      name: reportData?.[brandId]?.name,
      type: REPORT_LABEL_TYPES.BRAND,
      id: brandId.toString(),
    };
  });
  const channelDatasets =
    reportMeta?.channels?.map((channel) => {
      const data = brandIds?.map((brandId) => {
        const metricNameForChannel = reportMeta?.metrics?.find((metric) => {
          return !!reportData?.[brandId]?.metrics?.[metric]?.[channel]?.value;
        });
        return reportData?.[brandId]?.metrics?.[metricNameForChannel]?.[channel]?.value;
      });
      const backgroundColor = getChannelConfigProp(channel, 'chartColor');
      return {
        label: getChannelText(channel),
        data,
        backgroundColor,
      };
    }) ?? [];

  return {
    labels: brandData,
    datasets: channelDatasets,
  };
}

/**
 * This function is currently not being used. In order for this to properly report data in the
 * future we'll need to structure the label brandNames to contain an array of objects containing
 * a name, id, and type.
 * @param {*} report
 * @returns {object}
 */
export function extractStackedBarMetricChartDataByBrand(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const brandIds = extractPermittedAccountIdsFromReport(report);

  const brandNames = brandIds?.map((brandId) => {
    return reportData?.[brandId]?.name;
  });
  const firstMetricName = reportMeta?.metrics?.[0];
  const channelDatasets =
    reportMeta?.channels?.map((channel) => {
      const data = brandIds?.map(
        (brandId) => reportData?.[brandId]?.metrics?.[firstMetricName]?.value,
      );
      const backgroundColor = getChannelConfigProp(channel, 'chartColor');
      return {
        label: getChannelText(channel),
        data,
        backgroundColor,
      };
    }) ?? [];

  return {
    labels: brandNames,
    datasets: channelDatasets,
  };
}

/**
 * This function is currently not being used. In order for this to properly report data in the
 * future we'll need to structure the label dateLabels to contain an array of objects containing
 * a name, id, and type.
 * @param {*} report
 * @returns {object}
 */
export function extractStackedBarMetricTimeSeriesChartDataByChannel(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;

  const firstMetricName = reportMeta?.metrics?.[0];
  const firstChannel = reportMeta?.channels?.[0];
  const dateLabels = Object.keys(reportData?.[firstChannel]?.metrics?.[firstMetricName]);

  const channelDatasets =
    reportMeta?.channels?.map((channel) => {
      const data = dateLabels?.map(
        (dateLabel) => reportData?.[channel]?.metrics?.[firstMetricName]?.[dateLabel],
      );
      const backgroundColor = getChannelConfigProp(channel, 'chartColor');
      return {
        label: getChannelText(channel),
        data,
        backgroundColor,
      };
    }) ?? [];

  return {
    labels: dateLabels,
    datasets: channelDatasets,
  };
}

/**
 * This function is currently not being used. In order for this to properly report data in the
 * future we'll need to structure the label dateLabels to contain an array of objects containing
 * a name, id, and type.
 * @param {*} report
 * @returns {object}
 */
export function extractStackedBarMetricTimeSeriesChartDataByBrand(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const brandIds = extractPermittedAccountIdsFromReport(report);

  const firstBrandId = brandIds?.[0];
  const firstMetricName = reportMeta?.metrics?.[0];
  const dateLabels = Object.keys(reportData?.[firstBrandId]?.metrics?.[firstMetricName]);

  const brandDatasets =
    brandIds?.map((brandId, brandIndex) => {
      const brandName = reportData?.[brandId]?.name;
      const data = dateLabels?.map(
        (dateLabel) => reportData?.[brandId]?.metrics?.[firstMetricName]?.[dateLabel],
      );
      const backgroundColor = getChartColour(brandIndex);
      return {
        label: brandName,
        data,
        backgroundColor,
      };
    }) ?? [];

  return {
    labels: dateLabels,
    datasets: brandDatasets,
  };
}

/**
 * for each brand we have a number of campaigns, each brand is a separate column.
 * The campaigns should be stacked over eachother.
 * The campaigns for the all brands are used for the purposes of querying all the objects.
 */
export function extractAdsStackedBarMetricChartDataByBrand(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const reportChannel = reportMeta?.channels?.[0];
  const adsPlatformController = getAdsPlatformController(reportChannel);
  const brandIds = extractPermittedAccountIdsFromReport(report);
  const brandData = brandIds?.map((brandId) => {
    return {
      name: reportData?.[brandId]?.name,
      type: REPORT_LABEL_TYPES.BRAND,
      id: brandId.toString(),
    };
  });
  const metric = reportMeta?.metrics?.[0] ?? reportMeta?.metric;
  const campaigns = [
    ...new Set(
      Object.values(reportData)
        .map((data) => Object.keys(data?.metrics?.[metric] ?? {}))
        .flat(),
    ),
  ];

  // add the _values_ for the chosen _metric_ for each _brand_ to `data`
  const brandDatasets = campaigns.map((campaignId, campaignIndex) => {
    const data = brandIds.map((id) => reportData?.[id]?.metrics?.[metric]?.[campaignId]?.value);
    const label = adsPlatformController?.adCampaigns?.value[campaignId]?.name || campaignId;

    return {
      label,
      data,
      backgroundColor: getChartColour(campaignIndex),
    };
  });

  return {
    labels: brandData,
    datasets: brandDatasets,
  };
}

export function extractAdsStackedBarMetricChartDataBySegment(report) {
  const reportData = report?.data ?? {};
  const reportMeta = report?.meta;
  const reportMetric = reportMeta?.metrics?.[0] ?? reportMeta?.metric;

  const primaryBreakdownData = Object.values(reportData)
    .map((segmentData) => segmentData.metrics?.[reportMetric])
    .filter((data) => data);

  // Collect all possible values (and their formatted names) for the secondary breakdown
  const secondaryBreakdownNameMap = {};
  primaryBreakdownData.forEach((segmentData) => {
    Object.entries(segmentData).forEach(([segmentValue, data]) => {
      secondaryBreakdownNameMap[segmentValue] = data.name;
    });
  });

  const labels = Object.entries(reportData).map(([segmentValue, segmentData]) => {
    // type and id are used when sorting the data
    return {
      name: segmentData.name,
      type: segmentData.dataType,
      id: segmentValue,
    };
  });

  const datasets = Object.entries(secondaryBreakdownNameMap).map(
    ([segmentValue, segmentName], index) => {
      const data = Object.values(reportData).map(
        (primaryData) => primaryData.metrics?.[reportMetric]?.[segmentValue]?.value,
      );
      return {
        label: segmentName,
        data,
        backgroundColor: getChartColour(index),
      };
    },
  );

  return {
    labels,
    datasets,
  };
}

export function extractAdsBarMetricChartDataBySegment(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const reportMetric = reportMeta?.metrics?.[0] ?? reportMeta?.metric;

  const data = Object.values(reportData).map(
    (segmentData) => segmentData?.metrics?.[reportMetric]?.value,
  );

  const labels = Object.entries(reportData)?.map(([segmentValue, segmentData]) => {
    return {
      name: segmentData.name,
      type: segmentData.data_type,
      id: segmentValue.toString(),
    };
  });

  const metricDetails = getMetricDetails(
    reportMetric,
    reportMeta?.channels,
    METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  );

  const backgroundColor = labels.map((_, index) => getChartColour(index));

  return {
    labels,
    datasets: [
      {
        label:
          metricDetails?.displayName ??
          getMetricText(reportMeta?.channels[0], reportMetric, reportMeta?.channels),
        data,
        backgroundColor,
      },
    ],
  };
}

export function extractAdsBarMetricChartDataByAgeGender(report) {
  const reportMeta = report?.meta;
  const reportData = report?.data;
  const reportMetric = reportMeta?.metrics?.[0] ?? reportMeta?.metric;

  const genderData = {};
  const uniqAgeRanges = new Set();
  Object.values(reportData).forEach((value) => {
    const ageGenderName = value?.name;
    const subStrings = ageGenderName.split(' ');
    const ageRange = subStrings[subStrings.length - 1];
    const formattedGender = ageGenderName?.substring(0, ageGenderName?.lastIndexOf(' '));

    if (!genderData[formattedGender]) {
      genderData[formattedGender] = {
        label: formattedGender,
        data: {},
      };
    }

    genderData[formattedGender].data[ageRange] = value?.metrics[reportMetric]?.value;
    uniqAgeRanges.add(ageRange);
  });

  const uniqAgeRangesSorted = Array.from(uniqAgeRanges).sort((a, b) => {
    if (a === 'Unknown') return 1;
    if (b === 'Unknown') return -1;
    const startAgeA = +a.split(/[-+]/)[0];
    const startAgeB = +b.split(/[-+]/)[0];
    return startAgeA - startAgeB;
  });

  const labels = uniqAgeRangesSorted.map((name) => ({ name }));

  return {
    labels,
    datasets: Object.values(genderData).map((dataset, i) => {
      return {
        data: uniqAgeRangesSorted.map((ageRange) => dataset.data[ageRange]),
        label: dataset.label,
        backgroundColor: getChartColour(i),
      };
    }),
  };
}

const genderToSymbol = Object.freeze({
  MALE: 'Men',
  FEMALE: 'Women',
  UNKNOWN: 'Undefined',
  AUTOMATED_ADS: 'All (Automated App Ads)',
});
const genderOrder = Object.freeze({
  [genderToSymbol.MALE]: '0',
  [genderToSymbol.FEMALE]: '1',
  [genderToSymbol.UNKNOWN]: '2',
  [genderToSymbol.AUTOMATED_ADS]: '3',
});
function sortGendersForMetaAds(genderA, genderB) {
  const orderA = genderOrder[genderA[0]] ?? genderA[0];
  const orderB = genderOrder[genderB[0]] ?? genderB[0];
  return orderA.localeCompare(orderB, undefined, { numeric: true, sensitivity: 'base' });
}

function createLabelsAndGenderContainers(reportData, reportMetric) {
  let ageLabels = new Set();
  const metricByGender = {};
  Object.values(reportData ?? {}).forEach((data) => {
    const genderIndex = data.name.lastIndexOf(' ');
    const [gender, ageRange] = [
      xssEscapeHtml(data.name.substr(0, genderIndex)),
      xssEscapeHtml(data.name.substr(genderIndex).trim()),
    ];
    ageLabels.add(ageRange);
    Object.values(data?.metrics[reportMetric] ?? {}).forEach((metricValue) => {
      if (!metricByGender[metricValue.name]) metricByGender[metricValue.name] = {};
      if (!metricByGender[metricValue.name][gender]) metricByGender[metricValue.name][gender] = {};
      metricByGender[metricValue.name][gender][ageRange] = metricValue.value;
    });
  });
  ageLabels = [...ageLabels].sort();
  return { metricByGender, ageLabels };
}

function createDatasetsForStackedBarMetrics(ageLabels, metricByGender) {
  const datasets = [];
  Object.entries(metricByGender).forEach(([label, genderToData], labelIndex) => {
    const backgroundColor = getChartColour(labelIndex);
    Object.entries(genderToData)
      .sort(sortGendersForMetaAds)
      .forEach(([genderLabel, ageRangeToValue]) => {
        const data = new Array(ageLabels.length).fill(null);
        const tooltipTitles = [];
        Object.entries(ageRangeToValue).forEach(([rangeLabel, value]) => {
          const indexRange = ageLabels.indexOf(rangeLabel);
          data[indexRange] = value;
          tooltipTitles[indexRange] = `${genderLabel}, ${rangeLabel}`;
        });
        datasets.push({
          backgroundColor,
          label,
          stack: genderLabel,
          data,
          tooltipTitles,
        });
      });
  });
  return datasets;
}

export function extractAdsStackedBarMetricChartDataByAgeGender({
  data: reportData,
  meta: reportMeta,
}) {
  const reportMetric = reportMeta?.metrics?.[0] ?? reportMeta?.metric;
  const { ageLabels, metricByGender } = createLabelsAndGenderContainers(reportData, reportMetric);
  const datasets = createDatasetsForStackedBarMetrics(ageLabels, metricByGender);
  return {
    labels: ageLabels.map((label) => {
      return {
        name: label,
      };
    }),
    datasets,
  };
}

export function extractBarChartData(report) {
  const type = report?.type;
  const aggregateBy = report?.meta?.aggregate_by;

  if (type === REPORTS.BAR_METRIC.value) {
    if (aggregateBy === TOTAL_METRIC_GROUPS.CHANNEL) {
      return extractBarMetricChartDataByChannel(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
      return extractBarMetricChartDataByBrand(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.TAG) {
      return extractBarMetricChartDataByTag(report);
    }
  }

  if (type === REPORTS.STACKED_BAR_METRIC.value) {
    if (aggregateBy === TOTAL_METRIC_GROUPS.CHANNEL) {
      return extractStackedBarMetricChartDataByChannel(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
      return extractStackedBarMetricChartDataByBrand(report);
    }
    return extractStackedBarMetricChartData(report);
  }

  if (type === REPORTS.STACKED_BAR_METRIC_TIME_SERIES.value) {
    if (aggregateBy === TOTAL_METRIC_GROUPS.CHANNEL) {
      return extractStackedBarMetricTimeSeriesChartDataByChannel(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
      return extractStackedBarMetricTimeSeriesChartDataByBrand(report);
    }
  }

  if (
    type === REPORTS.META_ADS_STACKED_BAR_METRIC.value ||
    type === REPORTS.ADS_STACKED_BAR_METRIC.value
  ) {
    if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
      return extractAdsStackedBarMetricChartDataByBrand(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.AGE_GENDER) {
      return extractAdsStackedBarMetricChartDataByAgeGender(report);
    }
    return extractAdsStackedBarMetricChartDataBySegment(report);
  }

  if (type === REPORTS.COMPETITIVE_BAR_METRIC.value) {
    if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
      return extractBarMetricChartDataByBrand(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.TAG) {
      return extractBarMetricChartDataByTag(report);
    }
  }

  if (type === REPORTS.META_ADS_BAR_METRIC.value || type === REPORTS.ADS_BAR_METRIC.value) {
    if (aggregateBy === TOTAL_METRIC_GROUPS.BRAND) {
      return extractBarMetricChartDataByBrand(report);
    }
    if (aggregateBy === TOTAL_METRIC_GROUPS.AGE_GENDER) {
      return extractAdsBarMetricChartDataByAgeGender(report);
    }
    return extractAdsBarMetricChartDataBySegment(report);
  }

  return {
    labels: [],
    datasets: [],
  };
}
