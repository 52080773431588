import { COMMENT_RESOURCE_TYPES, LOGICAL_OPERATORS } from '@/config';
import { brandMediaTypes } from '@/app/library/constants';
import { colours } from '@/ux/colours';

export const ASSET_TYPE = {
  image: 'image',
  video: 'video',
};

export const COMMUNITY_ROUTE_NAMES = {
  INSIGHTS: 'community.insights',
  TOP_POSTS: 'community.insights.top-posts',
  MESSAGES: 'community.messages',
  INBOX: 'community.messages.inbox',
  ASSIGNED_TO_YOU: 'community.messages.you',
  ARCHIVE: 'community.messages.archive',
  ALL_MESSAGES: 'community.messages.all',
  INTERNAL_NOTES: 'community.internal-notes',
};

export const COMMUNITY_ROUTE_PRESET_FILTERS = {
  [COMMUNITY_ROUTE_NAMES.INSIGHTS]: {
    assigneeId: undefined,
    isArchived: undefined,
    mentionedUserIds: undefined,
  },
  [COMMUNITY_ROUTE_NAMES.INBOX]: {
    isArchived: false,
    mentionedUserIds: undefined,
  },
  [COMMUNITY_ROUTE_NAMES.ASSIGNED_TO_YOU]: {
    isArchived: false,
    mentionedUserIds: undefined,
  },
  [COMMUNITY_ROUTE_NAMES.ARCHIVE]: {
    isArchived: true,
    mentionedUserIds: undefined,
  },
  [COMMUNITY_ROUTE_NAMES.ALL_MESSAGES]: {
    isArchived: undefined,
    mentionedUserIds: undefined,
  },
  [COMMUNITY_ROUTE_NAMES.INTERNAL_NOTES]: {
    isArchived: undefined,
    assigneeId: undefined,
  },
};

export const COMMUNITY_SENTIMENT_ENABLED_PLATFORMS = {
  INSTAGRAM: 'Instagram',
  TIKTOK: 'TikTok',
  FACEBOOK: 'Facebook',
  TWITTER: 'X',
};

export const COMMUNITY_INTERACTION_PLATFORMS = {
  ...COMMUNITY_SENTIMENT_ENABLED_PLATFORMS,
  YOUTUBE: 'YouTube',
};

export const COMMUNITY_GENERIC_TYPES = {
  COMMENT: 'COMMENT',
  CONVERSATION: 'CONVERSATION',
};

export const COMMUNITY_INTERACTION_TYPES = {
  INSTAGRAM_COMMENT: 'INSTAGRAM_COMMENT',
  INSTAGRAM_CONVERSATION: 'INSTAGRAM_CONVERSATION',
  TIKTOK_COMMENT: 'TIKTOK_COMMENT',
  TIKTOK_COMMENT_MENTION: 'TIKTOK_COMMENT_MENTION',
  TIKTOK_CAPTION_MENTION: 'TIKTOK_CAPTION_MENTION',
  FACEBOOK_COMMENT: 'FACEBOOK_COMMENT',
  FACEBOOK_CONVERSATION: 'FACEBOOK_CONVERSATION',
  TWITTER_CONVERSATION: 'TWITTER_CONVERSATION',
  TWITTER_MENTION: 'TWITTER_MENTION',
  TWITTER_QUOTE: 'TWITTER_QUOTE',
  TWITTER_RETWEET: 'TWITTER_RETWEET',
  YOUTUBE_COMMENT: 'YOUTUBE_COMMENT',
};

export const COMMUNITY_INTERACTION_CHANNEL_FILTERS = {
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_DMS: 'INSTAGRAM_DMS',
  INSTAGRAM_COMMENTS: 'INSTAGRAM_COMMENTS',
  TIKTOK: 'TIKTOK',
  TIKTOK_COMMENTS: 'TIKTOK_COMMENTS',
  FACEBOOK: 'FACEBOOK',
  FACEBOOK_COMMENTS: 'FACEBOOK_COMMENTS',
  FACEBOOK_DMS: 'FACEBOOK_DMS',
  TWITTER: 'TWITTER',
  TWITTER_DMS: 'TWITTER_DMS',
  TWITTER_MENTIONS: 'TWITTER_MENTIONS',
  TWITTER_QUOTES: 'TWITTER_QUOTES',
  TWITTER_RETWEETS: 'TWITTER_RETWEETS',
  YOUTUBE: 'YOUTUBE',
  YOUTUBE_COMMENTS: 'YOUTUBE_COMMENTS',
};

export const COMMUNITY_INTERACTION_CHANNEL_FILTER_TYPES = {
  INSTAGRAM: [
    COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT,
    COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION,
  ],
  FACEBOOK: [
    COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT,
    COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION,
  ],
  TIKTOK: [
    COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT,
    COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT_MENTION,
    COMMUNITY_INTERACTION_TYPES.TIKTOK_CAPTION_MENTION,
  ],
  TWITTER: [
    COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION,
    COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION,
    COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE,
    COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET,
  ],
  YOUTUBE: [COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT],
};

export const COMMUNITY_ADS_INTERACTION_TYPES = {
  INSTAGRAM_ADS_COMMENT: 'INSTAGRAM_ADS_COMMENT',
  FACEBOOK_ADS_COMMENT: 'FACEBOOK_ADS_COMMENT',
};

export const COMMUNITY_INTERACTION_TYPES_WITH_ADS = {
  INSTAGRAM_COMMENT: 'INSTAGRAM_COMMENT',
  INSTAGRAM_CONVERSATION: 'INSTAGRAM_CONVERSATION',
  TIKTOK_COMMENT: 'TIKTOK_COMMENT',
  FACEBOOK_COMMENT: 'FACEBOOK_COMMENT',
  FACEBOOK_CONVERSATION: 'FACEBOOK_CONVERSATION',
  TWITTER_CONVERSATION: 'TWITTER_CONVERSATION',
  TWITTER_MENTION: 'TWITTER_MENTION',
  TWITTER_QUOTE: 'TWITTER_QUOTE',
  TWITTER_RETWEET: 'TWITTER_RETWEET',
  YOUTUBE_COMMENT: 'YOUTUBE_COMMENT',
  INSTAGRAM_ADS_COMMENT: 'INSTAGRAM_ADS_COMMENT',
  FACEBOOK_ADS_COMMENT: 'FACEBOOK_ADS_COMMENT',
};

export const COMMUNITY_AD_ENABLED_TYPES = [
  COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT,
  COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT,
];

export const COMMUNITY_INTERACTION_ACTIONS = {
  ADD_TAGS: 'ADD_TAGS',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  UNREAD: 'UNREAD',
  READ: 'READ',
  MARK_AS_FOLLOW_UP: 'MARK_AS_FOLLOW_UP',
  REMOVE_FOLLOW_UP: 'REMOVE_FOLLOW_UP',
  DELETE: 'DELETE',
  POSTED: 'POSTED',
  HIDE: 'HIDE',
  ASSIGN: 'ASSIGN',
  UNASSIGN: 'UNASSIGN',
};

export const COMMUNITY_INTERACTION_ADDITIONAL_HIDE_INFO = {
  YOUTUBE_COMMENT: 'It may take a few minutes for all messages to be removed from your inbox.',
};

export const COMMUNITY_INTERACTION_ACTIONS_ERROR_TOASTS = {
  POSTED:
    'Reply failed. Please try again or check if the original comment has been deleted from the post.',
  LIKE: 'Failed to like comment. Please try again.',
  HIDE: 'Failed to hide comment. Please try again.',
  UNHIDE: 'Failed to unhide comment. Please try again.',
  PIN: 'Failed to pin comment. Please try again.',
  UNPIN: 'Failed to unpin comment. Please try again.',
  RETWEET: 'Repost failed to publish. Please try again.',
  UNDO_RETWEET: 'Repost failed to remove from your timeline. Please try again.',
  HIDE_USER: 'Failed to hide user. Please try again.',
};

export const COMMUNITY_INTERACTION_ACTION_UPDATES = {
  [COMMUNITY_INTERACTION_ACTIONS.UNREAD]: { isRead: false },
  [COMMUNITY_INTERACTION_ACTIONS.READ]: { isRead: true },
  [COMMUNITY_INTERACTION_ACTIONS.ARCHIVE]: { isArchived: true },
  [COMMUNITY_INTERACTION_ACTIONS.UNARCHIVE]: { isArchived: false },
  [COMMUNITY_INTERACTION_ACTIONS.MARK_AS_FOLLOW_UP]: { isFollowUp: true },
  [COMMUNITY_INTERACTION_ACTIONS.REMOVE_FOLLOW_UP]: { isFollowUp: false },
};

export const COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATIONS = {
  LIST: 'LIST',
  PANEL: 'PANEL',
  MULTI_SELECT: 'MULTI_SELECT',
  COMMENT_POPUP: 'COMMENT_POPUP',
};

export const COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATION_LABELS = {
  [COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATIONS.LIST]: 'Community Interaction List',
  [COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATIONS.PANEL]: 'Community Interaction Panel',
  [COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATIONS.MULTI_SELECT]: 'Multi Select Toolbar',
  [COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATIONS.COMMENT_POPUP]:
    'Community Interaction Comment Popup',
};

export const COMMUNITY_INTERACTION_ERROR_TOAST = 'Something went wrong, please try again.';

export const COMMUNITY_INTERACTION_ACTION_LABELS = {
  ADD_TAGS: 'Add tags',
  ARCHIVE: 'Move to Archive',
  ASSIGN: 'Assign Message',
  UNARCHIVE: 'Remove from Archive',
  UNREAD: 'Mark as Unread',
  READ: 'Mark as Read',
  REASSIGN: 'Reassign Message',
  MARK_AS_FOLLOW_UP: 'Mark as Follow Up',
  REMOVE_FOLLOW_UP: 'Remove Follow Up',
  DELETE: 'Delete',
};

export const COMMUNITY_INTERACTION_ACTION_BY_PAYLOAD = {
  is_read: {
    true: COMMUNITY_INTERACTION_ACTION_LABELS[COMMUNITY_INTERACTION_ACTIONS.READ],
    false: COMMUNITY_INTERACTION_ACTION_LABELS[COMMUNITY_INTERACTION_ACTIONS.UNREAD],
  },
  is_archived: {
    true: COMMUNITY_INTERACTION_ACTION_LABELS[COMMUNITY_INTERACTION_ACTIONS.ARCHIVE],
    false: COMMUNITY_INTERACTION_ACTION_LABELS[COMMUNITY_INTERACTION_ACTIONS.UNARCHIVE],
  },
  is_follow_up: {
    true: COMMUNITY_INTERACTION_ACTION_LABELS[
      COMMUNITY_INTERACTION_ACTION_LABELS.MARK_AS_FOLLOW_UP
    ],
    false:
      COMMUNITY_INTERACTION_ACTION_LABELS[COMMUNITY_INTERACTION_ACTION_LABELS.REMOVE_FOLLOW_UP],
  },
};

export const COMMUNITY_INTERACTION_ERROR_STATES = {
  EXPIRED: 'EXPIRED', // the community interaction hasn't been updated in 7 days and can't be replied to
  DELETED: 'DELETED', // the community interaction was deleted by the brand and can't be replied to
  RECIPIENT_ID_INVALID: 'RECIPIENT_ID_INVALID', // follower was deleted or blocked the brand
  SEND_API_ACCESS_DISABLED: 'SEND_API_ACCESS_DISABLED', // brand was banned from using send API
};

export const COMMUNITY_INTERACTION_ERROR_STATE_BANNERS = {
  INSTAGRAM_CONVERSATION: {
    EXPIRED: {
      text: 'Instagram does not allow replying to direct messages received more than 7 days ago on third-party platforms.',
      icon: 'alertCircle',
      color: colours.TEXT.TEXT_SECONDARY,
      backgroundColor: colours.BACKGROUND.BACKGROUND_300,
    },
    DELETED: {
      text: 'This conversation has been deleted from your Instagram inbox. New messages cannot be sent by third-party platforms until the conversation has been initiated within the Instagram app.',
      icon: 'alertCircle',
      color: colours.TEXT.TEXT_SECONDARY,
      backgroundColor: colours.BACKGROUND.BACKGROUND_300,
    },
    RECIPIENT_ID_INVALID: {
      text: 'User not found. They may have disabled their account or blocked this account from messaging them.',
      icon: 'alert-triangle',
      color: colours.ERROR.ERROR_500,
      backgroundColor: colours.ERROR.ERROR_500Bg,
    },
    SEND_API_ACCESS_DISABLED: {
      text: 'Your account has been blocked from sending messages via the Messaging API. Please contact your Facebook representative for more information.',
      icon: 'alert-triangle',
      color: colours.ERROR.ERROR_500,
      backgroundColor: colours.ERROR.ERROR_500Bg,
    },
  },
  FACEBOOK_CONVERSATION: {
    EXPIRED: {
      text: 'Facebook does not allow replying to direct messages received more than 7 days ago on third-party platforms.',
      icon: 'alertCircle',
      color: colours.TEXT.TEXT_SECONDARY,
      backgroundColor: colours.BACKGROUND.BACKGROUND_300,
    },
    DELETED: {
      text: 'This conversation has been deleted from your Facebook inbox. New messages cannot be sent by third-party platforms until the conversation has been initiated within the Facebook messenger.',
      icon: 'alertCircle',
      color: colours.TEXT.TEXT_SECONDARY,
      backgroundColor: colours.BACKGROUND.BACKGROUND_300,
    },
    RECIPIENT_ID_INVALID: {
      text: 'User not found. They may have disabled their account or blocked this account from messaging them.',
      icon: 'alert-triangle',
      color: colours.ERROR.ERROR_500,
      backgroundColor: colours.ERROR.ERROR_500Bg,
    },
    SEND_API_ACCESS_DISABLED: {
      text: 'Your account has been blocked from sending messages via the Messaging API. Please contact your Facebook representative for more information.',
      icon: 'alert-triangle',
      color: colours.ERROR.ERROR_500,
      backgroundColor: colours.ERROR.ERROR_500Bg,
    },
  },
  TWITTER_CONVERSATION: {},
};

export const MESSAGE_REACTION_ERROR_MESSAGES = {
  MESSAGE_REQUEST:
    'Reaction failed: This is a message request. To accept the request, you must send a message to the user first.',
  OTHER: 'Reaction failed. This message could not be liked at this time.',
};

export const UNSUPPORTED_MESSAGE_TEXT = 'Unsupported message';

export const API_REPLY_LIMITATION_MESSAGE =
  'We are only able to show your replies to this comment due to API limitations.';

export const SHOW_HISTORICAL_IMPORT_BANNER_FOR_HOURS = 24;

export const ERROR_MESSAGES = {
  EMPTY_MESSAGE: 'Selected items will be shown here.',
  TEAMS_EMPTY_ERROR_MESSAGE: "Messages you've received will be shown here",
};

export const COMMUNITY_INTERACTION_FLAGS = ['isRead', 'isFollowUp', 'isArchived'];

export const PERMISSIONS_COPY = {
  USER_PERMISSION_MISSING: "Looks like you don't have permission to use this feature!",
  USER_CALL_TO_ACTION:
    'Dash Hudson brings you all of your essential social media solutions, including community management! Ask your Dash Hudson administrator to provide you access.',
  BRAND_PERMISSION_MISSING: 'Looks Like This Feature Isn’t Included In Your Plan!',
  BRAND_CALL_TO_ACTION:
    'Dash Hudson brings you all of the insights you love, including community management! If this feature isn’t in your package, contact your Customer Success Representative to try it out!',
};

export const CONNECTION_INSTRUCTIONS = {
  ENABLE_IG_MESSAGES_COPY:
    'The “Allow Access to Messages” setting allows you to manage and reply to your Instagram Direct Messages within Dash Hudson. Enable it from within the Instagram app > Settings > Privacy > Messages > Allow Access to Messages, or visit the link below for more information.',
  ENABLE_IG_MESSAGES_LINK: 'https://www.facebook.com/help/instagram/296884305019942',
  ENABLE_TWITTER_MESSAGES_LINK: 'https://twitter.com/settings/direct_messages',
  CONNECT_COPY:
    'To view and manage your Instagram Direct Messages you are required to authorize access within Dash Hudson.',
};

export const COMMUNITY_INTERACTION_ICON_NAMES = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
};

export const WEBSOCKET_UPDATE_ACTIONS = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

export const UNREAD_COMMUNITY_INTERACTIONS_CAP = 10000;
export const UNREAD_COMMUNITY_MESSAGES_CAP = 1000;

export const INBOX_SORT_BY = {
  SRC_UPDATED_AT_ASC: 'SOURCE_UPDATED_AT',
  SRC_UPDATED_AT_DESC: '-SOURCE_UPDATED_AT',
  RELEVANCE: 'RELEVANCE',
};

export const INTERNAL_NOTE_SORT_BY = {
  [INBOX_SORT_BY.SRC_UPDATED_AT_ASC]: 'CREATED_AT',
  [INBOX_SORT_BY.SRC_UPDATED_AT_DESC]: '-CREATED_AT',
};

export const IMPORT_BANNER_TOOLTIP = `Your history is being imported. This can take up to ${SHOW_HISTORICAL_IMPORT_BANNER_FOR_HOURS} hours to complete, depending on the number of messages in your inbox. Messages received more than 7 days ago will be imported to your archive folder.`;

export const removeCommunityTagConfirmMessage =
  'This action will also remove the tag from all related messages. Are you sure you want to delete it?';

export const TAG_ACTION_LABELS = {
  DELETE: 'Delete Tag',
};

export const DEFAULT_COMMUNITY_INTERACTION_SORT = INBOX_SORT_BY.SRC_UPDATED_AT_DESC;

export const COMMENT_DELETED_TOAST =
  'This comment was deleted. Refresh your browser to update your inbox.';

export const INSTAGRAM_MESSAGES_DISCONNECTED_TOOLTIP = 'Instagram DMs not connected';

export const INSTAGRAM_COMMENTS_DISCONNECTED_TOOLTIP = 'Instagram Page not connected';

export const TIKTOK_COMMENTS_DISCONNECTED_TOOLTIP = 'TikTok comments not connected';

export const FACEBOOK_MESSAGES_DISCONNECTED_TOOLTIP = 'Facebook DMs not connected';

export const FACEBOOK_COMMENTS_DISCONNECTED_TOOLTIP = 'Facebook Page not connected';

export const TWITTER_DISCONNECTED_TOOLTIP = 'X not connected';

export const YOUTUBE_COMMENTS_DISCONNECTED_TOOLTIP = 'YouTube not connected';

export const META_ADS_TOOLTIP = {
  INSIGHTS_CONNECTED: 'Filter for insights on Instagram and Facebook ads',
  INSIGHTS_DISCONNECTED: 'Get community insights on Instagram and Facebook ads',
  MESSAGE_CONNECTED: 'Filter for comments on Instagram and Facebook ads',
  MESSAGE_DISCONNECTED: 'Engage with comments on Instagram and Facebook ads',
};

export const MIXPANEL_MESSAGE_TYPES = {
  POST_COMMENT: 'Post Comment',
  DIRECT_MESSAGE: 'Direct Message',
  TIKTOK_MENTION: 'TikTok Mention',
};

export const COMMUNITY_INTERACTION_PLATFORM_BY_TYPE = {
  INSTAGRAM_CONVERSATION: {
    platform: 'Instagram',
    messageType: MIXPANEL_MESSAGE_TYPES.DIRECT_MESSAGE,
  },
  INSTAGRAM_COMMENT: {
    platform: 'Instagram',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
  TIKTOK_COMMENT: {
    platform: 'TikTok',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
  TIKTOK_COMMENT_MENTION: {
    platform: 'TikTok',
    messageType: MIXPANEL_MESSAGE_TYPES.TIKTOK_MENTION,
  },
  TIKTOK_CAPTION_MENTION: {
    platform: 'TikTok',
    messageType: MIXPANEL_MESSAGE_TYPES.TIKTOK_MENTION,
  },
  FACEBOOK_CONVERSATION: {
    platform: 'Facebook',
    messageType: MIXPANEL_MESSAGE_TYPES.DIRECT_MESSAGE,
  },
  FACEBOOK_COMMENT: {
    platform: 'Facebook',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
  TWITTER_MENTION: {
    platform: 'Twitter',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
  TWITTER_RETWEET: {
    platform: 'Twitter',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
  TWITTER_QUOTE: {
    platform: 'Twitter',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
  TWITTER_CONVERSATION: {
    platform: 'Twitter',
    messageType: MIXPANEL_MESSAGE_TYPES.DIRECT_MESSAGE,
  },
  YOUTUBE_COMMENT: {
    platform: 'YouTube',
    messageType: MIXPANEL_MESSAGE_TYPES.POST_COMMENT,
  },
};

export const COMMUNITY_INTERACTIONS_PAGE_SIZE = 30;

export const FILTER_TYPE = {
  SEARCH: 'Search',
  SORT: 'Sort',
  TAGS: 'Tags',
  NAVIGATION: 'Navigation',
  FOLLOW_UP: 'Follow Up',
  MAILBOX: 'Mailbox',
  POST: 'Post',
  QUESTION: 'Question',
  SENTIMENT: 'Sentiment',
  UNREAD: 'Unread',
  MESSAGE_DATE: 'Message Date',
  PLATFORM: 'Platform',
  ARCHIVED: 'Archived',
  MESSAGE_TYPE: 'Message Type',
  MENTIONED: 'Mentioned',
  ASSIGNEE: 'Assignee',
  MESSAGE_RULE: 'Message Rule',
  RESPONSE_RECOMMENDED: 'Response Recommended',
};

export const FILTER_ACTIONS = {
  ADDED: 'Added',
  REMOVED: 'Removed',
};

export const SORT_OPTIONS = {
  NEW_TO_OLD: 'Newest to Oldest',
  OLD_TO_NEW: 'Oldest to Newest',
};

export const MAILBOX_OPTIONS = {
  INBOX: 'Inbox',
  ARCHIVE: 'Archive',
};

export const SEARCH_TYPE = {
  KEYWORD: 'Keyword',
  USER: 'User',
};

export const COMMUNITY_POPUP_MESSAGES = {
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT]:
    'You must have a Business TikTok account to access your Community activity in Dash Hudson.',
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT]:
    'To connect an account you must have Admin access to your Facebook business page.',
};

export const TIKTOK_COMMENT_ACTIONS = {
  LIKE: 'LIKE',
  UNLIKE: 'UNLIKE',
  HIDE: 'HIDE',
  UNHIDE: 'UNHIDE',
  PIN: 'PIN',
  UNPIN: 'UNPIN',
};

export const THREADED_COMMENT_ACTIONS = {
  LIKE: 'LIKE',
  UNLIKE: 'UNLIKE',
  HIDE: 'HIDE',
  UNHIDE: 'UNHIDE',
  PIN: 'PIN',
  UNPIN: 'UNPIN',
  RETWEET: 'RETWEET',
  REMOVE_RETWEET: 'REMOVE_RETWEET',
};

export const TIKTOK_UPDATE_PAYLOAD_BY_ACTION = {
  [TIKTOK_COMMENT_ACTIONS.LIKE]: { is_liked: true },
  [TIKTOK_COMMENT_ACTIONS.UNLIKE]: { is_liked: false },
  [TIKTOK_COMMENT_ACTIONS.HIDE]: { is_hidden: true },
  [TIKTOK_COMMENT_ACTIONS.UNHIDE]: { is_hidden: false },
  [TIKTOK_COMMENT_ACTIONS.PIN]: { is_pinned: true },
  [TIKTOK_COMMENT_ACTIONS.UNPIN]: { is_pinned: false },
};

export const THREADED_COMMENT_UPDATE_PAYLOAD_BY_ACTION = {
  [THREADED_COMMENT_ACTIONS.LIKE]: { is_liked: true },
  [THREADED_COMMENT_ACTIONS.UNLIKE]: { is_liked: false },
  [THREADED_COMMENT_ACTIONS.HIDE]: { is_hidden: true },
  [THREADED_COMMENT_ACTIONS.UNHIDE]: { is_hidden: false },
  [THREADED_COMMENT_ACTIONS.PIN]: { is_pinned: true },
  [THREADED_COMMENT_ACTIONS.UNPIN]: { is_pinned: false },
  [THREADED_COMMENT_ACTIONS.RETWEET]: { is_retweeted: true },
  [THREADED_COMMENT_ACTIONS.REMOVE_RETWEET]: { is_retweeted: false },
};

export const TIKTOK_ACTION_BY_PAYLOAD = {
  is_liked: {
    true: TIKTOK_COMMENT_ACTIONS.LIKE,
    false: TIKTOK_COMMENT_ACTIONS.UNLIKE,
  },
  is_hidden: {
    true: TIKTOK_COMMENT_ACTIONS.HIDE,
    false: TIKTOK_COMMENT_ACTIONS.UNHIDE,
  },
  is_pinned: {
    true: TIKTOK_COMMENT_ACTIONS.PIN,
    false: TIKTOK_COMMENT_ACTIONS.UNPIN,
  },
};

export const THREADED_COMMENT_ACTION_BY_PAYLOAD = {
  is_liked: {
    true: THREADED_COMMENT_ACTIONS.LIKE,
    false: THREADED_COMMENT_ACTIONS.UNLIKE,
  },
  is_hidden: {
    true: THREADED_COMMENT_ACTIONS.HIDE,
    false: THREADED_COMMENT_ACTIONS.UNHIDE,
  },
  is_pinned: {
    true: THREADED_COMMENT_ACTIONS.PIN,
    false: THREADED_COMMENT_ACTIONS.UNPIN,
  },
  is_retweeted: {
    true: THREADED_COMMENT_ACTIONS.RETWEET,
    false: THREADED_COMMENT_ACTIONS.REMOVE_RETWEET,
  },
};

export const COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT = {
  INSTAGRAM: 1000,
  TIKTOK: 150,
  FACEBOOK: 2000,
  TWITTER: 280,
  TWITTER_DM: 10000,
  YOUTUBE: 10000,
};

// reserve 2 spaces for @ and the white space in `@handle `.
const RESERVED_SPACES = 2;

const COMMUNITY_INTERACTION_HANDLE_LIMIT = {
  INSTAGRAM: 30,
};

const instagramReplyLengthLimit =
  COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.INSTAGRAM -
  COMMUNITY_INTERACTION_HANDLE_LIMIT.INSTAGRAM -
  RESERVED_SPACES;

export const COMMUNITY_INTERACTION_PRESET_REPLY = {
  INSTAGRAM_COMMENT: {
    platform: 'INSTAGRAM',
    lengthLimit: instagramReplyLengthLimit,
  },
  INSTAGRAM_CONVERSATION: {
    platform: 'INSTAGRAM',
    lengthLimit: instagramReplyLengthLimit,
  },
  TIKTOK_COMMENT: {
    platform: 'TIKTOK',
    // we don't send the comment together with the TikTok handle for now.
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TIKTOK,
  },
  TIKTOK_COMMENT_MENTION: {
    platform: 'TIKTOK',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TIKTOK,
  },
  TIKTOK_CAPTION_MENTION: {
    platform: 'TIKTOK',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TIKTOK,
  },
  FACEBOOK_COMMENT: {
    platform: 'FACEBOOK',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.FACEBOOK,
  },
  FACEBOOK_CONVERSATION: {
    platform: 'FACEBOOK',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.FACEBOOK,
  },
  TWITTER_MENTION: {
    platform: 'TWITTER',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TWITTER,
  },
  TWITTER_RETWEET: {
    platform: 'TWITTER',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TWITTER,
  },
  TWITTER_QUOTE: {
    platform: 'TWITTER',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TWITTER,
  },
  TWITTER_CONVERSATION: {
    platform: 'TWITTER',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.TWITTER,
  },
  YOUTUBE_COMMENT: {
    platform: 'YOUTUBE',
    lengthLimit: COMMUNITY_INTERACTION_REPLY_LENGTH_LIMIT.YOUTUBE,
  },
};

export const COMMUNITY_POST_LEVEL_UPDATES_MAP = {
  [COMMUNITY_INTERACTION_ACTIONS.ARCHIVE]: {
    CONFIRM_TITLE: 'Archive all comments?',
    CONFIRM_MESSAGE: 'All comments on this post will be archived',
    CONFIRM_ALIAS: 'Archive All',
  },
  [COMMUNITY_INTERACTION_ACTIONS.UNARCHIVE]: {
    CONFIRM_TITLE: 'Remove all comments from archive?',
    CONFIRM_MESSAGE: 'All comments on this post will be moved to the inbox',
    CONFIRM_ALIAS: 'Remove From Archive',
  },
  [COMMUNITY_INTERACTION_ACTIONS.READ]: {
    CONFIRM_TITLE: 'Mark all comments as read?',
    CONFIRM_MESSAGE: 'All comments on this post will be marked as read',
    CONFIRM_ALIAS: 'Mark All As Read',
  },
};

export const TWITTER_POST_LEVEL_UPDATES_MAP = {
  [COMMUNITY_INTERACTION_ACTIONS.ARCHIVE]: {
    CONFIRM_TITLE: 'Archive all replies?',
    CONFIRM_MESSAGE: 'All replies to this post will be archived',
    CONFIRM_ALIAS: 'Archive All',
  },
  [COMMUNITY_INTERACTION_ACTIONS.UNARCHIVE]: {
    CONFIRM_TITLE: 'Remove all replies from archive?',
    CONFIRM_MESSAGE: 'All replies to this post will be moved to the inbox',
    CONFIRM_ALIAS: 'Remove From Archive',
  },
  [COMMUNITY_INTERACTION_ACTIONS.READ]: {
    CONFIRM_TITLE: 'Mark all replies as read?',
    CONFIRM_MESSAGE: 'All replies to this post will be marked as read',
    CONFIRM_ALIAS: 'Mark All As Read',
  },
};

export const REPORT_TYPE = {
  KEYWORD: 'Keyword',
  SENTIMENT: 'Sentiment',
  CHANNEL: 'Channel',
  VOLUME_OVER_TIME: 'Volume Over Time',
};

export const REPORT_ACTIONS = {
  LABEL_CLICKED: 'Label Clicked',
  LABEL_HOVER: 'Label Hover',
  DONUT_CHART_CLICKED: 'Donut Chart Clicked',
  KEYWORD_CLICKED: 'Keyword Clicked',
  KEYWORD_CHECKBOX_UPDATED: 'Keyword Checkbox Updated',
  SCALE_UPDATED: 'Scale Updated',
  GROUP_BY_UPDATED: 'Group By Updated',
};

export const FACEBOOK_MEDIA_ATTACHMENT_PLACEHOLDER = 'with media';

export const COMMUNITY_SUPPORTED_MEDIA_TYPES = {
  IMAGE: 'image',
  PHOTO: 'photo',
  VIDEO: 'video',
};

export const COMMENT_ACTIONS = {
  HIDE: 'HIDE',
  UNHIDE: 'UNHIDE',
};

export const UPDATE_PAYLOAD_BY_ACTION = {
  [COMMENT_ACTIONS.HIDE]: { is_hidden: true },
  [COMMENT_ACTIONS.UNHIDE]: { is_hidden: false },
};

export const ACTION_BY_PAYLOAD = {
  is_hidden: {
    true: COMMENT_ACTIONS.HIDE,
    false: COMMENT_ACTIONS.UNHIDE,
  },
};

export const PLATFORM_CONNECTIONS = {
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION]: {
    DEV: 'facebook_sandbox',
    PROD: 'facebook',
  },
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT]: {
    DEV: 'facebook_sandbox',
    PROD: 'facebook',
  },
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT]: {
    DEV: 'tiktok',
    PROD: 'tiktok',
  },
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION]: {
    DEV: 'facebook_analytics',
    PROD: 'facebook_analytics',
  },
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT]: {
    DEV: 'facebook_analytics',
    PROD: 'facebook_analytics',
  },
  [COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT]: {
    DEV: 'youtube',
    PROD: 'youtube',
  },
};

export const COMMUNITY_INTERACTION_MEDIA_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  REELS: 'REELS',
  CAROUSEL: 'CAROUSEL_ALBUM',
  SHORTS: 'SHORTS',
};

export const COMMUNITY_INTERACTION_ICONS_FOR_MEDIA_TYPE = {
  [COMMUNITY_INTERACTION_MEDIA_TYPES.REELS]: 'reels',
  [COMMUNITY_INTERACTION_MEDIA_TYPES.CAROUSEL]: 'multipleMedia',
  [COMMUNITY_INTERACTION_MEDIA_TYPES.VIDEO]: 'video',
  [COMMUNITY_INTERACTION_MEDIA_TYPES.SHORTS]: 'shorts',
};

export const TIKTOK_API_LIMIT_BANNER_MESSAGE =
  "TikTok currently doesn't support @mentioning from third-party publishers. Any @mentions in replies will appear as plain text.";

export const COMMUNITY_INTERACTION_FILTERS = {
  FILTER_POST: 'community_post',
  FILTER_TAGS: 'community_tags',
  FILTER_QUESTIONS: 'community_questions',
  FILTER_SENTIMENT: 'community_sentiment',
  FILTER_PLATFORM: 'community_platform',
  FILTER_FOLLOWUP: 'community_followup',
  FILTER_UNREAD: 'community_unread',
  FILTER_ARCHIVE: 'community_archive',
  FILTER_MESSAGE_TYPE: 'community_message_type',
  FILTER_MENTIONED: 'community_mentioned',
  FILTER_ASSIGNEE: 'community_assignee',
  FILTER_MESSAGE_RULE: 'community_message_rule',
  FILTER_RESPONSE_RECOMMENDED: 'community_response_recommended',
};

export const COMMUNITY_INTERACTION_FILTER_OPTIONS = [
  { label: 'Mentioned', value: COMMUNITY_INTERACTION_FILTERS.FILTER_MENTIONED },
  { label: 'Assignee', value: COMMUNITY_INTERACTION_FILTERS.FILTER_ASSIGNEE },
  { label: 'Follow Up', value: COMMUNITY_INTERACTION_FILTERS.FILTER_FOLLOWUP },
  { label: 'Message Rule', value: COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_RULE },
  { label: 'Message Type', value: COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_TYPE },
  { label: 'Post', value: COMMUNITY_INTERACTION_FILTERS.FILTER_POST },
  {
    label: 'Question',
    value: COMMUNITY_INTERACTION_FILTERS.FILTER_QUESTIONS,
    permission: 'sentimentAnalysis',
  },
  {
    label: 'Sentiment',
    permission: 'sentimentAnalysis',
    value: COMMUNITY_INTERACTION_FILTERS.FILTER_SENTIMENT,
  },
  { label: 'Tags', value: COMMUNITY_INTERACTION_FILTERS.FILTER_TAGS },
  { label: 'Unread', value: COMMUNITY_INTERACTION_FILTERS.FILTER_UNREAD },
  {
    label: 'Response Recommended',
    value: COMMUNITY_INTERACTION_FILTERS.FILTER_RESPONSE_RECOMMENDED,
  },
];

export const internalNoteFilterOptions = [
  COMMUNITY_INTERACTION_FILTERS.FILTER_MENTIONED,
  COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_TYPE,
  COMMUNITY_INTERACTION_FILTERS.FILTER_UNREAD,
];

export const COMMUNITY_NO_WIDGET_FILTER_OPTIONS = {
  [COMMUNITY_INTERACTION_FILTERS.FILTER_FOLLOWUP]: {
    label: 'Follow Up',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_FOLLOWUP,
    query: 'isFollowUp',
    active: true,
    inactive: undefined,
    value: undefined,
    type: FILTER_TYPE.FOLLOW_UP,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_UNREAD]: {
    label: 'Unread',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_UNREAD,
    query: 'isRead',
    active: false,
    inactive: undefined,
    value: undefined,
    type: FILTER_TYPE.UNREAD,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_ARCHIVE]: {
    label: 'Includes Archive',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_ARCHIVE,
    query: 'isArchived',
    active: undefined,
    inactive: false,
    value: undefined,
    type: FILTER_TYPE.ARCHIVED,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_RESPONSE_RECOMMENDED]: {
    label: 'Response Recommended',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_RESPONSE_RECOMMENDED,
    query: 'responseRecommended',
    active: true,
    inactive: undefined,
    value: undefined,
    type: FILTER_TYPE.RESPONSE_RECOMMENDED,
  },
};

export const COMMUNITY_INTERACTION_FORMATTED_FILTER_OPTIONS = {
  [COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_RULE]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_RULE,
    operator: 'is',
    value: null,
    emptyStates: true,
    type: FILTER_TYPE.MESSAGE_RULE,
    props: {
      disableOpenOnMountIfValueNull: true,
      disabled: false,
    },
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_ASSIGNEE]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_ASSIGNEE,
    operator: 'is',
    value: null,
    emptyStates: true,
    type: FILTER_TYPE.ASSIGNEE,
    props: {
      disableOpenOnMountIfValueNull: true,
      disabled: false,
    },
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_POST]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_POST,
    operator: 'contain',
    value: null,
    type: FILTER_TYPE.POST,
    props: {
      disableOutsideClick: true,
    },
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_QUESTIONS]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_QUESTIONS,
    permission: 'sentimentAnalysis',
    operator: 'contain',
    value: undefined,
    type: FILTER_TYPE.QUESTION,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_SENTIMENT]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_SENTIMENT,
    permission: 'sentimentAnalysis',
    operator: 'is',
    value: null,
    emptyStates: true,
    type: FILTER_TYPE.SENTIMENT,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_TAGS]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_TAGS,
    operator: 'contain',
    tagsFilterType: 'AND',
    value: [],
    type: FILTER_TYPE.TAGS,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_PLATFORM]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_PLATFORM,
    imgFilter: true,
    emptyOperator: true,
    valueToIconMap: COMMUNITY_INTERACTION_ICON_NAMES,
    value: null,
    emptyStates: false,
    type: FILTER_TYPE.PLATFORM,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_TYPE]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_TYPE,
    operator: 'is',
    value: null,
    emptyStates: true,
    type: FILTER_TYPE.MESSAGE_TYPE,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_MENTIONED]: {
    label: '',
    field: COMMUNITY_INTERACTION_FILTERS.FILTER_MENTIONED,
    operator: 'is',
    value: null,
    emptyStates: true,
    type: FILTER_TYPE.MENTIONED,
    props: {
      disableOpenOnMountIfValueNull: true,
      disabled: false,
    },
  },
};

export const COMMUNITY_INTERACTION_POST_FILTER_OPTIONS = {
  ALL: {
    label: 'All Channels',
    value: null,
  },
  FACEBOOK: {
    label: 'Facebook',
    value: 'FACEBOOK_OWNED',
  },
  INSTAGRAM: {
    label: 'Instagram',
    value: 'INSTAGRAM_OWNED',
  },
  TIKTOK: {
    label: 'TikTok',
    value: 'TIKTOK_OWNED',
  },
  TWITTER: {
    label: 'X',
    value: 'TWITTER_OWNED',
  },
  YOUTUBE: {
    label: 'YouTube',
    value: 'YOUTUBE_OWNED',
  },
};

export const SENTIMENT_COLORS = [
  colours.SUCCESS.SUCCESS_500,
  colours.ERROR.ERROR_500,
  colours.ACTION.ACTION_500,
];

export const SENTIMENT_LABEL_BY_TYPE = {
  positivePercentage: {
    title: 'Positive',
    iconName: 'dot',
    iconColor: colours.SUCCESS.SUCCESS_500,
  },
  neutralPercentage: {
    title: 'Neutral',
    iconName: 'dot',
    iconColor: colours.ACTION.ACTION_500,
  },
  negativePercentage: {
    title: 'Negative',
    iconName: 'dot',
    iconColor: colours.ERROR.ERROR_500,
  },
};

export const COMMUNITY_SENTIMENT_FILTER_WIDGET = {
  POSITIVE: { ...SENTIMENT_LABEL_BY_TYPE.positivePercentage, value: 'POSITIVE' },
  NEUTRAL: { ...SENTIMENT_LABEL_BY_TYPE.neutralPercentage, value: 'NEUTRAL' },
  NEGATIVE: { ...SENTIMENT_LABEL_BY_TYPE.negativePercentage, value: 'NEGATIVE' },
};

export const COMMUNITY_PLATFORM_FILTER_WIDGET = {
  // sort channels alphabetically
  FACEBOOK: {
    title: 'Facebook',
    iconName: 'facebook',
    value: 'FACEBOOK',
  },
  INSTAGRAM: {
    title: 'Instagram',
    iconName: 'instagram',
    value: 'INSTAGRAM',
  },
  TIKTOK: {
    title: 'TikTok',
    iconName: 'tiktok',
    value: 'TIKTOK',
  },
  TWITTER: {
    title: 'X',
    iconName: 'twitter',
    value: 'TWITTER',
  },
  YOUTUBE: {
    title: 'YouTube',
    iconName: 'youtube',
    value: 'YOUTUBE',
  },
};

export const COMMUNITY_PLATFORM_FILTER_DEFAULT = Object.values(
  COMMUNITY_PLATFORM_FILTER_WIDGET,
).map((platform) => platform.value);

export const communitySentimentTypesEnabled = () => {
  // exclude YouTube comment due to compliance issue
  const twitterCommentTypes = ['TWITTER_QUOTE', 'TWITTER_MENTION'];
  return Object.values(COMMUNITY_INTERACTION_TYPES_WITH_ADS).filter(
    (type) =>
      (type.endsWith('_COMMENT') && !type.startsWith('YOUTUBE')) ||
      twitterCommentTypes.includes(type),
  );
};

export const communitySentimentTypes = () => {
  const twitterCommentTypes = ['TWITTER_QUOTE', 'TWITTER_MENTION'];
  return Object.values(COMMUNITY_INTERACTION_TYPES_WITH_ADS).filter(
    (type) => type.endsWith('_COMMENT') || twitterCommentTypes.includes(type),
  );
};

export const commentMessageTypes = (includeTwitterTypes) => {
  const twitterCommentTypes = ['TWITTER_QUOTE', 'TWITTER_MENTION', 'TWITTER_RETWEET'];
  return Object.values(COMMUNITY_INTERACTION_TYPES).filter(
    (type) =>
      type.endsWith('_COMMENT') || (includeTwitterTypes && twitterCommentTypes.includes(type)),
  );
};

export const conversationMessageTypes = () => {
  return Object.values(COMMUNITY_INTERACTION_TYPES).filter((type) =>
    type.endsWith('_CONVERSATION'),
  );
};

export const adCommentMessageTypes = () => {
  return Object.values(COMMUNITY_ADS_INTERACTION_TYPES);
};

export const mentionMessageTypes = () => {
  return Object.values(COMMUNITY_INTERACTION_TYPES).filter((type) => type.endsWith('_MENTION'));
};

export const COMMUNITY_MESSAGE_TYPE = {
  ADS_COMMENT: 'ADS_COMMENT',
  COMMENT: 'COMMENT',
  CONVERSATION: 'CONVERSATION',
  MENTION: 'MENTION',
  QUOTE: 'QUOTE',
  RETWEET: 'RETWEET',
};

export const COMMUNITY_MESSAGE_TYPE_FILTER_WIDGET = {
  [COMMUNITY_MESSAGE_TYPE.ADS_COMMENT]: {
    label: 'Ad Comments',
    value: 'ADS_COMMENT',
    formattedLabel: 'Ad Comments',
    includes: adCommentMessageTypes(),
  },
  [COMMUNITY_MESSAGE_TYPE.COMMENT]: {
    label: 'Comments',
    value: 'COMMENT',
    includes: commentMessageTypes(false),
  },
  [COMMUNITY_MESSAGE_TYPE.CONVERSATION]: {
    label: 'Direct Messages',
    formattedLabel: 'DMs',
    value: 'CONVERSATION',
    includes: conversationMessageTypes(),
  },
  [COMMUNITY_MESSAGE_TYPE.MENTION]: {
    label: 'Mentions',
    value: 'MENTION',
    includes: mentionMessageTypes(),
  },
  [COMMUNITY_MESSAGE_TYPE.QUOTE]: {
    label: 'Quote Posts',
    value: 'QUOTE',
    formattedLabel: 'Quote Posts',
    includes: [COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE],
  },
  [COMMUNITY_MESSAGE_TYPE.RETWEET]: {
    label: 'Reposts',
    value: 'RETWEET',
    includes: [COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET],
  },
};

export const COMMUNITY_MESSAGE_TYPE_FILTER_DEFAULT = Object.values(
  COMMUNITY_MESSAGE_TYPE_FILTER_WIDGET,
).map((mType) => mType.value);

export const MAX_RECENT_BRAND_USERS = 4;

export const COMMUNITY_QUESTION_FILTER_WIDGET = {
  true: { label: 'Displayed', value: 'true' },
  false: { label: 'Excluded', value: 'false' },
};

export const COMMUNITY_INTERACTION_FILTER_WIDGETS = Object.freeze({
  [COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_RULE]: {
    name: 'MediaFilterRadioWidget',
    label: 'Message Rule',
    noDuplicate: true,
    options: {
      filterOptions: [],
      noDataText: 'No Message Rules Found',
      isScrollable: true,
    },
    disableDelete: false,
    type: FILTER_TYPE.MESSAGE_RULE,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_ASSIGNEE]: {
    name: 'MediaFilterUserSearchableSelectWidget',
    label: 'Assignee',
    noDuplicate: true,
    options: {
      filterOptions: [],
      // max number including self option + unassigned option + line
      maxInitialItemNumber: MAX_RECENT_BRAND_USERS + 3,
    },
    disableDelete: false,
    type: FILTER_TYPE.ASSIGNEE,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_TAGS]: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
      tagsFilterTypeOptions: [
        {
          value: LOGICAL_OPERATORS.AND,
          label: 'AND',
        },
      ],
      defaultTagsFilterType: LOGICAL_OPERATORS.AND,
      placeholderText: 'Search',
      iconName: 'closeCircleSolid',
      prependInnerIcon: 'search',
      prependInnerIconSize: 'xsmall',
      round: true,
      tall: true,
    },
    disableDelete: false,
    type: FILTER_TYPE.TAGS,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_POST]: {
    name: 'MediaFilterSelectMediaWidget',
    label: 'Post',
    noDuplicate: true,
    options: {
      source: COMMUNITY_INTERACTION_POST_FILTER_OPTIONS.INSTAGRAM.value,
      sourceOptions: [
        COMMUNITY_INTERACTION_POST_FILTER_OPTIONS.INSTAGRAM,
        COMMUNITY_INTERACTION_POST_FILTER_OPTIONS.TIKTOK,
        COMMUNITY_INTERACTION_POST_FILTER_OPTIONS.FACEBOOK,
        COMMUNITY_INTERACTION_POST_FILTER_OPTIONS.TWITTER,
        COMMUNITY_INTERACTION_POST_FILTER_OPTIONS.YOUTUBE,
      ],
      sourceSelectDisabled: false,
    },
    disableDelete: false,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_QUESTIONS]: {
    name: 'MediaFilterRadioWidget',
    label: 'Questions',
    noDuplicate: true,
    options: {
      filterOptions: Object.values(COMMUNITY_QUESTION_FILTER_WIDGET),
    },
    disableDelete: false,
    type: FILTER_TYPE.QUESTION,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_PLATFORM]: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Platform',
    noDuplicate: true,
    options: {
      noDataText: 'No accessible platform',
      filterOptions: Object.values(COMMUNITY_PLATFORM_FILTER_WIDGET).map((platform) => {
        return {
          label: platform.title,
          formattedLabel: platform.title,
          icon: {
            channelIcon: true,
            name: platform.iconName,
          },
          value: platform.value,
        };
      }),
    },
    disableDelete: false,
    type: FILTER_TYPE.PLATFORM,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_SENTIMENT]: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Sentiment',
    noDuplicate: true,
    options: {
      filterOptions: Object.values(COMMUNITY_SENTIMENT_FILTER_WIDGET).map((sentiment) => {
        return {
          label: sentiment.title,
          icon: {
            name: sentiment.iconName,
            color: sentiment.iconColor,
          },
          value: sentiment.value,
        };
      }),
    },
    disableDelete: false,
    type: FILTER_TYPE.SENTIMENT,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_TYPE]: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Message Type',
    noDuplicate: true,
    options: {
      optionStyles: 'min-width: 216px;',
      filterOptions: Object.values(COMMUNITY_MESSAGE_TYPE_FILTER_WIDGET),
    },
    disableDelete: false,
    type: FILTER_TYPE.MESSAGE_TYPE,
  },
  [COMMUNITY_INTERACTION_FILTERS.FILTER_MENTIONED]: {
    name: 'MediaFilterUserSearchableSelectWidget',
    label: 'Mentioned',
    noDuplicate: true,
    options: {
      maxInitialItemNumber: MAX_RECENT_BRAND_USERS + 2,
      filterOptions: [],
    },
    type: FILTER_TYPE.MENTIONED,
  },
});

export const twitterOnlyTypes = [COMMUNITY_MESSAGE_TYPE.QUOTE, COMMUNITY_MESSAGE_TYPE.RETWEET];

export const twitterReconnectThreshold = '2022-07-06T00:00:00+00:00';

export const TWITTER_TWEET_TYPES = [
  COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET,
  COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE,
  COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION,
];

export const retweetActionConstants = (isRetweet) => {
  return {
    confirmAlias: isRetweet ? 'Repost' : 'Undo',
    confirmMessage: isRetweet ? 'Repost this Post?' : 'Undo Repost?',
    successMessage: isRetweet ? 'Repost Published' : 'Repost removed from your timeline',
    errorMessage: isRetweet
      ? COMMUNITY_INTERACTION_ACTIONS_ERROR_TOASTS.RETWEET
      : COMMUNITY_INTERACTION_ACTIONS_ERROR_TOASTS.UNDO_RETWEET,
  };
};

export const SENTIMENT_CHART_CONFIG = {
  positive: {
    text: 'Positive',
    color: colours.SUCCESS.SUCCESS_500,
  },
  neutral: {
    text: 'Neutral',
    color: colours.ACTION.ACTION_500,
  },
  negative: {
    text: 'Negative',
    color: colours.ERROR.ERROR_500,
  },
};

export const COMMUNITY_OVERVIEW_CHART_TOOLTIPS = {
  SENTIMENT_OVERVIEW:
    'The total sentiment breakdown of comments in your inbox during the selected date range',
  TOP_KEYWORDS:
    'The top keywords used in the comments in your inbox during the selected date range',
  CHANNEL_DISTRIBUTION:
    'The channel and sentiment breakdown of comments in your inbox during the selected date range',
  TOP_POSTS:
    'Your posts that received the highest amount of engagements during the selected date range',
  TIMESERIES_SCALE_DISABLED: 'This option is not available as the data set is too large.',
};

export const COMMUNITY_OVERVIEW_ERROR_MESSAGES = {
  NO_DATA_FOUND: 'No data found matching your search',
  FAILED_TO_LOAD: 'Data failed to load. Refresh the page to try again.',
};

export const VOLUME_CHART_INFO = Object.freeze({
  TIKTOK: {
    value: 'TIKTOK',
    text: 'TikTok',
    channelIcon: 'tiktok',
    tooltip: 'Tik Tok',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_TIKTOK,
  },
  INSTAGRAM: {
    value: 'INSTAGRAM',
    text: 'Instagram',
    channelIcon: 'instagram',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_INSTAGRAM,
  },
  FACEBOOK: {
    value: 'FACEBOOK',
    text: 'Facebook',
    channelIcon: 'facebook',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_FACEBOOK,
  },
  TWITTER: {
    value: 'TWITTER',
    text: 'X',
    channelIcon: 'twitter',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_TWITTER,
  },
  YOUTUBE: {
    value: 'YOUTUBE',
    text: 'YouTube',
    channelIcon: 'youtube',
    tooltip: 'YouTube',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_YOUTUBE,
  },
  POSITIVE: {
    value: 'POSITIVE',
    text: 'Positive',
    tooltip: 'Positive Sentiment',
    chartColor: colours.SUCCESS.SUCCESS_500,
  },
  NEGATIVE: {
    value: 'NEGATIVE',
    text: 'Negative',
    tooltip: 'Negative Sentiment',
    chartColor: colours.ERROR.ERROR_500,
  },
  NEUTRAL: {
    value: 'NEUTRAL',
    text: 'Neutral',
    tooltip: 'Neutral Sentiment',
    chartColor: colours.ACTION.ACTION_500,
  },
  TOTAL_POSTS: {
    value: 'TOTAL_POSTS',
    text: 'Total Posts',
    tooltip: 'Total Posts',
    chartColor: colours.ACTION.ACTION_500,
  },
});

export const VOLUME_CHART_DATA_MAP = {
  instagramComment: VOLUME_CHART_INFO.INSTAGRAM,
  instagramMessage: VOLUME_CHART_INFO.INSTAGRAM,
  twitterRetweet: VOLUME_CHART_INFO.TWITTER,
  twitterQuote: VOLUME_CHART_INFO.TWITTER,
  twitterMention: VOLUME_CHART_INFO.TWITTER,
  twitterMessage: VOLUME_CHART_INFO.TWITTER,
  facebookComment: VOLUME_CHART_INFO.FACEBOOK,
  facebookMessage: VOLUME_CHART_INFO.FACEBOOK,
  tiktokComment: VOLUME_CHART_INFO.TIKTOK,
  youtubeComment: VOLUME_CHART_INFO.YOUTUBE,
};

export const VOLUME_CHART_TYPES = {
  CHANNEL: 'CHANNEL',
  SENTIMENT: 'SENTIMENT',
  TOTAL_POSTS: 'TOTAL_POSTS',
};

export const COMMUNITY_OVERVIEW_TOP_POSTS_MEDIA_TYPE_MAP = {
  INSTAGRAM: [brandMediaTypes.INSTAGRAM_OWNED],
  FACEBOOK: [
    brandMediaTypes.FACEBOOK_OWNED,
    brandMediaTypes.FACEBOOK_TEXT,
    brandMediaTypes.FACEBOOK_LINK,
    brandMediaTypes.FACEBOOK_TEXT_LINK,
  ],
  TIKTOK: [brandMediaTypes.TIKTOK_OWNED],
  TWITTER: [
    brandMediaTypes.TWITTER_OWNED,
    brandMediaTypes.TWITTER_OWNED_TEXT,
    brandMediaTypes.TWITTER_OWNED_LINK,
  ],
  YOUTUBE: [brandMediaTypes.YOUTUBE_OWNED],
};

export const TIMESERIES_SCALE_TYPES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const TIMESERIES_MAX_BUCKETS = 29;

export const UNREAD_QUERY_FILTER_KEYS = [
  'query',
  'sourceUpdatedAfter',
  'sourceUpdatedBefore',
  'mediaIds',
  'tagIds',
  'isQuestion',
  'sentiments',
  'isFollowUp',
  'isArchived',
  'ticketId',
  'assigneeId',
  'types',
  'interactionRuleId',
  'responseRecommended',
];

export const UNREAD_COUNTS_FILTER_KEYS = [
  ...UNREAD_QUERY_FILTER_KEYS,
  'platforms',
  'messageTypes',
  'mentionedUserIds',
];

export const PLATFORM_CREATED_AT = ['twitterCreatedAt', 'instagramCreatedAt', 'facebookCreatedAt'];

export const COMMUNITY_COMMENT_RESOURCE_TYPES = {
  [COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE]: 'COMMUNITY_INTERNAL_NOTE',
  [COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT]: 'COMMUNITY_ASSIGNMENT',
  [COMMENT_RESOURCE_TYPES.COMMUNITY_RULE_EXECUTED]: 'COMMUNITY_RULE_EXECUTED',
};

export const COMMENT_RESOURCE_LABEL_BY_TYPE = {
  [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT]: 'Assignment',
  [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE]: 'Internal Note',
  [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_RULE_EXECUTED]: 'Rule Executed',
};

export const THUMBNAIL_VARIANTS = { SQUARE: 'SQUARE', CIRCLE: 'CIRCLE', PORTRAIT: 'PORTRAIT' };

export const INTERACTION_CARD_HEADER_TEXT = {
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION]: 'DM',
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION]: 'Mention',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE]: 'Quote Post',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET]: 'Repost',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION]: 'DM',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION]: 'DM',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT_MENTION]: 'Mention',
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_CAPTION_MENTION]: 'Mention',
  [COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT]: 'Comment',
};

export const COMMUNITY_ASSIGNMENT_COMMENT_TEXT = {
  UNASSIGNED: '[UNASSIGNED]',
};

export const COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES = {
  [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT]: 'assigned you a message',
  [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE]: 'mentioned you in an internal note',
};

export const COMMUNITY_DIRECT_LINK_TOOLTIPS = {
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION]:
    'See this direct message on Instagram. If this account is private, you may need to open Instagram in a new browser window to view it.',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION]: 'See this reply on X',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE]: 'See this reply on X',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET]: 'See this reply on X',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION]: 'See this direct message on X',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT]: 'See this comment on Facebook',
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT]: 'See this comment on TikTok',
  [COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT]: 'See this comment on YouTube',
  [COMMUNITY_ADS_INTERACTION_TYPES.FACEBOOK_ADS_COMMENT]:
    'See this comment on Facebook. If an ad is inactive only users with access to the connected ad account will be able to see the preview.',
};

export const COMMUNITY_DIRECT_LINK_URL_PREFIX = {
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION]: 'https://ig.me/m/',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION]: 'https://twitter.com/',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE]: 'https://twitter.com/',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET]: 'https://twitter.com/',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION]: 'https://twitter.com/messages/',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT]: 'https://facebook.com/',
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT]:
    'https://www.tiktok.com/business-suite/comments?comment_id=',
};

export const FILTER_MATCH_COUNT_ERROR_MESSAGE =
  'There was an issue retrieving the match count. Please try again later';

export const COMMUNITY_INTERACTION_ARCHIVE_ERROR_TOAST =
  'There was an issue archiving your message. Please try again.';

export const INTERACTION_UPDATE_SENTIMENT_TEXT = {
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION]: 'DM',
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION]: 'Mention',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE]: 'Quote Post',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET]: 'Repost',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION]: 'DM',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION]: 'DM',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT]: 'Comment',
  [COMMUNITY_INTERACTION_TYPES_WITH_ADS.FACEBOOK_ADS_COMMENT]: 'Ad Comment',
  [COMMUNITY_INTERACTION_TYPES_WITH_ADS.INSTAGRAM_ADS_COMMENT]: 'Ad Comment',
};

export const COMMUNITY_SENTIMENT_UPDATE_TOASTS = {
  SUCCESS: 'sentiment has been modified',
  ERROR: 'There was an issue updating sentiment. Please try again.',
};

export const COMMUNITY_TRANSLATION_ERROR_TOAST =
  'There was an issue translating this message. Please try again.';

export const COMMUNITY_TRANSLATION_INCORRECT_LANGUAGE_TOAST =
  'Replies can only be translated from English';

export const DEFAULT_DETECTED_LANGAUGE = 'en';

export const replyTranslationConfirmation = (detected, preferred) => {
  if (detected && preferred) {
    return {
      title: 'Reply without translation?',
      confirmMessage: `This comment was written in ${detected}. Do you want to translate your response to this language?`,
      confirmAlias: 'Send Reply',
      cancelAlias: 'Edit Reply',
    };
  }
  return {};
};

export const COMMUNITY_BLOCK_TOAST = {
  ERROR: {
    DEFAULT: 'Failed to block user. Please try again.',
    RECENTLY_BLOCKED: `Failed to block user. You'll need to wait 48 hours to try again because the user was recently unblocked.`,
  },
  SUCCESS: 'User blocked. It may take a few minutes for all messages to be labeled as blocked.',
};

export const COMMUNITY_UNBLOCK_TOAST = {
  ERROR: 'Failed to unblock user. Please try again.',
  SUCCESS: 'User unblocked. It may take a few minutes for all messages to be labeled as unblocked.',
};

export const blockConfirmationPopup = (toBlocked) => {
  if (toBlocked) {
    return {
      title: 'Block this user?',
      confirmMessage: `This will hide their comments and disallow them from seeing your posts, tagging, inviting or messaging your Page in the future.`,
      confirmAlias: `Block`,
      cancelAlias: `Cancel`,
    };
  }
  return {
    title: 'Unblock this user?',
    confirmMessage: `This will allow them to see and engage with your Facebook Page.`,
    confirmAlias: `Unblock`,
    cancelAlias: `Cancel`,
  };
};

export const COMMUNITY_VISION_PROMPTS = {
  [COMMUNITY_GENERIC_TYPES.COMMENT]: 'Summarize Comments',
  [COMMUNITY_GENERIC_TYPES.CONVERSATION]: 'Summarize DMs',
};

export const COMMUNITY_LOADED_SOURCE = {
  VISION_AI: 'VISION_AI',
};

export const COMMUNITY_INTERACTION_RULES_SAVE_AND_APPLY_TO_EXISTING_POPUP = {
  title: 'Save and Apply to Existing Messages',
  confirmMessage:
    'This will start running the automation and apply the rule to the existing [NUM] messages that match the defined criteria.',
  confirmAlias: 'Continue',
  cancelAlias: 'Cancel',
};

export const COMMUNITY_INTERACTION_RULES_DELETE_POPUP = {
  title: 'Delete Message Rule',
  confirmMessage:
    'Are you sure you want to delete this message rule for all future messages? This will not affect any messages that have already been automated.',
  confirmAlias: 'Delete',
  cancelAlias: 'Cancel',
};

export const COMMUNITY_INTERACTION_RULES_UNDO_ACTION_POPUP = {
  title: 'Undo Message Rule Action',
  confirmMessage:
    'This will revert the message to its original state in the Inbox, including removing any automated tags that may have been applied. Are you sure you want to proceed?',
  confirmAlias: 'Undo',
};

export const COMMUNITY_INTERACTION_RULE_ACTIONS = {
  DELETE: {
    iconName: 'bin',
    tooltip: 'Delete rule',
    id: 'delete',
  },
  EDIT: {
    iconName: 'pencil',
    tooltip: 'Edit rule',
    id: 'edit',
  },
};

export const MESSAGE_RULE_BUILDER_VALIDATION_ERROR_MESSAGES = {
  ruleName: {
    required: 'This field is required',
    minLength: 'The rule name must contain at least 3 characters',
    nameExists: 'A rule with this name already exists',
  },
};

export const COMMUNITY_ENGAGEABLE_MESSAGES = {
  RESPONSE_RECOMMENDED_TOOLTIP: 'Response recommended',
};
