<script setup>
import { defineAsyncComponent, watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Drawer, useDrawer, useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import { isScreenXl } from '@/utils/tailwind';
import { SOCIAL_LISTENING_DRAWERS } from '@/app/socialListening/constants';
import { useGlobalModals } from '@/composables/useGlobalModals';

const route = useRoute();
const router = useRouter();

const { registerDrawer, stateKey, launchDrawer, closeDrawer, currentDrawer } = useDrawer();

// Centralized drawer registration
registerDrawer(
  'creatorDeliverablesPosts',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorDeliverablesPosts.vue')),
);
registerDrawer(
  'creatorProfile',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorProfile.vue')),
);
registerDrawer(
  'accountProfile',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/AccountProfile.vue')),
);
registerDrawer(
  'assignDeliverables',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Deliverables/AssignDeliverables.vue')),
);
registerDrawer(
  'topPerformingCreators',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/TopPerformingCreators.vue')),
);
registerDrawer(
  'addCreator',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Creators/AddCreatorsWizard.vue')),
);
registerDrawer(
  'creatorEmailThread',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorEmailThread.vue')),
);
registerDrawer(
  'creatorDeliverablesDateRangeEditor',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Deliverables/DateRangeEditor.vue')),
);
registerDrawer(
  'emailInvitation',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/EmailInvitation.vue')),
);

registerDrawer(
  'media',
  defineAsyncComponent(() => import('@/app/library/components/MediaPopup.vue')),
);
registerDrawer(
  'publicMedia',
  defineAsyncComponent(() => import('@/components/common/MediaPopupV2/PublicMediaPopup.vue')),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TOPIC_CREATOR,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopicCreator.vue'),
  ),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TRENDING_KEYWORD,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTrendingKeywords.vue'),
  ),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TOP_POST,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopPerformingPosts.vue'),
  ),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.VISUAL_TRENDS,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualTrends.vue'),
  ),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.VISUAL_TREND,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualTrend.vue'),
  ),
);
registerDrawer(
  'upgradePlan',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/UpgradePlan.vue')),
);

registerDrawer(
  'campaignCreation',
  defineAsyncComponent(() => import('@/app/campaigns/pages/CampaignCreationDrawer.vue')),
);
registerDrawer(
  'socialListeningTopWebResults',
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopWebResults.vue'),
  ),
);

registerDrawer(
  'selectMedia',
  defineAsyncComponent(() => import('@/components/core/SelectMediaPopup.vue')),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TOPIC_ALERT,
  defineAsyncComponent(() => import('@/app/socialListening/drawers/SocialListeningTopicAlert.vue')),
);

registerDrawer(
  'startTrial',
  defineAsyncComponent(() => import('@/components/InAppTrials/StartTrialPopup.vue')),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.VISUAL_FILTERS,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualFilters.vue'),
  ),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.MEDIA_POST,
  defineAsyncComponent(() => import('@/app/socialListening/drawers/SocialListeningMediaPost.vue')),
);

// Watch for stateKey changes and update the route query
watch(
  () => stateKey.value,
  (sk) => {
    const url = new URL(window.location.href);
    if (sk) {
      url.searchParams.set('d', sk);
    } else {
      url.searchParams.delete('d');
    }
    window.history.replaceState({}, '', url.toString());
  },
);

onMounted(() => {
  if (route.query?.d) {
    launchDrawer({ ...route.query, stateKey: route.query.d });
  } else {
    closeDrawer();
  }
});

const { expanded, collapseNavigationPanel } = useSecondaryNavigationPanel();

watch(
  () => [isScreenXl.value, currentDrawer.value],
  () => {
    if (!isScreenXl.value && expanded.value && currentDrawer.value) {
      collapseNavigationPanel();
    }
  },
);

const { hasGlobalModal } = useGlobalModals();
watch(
  () => hasGlobalModal.value,
  () => {
    closeDrawer();
  },
);

router.afterEach((to, from) => {
  if (to?.path !== from?.path) {
    closeDrawer();
  }
});

const { visible: filterVisible } = useFilterSidePanel();

const drawerMaxWidth = computed(() => {
  let usedSpace = '(var(--primary-navigation-width) + 40px)';
  if (filterVisible.value) {
    usedSpace = 'var(--filter-side-panel-width)';
  } else if (expanded.value) {
    if (isScreenXl.value) {
      usedSpace = '(var(--combined-navigation-width) + 40px)';
    }
  }
  return `calc(100% - ${usedSpace})`;
});
</script>

<template>
  <Drawer
    :keep-alive="{
      exclude: ['MediaPopup', 'CreatorDeliverablesPosts', 'CreatorProfile', 'AccountProfile'],
    }"
    :style="{
      maxWidth: drawerMaxWidth,
    }"
  />
</template>
