import { CALENDAR_MODE } from '@/components/core/calendar/constants';

export const defaultFilterValues = (optionMap) => {
  return Object.values(optionMap).map((o) => o.value);
};

export const CHANNEL_FILTER_OPTIONS = Object.freeze({
  INSTAGRAM: { label: 'instagram', value: 'INSTAGRAM', icon: 'instagram-color' },
  INSTAGRAM_STORY: {
    label: 'instagramStory',
    value: 'INSTAGRAM_STORY',
    icon: 'instagram-story-color',
  },
  TIKTOK: { label: 'tiktok', value: 'TIKTOK', icon: 'tiktok-color' },
  FACEBOOK: { label: 'facebook', value: 'FACEBOOK', icon: 'facebook-color' },
  PINTEREST: { label: 'pinterest', value: 'PINTEREST', icon: 'pinterest-color' },
  TWITTER: { label: 'twitter', value: 'TWITTER', icon: 'twitter-color' },
  LINKEDIN: { label: 'linkedin', value: 'LINKEDIN', icon: 'linkedin-color' },
});

export const POST_TYPE_FILTER_OPTIONS = Object.freeze({
  ASSIGNED_TO_YOU: { label: 'Assigned to You', value: 'ASSIGNED_TO_YOU' },
  UNASSIGNED: { label: 'Unassigned', value: 'UNASSIGNED' },
  APPROVED: { label: 'Approved', value: 'APPROVED' },
  PENDING: { label: 'Pending Approval', value: 'PENDING' },
  DRAFTS: { label: 'Drafts', value: 'DRAFTS' },
});

export const CALENDAR_VIEW_OPTIONS = Object.freeze({
  WEEK: { label: 'Week', value: CALENDAR_MODE.WEEK },
  MONTH: { label: 'Month', value: CALENDAR_MODE.MONTH },
});

export const SCHEDULER_SCOPED_FILTERS = {
  SCHEDULER: {
    scope: 'scheduler',
    options: {
      channels: defaultFilterValues(CHANNEL_FILTER_OPTIONS),
      postTypes: Object.values(POST_TYPE_FILTER_OPTIONS),
      calendarView: Object.values(CALENDAR_VIEW_OPTIONS),
    },
    default: {
      channels: [],
      postTypes: [],
      calendarView: CALENDAR_VIEW_OPTIONS.WEEK.value,
      showDrafts: true,
    },
  },
};

export const SCHEDULER_DEFAULT_FILTERS = Object.freeze({
  channels: defaultFilterValues(CHANNEL_FILTER_OPTIONS),
  postTypes: [],
  calendarView: CALENDAR_VIEW_OPTIONS.WEEK.value,
  showDrafts: true,
});
