/* eslint-disable import/no-extraneous-dependencies */
import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const { width } = useWindowSize();

export function styleToPixels(value) {
  if (value?.length > 0) {
    if (value.endsWith('px')) {
      return parseInt(value.substring(0, value.length - 2), 10);
    }
  }
  return -1;
}

export const fullTailwindConfig = resolveConfig(tailwindConfig);

export const isScreenXl = computed(
  () => width.value > styleToPixels(fullTailwindConfig.theme.screens.xl),
);
