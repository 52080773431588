<template>
  <Popup
    :close="hideRequestCommentsPopup"
    :close-confirmation="closeConfirmation"
    :inline-styles="{
      height: 'calc(100vh - 5rem)',
      maxHeight: '46.875rem',
      margin: '5rem auto 0',
      position: 'relative',
    }"
    show-when-drawer-open
  >
    <header class="request-comments-header">
      <div class="request-comments-popup-title">Choose Request Comment</div>
      <div class="request-comments-popup-header-buttons">
        <Button
          v-tooltip="'Add new request comment'"
          icon-name="add"
          class="rectangular"
          @click="addBufferComment"
        />
        <Button
          v-tooltip="'Manage request comments'"
          :to="{ name: 'instagram.ugc.content-rights-settings' }"
          icon-name="settings"
          class="rectangular"
        />
      </div>
    </header>
    <CircularLoader v-if="showCommentsLoader" class="request-comment-popup-center-loader" />
    <div v-else>
      <template v-if="commentList && commentList.length > 0">
        <div class="request-comments-list" data-cy="RequestCommentList">
          <ContentRightsCommentForm
            v-for="(requestComment, index) in commentList"
            ref="requestCommentForms"
            :key="requestComment.id"
            :on-select="onSelect"
            :request-comment="requestComment"
            :index="index"
            :checked="index == selectedIndex ? true : false"
            :term-links="termLinks"
            :on-delete="onDelete"
            :on-remove-buffer="onRemoveBuffer"
            @on-edit="onEditRequestComment"
            @off-edit="offEditRequestComment"
          />
        </div>
        <footer>
          <Button :disabled="isEditing" :loading="isSending" large primary @click="sendRequest">
            Send Request
          </Button>
        </footer>
      </template>

      <div v-else>
        <div>
          <EmptyStatus title="You don't have any request comments yet" image="empty-catcouch.png">
            <p>
              <a @click="addBufferComment">Add a request comment</a>
              to get started.
            </p>
          </EmptyStatus>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import humps from 'humps';
import { useTrackingStore } from '@/stores/tracking';
import { useNotificationStore } from '@/stores/notification';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';
import ContentRightsCommentForm from '@/app/instagram/components/RequestComments/ContentRightsCommentForm.vue';
import EmptyStatus from '@/components/EmptyStatus.vue';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import CircularLoader from '@/components/CircularLoader.vue';
import { useInstagramRightsRequestStore } from '@/stores/instagram-rights-request';
import { useInstagramContentRightsStore } from '@/stores/instagram-content-rights';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ContentRightsCommentsPopup',
  components: {
    EmptyStatus,
    CircularLoader,
    Button,
    Popup,
    ContentRightsCommentForm,
  },
  props: {
    hideRequestCommentsPopup: { type: Function, required: true },
    postId: { type: String, default: null },
  },
  emits: ['updatePopupStatus'],
  data() {
    return {
      selectedIndex: 0,
      isEditing: false,
      isSending: false,
      isAdding: false,
      bufferComment: null,
      closeConfirmation: null,
      termLinks: [],
      trackingHashtag: null,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useInstagramAccountStore,
      useInstagramRightsRequestStore,
      useInstagramContentRightsStore,
      useTrackingStore,
    ),
    showCommentsLoader() {
      if (
        this.instagramAccountStore.pending.brandInstagramAccount ||
        this.instagramRightsRequestStore.pending.rightsRequestCommentsStatus
      ) {
        return true;
      }
      return false;
    },
    commentList() {
      if (this.bufferComment) {
        return [this.bufferComment].concat(this.instagramRightsRequestStore.rightsRequestComments);
      }
      return this.instagramRightsRequestStore.rightsRequestComments;
    },
  },
  watch: {
    isEditing(val) {
      this.closeConfirmation = val
        ? 'If you exit out now, your unsaved changes will be discarded.'
        : null;
    },
  },
  async created() {
    await this.instagramRightsRequestStore.getRightsRequestComments();
    const rightsRequestDocuments =
      await this.instagramContentRightsStore.getRightsRequestDocuments();
    this.termLinks = humps.camelizeKeys(rightsRequestDocuments.data);
    const hashtag = await this.instagramContentRightsStore.getHashtag();
    this.trackingHashtag = humps.camelizeKeys(hashtag);
  },
  methods: {
    onRemoveBuffer() {
      this.bufferComment = null;
      this.offEditRequestComment();
    },
    async onDelete() {
      const title = 'Remove Request Comment';
      const message = 'Are you sure you want to remove it?';
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.confirmDelete,
      });
    },
    onEditRequestComment() {
      this.$refs.requestCommentForms
        .filter((requestCommentForm) => requestCommentForm.checked === false)
        .forEach((form) => {
          const mutateForm = form;
          mutateForm.disabled = true;
        });
      this.isEditing = true;
    },
    offEditRequestComment() {
      this.$refs.requestCommentForms.forEach((form) => {
        const mutateForm = form;
        mutateForm.disabled = false;
      });
      this.isEditing = false;
    },
    async confirmDelete() {
      const commentId =
        this.instagramRightsRequestStore.rightsRequestComments[this.selectedIndex].id;
      await this.instagramRightsRequestStore.deleteRightsRequestComment({
        commentId,
      });

      this.notificationStore.setToast({ message: `Request comment has been deleted.` });
      this.instagramRightsRequestStore.getRightsRequestComments();
    },
    scrollToTop() {
      const allComments = this.$el.querySelector('div.request-comments-list');
      const latestComment = this.$refs.requestCommentForms.slice(-1)[0];
      allComments.scrollTop = latestComment;
    },
    addBufferComment() {
      if (this.bufferComment) {
        return;
      }
      this.bufferComment = {
        id: 0,
        commentText: `Hey! We love this post and want to share it on our feed. If you agree with these terms ${this.termLinks[0].termLink} reply by @ mentioning @${this.instagramAccountStore.brandInstagramAccount.handle} + #${this.trackingHashtag.trackingHashtag}`,
        commentHtml: '',
        document: this.termLinks[0],
        hashtag: this.trackingHashtag,
        isBuffer: true,
      };
      this.$forceUpdate();
      nextTick(() => {
        this.$refs.requestCommentForms.slice(-1)[0].editRequestComment();
      });
      this.selectedIndex = 0;
      this.scrollToTop();
    },
    onSelect(index) {
      this.selectedIndex = index;
    },
    async sendRequest() {
      const selectedData =
        this.instagramRightsRequestStore.rightsRequestComments[this.selectedIndex];
      const selectedMessage = selectedData.commentText;
      if (selectedMessage.trim() !== '') {
        this.isSending = true;
        await this.instagramContentRightsStore.createRightsRequestMedia({
          postId: this.postId,
          type: 'REQUESTED',
          requestComment: selectedMessage,
          termLink: selectedData.document.termLink,
          trackingHashtag: selectedData.hashtag.trackingHashtag,
        });
        this.trackingStore.track('Content Rights Requested', { selectedMessage, selectedData });
        try {
          await this.instagramRightsRequestStore.postFacebookComment({
            postId: this.postId,
            message: selectedMessage,
          });
          this.$emit('updatePopupStatus', 'showRequestSuccessPopup', true);
        } catch (error) {
          /* Copy the selected message to clipboard. */
          const textArea = document.createElement('textarea');
          textArea.value = selectedMessage;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          this.$emit('updatePopupStatus', 'showRequestErrorPopup', true);
          throw error;
        } finally {
          this.hideRequestCommentsPopup();
          this.isSending = false;
        }
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.request-comment-popup-center-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.request-comments-list {
  top: var(--space-64);
  padding-top: var(--space-16);
  position: absolute;
  height: calc(100% - 9.125rem);
  width: 100%;
  overflow: auto;
  left: 0;
  background: var(--background-300);
}

.request-comments-header {
  width: 100%;
  display: flex;
  border-top-right-radius: var(--round-corner);
  border-top-left-radius: var(--round-corner);
  padding: var(--space-14) var(--space-24) var(--space-14) 0;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  font-weight: var(--font-medium);
  font-size: var(--x16);
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-0);
  z-index: var(--z-index-sticky);
  box-shadow: var(--shadow-1);

  .request-comments-popup-title {
    display: flex;
    margin-left: var(--space-32);
    justify-content: flex-start;
    align-items: center;
  }

  .request-comments-popup-header-buttons {
    display: flex;

    button {
      margin: 0 0.625rem;
      background-color: var(--background-300);
    }

    a {
      background-color: var(--background-300);
    }
  }
}

footer {
  display: flex;
  border-top: 1px solid var(--border);
  padding: var(--space-20) 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--background-300);
  z-index: var(--z-index-sticky);
  border-bottom-right-radius: var(--round-corner);
  border-bottom-left-radius: var(--round-corner);
}
</style>
