<template>
  <section>
    <div class="user-section" :class="{ 'no-avatar': !showUserAvatar }">
      <div class="left">
        <UserAvatar v-if="showUserAvatar" :user="mediaItem" class="avatar-placeholder" small />
        <dl>
          <dt v-if="showUserName" data-cy="media-popup-user-name" class="username">
            <a v-if="userNameExists" :href="platformHomePageUrl" target="_blank" rel="noopener">
              {{ mediaItem.userName }}
            </a>
            <p v-else>
              {{ placeholderUserName }}
            </p>
          </dt>
          <dt v-else-if="mediaItem.firstName && mediaItem.lastName">
            {{ `${mediaItem.firstName} ${mediaItem.lastName}` }}
          </dt>
          <dd v-if="mediaItem.datePosted" class="date">{{ timeDisplayed }}</dd>
        </dl>
      </div>
      <div v-if="showAddToRelationshipButton" v-tooltip="addToRelationshipButtonConfig.tooltip">
        <Button
          :loading="instagramRelationshipsStore.pending.createRelationshipStatus"
          :icon-name="addToRelationshipButtonConfig.iconName"
          :icon-size="addToRelationshipButtonConfig.iconSize"
          :disabled="!!mediaItem.relationship"
          :icon-color="colours.ICON.ICON_SECONDARY"
          :icon-hover-color="colours.ACTION.ACTION_500"
          round-small
          @click="addToRelationships"
        />
      </div>
    </div>
    <div v-if="showPostInfo">
      <div class="text-section">
        <p v-if="title" class="post-title">{{ title }}</p>
        <p
          ref="message"
          v-sanitize-html="messageHTML"
          :class="['post-text', { truncated: !isMessageExpanded && isLongMessage }]"
        />
        <p v-if="showSeeMore">
          <span class="see-more-toggle" @click="toggleTextTruncate">
            {{ isMessageExpanded ? 'See Less' : 'See More' }}
          </span>
        </p>
      </div>
      <div class="flex items-center justify-center gap-2">
        <a :href="mediaItem.postUrl" target="_blank" rel="noopener">
          <Button light class="post-url" @click="handleMixpanelEvent">
            {{ seeOnButtonName }}
          </Button>
        </a>
        <a v-if="showPromotePostButton" :href="boostUrl" target="_blank" rel="noopener">
          <Button primary class="post-url" @click="trackPromotePost"> Promote Post </Button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useIntercomStore } from '@/stores/intercom';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useMediaDetailStore } from '@/stores/media-detail';
import { useInstagramRelationshipsStore } from '@/stores/instagram-relationships';
import { useCreatorsStore } from '@/stores/creators';
import { useFlagStore } from '@/stores/flag';
import { colours } from '@/ux/colours';
import { getUserTimezone } from '@/utils/timezone';
import {
  encodeForTiptapFromFacebook,
  encodeForTiptapFromTweetStatus,
  encodeForTiptapFromInstagramFeed,
  encodeForTiptapFromLinkedinCaption,
} from '@/app/scheduler/utils/tiptap';
import enumTypes, { boostUrl } from '@/app/library/constants';
import { MediaPopupUserEventTracker } from '@/app/library/mixpanel';
import { CreatorDiscoveryEventTracker } from '@/app/relationships/mixpanel';
import Button from '@/components/foundation/Button.vue';
import UserAvatar from '@/components/core/UserAvatar.vue';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { useInstagramPostsStore } from '@/stores/instagram-posts';
import { usePlatformStore } from '@/stores/platform';
import { validateRealUser } from '@/utils/user';
import constants from '@/app/relationships/constants';
import { constants as channels } from '@/config';

import { CHANNELS } from '@/models/dashboards/channels.enum';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'UserSection',
  components: {
    Button,
    UserAvatar,
  },
  props: {
    mediaItem: { type: Object, default: null },
    allowExpandingMessage: { type: Boolean, default: true },
  },
  setup() {
    const discoveryEventTracker = new CreatorDiscoveryEventTracker();

    return {
      discoveryEventTracker,
    };
  },
  data() {
    return {
      enumTypes,
      boostUrl,
      isMessageExpanded: false,
      isLongMessage: false,
      userEventTracker: new MediaPopupUserEventTracker(),
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useAuthStore,
      useMediaDetailStore,
      useInstagramPostsStore,
      usePlatformStore,
      useInstagramRelationshipsStore,
      useCreatorsStore,
      useTrackingStore,
      useIntercomStore,
      useFlagStore,
    ),
    colours() {
      return colours;
    },
    postTypeTextMap() {
      return {
        INSTAGRAM: {
          text: 'Instagram',
        },
        INSTAGRAM_STORY: {
          text: 'Instagram',
        },
        PINTEREST: {
          text: 'Pinterest',
        },
        TWITTER: {
          text: 'X',
        },
        YOUTUBE: {
          text: 'YouTube',
        },
      };
    },
    hasRelationshipsPermission() {
      return (
        this.authStore.guard(BRAND.INSTAGRAM.CAN_ACCESS_RELATIONSHIPS) &&
        this.authStore.guard(USER.INSTAGRAM.CAN_ACCESS_RELATIONSHIPS)
      );
    },
    showAddToRelationshipButton() {
      return (
        this.mediaItem.postType === enumTypes.INSTAGRAM &&
        (this.mediaItem.sourceType === enumTypes.INSTAGRAM_UGC ||
          this.mediaItem.sourceType === enumTypes.INSTAGRAM_OTHER ||
          this.mediaItem.sourceType === enumTypes.INSTAGRAM_UGC_IGTV ||
          this.mediaItem.sourceType === enumTypes.INSTAGRAM_OTHER_IGTV) &&
        this.hasRelationshipsPermission
      );
    },
    addToRelationshipButtonConfig() {
      if (this.mediaItem.relationship) {
        return {
          iconName: 'userCheck',
          iconSize: null,
          tooltip: 'Added to Your Creators',
        };
      }
      return {
        iconName: 'add',
        iconSize: 'xxsmall',
        tooltip: 'Add to Your Creators',
      };
    },
    showPostInfo() {
      return ![enumTypes.INSTAGRAM_STORY].includes(this.mediaItem.postType);
    },
    placeholderUserName() {
      if (this.mediaItem.postType in this.postTypeTextMap) {
        return `${this.postTypeTextMap[this.mediaItem.postType].text} User`;
      }
      if (
        [
          enumTypes.FACEBOOK_OWNED,
          enumTypes.FACEBOOK_TEXT,
          enumTypes.FACEBOOK_LINK,
          enumTypes.FACEBOOK_TEXT_LINK,
        ].includes(this.mediaItem.sourceType)
      ) {
        return 'Facebook User';
      }
      if (
        this.mediaItem.sourceType === enumTypes.TIKTOK_OWNED ||
        this.mediaItem.sourceType === enumTypes.TIKTOK_UGC
      ) {
        return 'TikTok User';
      }
      return ' User';
    },
    platformHomePageUrl() {
      if (this.mediaItem.platformHomePageUrl) {
        return this.mediaItem.platformHomePageUrl;
      }

      if (this.mediaItem.fbPageId) {
        return `https://www.facebook.com/${this.mediaItem.fbPageId}`;
      }

      if (this.mediaItem.userName) {
        let platform = this.mediaItem.postType.toLowerCase();
        if (platform.includes('instagram')) {
          platform = 'instagram';
        }
        if (platform === 'tiktok') {
          return `https://www.tiktok.com/@${this.mediaItem.userName}`;
        }
        if (platform === 'threads') {
          return `https://www.threads.net/@${this.mediaItem.handle}`;
        }
        return `https://www.${platform}.com/${this.mediaItem.userName}`;
      }
      return null;
    },
    userNameExists() {
      return validateRealUser(String(this.mediaItem.userName));
    },
    showSeeMore() {
      if (!this.allowExpandingMessage) {
        return false;
      }

      return this.message && this.isLongMessage;
    },
    seeOnButtonName() {
      const platform = this.mediaItem.postType.toUpperCase().split('_')[0];
      const platformFormattedName = CHANNELS[platform].text;
      return `See on ${platformFormattedName}`;
    },
    showUserName() {
      return (
        (this.mediaItem.userName ||
          this.mediaItem.userName === undefined ||
          (this.mediaItem.userName === null && this.mediaItem.postType === enumTypes.TIKTOK)) &&
        ([
          enumTypes.INSTAGRAM,
          enumTypes.INSTAGRAM_STORY,
          enumTypes.PINTEREST,
          enumTypes.TWITTER,
          enumTypes.YOUTUBE,
          enumTypes.LINKEDIN,
          enumTypes.THREADS,
        ].includes(this.mediaItem.postType) ||
          [
            enumTypes.FACEBOOK_OWNED,
            enumTypes.FACEBOOK_TEXT,
            enumTypes.FACEBOOK_LINK,
            enumTypes.FACEBOOK_TEXT_LINK,
            enumTypes.TIKTOK_OWNED,
            enumTypes.TIKTOK_UGC,
          ].includes(this.mediaItem.sourceType) ||
          enumTypes.FACEBOOK_COMPETITIVE_SOURCE_LIST.includes(this.mediaItem.sourceType))
      );
    },
    timeDisplayed() {
      return dayjs.utc(this.mediaItem.datePosted).tz(getUserTimezone()).format('LLL');
    },
    title() {
      return this.mediaItem.postTitle;
    },
    message() {
      /**
       * When adding new channels, where their posts inevitably have different names for "message",
       * we'd ideally like to consolidate them on the mediaItem into one attribute so this property
       * doesn't grow in size for every channel we add. For future additions, we should define
       * this property as "postMessage" in app/library/store/mediaDetail.js when ingesting the data
       */
      return (
        this.mediaItem.postMessage ||
        this.mediaItem.tweetStatus ||
        this.mediaItem.message ||
        this.mediaItem.caption ||
        this.mediaItem.postCaption ||
        this.mediaItem.title ||
        ''
      );
    },
    pinterestBoardUrl() {
      const { boardName } = this.mediaItem;
      if (!boardName) {
        return null;
      }
      return `https://www.pinterest.com/${
        this.platformStore.pinterestAccount?.pinterestUsername
      }/${boardName
        .toLowerCase()
        .replaceAll(/[^a-z0-9\s]/g, '')
        .replaceAll(/\s+/g, '-')}`;
    },
    messageHTML() {
      switch (this.mediaItem.postType) {
        case enumTypes.TWITTER:
          return encodeForTiptapFromTweetStatus(this.message);
        case enumTypes.FACEBOOK:
          return encodeForTiptapFromFacebook(this.message);
        case enumTypes.INSTAGRAM:
          return encodeForTiptapFromInstagramFeed(this.message);
        case enumTypes.LINKEDIN:
          return encodeForTiptapFromLinkedinCaption(this.message);
        case enumTypes.PINTEREST:
          return `
<section class="pin-text">
            <span style="font-weight: 500">Pinterest Board</span><br/><br/>
            ${
              this.mediaItem.boardName
                ? `<a target="_blank" href="${this.pinterestBoardUrl}">${this.mediaItem.boardName}</a>`
                : '-'
            }<br/>
            <br><span style="font-weight: 500">Title</span></br><br/>
            <span>
            ${this.message}</span>
            <br/>
            <br><span style="font-weight: 500">Description</span></br><br/>
            <span>
            ${this.mediaItem.description}</span>
               <br/>
            <br><span style="font-weight: 500">Link to Site</span><br/>
            ${
              this.formatLinkToSite
                ? `<a target="_blank" href="${this.formatLinkToSite}">${this.formatLinkToSite}</a>`
                : '-'
            }
            </section>
          `;

        default:
          return this.message;
      }
    },
    /*
    formatLinkToSite() is for when a zero is returned from backend due to a null value. Here we change the zero back to null for Pinterest
     */
    formatLinkToSite() {
      if (this.mediaItem.linkToSite === '0') {
        return null;
      }

      return this.mediaItem.linkToSite;
    },
    showUserAvatar() {
      const isTikTokUGCWithoutUsername = this.mediaItem.sourceType === enumTypes.TIKTOK_UGC;
      return isTikTokUGCWithoutUsername;
    },
    showPromotePostButton() {
      return (
        this.flagStore.ready &&
        this.flagStore.flags.promotePostButton &&
        (this.mediaItem.postType === channels.FACEBOOK ||
          this.mediaItem.postType === channels.INSTAGRAM) &&
        [
          ...enumTypes.FACEBOOK_OWNED_SOURCE_LIST,
          ...enumTypes.INSTAGRAM_OWNED_SOURCE_LIST,
        ].includes(this.mediaItem.sourceType)
      );
    },
  },
  watch: {
    'instagramPostsStore.pending.relationshipId': function relationshipIdWatcher(to) {
      if (!to) {
        if (this.instagramPostsStore.error.relationshipId) {
          this.notificationStore.setToast({
            message: this.instagramPostsStore.error.relationshipId.response.data.description,
            type: 'error',
          });
        } else {
          this.notificationStore.setToast({ message: 'Relationship Added.' });
          this.mediaDetailStore.updateIsRIQ({ value: true });
          this.trackingStore.track('Media Added To Instagram RIQ', {
            newRiQUser: this.mediaItem.userName,
          });
        }
      }
    },
    'instagramRelationshipsStore.pending.createRelationshipStatus':
      function createRelationshipWatcher(to) {
        if (!to && this.instagramRelationshipsStore.relationship) {
          if (this.instagramRelationshipsStore.error.createRelationshipStatus) {
            return;
          }
          const { mediaItem } = this;
          mediaItem.relationship = this.instagramRelationshipsStore.relationship;
          this.mediaDetailStore.setMediaDetail({ mediaItem });
          this.notificationStore.setToast({
            message: 'Account is added to Your Creators!',
          });
        }
      },
    'instagramRelationshipsStore.error.createRelationshipStatus':
      function createRelationshipErrorWatcher(to) {
        if (to) {
          this.notificationStore.setToast({
            message: to,
            type: 'error',
          });
        }
      },
  },
  mounted() {
    this.checkIsLongMessage();
  },
  methods: {
    addToRiq() {
      this.instagramPostsStore.addRelationship({
        instagramName: this.mediaItem.userName,
      });
    },
    async addToRelationships() {
      if (this.igUser) {
        return;
      }
      try {
        await this.instagramRelationshipsStore.createRelationship({
          brandId: this.authStore.currentBrand.id,
          handle: this.mediaItem.userName,
        });
        const sourceCreatorId =
          this.instagramRelationshipsStore.relationship.relationInstagramId?.toString();
        this.creatorsStore.removeAccount({ source: 'INSTAGRAM', sourceCreatorId });
        this.discoveryEventTracker.creatorAdded({
          'Creator ID': sourceCreatorId,
        });
      } catch (e) {
        if (e.response?.data?.description?.includes?.('already exists in your list')) {
          this.notificationStore.setToast({
            message: constants.addToYourCreatorsFailureBecauseAlreadyAddedMessage,
            type: 'warning',
          });
        } else {
          this.notificationStore.setToast({
            message: constants.addToYourCreatorsFailureMessage,
            type: 'error',
          });
          throw e;
        }
      }
    },
    toggleTextTruncate() {
      this.isMessageExpanded = !this.isMessageExpanded;
    },
    handleMixpanelEvent() {
      const platform = this.mediaItem.postType.toLowerCase();
      this.userEventTracker.seeOnPlatformClicked(this.mediaItem, this.$route, platform);
    },
    trackPromotePost() {
      const event = 'Promote Button Clicked';
      const properties = {
        location: 'Global Popup',
        channel: this.mediaItem.postType,
        mediaType: this.mediaItem.mediaType,
      };
      this.trackingStore.track(event, properties);
      this.intercomStore.trackEvent(event, properties);
    },
    checkIsLongMessage() {
      const messageContainer = this.$refs?.message;
      const lineHeight = 18;
      const maxLines = 3;
      // to subtract the bottom padding of the caption (.truncated) which is included in the scroll height
      const bottomPadding = 2;
      if (messageContainer) {
        this.isLongMessage = messageContainer.scrollHeight - bottomPadding > lineHeight * maxLines;
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.user-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    .avatar-placeholder {
      margin-right: var(--space-8);
    }

    dl {
      text-align: left;

      dd {
        font-size: var(--x12);
        color: var(--text-secondary);
        line-height: normal;
      }

      .username a:hover {
        color: var(--action-500);
        cursor: pointer;
      }
    }
  }

  .riq-added {
    background: transparent;
    border-radius: var(--button-border-radius);
    border: 1px solid var(--border);
    padding: var(--space-4) var(--space-16);
    font-size: var(--x13);
    font-weight: var(--font-medium);
    color: var(--text-secondary);
  }
}

.text-section {
  display: block;
  margin: var(--space-8);
  text-align: left;
  font-size: var(--x14);
  line-height: 18px;

  .post-title {
    font-weight: var(--font-medium);
  }

  .post-text {
    color: var(--text-primary);
    word-wrap: normal;
    user-select: text;
    margin-top: var(--space-14);
    overflow-wrap: break-word;

    :deep(a.twitter-mention) {
      font-weight: normal !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0.063rem;
  }

  .see-more-toggle {
    color: var(--action-500);
    cursor: pointer;
    display: block;
    margin-top: var(--space-4);
  }
}

.post-url {
  display: inline;
  margin: var(--space-12) auto;
}

.pin-text {
  display: block;
}

.no-avatar {
  margin-left: var(--space-8);

  .left dl dd.date {
    font-size: var(--x14);
  }
}
</style>
