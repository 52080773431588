import isEmpty from 'lodash/isEmpty';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { handleCancelError, refreshAbortController } from '@/apis/axios.utils';
import * as InstagramAPI from '@/apis/instagram';
import { useIdentityStore } from '@/stores/identity';

export const useInstagramAccountStore = defineStore(
  'instagramAccount',
  () => {
    const identityStore = useIdentityStore();

    const pending = ref({
      igUser: false,
      addAccountStatus: false,
      brandInstagramAccount: false,
    });

    const initialized = ref({
      brandInstagramAccount: false,
    });

    const error = ref({
      addAccountStatus: null,
      getIgUser: null,
    });

    const abortControllers = {
      getIgUser: ref(null),
    };

    const brandInstagramAccount = ref(null);
    const addedAccount = ref(null);
    const igUser = ref({});
    const igUserPosts = ref([]);
    const igUserNextToken = ref(null);

    async function getIgUser({ handle, after, limit }) {
      pending.value.igUser = true;
      try {
        const signal = refreshAbortController(abortControllers.getIgUser);

        // If the handle were searching doesn't match the existing posts, clear them.
        if (igUserPosts.value[0]?.handle !== handle) {
          igUserPosts.value = [];
        }

        const brandId = identityStore.currentBrand?.id;
        const response = await InstagramAPI.getInstagramUsers(
          { brandId, handle, after, limit },
          { signal },
        );
        const payload = response?.data;

        if (isEmpty(igUser.value)) {
          igUser.value = payload?.user;
        }

        if (payload?.posts) {
          const posts = payload.posts.map((item) => ({ handle: igUser.value?.handle, ...item }));
          igUserPosts.value = [...igUserPosts.value, ...posts];
          igUserNextToken.value = payload.after;
        }

        return payload;
      } catch (e) {
        error.value.getIgUser = e.response;
        if (e.response?.status === 422) {
          igUserPosts.value = [];
          return [];
        }
        return handleCancelError(e);
      } finally {
        pending.value.igUser = false;
      }
    }

    async function addAccount({ handle }) {
      pending.value.addAccountStatus = true;
      error.value.addAccountStatus = null;
      try {
        const response = await InstagramAPI.addAccount({ handle });
        const payload = response?.data;
        addedAccount.value = payload;
        return payload;
      } catch (e) {
        error.value.addAccountStatus = e;
        throw e;
      } finally {
        pending.value.addAccountStatus = false;
      }
    }

    async function getBrandInstagramAccount() {
      const brandId = identityStore.currentBrand?.id;
      pending.value.brandInstagramAccount = true;
      try {
        const response = await InstagramAPI.getBrandInstagramAccount({ brandId });
        const payload = response?.data;
        brandInstagramAccount.value = payload;
        initialized.value.brandInstagramAccount = true;
        return payload;
      } catch (e) {
        if (e?.response?.status === 404) {
          brandInstagramAccount.value = null;
        } else {
          throw e;
        }
      } finally {
        pending.value.brandInstagramAccount = false;
      }
      return undefined;
    }

    function clearBrandInstagramAccount() {
      brandInstagramAccount.value = null;
    }

    function clearIgUser() {
      igUser.value = null;
      igUserPosts.value = [];
      error.value.getIgUser = null;
      igUserNextToken.value = null;
    }

    return {
      pending,
      initialized,
      error,
      igUser,
      igUserPosts,
      igUserNextToken,
      addedAccount,
      brandInstagramAccount,
      clearBrandInstagramAccount,
      getIgUser,
      clearIgUser,
      addAccount,
      getBrandInstagramAccount,
    };
  },
  { resetOnBrandChange: true },
);
