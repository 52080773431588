<template>
  <div
    v-tooltip="chipTooltip"
    class="chip inline-flex items-center"
    :class="[outerClasses]"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div class="chip-content flex items-center justify-center" :class="[innerClasses]">
      <slot name="prependIcon">
        <template v-if="prependIcon">
          <Icon v-bind="iconPropBindings" :name="prependIcon" :color="prependIconColor" xsmall />
        </template>
      </slot>
      <template v-if="prependImg">
        <img :src="prependImg" class="img-left" />
      </template>
      <template v-if="dot">
        <Dot :color="dotColor" class="dot" />
      </template>
      <div v-if="$slots.default" class="chip-text" :style="wrapSizeStyle">
        <slot />
      </div>
      <slot name="appendIcon">
        <template v-if="showAppendIcon">
          <Icon v-bind="iconPropBindings" :name="appendIcon" :color="appendIconColor" />
        </template>
      </slot>
      <template v-if="showRemoveButton">
        <button class="remove-button" data-cy="remove-chip" @click.stop="onRemoveClick">
          <Icon name="close" class="icon" xxxsmall />
        </button>
      </template>
      <template v-if="loadingRight"> <div class="loading">&nbsp;</div> </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import Dot from '@/components/foundation/Dot.vue';

/**
 * Generic chip component. Similar to button but much more restricted.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Chip',
  components: {
    Dot,
    Icon,
  },
  props: {
    remove: {
      type: Boolean,
      default: false,
    },
    dot: {
      type: Boolean,
      default: false,
    },
    dotColor: {
      type: String,
      default: 'blue',
    },
    prependIcon: {
      type: String,
      default: null,
    },
    prependIconColor: {
      type: String,
      default: null,
    },
    prependImg: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    appendIconColor: {
      type: String,
      default: null,
    },
    /**
     * Puts the card into hover mode.  Mousing over the chip will raise the chip up off the page.
     */
    hoverMode: {
      type: Boolean,
      default: false,
    },
    forceHover: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Tooltip to show in normal / not disabled state
     */
    tooltip: {
      type: String,
      default: null,
    },
    /**
     * Tooltip to show in disabled state
     */
    disabledTooltipText: {
      type: String,
      default: null,
    },
    /**
     * Additional options for the tooltip
     */
    tooltipOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * Props that will be passed into the icon component
     */
    iconProps: {
      type: Object,
      default: () => {},
    },
    /**
     * Sets background colour
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * Sets text colour
     */
    textColor: {
      type: String,
      default: null,
    },
    truncatedTextWidth: {
      type: String,
      default: 'fit-content',
    },
    loadingRight: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: null,
    },

    // General styles
    primary: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    rectangular: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    gap: { type: Boolean, default: true },
    // Text styles
    fontWeightNormal: { type: Boolean, default: false },

    // Sizes
    roundSmall: { type: Boolean, default: false },
    xwide: { type: Boolean, default: false },
    wide: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xsmall: { type: Boolean, default: false },
    xxsmall: { type: Boolean, default: false },
    wrapSize: { type: String, default: '' },
  },
  emits: ['remove', 'mouseenter', 'mouseleave'],
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    outerClasses() {
      return {
        primary: this.primary,
        danger: this.danger,
        light: this.light,
        rectangular: this.rectangular,
        round: this.round,
        hover: (this.hoverMode && this.isHovering) || this.forceHover,
        border: this.border,
        hasClick: this.hasClickListener,
        disabled: this.disabled,
      };
    },
    innerClasses() {
      return {
        'round-small': this.roundSmall,
        small: this.small,
        xsmall: this.xsmall,
        xxsmall: this.xxsmall,
        wide: this.wide,
        xwide: this.xwide,
        'font-weight-normal': this.fontWeightNormal,
        'gap-2': this.gap,
      };
    },
    wrapSizeStyle() {
      if (!this.wrapSize) {
        return {};
      }
      return { 'max-width': `${this.wrapSize}px` };
    },
    iconPropBindings() {
      return {
        small: true,
        xsmall: this.xsmall,
        xxsmall: this.xxsmall,
        ...this.iconProps,
      };
    },
    hasClickListener() {
      return !!this.$attrs?.onClick;
    },
    chipTooltip() {
      if (this.disabled) {
        return this.disabledTooltipText;
      }
      return { content: this.tooltip, placement: 'top', ...this.tooltipOptions };
    },
    showAppendIcon() {
      return this.appendIcon && !this.loadingRight;
    },
    showRemoveButton() {
      return this.remove && !this.disabled && !this.loadingRight;
    },
    chipBackgroundColor() {
      return this.color ?? 'var(--background-300)';
    },
    chipTextColor() {
      return this.textColor ?? 'var(--text-primary)';
    },
    chipBorderColor() {
      return this.borderColor ?? 'var(--border)';
    },
    chipHorizontalPadding() {
      return this.padding ?? 'var(--space-6)';
    },
  },
  methods: {
    onRemoveClick() {
      if (this.disabled) {
        return;
      }
      this.$emit('remove');
    },
    mouseEnter() {
      this.$emit('mouseenter');
      this.isHovering = true;
    },
    mouseLeave() {
      this.$emit('mouseleave');
      this.isHovering = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.chip {
  border-radius: var(--button-border-radius);
  font-size: var(--x14);
  font-weight: var(--font-medium);
  color: v-bind(chipTextColor);
  background-color: v-bind(chipBackgroundColor);
  transition: var(--transition-all);

  &.border {
    border: 1px solid v-bind(chipBorderColor);
  }

  &.hasClick {
    cursor: pointer;
  }

  &.disabled {
    cursor: auto;
    opacity: 0.3;
    pointer-events: none;
  }

  &.hover {
    box-shadow: var(--shadow-2);
    transform: var(--hover-move);
    z-index: var(--z-index-raised);
  }

  .img-left {
    width: var(--space-16);
    height: var(--space-16);
  }
}

.chip-content {
  height: 2.5rem;
  padding: 0 v-bind(chipHorizontalPadding);
  margin: 0;
  white-space: nowrap;
}

.primary {
  background: var(--action-500);
  color: var(--white);
}

.round {
  background: var(--background-0);
  color: var(--text-primary);
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 var(--space-4);
  border-radius: 100%;
  padding: 0;
}

.danger {
  color: var(--error-500);
}

.light {
  background: var(--background-0);
  color: var(--text-primary);
}

.rectangular {
  border-radius: var(--round-corner-small);
}

.xxsmall {
  height: var(--space-16);
  padding: 0 var(--space-8);
  font-size: var(--x10);

  .img-left {
    width: var(--space-12);
    height: var(--space-12);
  }
}

.xsmall {
  height: var(--space-24);
  padding: 0 var(--space-8);
  font-size: var(--x12);

  .img-left {
    width: var(--space-14);
    height: var(--space-14);
  }
}

.small {
  height: var(--space-32);
  padding: 0 var(--space-10);
  font-size: var(--x13);
}

.round-small {
  width: var(--space-32);
  height: var(--space-32);
}

.wide {
  min-width: 10rem;
}

.xwide {
  min-width: 12rem;
}

.chip-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: v-bind(truncatedTextWidth);
}

.remove-button {
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  align-items: center;
  padding: var(--space-6);

  .icon {
    fill: var(--icon-secondary);
  }

  &:hover .icon {
    fill: var(--action-500);
  }
}

.font-weight-normal {
  font-weight: var(--font-normal);
}

.loading {
  opacity: 0.5;
  position: relative;
  width: 22px;
}

.loading::after {
  content: '';
  display: block;
  border: 2px solid var(--text-primary);
  border-left-color: rgb(76 95 113 / 20%);
  border-right-color: rgb(76 95 113 / 20%);
  border-bottom-color: rgb(76 95 113 / 20%);
  height: 1em;
  width: 1em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 100%;
  box-sizing: border-box;
  margin-left: -1.25em;
  margin-top: -0.5em;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
