import containerQueries from '@tailwindcss/container-queries';

/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
/** @type {import('tailwindcss').Config} */
export default {
  content: ['./src/**/*.{html,js,vue}'],
  safelist: ['pl-6', 'pl-12', 'pl-18', 'pl-24'],
  important: true,
  theme: {
    extend: {
      backgroundColor: {
        grey: {
          0: 'var(--background-0)',
          50: 'var(--background-50)',
          300: 'var(--background-300)',
          400: 'var(--background-400)',
          500: 'var(--background-500)',
          700: 'var(--background-700)',
          900: 'var(--background-900)',
        },
      },
      boxShadow: {
        DEFAULT: 'var(--shadow-1)',
        md: 'var(--shadow-2)',
        lg: 'var(--shadow-3)',
        xl: 'var(--shadow-4)',
        heavy: 'var(--heavy-shadow)',
        blue: 'var(--shadow-blue)',
        visionAiLeft: 'var(--vision-ai-left)',
        visionAiRight: 'var(--vision-ai-right)',
      },
      colors: {
        'vision-01': 'var(--vision-01)',
        'vision-02': 'var(--vision-02)',
        'action-200': 'var(--action-200)',
        'action-500': 'var(--action-500)',
      },
      container: {
        screens: {
          DEFAULT: '100%',
        },
      },
      fontFamily: {
        inter: ['InterVariable', 'Arial', 'sans-serif'],
      },
      screens: {
        xl: '1280px',
        '2xl': '1440px',
      },
      zIndex: {
        dropdown: 'var(--z-index-dropdown)',
        popup: 'var(--z-index-popup)',
      },
    },
  },
  plugins: [containerQueries],
  corePlugins: {
    preflight: false,
  },
};
