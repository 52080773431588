const dateRanges = [
  { label: 'Last 24 Hours', value: '1DAY', type: 'item' },
  { label: 'Last 7 Days', value: '7DAY', type: 'item' },
  { label: 'Last 30 Days', value: '30DAY', type: 'item' },
  { label: 'Last 120 Days', value: '120DAY', type: 'item' },
];

const countryCodes = [
  { label: 'Andorra', value: 'AD' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Albania', value: 'AL' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Angola', value: 'AO' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Argentina', value: 'AR' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Austria', value: 'AT' },
  { label: 'Australia', value: 'AU' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Aland', value: 'AX' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bosnia & Herzegovina', value: 'BA' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Brunei', value: 'BN' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Caribbean Netherlands', value: 'BQ' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Commonwealth of the Bahamas', value: 'BS' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Democratic Republic of the Congo', value: 'CD' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Republic of the Congo', value: 'CG' },
  { label: 'Switzerland', value: 'CH' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Chile', value: 'CL' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'the Chinese mainland', value: 'CN' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Germany', value: 'DE' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Egypt', value: 'EG' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Spain', value: 'ES' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Finland', value: 'FI' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Falkland Islands', value: 'FK' },
  { label: 'Micronesia', value: 'FM' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'France', value: 'FR' },
  { label: 'Gabon', value: 'GA' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Georgia', value: 'GE' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Greece', value: 'GR' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Hong Kong, China', value: 'HK' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Israel', value: 'IL' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'India', value: 'IN' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Iran', value: 'IR' },
  { label: 'Iceland', value: 'IS' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Japan', value: 'JP' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Saint Christopher and Nevis', value: 'KN' },
  { label: 'North Korea', value: 'KP' },
  { label: 'South Korea', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Laos', value: 'LA' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Libya', value: 'LY' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Macedonia', value: 'MK' },
  { label: 'Mali', value: 'ML' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Macao, China', value: 'MO' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Malta', value: 'MT' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Namibia', value: 'NA' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'Niger', value: 'NE' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Norway', value: 'NO' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Niue', value: 'NU' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Oman', value: 'OM' },
  { label: 'Panama', value: 'PA' },
  { label: 'Peru', value: 'PE' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Poland', value: 'PL' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Pitcairn Islands', value: 'PN' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Palestine', value: 'PS' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Palau', value: 'PW' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Russia', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Somali', value: 'SO' },
  { label: 'Suriname', value: 'SR' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Sao Tomé and Príncipe', value: 'ST' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Syria', value: 'SY' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Chad', value: 'TD' },
  { label: 'French Southern and Antarctic Lands', value: 'TF' },
  { label: 'Togo', value: 'TG' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Taiwan, China', value: 'TW' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'Uganda', value: 'UG' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'United States', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vatican City', value: 'VA' },
  { label: 'Saint Vincent and The Grenadines', value: 'VC' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'British Virgin Islands', value: 'VG' },
  { label: 'Virgin Islands of the United States', value: 'VI' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Samoa', value: 'WS' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

const categoryNames = [
  { value: 'ALL', label: 'All Categories', type: 'item' },
  {
    value: 'APPAREL_AND_ACCESSORIES',
    label: 'Apparel and Accessories',
    type: 'item',
  },
  { value: 'OUTFITS', label: 'Outfits', type: 'item', isIndented: true },
  {
    value: 'BABY_KIDS_AND_MATERNITY',
    label: 'Baby, Kids, and Maternity',
    type: 'item',
  },
  {
    value: 'BEAUTY_AND_PERSONAL_CARE',
    label: 'Beauty and Personal Care',
    type: 'item',
  },
  {
    value: 'BEAUTY_SERVICES',
    label: 'Beauty Services',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'HAIR_CARE',
    label: 'Hair Care',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'OTHER_BEAUTY',
    label: 'Other Beauty',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'BUSINESS_SERVICES',
    label: 'Business Services',
    type: 'item',
  },
  { value: 'EDUCATION', label: 'Education', type: 'item' },
  { value: 'COLLEGE', label: 'College', type: 'item', isIndented: true },
  {
    value: 'HISTORY_AND_LITERATURE',
    label: 'History and Literature',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'LEARNING_AND_DEVELOPMENT',
    label: 'Learning and Development',
    type: 'item',
    isIndented: true,
  },
  { value: 'SCIENCE', label: 'Science', type: 'item', isIndented: true },
  {
    value: 'FINANCIAL_SERVICES',
    label: 'Financial Services',
    type: 'item',
  },
  {
    value: 'FOOD_AND_BEVERAGE',
    label: 'Food and Beverage',
    type: 'item',
  },
  { value: 'DRINKS', label: 'Drinks', type: 'item', isIndented: true },
  {
    value: 'EATING_EXPERIENCES',
    label: 'Eating Experiences',
    type: 'item',
    isIndented: true,
  },
  { value: 'FOOD', label: 'Food', type: 'item', isIndented: true },
  {
    value: 'FOOD_RECOMMENDATIONS',
    label: 'Food Recommendations',
    type: 'item',
    isIndented: true,
  },
  { value: 'GAMES', label: 'Games', type: 'item' },
  {
    value: 'VIDEO_GAMES',
    label: 'Video Games',
    type: 'item',
    isIndented: true,
  },
  { value: 'HEALTH', label: 'Health', type: 'item' },
  {
    value: 'HOME_IMPROVEMENT',
    label: 'Home Improvement',
    type: 'item',
  },
  {
    value: 'HOUSEHOLD_PRODUCTS',
    label: 'Household Products',
    type: 'item',
  },
  { value: 'LIFE_SERVICES', label: 'Life Services', type: 'item' },
  {
    value: 'DIARY_AND_VLOG',
    label: 'Diary and VLog',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'ENVIRONMENT',
    label: 'Environment',
    type: 'item',
    isIndented: true,
  },
  { value: 'FAMILY', label: 'Family', type: 'item', isIndented: true },
  {
    value: 'PHOTOGRAPHY',
    label: 'Photography',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'PRODUCTIVITY_AND_EFFICIENCY',
    label: 'Productivity and Efficiency',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'NEWS_AND_ENTERTAINMENT',
    label: 'News and Entertainment',
    type: 'item',
  },
  { value: 'ART', label: 'Art', type: 'item', isIndented: true },
  { value: 'COMEDY', label: 'Comedy', type: 'item', isIndented: true },
  {
    value: 'COMMUNITY_UPDATES_AND_ISSUES',
    label: 'Community Updates and Issues',
    type: 'item',
    isIndented: true,
  },
  { value: 'CULTURE', label: 'Culture', type: 'item', isIndented: true },
  { value: 'DANCE', label: 'Dance', type: 'item', isIndented: true },
  {
    value: 'ENTERTAINMENT_AND_SPORTS_NEWS',
    label: 'Entertainment and Sports News',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'MISCELLANEOUS_PERFORMANCES',
    label: 'Miscellaneous Performances',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'MOVIES_AND_TV',
    label: 'Movies and TV',
    type: 'item',
    isIndented: true,
  },
  { value: 'MUSIC', label: 'Music', type: 'item', isIndented: true },
  {
    value: 'PERFORMING_ARTS',
    label: 'Performing Arts',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'PERSONAL_PHOTOGRAPHY',
    label: 'Personal Photography',
    type: 'item',
    isIndented: true,
  },
  { value: 'ROMANCE', label: 'Romance', type: 'item', isIndented: true },
  {
    value: 'SENSORY_EXPERIENCES',
    label: 'Sensory Experiences',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'SHOWS_FESTIVALS_AND_EXHIBITIONS',
    label: 'Shows, Festivals and Exhibitions',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'SPECIAL_EFFECTS',
    label: 'Special Effects',
    type: 'item',
    isIndented: true,
  },
  { value: 'SPORTS', label: 'Sports', type: 'item', isIndented: true },
  {
    value: 'SUPERNATURAL_AND_HORROR',
    label: 'Supernatural and Horror',
    type: 'item',
    isIndented: true,
  },
  { value: 'PETS', label: 'Pets', type: 'item' },
  {
    value: 'LIVESTOCK_AND_FARMING',
    label: 'Livestock and Farming',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'WILDLIFE_AND_EXOTIC_PETS',
    label: 'Wildlife and Exotic Pets',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'SPORTS_AND_OUTDOOR',
    label: 'Sports and Outdoor',
    type: 'item',
  },
  {
    value: 'EXERCISE_AND_FITNESS',
    label: 'Exercise and Fitness',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'EXTREME_AND_ADVENTURE_SPORTS',
    label: 'Extreme and Adventure Sports',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'LEISURE_AND_RECREATION_FACILITIES',
    label: 'Leisure and Recreation Facilities',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'OUTDOOR_RECREATION',
    label: 'Outdoor Recreation',
    type: 'item',
    isIndented: true,
  },
  { value: 'TECHNOLOGY', label: 'Technology', type: 'item' },
  {
    value: 'SOFTWARE_AND_APPS',
    label: 'Software and Apps',
    type: 'item',
    isIndented: true,
  },
  { value: 'TRAVEL', label: 'Travel', type: 'item' },
  {
    value: 'VEHICLE_AND_TRANSPORTATION',
    label: 'Vehicle and Transportation',
    type: 'item',
  },
  {
    value: 'AUTOMOTIVE',
    label: 'Automotive',
    type: 'item',
    isIndented: true,
  },
  {
    value: 'OTHER_TRANSPORTATION',
    label: 'Other Transportation',
    type: 'item',
    isIndented: true,
  },
];

export function useTrendingHashtagsSearchArgs() {
  return {
    categoryNames,
    countryCodes,
    dateRanges,
  };
}
