<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { NavSecondaryMenu } from '@dashhudson/dashing-ui';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  menuItems: { type: Array, required: true },
});

const expandedKeys = ref({});

const menuItemsWithKeys = computed(() => {
  return props.menuItems.map((item, i) => {
    const itemWithKey = {
      ...item,
      key: `${i}`,
    };
    if (item.items) {
      itemWithKey.items = item.items.map((subItem, index) => ({
        ...subItem,
        key: `${i}_${index}`,
      }));
    }
    return itemWithKey;
  });
});

onMounted(() => {
  const expandedKeysMap = {};
  menuItemsWithKeys.value.forEach((item) => {
    if (item.items) {
      if (item.preExpand) {
        expandedKeysMap[item.key] = true;
      }
      item.items.forEach((subItem) => {
        if (subItem.route.name === route.name) {
          expandedKeysMap[item.key] = true;
        }

        if (subItem.childNames) {
          subItem.childNames.forEach((childName) => {
            if (childName === route.name) {
              expandedKeysMap[item.key] = true;
            }
          });
        }
      });
    }
  });
  expandedKeys.value = expandedKeysMap;
});

function handleOpen(item) {
  Object.keys(expandedKeys.value).forEach((key) => {
    if (item.key !== key) {
      delete expandedKeys.value[key];
    }
  });
}

watch(
  () => route.name,
  (to) => {
    menuItemsWithKeys.value.forEach((parentItem) => {
      if (parentItem.items) {
        parentItem.items.forEach((childItem) => {
          if (childItem.route.name === to) {
            expandedKeys.value = { [parentItem.key]: true };
          }
        });
      }
    });
  },
);
</script>

<template>
  <NavSecondaryMenu
    v-model:expanded-keys="expandedKeys"
    class="cy-test--secondary-navigation-menu"
    :menu-items="menuItemsWithKeys"
    @panel-open="handleOpen($event.item)"
  />
</template>
