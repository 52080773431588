import parse from 'date-fns/parse';
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import dayjs from 'dayjs';

export const FAR_FUTURE = new Date(8640000000000000);
export const DISTANT_PAST = new Date(-8640000000000000);
export const ALL_TIME = Object.freeze({ start: DISTANT_PAST, end: FAR_FUTURE });

/**
 * An object that combines two dates to represent the time interval.
 * https://date-fns.org/v2.28.0/docs/Interval
 * @typedef {{start: Date, end: Date}} Interval
 */

/**
 * An object that combines two Dayjs objects to represent the time interval.
 * @typedef {{start: Dayjs, end: Dayjs}} DayjsInterval
 */

/**
 * Convert date string from a given format to another format.
 * i.e., reformatDateString('2000-03-15', 'yyyy-MM-dd', 'MMMM d, y') = 'March 15, 2000'
 * @param {string} dateString
   - The original date string.
 * @param {string} inputDateFormat
   - Date format code for the original date string.
 * @param {string} targetDateFormat
   - Date format code for the target output.
 * @response {string}
   - Reformatted date string according to the time code
   - i.e., reformatJSONDateString('2000-03-15', 'MMMM d, y') = 'March 15, 2000'
 - For more timeFormat short code, check: https://date-fns.org/v2.22.1/docs/format
 */
export function reformatDateString(dateString, inputDateFormat, targetDateFormat) {
  const dateObj = parse(dateString, inputDateFormat, new Date());
  return format(dateObj, targetDateFormat);
}

/**
 * Get a merged date range string.
 * @param {string} startDateString - Start date in yyyy-MM-dd format. 2021-01-01
 * @param {string} endDateString - End date in yyyy-MM-dd format.
 * @response {string} i.e., 'Jane 11 - June 12, 2021'
 */
export function getMergedDateRange(startDateString, endDateString) {
  let formattedStart = reformatDateString(startDateString, 'yyyy-MM-dd', 'MMMM d, y');
  const formattedEnd = reformatDateString(endDateString, 'yyyy-MM-dd', 'MMMM d, y');
  // Merge common year if start and end has the same value
  if (formattedStart.split(', ')[1] === formattedEnd.split(', ')[1]) {
    [formattedStart] = formattedStart.split(', ');
  }
  return `${formattedStart} - ${formattedEnd}`;
}

/**
 * Get days interval between two dates.
 * @param {string} startDate - Start date in yyyy-MM-dd format. 2021-01-01
 * @param {string} endDate - End date in yyyy-MM-dd format.
 * @response {int} i.e., 3
 */
export function differenceBetweenDays(startDate, endDate) {
  return differenceInCalendarDays(new Date(endDate), new Date(startDate));
}

/**
 * Return the formatted date string in the given format.
 * @param {object} date
 * @param {string} pattern(Select it from here:https://date-fns.org/v2.16.1/docs/format)
 * @returns {string | *}
 */
export function formatDate(date, pattern) {
  return format(date, pattern);
}

/**
 * Check if date is within interval.
 * @param {Date} date
 * @param {Interval | false} interval
 * @returns {Boolean}
 */
export const isWithinInterval = (date, interval) =>
  !!interval && date >= interval.start && date <= interval.end;

/**
 * Check if intervalOne is contained within intervalTwo.
 * @param {Interval} intervalOne
 * @param {Interval} intervalTwo
 * @returns {Boolean}
 */
export const isIntervalWithinInterval = (intervalOne, intervalTwo) =>
  isWithinInterval(intervalOne.start, intervalTwo) &&
  isWithinInterval(intervalOne.end, intervalTwo);

/**
 * Get date interval from start of day to end of day
 * @param {Date} date
 * @returns {Interval}
 */
export const getDayAsInterval = (date) => ({
  start: startOfDay(date),
  end: endOfDay(date),
});

/**
 * Returns true if date is both a Date object and a valid Date.
 * @param {Date} date
 * @returns {Boolean}
 */
export const isValidDate = (date) => isDate(date) && isValid(date);

export const isValidDateString = (date) => {
  const dateValue = Date.parse(date);
  return isValidDate(new Date(dateValue));
};

/**
 * Attempts to parse isoString into a Date. If it cannot be successfully parsed,
 * returns fallbackDate instead.
 * @param {string} isoString
 * @param {Date} fallbackDate
 * @returns {Date}
 */
export const parseISOOrElse = (isoString, fallbackDate) =>
  isValid(parseISO(isoString)) ? parseISO(isoString) : fallbackDate;

/**
 * Returns true if interval is valid.
 * @param {Interval} interval
 * @returns {Boolean}
 */
export const isValidInterval = (interval) =>
  isValidDate(interval?.start) &&
  isValidDate(interval?.end) &&
  (isBefore(interval.start, interval.end) || isEqual(interval.start, interval.end));

/**
 * Returns date if date is within interval -- otherwise returns the nearest endpoint of interval.
 * @param {Date} date
 * @param {Interval} interval
 * @returns {Boolean}
 */
export const clamp = (date, interval) => {
  if (dayjs(date).isBefore(interval.start)) return interval.start;
  if (dayjs(date).isAfter(interval.end)) return interval.end;
  return date;
};

/**
 * Returns a list of dates for each day in a specified interval
 * @param {Interval} interval
 * @returns {Date[]}
 */
export const eachDayOfInterval = (interval) => {
  let day = dayjs(interval.start).startOf('day');
  const dates = [];
  while (!day.isAfter(interval.end)) {
    dates.push(day.toDate());
    day = day.add(1, 'day');
  }
  return dates;
};

/**
 * Check if intervals overlap each other inclusively.
 * @param {Interval | false} intervalOne
 * @param {Interval | false} intervalTwo
 * @returns {Boolean}
 */
export const areIntervalsOverlapping = (intervalOne, intervalTwo) =>
  !!intervalOne &&
  !!intervalTwo &&
  (isWithinInterval(intervalOne.start, intervalTwo) ||
    isWithinInterval(intervalOne.end, intervalTwo) ||
    isWithinInterval(intervalTwo.start, intervalOne) ||
    isWithinInterval(intervalTwo.end, intervalOne));

export function isRangeInCurrentYear(range) {
  const today = dayjs();
  const start = dayjs(range?.[0], 'YYYY-MM-DD');
  const end = dayjs(range?.[1], 'YYYY-MM-DD');
  return today.isSame(start, 'year') && today.isSame(end, 'year');
}

export function bothRangesInCurrentYear(firstRange, secondRange) {
  return isRangeInCurrentYear(firstRange) && isRangeInCurrentYear(secondRange);
}

export function isSameRange(firstRange, secondRange) {
  const firstStartDate = dayjs(firstRange?.[0], 'YYYY-MM-DD');
  const firstEndDate = dayjs(firstRange?.[1], 'YYYY-MM-DD');
  const secondStartDate = dayjs(secondRange?.[0], 'YYYY-MM-DD');
  const secondEndDate = dayjs(secondRange?.[1], 'YYYY-MM-DD');
  return firstStartDate.isSame(secondStartDate, 'day') && firstEndDate.isSame(secondEndDate, 'day');
}

export function isSameDateRange(firstRange, secondRange) {
  const firstStartDate = dayjs(firstRange?.[0]);
  const firstEndDate = dayjs(firstRange?.[1]);
  const secondStartDate = dayjs(secondRange?.[0]);
  const secondEndDate = dayjs(secondRange?.[1]);
  return firstStartDate.isSame(secondStartDate, 'day') && firstEndDate.isSame(secondEndDate, 'day');
}

/**
 * Get Interval from today looking back a number of days.
 * @param {Number} daysBack
 * @param {string} pattern Select it from here:https://date-fns.org/v2.16.1/docs/format) default 'YYYY-MM-DD'
 * @returns {Interval} but as string type in given format
 */
export function getDateRangeDaysBack(daysBack, pattern = 'YYYY-MM-DD') {
  const endDate = dayjs().format(pattern);
  const startDate = dayjs().subtract(daysBack, 'day').format(pattern);

  return [startDate, endDate];
}

/**
 * Find the max of a list of dates.
 * @param {Date} dates
 * @returns {Date}
 */
export const maxDates = (...dates) =>
  dates.reduce((maxSoFar, d) => (d > maxSoFar ? d : maxSoFar), DISTANT_PAST);

/**
 * Find the min of a list of dates.
 * @param {Date} dates
 * @returns {Date}
 */
export const minDates = (...dates) =>
  dates.reduce((minSoFar, d) => (d < minSoFar ? d : minSoFar), FAR_FUTURE);

/**
 * Find the interval consisting of dates which fall within both provided intervals.
 * @param {(Interval | false)[]} intervals
 * @returns {Interval | false}
 */
export const intersectIntervals = (...intervals) => {
  if (intervals.some((interval) => !interval)) {
    return false;
  }
  const start = maxDates(...intervals.map((interval) => interval.start));
  const end = minDates(...intervals.map((interval) => interval.end));
  return end < start ? false : { start, end };
};

/**
 * Find the duration of a date interval in milliseconds
 * @param {Interval | false} interval
 * @returns {Number}
 */
export const getIntervalMillisecondDuration = (interval) => {
  if (!interval) {
    return 0;
  }
  return interval.end - interval.start;
};

/**
 * Return an interval including all future dates.
 * @returns {Interval}
 */
export const getFutureAsInterval = () => ({ start: new Date(), end: FAR_FUTURE });

/**
 * Return true if the interval is open-ended.
 * @param {Interval | false} interval
 * @returns {Boolean}
 */
export const isAllTime = (interval) =>
  interval?.start === DISTANT_PAST && interval?.end === FAR_FUTURE;

/**
 * Return an array of date strings equal or between a start and end date
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {String} dateFmt
 * @param {String} timeUnit
 * @returns {String[]}
 */
export const generateStringsForDateRange = (startDate, endDate, dateFmt, timeUnit) => {
  const dateRange = [];
  let currentDate = dayjs(startDate);
  while (!currentDate.isAfter(endDate, timeUnit)) {
    dateRange.push(currentDate.format(dateFmt));
    currentDate = currentDate.add(1, timeUnit);
  }
  return dateRange;
};

/**
 * Parses a date string into a Date object
 * @param {String} value
 * @returns {Date}
 */
export function parseDate(value) {
  return dayjs(value, 'YYYY-MM-DD').toDate();
}

/**
 * Returns Date object
 * @param {Number} value
 * @returns {Date}
 */
export function daysAgo(value) {
  return dayjs().subtract(value, 'd').toDate();
}

/**
 * Return date interval with a specified amount of time subtracted from the specified date
 * @param {Date|String} endDate
 * @param {Number} subtractValue
 * @param {String} timeUnit
 * @returns {Interval}
 */
export function getDateRange(endDate, subtractValue, unit = 'day') {
  const endDayJs =
    unit === 'day' ? dayjs(endDate) : dayjs(endDate).startOf(unit).subtract(1, 'day');
  const end = dayjs(endDayJs).endOf('day').toDate();

  const offset = unit === 'day' ? subtractValue : subtractValue - 1;
  const startDayJs =
    subtractValue > 1 || unit !== 'day' ? dayjs(end).subtract(offset, unit) : dayjs(end);
  const start = startDayJs.startOf(unit).toDate();
  return { start, end };
}

/**
 * Return date interval with a specified amount of time subtracted
 * @param {Number} subtractValue
 * @param {String} timeUnit
 * @returns {Interval}
 */
export function lastDateRange(subtractValue = 1, unit = 'day') {
  return getDateRange(new Date(), subtractValue, unit);
}

/**
 * Get a date range starting from a given number of days ago to today
 * @param {Number} offsetDays
 * @returns {DayjsInterval}
 */
export function getDateRangeOffsetFromToday(offsetDays) {
  return {
    start: dayjs().subtract(offsetDays, 'd').startOf('day'),
    end: dayjs().endOf('day'),
  };
}

/**
 * Returns a date range spanning from start of first day to end of last day
 * @param {Date|String} startDate
 * @param {Date|String} endDate
 * @returns {DayjsInterval}
 */
export function getDateRangeFromDates(startDate, endDate) {
  return {
    start: dayjs(startDate).startOf('day'),
    end: dayjs(endDate).endOf('day'),
  };
}
