<template>
  <section class="all-insights-container">
    <section class="main-insights-container">
      <InsightsDropdown
        :platform-icon="platformIcon"
        :has-insights-access="true"
        @toggle-insights="sendToggleEvent"
      >
        <template #dropdownContents>
          <div class="insights-list">
            <div v-if="!insightsExist" class="alert-message">
              <p>{{ noInsightsExistMsg }}</p>
            </div>
            <div v-else>
              <ul class="insights-stats">
                <template v-for="(insight, index) in insights" :key="index">
                  <li :class="{ indented: insight.isIndented }">
                    <span class="stat-name">
                      {{ formatKey(insight.label) }}
                      <InfoTooltip v-if="!!insight?.tooltip" :tooltip="insight.tooltip" xsmall />
                    </span>
                    <span class="stat">{{ formatValue(insight.value) }}</span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <CustomMetricsInsights
            v-if="canAccessCustomMetrics && channelWithCustomMetrics && insightsExist"
            :media-item="mediaItem"
          />
        </template>
      </InsightsDropdown>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import InsightsDropdown from '@/components/common/MediaPopupV2/InsightsDropdown.vue';
import CustomMetricsInsights from '@/app/library/components/MediaPopup/CustomMetricsInsights.vue';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Insights',
  components: { InsightsDropdown, InfoTooltip, CustomMetricsInsights },
  props: {
    // [{label: str, value: number, isIndented: bool, tooltip: string}]
    insights: { type: Array, default: () => [] },
    mediaItem: { type: Object, default: null },
    platformIcon: { type: String, required: true },
    noInsightsExistMsg: { type: String, default: null },
    channelWithCustomMetrics: { type: Boolean, default: false },
  },
  emits: ['toggleInsights'],
  setup() {
    const { canAccessCustomMetrics } = useCustomMetrics();
    return {
      canAccessCustomMetrics,
    };
  },
  computed: {
    insightsExist() {
      return this.insights.length > 0;
    },
  },
  methods: {
    sendToggleEvent(isOpen) {
      const actionName = isOpen ? 'Show' : 'Hide';
      this.$emit('toggleInsights', 'Insights', `${actionName} insights`);
    },
    formatValue(value) {
      if (value === null || value === undefined) {
        return '-';
      }
      return value;
    },
    formatKey(key) {
      return startCase(key);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.all-insights-container {
  width: 100%;

  .main-insights-container {
    background: var(--background-300);
    border-radius: var(--round-corner);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-24);
    margin: var(--space-8) auto;
    font-size: var(--x14);
    color: var(--text-primary);
  }
}

.insights-list {
  .stat-interval {
    display: flex;
    align-items: center;
    margin: var(--space-16) 0 0 var(--space-32);
    padding-left: var(--space-4);

    .select-control {
      border: 1px solid var(--border);
      border-radius: var(--button-border-radius);
      height: var(--space-32);
      background: var(--background-0);
      align-items: center;
      margin: 0 0 0 auto;

      :deep(.picker) {
        padding-bottom: var(--space-12);
        background-position-y: 0.3rem;
        font-size: var(--x12);
      }
    }

    p {
      color: var(--text-secondary);
      font-size: var(--x12);
    }
  }

  .warning-message {
    color: var(--error-500);
  }

  .stat-name {
    margin-right: var(--space-12);
  }

  .indented {
    padding-left: var(--space-30);
  }
}
</style>
