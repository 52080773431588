import isFunction from 'lodash/isFunction';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { watchGlobalModals } from '@/utils/globalModals';

export function useGlobalModals() {
  const globalModals = ref([]);
  const unsubscribeCallback = ref(null);

  function modalOpenState(name) {
    return computed(() => globalModals.value.some((modal) => modal.name === name));
  }

  const hasGlobalModal = computed(() => globalModals.value?.length > 0);

  onMounted(() => {
    unsubscribeCallback.value = watchGlobalModals(async (modals) => {
      globalModals.value = await Promise.all(
        modals.map(async (modal) => {
          const component = isFunction(modal.component) ? modal.component() : modal.component;
          // Uses internal Vue implementation detail, may break in the future
          // https://github.com/vuejs/core/blob/main/packages/runtime-core/src/apiAsyncComponent.ts
          if (isFunction(component.__asyncLoader)) {
            return component.__asyncLoader();
          }
          return component;
        }),
      );
    });
  });

  onUnmounted(() => {
    unsubscribeCallback.value?.();
  });

  return {
    modalOpenState,
    hasGlobalModal,
    ...(process.env.NODE_ENV === 'test' ? { unsubscribeCallback } : {}),
  };
}
