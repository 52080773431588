import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { browserStorageSetItem } from '@/utils/browserStorage';
import enumTypes from '@/app/library/constants';
import router from '@/router';
import {
  clearGlobalModals,
  getGlobalModals,
  popGlobalModal,
  pushGlobalModal,
} from '@/utils/globalModals';
import { useAuthStore } from '@/stores/auth';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { usePlatformStore } from '@/stores/platform';
import { stringifyQuery } from '@/utils/query';
import pinia from '@/stores/pinia';
import { useFlagStore } from '@/stores/flag';
import { trackSubscriptionUpdated } from '@/app/settings/mixpanel';
import { useImpersonatorStore } from '@/stores/impersonator';
import { useIdentityStore } from '@/stores/identity';
import { loginPageRedirect } from '@/utils/redirect';
import { useTrackingStore } from '@/stores/tracking';
import { useHubspotStore } from '@/stores/hubspot';
import { DASHBOARD_ROUTE_NAMES } from '@/app/dashboards/constants';

const flagStore = useFlagStore(pinia);

function isImpersonatedUserTokenExpired() {
  const impersonatorStore = useImpersonatorStore(pinia);
  return impersonatorStore.isImpersonating && impersonatorStore.isImpersonatedUserExpired;
}

async function processLogout(next) {
  const authStore = useAuthStore(pinia);
  await authStore.logout();
  next(false);
}

function setCurrentBrandForRoute(to, from) {
  const authStore = useAuthStore(pinia);
  const brandLabel = isFunction(to?.params?.brandLabel)
    ? to.params.brandLabel()
    : to?.params?.brandLabel;
  if (
    brandLabel &&
    brandLabel !== authStore.currentBrandLabel &&
    from.name !== DASHBOARD_ROUTE_NAMES.ID
  ) {
    authStore.setCurrentBrand({ brandLabel });
  }
}

function redirectLegacyURL(to, next) {
  const authStore = useAuthStore(pinia);
  // Assign redirect to brand
  let brandLabel = authStore.currentBrand?.label;
  let { query } = to;
  const { href } = to;
  // sometimes an image url has additional parameters that result in a broken query
  if (href && href.startsWith('/library/discover')) {
    const url = href.match(/\?(url=)([^&]+)(\?.+)?$/) || [];
    if (url.length >= 3) {
      query.url = url[2];
      query.urlParams = url[3] ?? '';
    }
  }
  // If this is a report, and there's a brand query param, we need to use it as the brand
  // label instead of what's stored in Pinia -- this is to support redirects from admin
  // before it has been updated to use the new URLs, and can be removed once it has been updated.
  if (to.matched.some((route) => route.meta.report) && query.brand) {
    const { brand, ...remainingQuery } = query;
    brandLabel = brand;
    query = remainingQuery;
  }

  next({ path: `/${brandLabel}${to.path}`, query, replace: true });
}

function openGlobalModal(to, from, next) {
  const modal = to.meta?.modal;
  // Use rootPath defined in the route meta, otherwise get last available matched path
  let rootPath = to.meta?.rootPath ? to.meta.rootPath : to.matched[to.matched.length - 2]?.path;
  if (rootPath && to.params.brandLabel) {
    rootPath = rootPath.replace(/:brandLabel[^/]*/, to.params.brandLabel);
  }
  const coldLoadModal = !from.name;
  const fromForwardButton = window.location.pathname === to.fullPath && !coldLoadModal;
  const fromLogin = from.name === 'auth.login';
  const { replaceModal: _, ...restOfQuery } = to.query ?? {};
  const shouldReplace = to.query?.replaceModal && !coldLoadModal;

  if (coldLoadModal || fromLogin) {
    // push the root path onto the history stack
    // so we have something to go back to when closing the modal
    window.history.pushState({}, null, rootPath);
  }

  // push a state onto the browser history stack to allow the user to see the modal's url,
  // and go back to dismiss
  if (!fromLogin && !fromForwardButton) {
    let { path } = to;

    if (!isEmpty(restOfQuery)) {
      path = `${path}?${stringifyQuery(restOfQuery)}`;
    }
    window.history[shouldReplace ? 'replaceState' : 'pushState'](
      { modalCount: getGlobalModals().length + 1 },
      null,
      path,
    );
  }

  if (shouldReplace) {
    popGlobalModal();
  }

  pushGlobalModal({
    component: modal.component,
    props: {
      ...(modal.props || {}),
      ...restOfQuery,
      ...to.params,
      ...(to.query ?? {}),
      hash: to.hash?.replace?.(/^#/, ''),
    },
  });

  // Prevent legacy route redirect from duplicating modals
  if (to.matched.some((route) => route.meta.legacy)) {
    popGlobalModal();
  }

  // if this is a cold load of the modal, we need to load the initial route.
  if (coldLoadModal || fromLogin) {
    next();
  }
}

// this navigation guard enables global modals with their own URL
// (see docs/global_modals.md for details)
function handleGlobalModalRouting(to, from, next) {
  if (to.meta?.modal) {
    openGlobalModal(to, from, next);
  } else {
    next();

    // if navigating anywhere without a modal specified, close all global modals.
    clearGlobalModals();
  }
}

async function handlePlatformConnection(to, from, next) {
  const authStore = useAuthStore(pinia);
  const platformStore = usePlatformStore(pinia);
  const { token, brandId } = to.params;
  browserStorageSetItem('oauthBrandToken', token);
  const brandParams = { ids: brandId };
  const platform =
    to.meta.platform === 'pinterest_v5' ? 'pinterest_v5' : snakeCase(to.meta.platform);
  const tokenKey = `connect_${platform}_token`;
  brandParams[tokenKey] = token;
  if (
    to.meta.platform === enumTypes.INSTAGRAM_INFLUENCER ||
    to.meta.platform === enumTypes.TIKTOK_CREATOR
  ) {
    brandParams.is_influencer = true;
  }

  authStore.setCurrentBrandByAttributes({ brand: { id: brandId } });
  try {
    const brands = await authStore.getBrands(brandParams);
    authStore.setCurrentBrandByAttributes({ brand: brands[0] });
    await platformStore.getPlatformConnectionsByBrandToken({
      brandId,
      brandToken: token,
      platformType: platform,
    });
  } catch (error) {
    // fail here most likely meaning the brand_token is bad, so we redirect to login
    // to prevent the connect page from being exposed to public who doesn't have valid token
    next({ name: 'auth.login' });
  }

  const platformConnectionsByBrandToken = platformStore.platformConnectionsByBrandToken;
  // redirect to success page for connected brands, otherwise go to connection
  try {
    if (platformConnectionsByBrandToken[0].status === 'connected') {
      const platformType =
        to.meta.platform === enumTypes.FACEBOOK_ANALYTICS ||
        to.meta.platform === enumTypes.FACEBOOK_SANDBOX
          ? 'facebook'
          : to.meta.platform;
      if (
        to.meta.platform !== enumTypes.INSTAGRAM_INFLUENCER &&
        to.meta.platform !== enumTypes.TIKTOK_CREATOR
      ) {
        const connectionSuccessName = `connect.${camelCase(platformType)}.success`;
        next({ name: connectionSuccessName });
      } else {
        next();
      }
    } else if (platformConnectionsByBrandToken[0].id) {
      const connectionId = platformConnectionsByBrandToken[0].id;
      browserStorageSetItem('oauthConnectionId', connectionId);
      next();
    } else {
      next();
    }
  } catch (error) {
    next();
  }
}

async function skipLoginForReports(to, from, next) {
  const authStore = useAuthStore(pinia);
  try {
    const { token } = to.query;
    const brand = to.query?.brand ?? to.params.brandLabel;
    if (token) {
      authStore.setToken({ token });
    }
    await authStore.loginByTokenAndBrandLabel({ token, brand });
  } catch (error) {
    if (error?.response?.status === 401) {
      next({ name: 'auth.login' });
    } else {
      throw error;
    }
  }
}

function handleReportRoutes(to, from, next) {
  const authStore = useAuthStore(pinia);
  if (to.matched.some((route) => route.meta.legacy)) {
    redirectLegacyURL(to, next);
  } else if (authStore.isLoggedIn) {
    next();
  } else {
    next({ name: 'auth.login' });
  }
}

async function handleAuthenticatedRoutes(to, from, next) {
  const authStore = useAuthStore(pinia);
  if (authStore.isLoggedIn) {
    if (!authStore.identity) {
      await authStore.logout();
      return;
    }

    authStore.checkRouteAccess({ route: to });

    // If route is a legacy route, redirect the user
    if (to.matched.some((route) => route.meta.legacy)) {
      redirectLegacyURL(to, next);
    } else {
      next();
    }
  } else {
    authStore.setRouteUnauthorized({ unauthorized: true });
    next({ name: 'auth.login', query: { next: to.fullPath } });
  }
}

export async function handleRedirectsBasedOnPlatformConnections(to, from, next) {
  // Disable this check for e2e tests
  if (window.Cypress) {
    next();
    return;
  }

  const targetRouteAllowedWhenNoConnections = to.matched.some(
    (route) => !!route?.meta?.allowWhenNoConnections,
  );

  if (targetRouteAllowedWhenNoConnections) {
    next();
    return;
  }

  const platformStore = usePlatformStore(pinia);
  if (platformStore.platformConnectionsInitialized === false) {
    await platformStore.getPlatformConnections();
  }

  const authStore = useAuthStore(pinia);
  const customerJourneyStore = useCustomerJourneyStore(pinia);
  const brandUserNeedsToConnect =
    customerJourneyStore.isProductLedGrowTrial &&
    !authStore.canAccessMultipleBrands &&
    !platformStore.hasOneRequiredBrandPlatformConnection &&
    !authStore.canOnlyAccessInactiveBrand;

  if (brandUserNeedsToConnect) {
    if (to?.name === 'onboardingFlowPage') {
      next();
      return;
    }
    next({ name: 'onboardingFlowPage' });
  } else {
    next();
  }
}

export async function globalBeforeEach(to, from, next) {
  const identityStore = useIdentityStore(pinia);
  const authStore = useAuthStore(pinia);
  // If we don't have the  user's identity, try to fetch it from the /self endpoint and put it in the store.
  if (!authStore.hasAttemptedIdentification && !authStore.isLoggedIn) {
    await authStore.identifyOrLogout();
  }

  // This prevents users from avoiding the setup by pasting another page's url
  if (
    router &&
    identityStore.identity?.invitation_status === 'accepted' &&
    to.name !== 'account.setup'
  ) {
    loginPageRedirect(router);
  }

  // Skip login page for reports - these are only accessed by the PDF service which provides
  // a brand and access token via query params.
  if (to.matched.some((route) => route.meta.report)) {
    await skipLoginForReports(to, from, next);
  }

  // If a user's ID is provided in query params, initialize impersonation
  const impersonatorStore = useImpersonatorStore(pinia);
  const { impersonateUserId } = to?.query ?? {};
  if (
    impersonateUserId &&
    impersonateUserId !== impersonatorStore.impersonatedUserId &&
    (identityStore.isSuperAdmin || impersonatorStore.isImpersonating)
  ) {
    await impersonatorStore.impersonateUserById({ userId: impersonateUserId });
    const identified = await authStore.identifyOrLogout();
    if (!identified) {
      next({ name: 'auth.login', query: { next: to.fullPath } });
      return;
    }
    setCurrentBrandForRoute(to, from);
  }

  if (isImpersonatedUserTokenExpired()) {
    await processLogout(next);
    return;
  }

  if (!from?.name && (to?.query?.cancelled || to?.query?.success)) {
    trackSubscriptionUpdated({ lookupKey: to?.query?.price, success: !!to.query?.success });
  }

  if (
    to.path === '/' &&
    to.matched.length > 1 &&
    to.params?.brandLabel &&
    !to.path.includes(to.params.brandLabel)
  ) {
    const { name, params, query } = to;
    next({ name, params, query, replace: true });
  }

  // If the route is for one of our "Connect Your <Platform> Account" pages (e.g.
  // /brand-connect-facebook/<brand-id>/<token>), check to see if the brand is already connected
  // and show either the connection success page or the connection prompt.
  // TODO: Move this out of the global route guard and into a per-route guard or in-component
  // guard. See https://router.vuejs.org/guide/advanced/navigation-guards.html#per-route-guard.
  else if (to.matched.some((route) => route.meta.connection)) {
    await handlePlatformConnection(to, from, next);
  } else if (to.matched.some((route) => route.meta.report)) {
    handleReportRoutes(to, from, next);
  }

  // If the route is login protected and the user isn't logged in, show them the login page.
  else if (to.matched.some((route) => route.meta.requiresAuth)) {
    await handleAuthenticatedRoutes(to, from, next);
  }

  // Continue to next route otherwise
  else {
    next();
  }
}

async function waitForFlags(to, from, next) {
  const authStore = useAuthStore(pinia);
  if (authStore.isLoggedIn) {
    try {
      await flagStore.waitForUserFlags;
    } finally {
      next();
    }
  } else {
    next();
  }
}

function updateCurrentBrandIfRequired(to, from, next) {
  setCurrentBrandForRoute(to, from);
  next();
}

function refreshHubSpotCallToAction(to, from) {
  if (to?.path !== from?.path) {
    const hubSpotStore = useHubspotStore(pinia);
    hubSpotStore.refreshCallToAction();
  }
}

function trackHubSpotEvent(to, from) {
  if (to?.path !== from?.path) {
    const hubSpotStore = useHubspotStore(pinia);
    hubSpotStore.trackEvent({ eventName: 'trackPageView' });
  }
}

export function handleExpiredTrialAccess(to, from, next) {
  if (['settings.billing', 'settings.socialPlatform', 'auth.logout', 'plans'].includes(to?.name)) {
    next();
  } else {
    const authStore = useAuthStore(pinia);
    if (authStore.canOnlyAccessInactiveBrand) {
      next({ name: 'settings.billing', params: { brandLabel: authStore.currentBrandLabel } });
    } else {
      next();
    }
  }
}

useTrackingStore(pinia).setupRouter(router);

router.beforeEach(updateCurrentBrandIfRequired);
router.beforeEach(waitForFlags);
router.beforeEach(handleGlobalModalRouting);
router.beforeEach(globalBeforeEach);
router.beforeEach(handleExpiredTrialAccess);
router.beforeEach(handleRedirectsBasedOnPlatformConnections);
router.afterEach(refreshHubSpotCallToAction);
router.afterEach(trackHubSpotEvent);

export default router;
