<template>
  <div class="add-to-dropdown">
    <div class="gallery-search-dropdown">
      <MultiSelectList
        v-model="selectedUnscheduledChannels"
        :loading="addToUnscheduledPending"
        :items="multiSelectItems"
        hide-search-bar
        class="gallery-search-list"
      >
        <template #topNav>
          <div class="back" @click="handleBackClick">
            <Icon name="caret" dir="down" xxsmall />
          </div>
        </template>
        <template #footer>
          <footer>
            <a />
            <a
              :class="{ disabled: selectedUnscheduledChannels.length === 0 }"
              class="blue"
              data-cy="save-to-unscheduled"
              @click="handleSaveClicked"
            >
              Save
            </a>
          </footer>
        </template>
      </MultiSelectList>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import enumTypes, { connectionPlatforms, mediaTypes } from '@/app/library/constants';
import Icon from '@/components/foundation/Icon.vue';
import MultiSelectList from '@/components/MultiSelectListRefactored.vue';
import { toolTips } from '@/config';
import { useSchedulerStore } from '@/stores/scheduler';
import { useSearchStore } from '@/stores/search';
import { useMediaSelectStore } from '@/stores/media-select';
import { useSharedStore } from '@/stores/shared';
import { useFlagStore } from '@/stores/flag';
import { fbPostTypes } from '@/app/scheduler/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AddToUnscheduled',
  components: {
    Icon,
    MultiSelectList,
  },
  props: {
    // Media that comes from the global popup
    selectedMedia: { type: Object, default: null },
  },
  emits: ['back', 'close'],
  data() {
    return {
      addToUnscheduledPending: false,
      selectedUnscheduledChannels: [],
    };
  },
  computed: {
    ...mapStores(
      useSchedulerStore,
      useNotificationStore,
      useSearchStore,
      useMediaSelectStore,
      useSharedStore,
      useTrackingStore,
      useFlagStore,
    ),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'user_can', 'brand_can']),
    channels() {
      const { name } = this.$route;
      const channels = [
        'TikTok',
        'Instagram Feed',
        'Instagram Story',
        'Instagram Reel',
        'Facebook',
        'Pinterest',
        'X',
        'LinkedIn',
      ];
      // filter out current channel as one of the 'add to unscheduled' options
      return channels.filter(
        (channel) => name !== `scheduler.${channel.split(' ')[0].toLowerCase()}.unscheduled`,
      );
    },
    showLinkedInSelectItem() {
      return this.flagStore.ready && this.flagStore.flags.linkedinAutoPublishingWeb;
    },
    multiSelectItems() {
      let channels = this.channels;
      if (!this.showLinkedInSelectItem) {
        channels = this.channels.filter((channel) => channel !== 'LinkedIn');
      }
      return channels.map((channel) => ({
        id: channel,
        name: channel,
        disabled: this.isDisabled(channel),
        tooltip: this.getTooltip(channel),
      }));
    },
  },
  methods: {
    handleBackClick() {
      this.$emit('back');
    },
    getPostType(channel) {
      if (channel.id === 'Facebook') {
        return fbPostTypes.POST;
      }
      return channel.id.split(' ').pop().toUpperCase() ?? 'FEED';
    },
    handleSaveClicked: debounce(function preventFastClick() {
      this.handleSave();
    }, 50),
    async handleSave() {
      let mediaList = [];
      // If the items are selected using multiselect
      if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        if (this.$route.meta.schedulerRoute) {
          // multi selected items are post instead of media in scheduler routes
          this.mediaSelectStore.multiSelectSelectedItems.forEach((item) => {
            if (item.media_ids) {
              mediaList = [...mediaList, ...item.media_ids];
            } else mediaList = [...mediaList, ...item.mediaIds];
          });
        } else {
          mediaList = this.mediaSelectStore.multiSelectSelectedItems.map((item) => item.id);
        }
      } else if (this.selectedMedia) {
        // If the item is being added from the media popup
        mediaList.push(this.selectedMedia.id);
      } else if (this.uploadedMedia.length > 0) {
        mediaList = this.uploadedMedia.map((media) => media.id);
      }

      // select channels of unscheduled post
      this.addToUnscheduledPending = true;
      await Promise.all(
        this.selectedUnscheduledChannels.map(async (channel) => {
          if (this.selectedMedia) {
            // add to unscheduled from media popup, has all media detail information
            // send over caption
            let baseCaption = this.selectedMedia?.postCaption || '';
            if (this.selectedMedia.sourceType === enumTypes.INSTAGRAM_UGC) {
              baseCaption = this.selectedMedia?.userName;
            } else if (this.selectedMedia.postType === connectionPlatforms.TWITTER) {
              baseCaption = this.selectedMedia?.tweetStatus;
            } else if (this.selectedMedia.postType === connectionPlatforms.FACEBOOK) {
              baseCaption = this.selectedMedia?.message || this.selectedMedia?.title;
            }
            const captionHandle =
              this.selectedMedia.sourceType === enumTypes.INSTAGRAM_UGC
                ? this.selectedMedia.userName
                : null;
            const postData = {
              brandId: this.currentBrand.id,
              mediaIds: [this.selectedMedia.id],
              timestamp: null,
              autoPublish: false,
              postType: this.getPostType(channel),
            };
            if (channel.id.split(' ').pop().toUpperCase() === enumTypes.LINKEDIN) {
              postData.text = baseCaption + (captionHandle ? ` | @${captionHandle}` : '');
            } else {
              postData.caption = baseCaption + (captionHandle ? ` | @${captionHandle}` : '');
            }
            // make 'Instagram Story', 'Instagram Feed' to 'Instagram' here
            return this.createPost(channel, postData);
          }
          if (this.$route.meta.schedulerRoute) {
            // TODO: implement copy post across channels from scheduled backend
            // add posts from one unscheduled channel to another
            const postMediaList = this.mediaSelectStore.multiSelectSelectedItems
              .map((item) => {
                if (item.media) {
                  if (item.media_ids) {
                    return {
                      id: item.media_ids[0], // main media,
                      ids: item.media_ids, // all carousel media id
                    };
                  }
                  return {
                    id: item.mediaIds[0], // main media,
                    ids: item.mediaIds, // all carousel media id
                  };
                }
                return null;
              })
              .filter((item) => item !== null);
            await this.searchStore.getMediaSourceList({
              brandId: this.currentBrand.id,
              globalIds: postMediaList.map((media) => media.id).join(','),
            });
            postMediaList.map((media) => {
              const formattedPost = {
                brandId: this.currentBrand.id,
                mediaIds: media.ids,
                timestamp: null,
                autoPublish: false,
                postType: this.getPostType(channel),
                caption: this.searchStore.mediaSourceList?.[media.id]?.sourceData?.caption ?? '',
              };
              this.createPost(channel, formattedPost);
              return media;
            });
          } else {
            // add multiple media from media list
            await this.searchStore.getMediaSourceList({
              brandId: this.currentBrand.id,
              globalIds: mediaList.join(','),
            });
            const mediaDetailDict = keyBy(
              this.searchStore.mediaSourceList.data,
              (media) => media.mediaId,
            );
            return Promise.all(
              mediaList.map(async (media) => {
                const mediaDetail = mediaDetailDict?.[media];
                const formattedPost = this.createFormattedPost(
                  mediaDetail,
                  channel.id.toUpperCase(),
                );
                await this.createPost(channel, formattedPost);
                return media;
              }),
            );
          }
          return undefined;
        }),
      );
      this.$emit('close');
      this.sharedStore.clearSelectedMultiSelectListItems();
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.addToUnscheduledPending = false;
      if (this.selectedUnscheduledChannels.length > 0) {
        this.notificationStore.setToast({
          message: 'Media has been added to unscheduled posts.',
        });
        this.trackingStore.track('Media added to Unscheduled Posts.', {
          mediaIds: mediaList,
          channels: this.selectedUnscheduledChannels.map((channel) => channel.id),
        });
      }
    },
    createFormattedPost(mediaDetail, targetChannel) {
      const formattedPost = {
        brandId: this.currentBrand.id,
        mediaIds: [mediaDetail.mediaId],
        timestamp: null,
        autoPublish: false,
      };

      let title = null;
      let description = null;

      if (mediaDetail.source === enumTypes.FACEBOOK) {
        title = mediaDetail?.sourceData?.title;
        description = mediaDetail?.sourceData?.message;
      } else if (mediaDetail.source === enumTypes.TWITTER) {
        description = mediaDetail?.sourceData?.text;
      } else if (mediaDetail.source === enumTypes.PINTEREST) {
        description = mediaDetail?.sourceData?.note;
      } else {
        const baseCaption = mediaDetail?.sourceData?.caption || '';
        const captionHandle =
          mediaDetail.type === enumTypes.INSTAGRAM_UGC
            ? mediaDetail?.sourceData?.instagramUser?.handle
            : null;

        description = baseCaption + (captionHandle ? ` | @${captionHandle}` : '');
      }

      if (targetChannel === enumTypes.FACEBOOK) {
        formattedPost.title = title;
        formattedPost.message = description;
        formattedPost.postType = fbPostTypes.POST;
      } else if (targetChannel === enumTypes.TWITTER) {
        formattedPost.tweetStatus = description;
      } else if (targetChannel === enumTypes.PINTEREST) {
        formattedPost.note = description;
      } else if (targetChannel === enumTypes.LINKEDIN) {
        formattedPost.text = description;
      } else {
        formattedPost.postType = targetChannel.split(' ').pop() ?? 'FEED';
        formattedPost.caption = description;
      }

      return formattedPost;
    },
    isDisabled(channel) {
      if (['TikTok', 'Instagram Reel', 'LinkedIn'].includes(channel)) {
        const supportedMediaType = ['TikTok', 'Instagram Reel'].includes(channel)
          ? mediaTypes.VIDEO
          : mediaTypes.IMAGE;
        return (
          !this.hasUserPermissions(channel) ||
          (this.selectedMedia == null &&
            this.mediaSelectStore.multiSelectSelectedItems.some(
              (item) =>
                item.mediaGroup === null ||
                (item.type !== supportedMediaType && item.mediaType !== supportedMediaType),
            )) ||
          (this.selectedMedia && this.selectedMedia.mediaType !== supportedMediaType)
        );
      }
      return false;
    },
    getTooltip(channel) {
      if (this.isDisabled(channel)) {
        if (!this.hasBrandPermissions(channel)) {
          return toolTips.featureNotIncludedInPlan;
        }
        if (!this.hasUserPermissions(channel)) {
          return toolTips.tikTokNoUserPermission;
        }
        if (channel === 'TikTok') {
          return toolTips.tikTokDisabledTooltip;
        }
        if (channel === 'LinkedIn') {
          return toolTips.linkedInDisabledTooltip;
        }
        return toolTips.reelsDisabledTooltip;
      }
      return '';
    },
    hasUserPermissions(channel) {
      if (channel === 'TikTok') {
        return (
          this.hasBrandPermissions(channel) &&
          this.user_can('scheduler', 'can_access_scheduler_tiktok')
        );
      }
      return true;
    },
    hasBrandPermissions(channel) {
      if (channel === 'TikTok') {
        return this.brand_can('scheduler', 'can_access_scheduler_tiktok');
      }
      return true;
    },
    async createPost(channel, data) {
      const platform = channel.id.split(' ')[0].toLowerCase();
      return this.schedulerStore.createPost({ platform, ...data });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.add-to-dropdown {
  z-index: var(--z-index-dropdown);
}

.gallery-search-dropdown,
.new-gallery-dropdown {
  position: absolute;
  z-index: var(--z-index-dropdown);
}

.gallery-search-list {
  padding-top: var(--space-8);
}

.gallery-search-list .back {
  margin-left: var(--space-16);
  height: auto;
  min-height: var(--space-16);
  text-align: left;
  font-size: var(--x14);
  color: var(--text-secondary);
  cursor: pointer;
  transition: var(--transition-all);

  &:hover {
    color: var(--action-500);
  }

  svg {
    margin-right: var(--space-8);
  }
}

.empty-gallery-view div {
  text-align: center;
  padding: var(--space-48) 0 var(--space-72);

  p {
    text-align: center;
    font-size: var(--x14);
    margin-bottom: var(--space-16);
  }

  .add-new-button {
    width: 8.3rem;
    margin: auto;
  }
}

footer {
  height: var(--space-48);
  background: var(--background-300);
  font-size: var(--x14);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-24);
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);

  a {
    &:hover {
      color: var(--action-500);
    }
  }

  a.disabled,
  a.disabled:hover {
    cursor: auto;
    opacity: 0.3;
  }

  .blue {
    color: var(--action-500);
  }
}
</style>
