import { useSearchStore } from '@/stores/search';
import { useDashboardsStore } from '@/stores/dashboards';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { sortBrandsByName } from '@/app/dashboards/utils/dashboard-general.util';

export function getAllAvailableCompetitorIdsByChannel(reportChannels) {
  const searchStore = useSearchStore();
  const dashboardsStore = useDashboardsStore();
  const dashboardCompetitiveIdentityBrandsByIds =
    dashboardsStore.dashboardCompetitiveIdentityBrands.map((brand) => brand.id);
  return (
    reportChannels?.reduce((acc, channel) => {
      if (CHANNELS[channel]?.competitiveChannelType) {
        acc[channel] =
          searchStore.competitors[CHANNELS[channel].competitiveChannelType]
            ?.filter((competitor) =>
              competitor?.brandDetails?.some((brandDetail) =>
                dashboardCompetitiveIdentityBrandsByIds.includes(brandDetail.brandId),
              ),
            )
            .map((competitor) => competitor.sourceAccountId) ?? [];
      }
      return acc;
    }, {}) ?? {}
  );
}

export function getCompetitorList(competitors, channel) {
  const filteredCompetitorList =
    competitors[channel]?.map((competitor) => {
      competitor.name = competitor.handle ?? '';
      competitor.avatarUrl = competitor.avatarUrl ?? '';
      return competitor;
    }) ?? [];
  return sortBrandsByName(filteredCompetitorList);
}
