import auth from '@/app/auth/routes';
import campaigns from '@/app/campaigns/routes';
import communityLegacy from '@/app/community/legacy-routes';
import community from '@/app/community/routes';
import competitive from '@/app/competitive/routes';
import creators from '@/app/creators/routes';
import dashboards from '@/app/dashboards/routes';
import facebook from '@/app/facebook/routes';
import instagram from '@/app/instagram/routes';
import landingPage from '@/app/landingPage/routes';
import library from '@/app/library/routes';
import linkedin from '@/app/linkedin/routes';
import pinterest from '@/app/pinterest/routes';
import relationships from '@/app/relationships/routes';
import reports from '@/app/reports/routes';
import scheduler from '@/app/scheduler/routes';
import settings from '@/app/settings/routes';
import socialListening from '@/app/socialListening/routes';
import tiktok from '@/app/tiktok/routes';
import twitter from '@/app/twitter/routes';
import youtube from '@/app/youtube/routes';
import threads from '@/app/threads/routes';
import { getGlobalModals, popGlobalModal } from '@/utils/globalModals';
import { logger } from '@/utils/logger';
import { parseQuery, routerStringifyQuery } from '@/utils/query';
import isFunction from 'lodash/isFunction';
import { h, resolveComponent, defineAsyncComponent } from 'vue';
import {
  NavigationFailureType,
  createRouter,
  createWebHistory,
  isNavigationFailure,
} from 'vue-router';
import LDRouteGuard from '@/components/core/LDRouteGuard';

const MediaPopup = () => import('@/app/library/components/MediaPopup.vue');
const PageNotFound = () => import('@/components/PageNotFound.vue');
const Plans = () => import('@/components/Plans.vue');
const CreatorAddons = defineAsyncComponent(() => import('@/components/CreatorAddons.vue'));
const InformationRequest = () => import('@/app/creators/pages/InformationRequest.vue');

export function handleHistoryPopState(event) {
  const stateModalCount = event.state?.modalCount || 0;
  const modalCount = getGlobalModals().length;
  const goingForward = stateModalCount > modalCount;
  if (!goingForward) {
    popGlobalModal();
    if (getGlobalModals().length > 0) {
      event.stopImmediatePropagation();
    }
  }
}

window.addEventListener('popstate', handleHistoryPopState);

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  parseQuery,
  stringifyQuery: routerStringifyQuery,
  scrollBehavior(to, from) {
    const scrollToTop = isFunction(to?.meta?.scrollToTop)
      ? to.meta.scrollToTop(to, from)
      : to?.meta?.scrollToTop;

    // make sure we scroll to top on route change if different path
    if (to?.path !== from?.path && (scrollToTop ?? true)) {
      document.getElementById('app')?.scrollIntoView();
    }
  },
  routes: [
    landingPage,
    auth,
    dashboards,
    // `legacy: true` will redirect users to our new routes that have the brand label in them
    {
      path: '/settings',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/facebook',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/instagram',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/library',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: 'media/:id',
          meta: { modal: { component: MediaPopup, legacy: true } },
        },
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/scheduler',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/tiktok',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/linkedin',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/twitter:pathMatch(.*)*',
      redirect: (to) => ({ ...to, path: to.path.replace('/twitter', '/x') }),
    },
    {
      path: '/x',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/youtube',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/threads',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/relationships',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/creators',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/campaigns',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/competitors',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/community',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/listening',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/messages',
      meta: { requiresAuth: true, legacy: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      path: '/reports',
      meta: { requiresAuth: true, legacy: true, report: true },
      children: [
        {
          path: ':_(.*)*',
          component: {},
        },
      ],
    },
    {
      name: 'creatorPlans',
      path: '/creator-plans',
      component: LDRouteGuard,
      props: {
        component: CreatorAddons,
        requiredFeatureFlag: 'creatorPackagingAndPermissions',
        to: { name: 'plans' },
      },
      meta: {
        requiresAuth: true,
        analytics: 'Creator Plans',
        legacy: false,
        hideSecondaryNav: true,
      },
    },
    // end legacy routes
    {
      name: 'plans',
      path: '/plans',
      component: Plans,
      meta: {
        analytics: 'Plans',
        legacy: false,
        requiresAuth: true,
        hideSecondaryNav: true,
      },
    },
    {
      name: 'informationRequestForm',
      path: '/information-request-form',
      component: InformationRequest,
      meta: {
        analytics: 'InformationRequest',
        requiresAuth: false,
        hideVisionAi: true,
        hideNav: true,
        hideSecondaryNav: true,
        hidePrimaryNav: true,
      },
    },
    {
      path: '/:brandLabel([A-Za-z0-9-]+)',
      redirect: { name: 'library' },
      component: {
        // For now, /:brandLabel is not a destination on its own, but is just a pass-through
        // to the children below it. Render a <router-view> component to complete this pass-through.
        // Solution borrowed from https://github.com/vuejs/vue-router/issues/2105#issuecomment-678980641.
        render: () => h(resolveComponent('router-view')),
      },
      children: [
        {
          path: 'twitter:pathMatch(.*)*',
          redirect: (to) => ({ ...to, path: to.path.replace('/twitter', '/x') }),
        },
        campaigns,
        community,
        communityLegacy,
        competitive,
        facebook,
        instagram,
        library,
        pinterest,
        relationships,
        creators,
        reports,
        scheduler,
        settings,
        socialListening,
        tiktok,
        linkedin,
        twitter,
        youtube,
        threads,
      ],
    },
    {
      path: '/not_found',
      component: PageNotFound,
      meta: { analytics: 'Page Not Found', hideNav: true, hideVisionAi: true },
    },
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound,
      meta: {
        analytics: 'Page Not Found',
        hideNav: true,
        hideVisionAi: true,
        hidePrimaryNav: true,
        hideSecondaryNav: true,
      },
    },
  ],
});

// These overrides are to prevent errors from navigation failures

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (isNavigationFailure(err)) {
      if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
        logger.warn('$router.push failure', { location }, err);
      }
      return err;
    }

    return Promise.reject(err);
  });
};

const originalReplace = router.replace;
router.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }

  return originalReplace.call(this, location).catch((err) => {
    if (isNavigationFailure(err)) {
      if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
        logger.warn('$router.replace failure', { location }, err);
      }
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
