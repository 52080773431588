<script setup>
import { Icon } from '@dashhudson/dashing-ui';
import { useDocumentVisibility, useWindowFocus } from '@vueuse/core';
import { destroyTooltip } from 'floating-vue';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { usePrimaryNavigationMenu } from '@/composables/layout/navigation/usePrimaryNavigationMenu';
import { trackNavigation } from '@/utils/analytics/tracking';

const emit = defineEmits(['activeItemClick']);

const route = useRoute();

const selectionMap = {
  instagram: 'analytics',
  facebook: 'analytics',
  tiktok: 'analytics',
  twitter: 'analytics',
  youtube: 'analytics',
  linkedin: 'analytics',
  pinterest: 'analytics',
};

const { visibleNavigationItems } = usePrimaryNavigationMenu();

const activeSelection = computed(() => {
  const routeName = route.name.split('.')[0];
  const currentSelection = selectionMap[routeName] ?? routeName ?? null;
  return find(visibleNavigationItems.value, ['name', currentSelection])?.name;
});

const linkRefs = ref([]);

function setRefs(el, index) {
  if (el) {
    linkRefs.value[index] = el;
  }
}

const windowVisible = useDocumentVisibility();
const windowFocus = useWindowFocus();
watch(
  () => [windowVisible.value, windowFocus.value],
  (to, from) => {
    if (!isEqual(to, from)) {
      linkRefs.value.forEach((el) => {
        destroyTooltip(el);
      });
    }
  },
);

const navigationSelectionMap = {
  competitors: 'Competitors',
};

function handleItemClick(selection) {
  if (selection === activeSelection.value) {
    emit('activeItemClick', selection);
  } else {
    trackNavigation(navigationSelectionMap[selection] ?? selection);
  }
}
</script>

<template>
  <ul class="grid max-h-[calc(100vh-12rem)] w-fit gap-5 overflow-y-auto px-1 pb-4 pt-1">
    <li v-for="(item, index) in visibleNavigationItems" :key="item.to.name">
      <router-link
        :ref="(el) => setRefs(el, index)"
        v-dash-tooltip="{
          content: item.label,
          theme: 'dash-tooltip--nav',
        }"
        :to="item.to"
        class="dash-rounded-sm dash-transition-sm group relative grid h-8 w-8 place-items-center focus:outline-[color:--action-500]"
        :class="[
          `hover:${item.backgroundClass}`,
          activeSelection === item.name && item.backgroundClass,
        ]"
        :data-cy="`ProductNav-${item.name}`"
        @click="handleItemClick(item.name)"
      >
        <Icon
          :name="item.icon"
          size="md"
          class="dash-fill-icon-subtle group-hover:fill-white"
          :class="{ 'fill-white': activeSelection === item.name }"
        />
        <span class="visually-hidden"> Go to {{ item.label }} </span>
      </router-link>
    </li>
  </ul>
</template>
