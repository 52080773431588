import { watch, ref, computed } from 'vue';
import { defineStore } from 'pinia';
import merge from 'lodash/merge';
import { env } from '@/env';
import { useAuthStore } from '@/stores/auth';
import * as AuthAPI from '@/apis/auth';
import { logger } from '@/utils/logger';
import { BRAND } from '@/models/auth/permissions.enum';
import { useNotificationStore } from '@/stores/notification';
import salesforceIcon from '@/assets/img/integrations/salesforce.svg';
import zendeskIcon from '@/assets/img/integrations/zendesk.svg';
import bigqueryIcon from '@/assets/img/integrations/bigquery.svg';
import klaviyoIcon from '@/assets/img/integrations/klaviyo.svg';
import mailchimpIcon from '@/assets/img/integrations/mailchimp.svg';
import gmailIcon from '@/assets/img/integrations/gmail.svg';
import googleSheetsIcon from '@/assets/img/integrations/googleSheets.svg';
import googleDriveIcon from '@/assets/img/integrations/googleDrive.svg';
import microsoftOutlookIcon from '@/assets/img/integrations/microsoft-outlook.svg';
import s3Icon from '@/assets/img/integrations/s3.svg';
import rssFeedIcon from '@/assets/img/integrations/rssFeed.svg';
import airtableIcon from '@/assets/img/integrations/airtable.svg';
import { CUSTOMER_PLAN } from '@/models/auth/customer-plan.enum';
import { useTrackingStore } from '@/stores/tracking';
import { paragon } from '@useparagon/connect';
import { useFlagStore } from './flag';

const INTEGRATION_API_KEY_LABEL = 'dh-integrations';
const DH_INTEGRATIONS_OAUTH_URL = 'https://integrations.dashhudson.com/oauth';
const ENV_SPECIFIC_INTEGRATIONS_OAUTH_URL = env.integrationsRedirectUrl;

export const INTEGRATIONS = {
  BRAND: {
    SALESFORCE: {
      name: 'Salesforce',
      value: 'salesforce',
      icon: salesforceIcon,
      description: 'Connect to Salesforce to create cases.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
    },
    ZENDESK: {
      name: 'Zendesk',
      value: 'zendesk',
      icon: zendeskIcon,
      description: 'Connect to Zendesk to create tickets.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
    },
    BIGQUERY: {
      name: 'BigQuery',
      value: 'bigquery',
      icon: bigqueryIcon,
      description: 'Access your most important social metrics from any channel in your BigQuery.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      connectOptions: {
        overrideRedirectUrl: DH_INTEGRATIONS_OAUTH_URL,
      },
      flag: 'integrationBigquery',
    },
    KLAVIYO: {
      name: 'Klaviyo',
      value: 'klaviyo',
      icon: klaviyoIcon,
      description: 'Send your LikeShop email submissions directly to your lists in Klaviyo.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      flag: 'integrationKlaviyo',
    },
    MAILCHIMP: {
      name: 'Mailchimp',
      value: 'mailchimp',
      icon: mailchimpIcon,
      description: 'Send your LikeShop email submissions directly to your lists in Mailchimp.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      flag: 'integrationMailchimp',
    },
    GOOGLE_SHEETS: {
      name: 'Google Sheets',
      value: 'googlesheets',
      icon: googleSheetsIcon,
      description:
        'Automatically sync your social media metrics directly from Dash Hudson to Google Sheets.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      connectOptions: {
        overrideRedirectUrl: DH_INTEGRATIONS_OAUTH_URL,
      },
      flag: 'integrationGoogleSheets',
    },
    GOOGLE_DRIVE: {
      name: 'Google Drive',
      value: 'googledrive',
      icon: googleDriveIcon,
      description:
        'Sync your approved assets live directly from Google Drive to Dash Hudson’s Library.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      connectOptions: {
        overrideRedirectUrl: DH_INTEGRATIONS_OAUTH_URL,
      },
      flag: 'integrationGoogleDrive',
    },
    AMAZON_S3: {
      name: 'Amazon S3',
      value: 'amazons3',
      icon: s3Icon,
      description: 'Access your most important social metrics from any channel in your AWS s3.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      flag: 'integrationS3',
    },
    AIRTABLE: {
      name: 'Airtable',
      value: 'airtable',
      icon: airtableIcon,
      description: 'Access your most important social metrics from any channel in your Airtable.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      flag: 'integrationAirtable',
    },
    RSSFEED: {
      name: 'RSS Feed',
      value: 'custom.rssfeed',
      icon: rssFeedIcon,
      description: 'Automates content transfer from RSS feeds to social media.',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      flag: 'integrationRssFeedV1',
    },
  },
  USER: {
    GMAIL: {
      name: 'Gmail',
      value: 'gmail',
      icon: gmailIcon,
      description: 'Connect a gmail account',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      connectOptions: {
        overrideRedirectUrl: ENV_SPECIFIC_INTEGRATIONS_OAUTH_URL,
      },
      flag: 'creatorEmail',
    },
    OUTLOOK: {
      name: 'Outlook',
      value: 'outlook',
      icon: microsoftOutlookIcon,
      description: 'Connect a Microsoft outlook account',
      plans: [
        CUSTOMER_PLAN.ADVANCE,
        CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ADVANCE_PREMIUM,
        CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE,
        CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
        CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
      ],
      connectOptions: {
        overrideRedirectUrl: ENV_SPECIFIC_INTEGRATIONS_OAUTH_URL,
      },
      flag: 'creatorEmail',
    },
  },
};

export const useIntegrationStore = defineStore('integration', () => {
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const flagStore = useFlagStore();
  const trackingStore = useTrackingStore();

  const initialized = ref(false);
  const paragonIntegrations = ref(null);
  const paragonIntegrationMetadata = ref([]);
  const authenticating = ref(false);
  const authenticatedAsBrand = ref(false);
  const authenticatedAsUser = ref(false);
  const pendingAuthentication = ref(Promise.resolve());

  function extractIntegrationType(type) {
    return (type?.value ?? type)?.toLowerCase();
  }

  const hasAccessToIntegrations = computed(() => {
    return authStore.guard(BRAND.SETTINGS.CAN_ACCESS_INTEGRATIONS);
  });

  const integrations = computed(() => {
    return Object.keys(paragonIntegrations.value ?? {}).reduce((acc, integrationType) => {
      const paragonMetadata = paragonIntegrationMetadata.value?.find(
        (metadata) => metadata.type === integrationType,
      );

      const dhMetadata = Object.values(INTEGRATIONS)
        .flatMap((integrationCategory) => Object.values(integrationCategory))
        .find((integration) => integration.value === integrationType);

      const isIntegrationActive = !!paragonMetadata;
      const hasFlag = dhMetadata && (!dhMetadata.flag || flagStore.flags[dhMetadata.flag]);
      const hasPlan = authStore.hasPlan(dhMetadata?.plans);

      if (isIntegrationActive && hasFlag && hasPlan) {
        acc[integrationType] = paragonIntegrations.value[integrationType] ?? {};
        acc[integrationType].metadata = paragonMetadata ?? {};
        acc[integrationType].metadata.description = dhMetadata?.description;
      }

      return acc;
    }, {});
  });

  const integrationList = computed(() => {
    return Object.values(integrations.value);
  });
  const integrationIcon = computed(() => {
    return (type) => {
      const value = extractIntegrationType(type);
      return (
        Object.values(INTEGRATIONS.BRAND).find((i) => i.value === value)?.icon ||
        Object.values(INTEGRATIONS.USER).find((i) => i.value === value)?.icon
      );
    };
  });
  const isConnected = computed(() => {
    return (integrationValue) => {
      const value = extractIntegrationType(integrationValue);
      return !!paragonIntegrations.value?.[value]?.enabled;
    };
  });
  const getWorkflow = computed(() => {
    return (integrationValue, workflowId) => {
      const value = extractIntegrationType(integrationValue);
      return paragonIntegrations.value?.[value]?.configuredWorkflows?.[workflowId];
    };
  });
  const hasWorkflow = computed(() => {
    return (integrationValue, workflowId) => {
      return !!getWorkflow.value(integrationValue, workflowId);
    };
  });
  const isWorkflowEnabled = computed(() => {
    return (integrationValue, workflowId) => {
      return !!getWorkflow.value(integrationValue, workflowId)?.enabled;
    };
  });

  function integrationName(integrationValue) {
    const value = extractIntegrationType(integrationValue);
    return (
      Object.values(INTEGRATIONS.BRAND).find((i) => i.value === value)?.name ||
      Object.values(INTEGRATIONS.USER).find((i) => i.value === value)?.name
    );
  }

  function integrationConnectOptions(integrationValue) {
    const value = extractIntegrationType(integrationValue);
    return (
      Object.values(INTEGRATIONS.BRAND).find((i) => i.value === value)?.connectOptions ||
      Object.values(INTEGRATIONS.USER).find((i) => i.value === value)?.connectOptions ||
      {}
    );
  }

  function integrationMixpanelProps(integration) {
    const type = extractIntegrationType(integration);
    const name = integrationName(integration);
    return {
      integrationType: type,
      integrationName: name,
    };
  }

  async function deleteOldIntegrationApiKeys() {
    try {
      const response = await authStore.getApiKeysForBrand({
        brandId: authStore.currentBrand.id,
      });
      const payload = response?.data ?? [];
      if (Array.isArray(payload)) {
        const deleteRequests = payload
          .filter((apiKey) => apiKey.label === INTEGRATION_API_KEY_LABEL)
          .map((apiKey) =>
            AuthAPI.deleteApiKeyForBrand({
              brandId: authStore.currentBrand?.id,
              apiKeyId: apiKey?.apiKeyId,
            }),
          );
        await Promise.all(deleteRequests);
      }
    } catch (error) {
      logger.error('Failed to delete old integration api key', {}, error);
      throw error;
    }
  }

  async function updateDHApiKey() {
    try {
      await deleteOldIntegrationApiKeys();
    } catch (error) {
      // ignoring errors here so we still create new api key
    }
    const response = await authStore.generateApiKey({
      label: INTEGRATION_API_KEY_LABEL,
      brandId: authStore.currentBrand?.id,
    });
    const payload = response?.data;
    const token = payload?.token;
    await paragon.setUserMetadata({
      api_key: token,
    });
  }

  async function hasValidApiKey(user) {
    const apiKey = user?.meta?.api_key;
    if (!apiKey) {
      return false;
    }
    try {
      await AuthAPI.validateApiKey({ token: apiKey });
    } catch (error) {
      return false;
    }
    return true;
  }

  async function updateDHApiKeyIfRequired() {
    const user = paragon.getUser();
    if (user?.meta) {
      if (!(await hasValidApiKey(user))) {
        await updateDHApiKey();
      }
    }
  }

  function loadIntegrations() {
    const user = paragon.getUser();
    paragonIntegrations.value = user?.integrations;
    paragonIntegrationMetadata.value = paragon.getIntegrationMetadata();
  }

  async function authenticate({ asUser = false } = {}) {
    pendingAuthentication.value = pendingAuthentication.value.finally(async () => {
      const requestedAuthenticationType = asUser ? 'user' : 'brand';
      const currentAuthenticationType =
        (authenticatedAsUser.value && 'user') || (authenticatedAsBrand.value && 'brand') || null;
      if (requestedAuthenticationType === currentAuthenticationType) {
        return;
      }

      if (asUser && !env.paragonUserProjectId) {
        logger.error(`Paragon User Project ID is missing.`);
        return;
      }
      if (!env.paragonBrandProjectId) {
        logger.error(`Paragon Project ID is missing.`);
        return;
      }
      const brand = authStore.currentBrand;
      authenticating.value = true;
      try {
        if (brand) {
          const response = asUser
            ? await AuthAPI.getUserIntegrations()
            : await AuthAPI.getBrandIntegrations({ brandId: brand.id });
          const paragonJwtToken = response?.data?.paragonJwtToken;
          if (paragonJwtToken) {
            const paragonProjectId = asUser ? env.paragonUserProjectId : env.paragonBrandProjectId;
            await paragon.authenticate(paragonProjectId, paragonJwtToken);

            await paragon.setUserMetadata({
              name: brand.name,
              brand_id: brand.id,
            });
            // API keys are only needed at the brand level.
            if (!asUser) {
              await updateDHApiKeyIfRequired();
            }
            loadIntegrations();
          } else {
            logger.error(`Paragon token is missing, unable to authenticate.`);
          }
        } else {
          paragonIntegrations.value = null;
        }
      } catch (error) {
        logger.error('Failed paragon authentication', {}, error);
        throw error;
      } finally {
        authenticating.value = false;
        const authenticated = paragon?.getUser()?.authenticated;
        if (asUser) {
          authenticatedAsUser.value = authenticated;
          authenticatedAsBrand.value = false;
        } else {
          authenticatedAsUser.value = false;
          authenticatedAsBrand.value = authenticated;
        }
        initialized.value = true;
      }
    });

    await pendingAuthentication.value;
  }

  function logout() {
    localStorage.removeItem('paragon-connect-user-state');
    authenticatedAsBrand.value = false;
    authenticatedAsUser.value = false;
  }

  async function disconnect(integrationValue) {
    if (!integrationValue) {
      return;
    }

    const value = extractIntegrationType(integrationValue);

    const isUserIntegration = Object.values(INTEGRATIONS.USER).some((i) => i.value === value);

    if (isUserIntegration && authenticatedAsBrand.value) {
      await authenticate({ asUser: true });
    } else if (!isUserIntegration && authenticatedAsUser.value) {
      await authenticate();
    }

    const integration = integrationList.value.find((i) => i.metadata.type === value);
    const credentialId = integration ? integration.credentialId : null;

    await paragon.uninstallIntegration(value, credentialId);
    loadIntegrations();
  }

  async function connect(integrationValue) {
    const value = extractIntegrationType(integrationValue);
    const name = integrationName(integrationValue);
    const connectOptions = integrationConnectOptions(integrationValue);
    const mixpanelProps = integrationMixpanelProps(integrationValue);
    trackingStore.track('Started Integration Connection', mixpanelProps);

    const isUserIntegration = Object.values(INTEGRATIONS.USER).some((i) => i.value === value);

    if (isUserIntegration && authenticatedAsBrand.value) {
      await authenticate({ asUser: true });
    } else if (!isUserIntegration && authenticatedAsUser.value) {
      await authenticate();
    }
    paragon.connect(
      value,
      merge(
        {
          onSuccess: () => {
            trackingStore.track('Integration Connected', {
              integrationType: value,
              integrationName: name,
            });
            notificationStore.setToast({
              message: `Successfully connected to ${name}`,
            });
            loadIntegrations();
          },
          onUninstall: () => {
            trackingStore.track('Integration Disconnected', mixpanelProps);
            loadIntegrations();
          },
          onWorkflowChange: () => {
            trackingStore.track('Integration Workflow Change', mixpanelProps);
            loadIntegrations();
          },
          onError: (error) => {
            trackingStore.track('Integration Connection Failure', {
              ...mixpanelProps,
              errorType: error,
            });
            notificationStore.setToast({
              message: `There was a problem connecting your ${name} account.  Please try again.`,
              type: 'error',
            });
            loadIntegrations();
          },
        },
        connectOptions,
      ),
    );
  }

  async function runWorkflow(workflowId, payload) {
    if (!authenticatedAsBrand.value) {
      await authenticate();
    }
    return paragon?.workflow(workflowId, payload);
  }

  function instanceUrl(integration) {
    const integrationType = extractIntegrationType(integration);
    const user = paragon.getUser();
    const providerData = user?.integrations?.[integrationType]?.providerData;
    return providerData?.instanceUrl ?? '';
  }

  watch(
    () => authStore.currentBrand,
    async () => {
      if (!authenticatedAsUser.value) {
        await authenticate();
      }
    },
  );

  watch(
    () => authStore.isLoggedIn,
    async (newIsLoggedIn, oldIsLoggedIn) => {
      if (newIsLoggedIn === oldIsLoggedIn) {
        return;
      }
      if (!newIsLoggedIn) {
        logout();
      }
    },
    { immediate: true },
  );

  return {
    hasAccessToIntegrations,
    initialized,
    paragonIntegrations,
    paragonIntegrationMetadata,
    authenticating,
    integrationList,
    integrations,
    integrationIcon,
    authenticate,
    authenticatedAsBrand,
    authenticatedAsUser,
    connect,
    disconnect,
    isConnected,
    getWorkflow,
    hasWorkflow,
    isWorkflowEnabled,
    integrationName,
    runWorkflow,
    instanceUrl,
    extractIntegrationType,
    integrationMixpanelProps,
    logout,
  };
});
