import { BRAND, USER } from '@/models/auth/permissions.enum';
import { getChannelText, getChannelConfigProp } from '@/models/dashboards/channels.enum';
import { CHANNELS } from '@/app/campaigns/constants';
import { CHART } from '@/ux/colours';
import { formatDateRangeLabel } from '@/utils/formatters';
import { formatInTimeZone } from 'date-fns-tz';
import { getUserTimezone } from '@/utils/timezone';
import dayjs from 'dayjs';

/**
 * Returns an object checking whether the brand and user have access to a module.
 * @param {function} [guard] - Function that checks permission for a module.
 */
export const getPermissionsMap = (guard) => {
  return {
    TIKTOK:
      guard(USER.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS) &&
      guard(BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS),
    INSTAGRAM:
      guard(USER.INSTAGRAM.CAN_ACCESS_INSTAGRAM) && guard(BRAND.INSTAGRAM.CAN_ACCESS_INSTAGRAM),
    INSTAGRAM_STORY:
      guard(USER.INSTAGRAM.CAN_ACCESS_INSTAGRAM) && guard(BRAND.INSTAGRAM.CAN_ACCESS_INSTAGRAM),
    FACEBOOK:
      guard(USER.FACEBOOK.CAN_ACCESS_FB_INSIGHTS) && guard(BRAND.FACEBOOK.CAN_ACCESS_FB_INSIGHTS),
    PINTEREST: guard(USER.PINTEREST.CAN_ACCESS_PINT) && guard(BRAND.PINTEREST.CAN_ACCESS_PINT),
    TWITTER:
      guard(USER.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS) &&
      guard(BRAND.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS),
    YOUTUBE:
      guard(USER.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS) &&
      guard(BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS),
    UGC: guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE) && guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE),
    RELATIONSHIPS:
      guard(USER.INSTAGRAM.CAN_ACCESS_RELATIONSHIPS) &&
      guard(BRAND.INSTAGRAM.CAN_ACCESS_RELATIONSHIPS),
    GOOGLE_ANALYTICS:
      guard(USER.CAMPAIGNS.CAN_ACCESS_CAMPAIGNS_GOOGLE_ANALYTICS) &&
      guard(BRAND.CAMPAIGNS.CAN_ACCESS_CAMPAIGNS_GOOGLE_ANALYTICS),
    SENTIMENT: guard(BRAND.GENERAL.CAN_ACCESS_SENTIMENT),
  };
};

/**
 * Return the formatted label for the provided channel.
 * @param {string} channel - The key for the channel (e.g. TIKTOK).
 */
export const getChannelLabel = (channel) => {
  switch (channel) {
    case CHANNELS.RELATIONSHIPS:
      return 'Relationships';
    case CHANNELS.UGC:
      return 'UGC';
    default:
      return getChannelText(channel);
  }
};

/**
 * Return the chart color for the provided channel.
 * @param {string} channel - The key for the channel (e.g. TIKTOK).
 */
export const getChannelChartColor = (channel) => {
  switch (channel) {
    case CHANNELS.RELATIONSHIPS:
      return CHART.SET.CHART_SET_16;
    case CHANNELS.UGC:
      return CHART.SET.CHART_SET_11;
    default:
      return getChannelConfigProp(channel, 'chartColor');
  }
};

/**
 * Return sourceType value from media item object.
 */
export function getSourceType(item) {
  return item?.source_type ?? item?.sourceType ?? '';
}

/**
 * Return a formatted date range for the creators date range.
 * @param {date} sourceCreatedAt - The date object for the creators date range.
 */
export function getCreatorsDateRange(sourceCreatedAt) {
  if (!sourceCreatedAt) return 'All Time';

  const dateFormat = 'yyyy-MM-dd';
  const timeZone = getUserTimezone();

  const startDateString = formatInTimeZone(sourceCreatedAt.start, timeZone, dateFormat);
  const endDateString = formatInTimeZone(sourceCreatedAt.end, timeZone, dateFormat);

  return formatDateRangeLabel(startDateString, endDateString);
}

/**
 * Return a status of a campaign based on creators date range.
 * @param {date} sourceCreatedAt - The date object for the creators date range.
 */
export function getCampaignStatus(sourceCreatedAt) {
  if (!sourceCreatedAt) return { status: 'Active', severity: 'success' };
  const timeZone = getUserTimezone();
  const now = dayjs();
  const start = dayjs.utc(sourceCreatedAt.start).tz(timeZone);
  const end = dayjs.utc(sourceCreatedAt.end).tz(timeZone);
  return now.isBetween(start, end)
    ? { status: 'Active', severity: 'success' }
    : { status: 'Inactive', severity: 'statusNeutral' };
}

/**
 * Return a number of creator deliverables have been published without over reporting from one of the deliverable channels.
 * @param {object} creatorDeliverables - The deliverables object.
 */
export function getDeliverablesPublished(creatorDeliverables) {
  const platforms = [
    { published: 'publishedInstagramFeed', deliverable: 'deliverableInstagramFeed' },
    { published: 'publishedInstagramReels', deliverable: 'deliverableInstagramReels' },
    { published: 'publishedInstagramStories', deliverable: 'deliverableInstagramStories' },
    { published: 'publishedTiktokVideos', deliverable: 'deliverableTiktokVideos' },
  ];

  const deliverablesPublished = platforms.reduce(
    (total, platform) =>
      total +
      Math.min(creatorDeliverables[platform.published], creatorDeliverables[platform.deliverable]),
    0,
  );

  const allDeliverablesCompleted = platforms.every(
    (platform) =>
      creatorDeliverables[platform.published] >= creatorDeliverables[platform.deliverable],
  );

  return allDeliverablesCompleted ? creatorDeliverables.totalPublishedPosts : deliverablesPublished;
}

/**
 * Return an array of metrics for use on creator campaign cards
 * @param {object} campaign - The campaign object.
 */
export function getCreatorCampaignMetricItems(campaign) {
  const creatorDeliverables = campaign.creator_deliverables ?? {};
  let creatorDeliverablesStatus;
  if (creatorDeliverables.deliverableGifted) {
    creatorDeliverablesStatus = 'Gifted';
  } else if (creatorDeliverables.totalDeliverables) {
    const published = getDeliverablesPublished(creatorDeliverables);
    const total = creatorDeliverables.totalDeliverables ?? 0;
    creatorDeliverablesStatus = `${published}/${total}`;
  } else {
    creatorDeliverablesStatus = '-';
  }

  const dateRange = getCreatorsDateRange(campaign.creator_filters?.source_created_at);

  return [
    { label: 'Deliverables', value: creatorDeliverablesStatus },
    { label: 'Date', value: dateRange },
  ];
}
