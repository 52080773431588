import { computed } from 'vue';

import snakeCase from 'lodash/snakeCase';

import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { useDashboardsStore } from '@/stores/dashboards';
import { usePlatformStore } from '@/stores/platform';
import { useMetricsStore } from '@/stores/metrics';
import { CUSTOM_METRIC_CHANNELS_FOR_CHANNEL_ICONS } from '@/app/dashboards/constants';
import constants from '@/app/settings/components/CustomMetrics/constants';
import { toolTips } from '@/config';
import { BRAND, ORGANIZATION_USER } from '@/models/auth/permissions.enum';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { PLATFORM_CONNECTION_STATUS } from '@/models/platform/platform-connection.enum';
import cloneDeep from 'lodash/cloneDeep';
import { useCustomMetricsStore } from '@/stores/custom-metrics';
import { useMetricDetails } from '@/app/dashboards/composables/useMetricDetails';
import { METRIC_TYPE_REPORT_KEYS } from '@/app/dashboards/utils/reports.enum';

export function useCustomMetrics() {
  const { CHANNEL_TO_CONNECTION_FIELD_MAP } = constants;
  const platformStore = usePlatformStore();
  const flagStore = useFlagStore();
  const identityStore = useIdentityStore();
  const customMetricsStore = useCustomMetricsStore();
  const metricsStore = useMetricsStore();

  const isCustomMetricsFlagEnabled = computed(() => {
    return flagStore.ready && flagStore.flags?.customMetricsV1;
  });

  const canAccessCustomMetrics = computed(() => {
    const brandHasAccessToCustomMetrics = identityStore.guard(
      BRAND.SETTINGS.CAN_ACCESS_CUSTOM_METRICS,
    );
    return isCustomMetricsFlagEnabled.value && brandHasAccessToCustomMetrics;
  });

  const canManageCustomMetrics = computed(() => {
    const userOrgCanManageCustomMetrics = identityStore.guard(
      ORGANIZATION_USER.SETTINGS.CAN_MANAGE_CUSTOM_METRICS,
    );
    return isCustomMetricsFlagEnabled.value && userOrgCanManageCustomMetrics;
  });

  const canViewOrManageCustomMetrics = computed(() => {
    return canAccessCustomMetrics.value || canManageCustomMetrics.value;
  });

  function getChannelConnectionStatuses(uniqueChannelsUsed, brandIds) {
    return uniqueChannelsUsed.reduce((statuses, channel) => {
      const connectionField = CHANNEL_TO_CONNECTION_FIELD_MAP[channel];
      const accounts = connectionField
        ? platformStore.platformConnectionsMap[connectionField]
        : null;
      if (!connectionField || !accounts) {
        return { ...statuses, [channel]: false };
      }
      const hasDisconnected = brandIds.some((brandId) => {
        const status = accounts[brandId]?.status;
        return status !== PLATFORM_CONNECTION_STATUS.CONNECTED;
      });
      return { ...statuses, [channel]: hasDisconnected };
    }, {});
  }
  function sanitizeFormulaForChip(formulaString) {
    if (!formulaString) return [];

    return [formulaString.replace(/\s+/g, '')];
  }

  function updateCustomMetricBuilder(payload, mode) {
    const mappedPayload = {
      aggregation: payload.aggregation,
      brandIds: payload.brand_ids,
      description: payload.description || '',
      formula: payload.formula,
      metricFormat: payload.metric_format,
      name: payload.name,
      trendInterpretation: payload.trend_interpretation,
      id: payload.id,
    };
    if (mode === constants.CUSTOM_METRIC_SAVE_MODES.DUPLICATE) {
      mappedPayload.name = `${mappedPayload.name} (copy)`;
    }

    Object.assign(customMetricsStore.customMetricBuilder, cloneDeep(mappedPayload));
    customMetricsStore.customMetricBuilder.mode = mode;

    customMetricsStore.originalCustomMetricData = cloneDeep(mappedPayload);

    customMetricsStore.currentCustomMetricFormula = sanitizeFormulaForChip(
      customMetricsStore.originalCustomMetricData.formula,
    );
    customMetricsStore.currentCustomMetricFormat = mappedPayload.metricFormat;
  }

  function updateChannelConnectionStatuses(brandIds) {
    customMetricsStore.channelConnectionStatuses = getChannelConnectionStatuses(
      constants.PRE_DEFINED_CHANNELS,
      brandIds,
    );
  }
  function brandCanAccessCustomMetrics(brand) {
    return identityStore.guard(BRAND.SETTINGS.CAN_ACCESS_CUSTOM_METRICS, brand);
  }

  function brandHasReachedCustomMetricsLimit(brandUsage) {
    return brandUsage?.usage >= brandUsage?.limit;
  }

  function brandHasAlmostReachedCustomMetricsLimit(brandUsage) {
    if (brandUsage) {
      return brandUsage.usage < brandUsage.limit && brandUsage.usage > brandUsage.limit / 2;
    }
    return false;
  }

  function disableBrand(brand, brandUsage) {
    return !brandCanAccessCustomMetrics(brand) || brandHasReachedCustomMetricsLimit(brandUsage);
  }

  function disabledBrandTooltip(brand, brandUsage) {
    if (!brandCanAccessCustomMetrics(brand)) {
      return toolTips.customMetrics?.brandCannotAccessCustomMetrics;
    }

    if (!brandUsage?.usage) return null;
    if (brandHasReachedCustomMetricsLimit(brandUsage)) {
      return `Custom Metric limit reached. A brand can only have ${brandUsage?.limit} custom metrics`;
    }
    return `This brand has already been selected for ${brandUsage?.usage} Custom Metrics, out of ${brandUsage?.limit} max.`;
  }

  function setCustomMetricBrandChip(brandUsage) {
    if (
      brandHasAlmostReachedCustomMetricsLimit(brandUsage) ||
      brandHasReachedCustomMetricsLimit(brandUsage)
    ) {
      return `${brandUsage.usage} / ${brandUsage.limit}`;
    }
    return null;
  }

  function setCustomMetricsUsageChipColor(brandUsage) {
    if (brandHasReachedCustomMetricsLimit(brandUsage)) {
      return constants.CUSTOM_METRICS_USAGE_THRESHOLD_COLORS.LIMIT_REACHED;
    }
    if (brandHasAlmostReachedCustomMetricsLimit(brandUsage)) {
      return constants.CUSTOM_METRICS_USAGE_THRESHOLD_COLORS.WARNING;
    }
    return constants.CUSTOM_METRICS_USAGE_THRESHOLD_COLORS.OK;
  }

  function getChannelText(channel) {
    return CHANNELS[channel]?.text;
  }

  function getChannelIcon(channel) {
    return CHANNELS[channel]?.channelIcon;
  }

  function generateCustomMetricTooltip(customMetric) {
    const name = customMetric.name;
    const description = customMetric?.description;
    const formula = customMetric.formula;

    if (!description) {
      return `${name}\n${formula}`;
    }
    return `${name}\n${description}\n${formula}`;
  }

  function getChannelConnectionData(
    uniqueChannelsUsed,
    brandIds,
    parsedCalculation,
    getChannelIconFromMetric,
  ) {
    if (!uniqueChannelsUsed.length || !brandIds.length) {
      return {
        channelConnectionStatuses: {},
        hasChannelDisconnection: false,
        metricErrors: {},
      };
    }
    const statuses = getChannelConnectionStatuses(uniqueChannelsUsed, brandIds);
    const hasDisconnection = Object.values(statuses).some(Boolean);
    const errors = parsedCalculation.reduce((acc, token) => {
      if (token.type === 'metric') {
        const metricName = token.value;
        const channelKey = getChannelIconFromMetric(metricName);
        acc[metricName] = statuses[channelKey] ?? false;
      }
      return acc;
    }, {});
    return {
      channelConnectionStatuses: statuses,
      hasChannelDisconnection: hasDisconnection,
      metricErrors: errors,
    };
  }

  function findDisplayNameByChannelMetricKey(metricKey) {
    const [channel, metric] = metricKey.split(':');
    const metricsReport = metricsStore.metricDetails[channel]?.metrics_report ?? {};
    return metricsReport[metric]?.display_name ?? metric;
  }

  function extractDisplayNameFromMetric(metricName) {
    const [metricChannel, metricKey] = metricName.split(':');
    const metricDetails = useMetricDetails({
      metric: metricKey,
      channels: [metricChannel],
      metricReportTypeKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
    });

    return metricDetails?.displayName?.value ?? metricKey;
  }

  function formatCustomMetricTooltip(customMetric) {
    const customMetricsFormulaList =
      customMetric.formula?.match(
        constants.CUSTOM_METRIC_REGEX.PERMITTED_FORMULA_CHARACTERS_PATTERN,
      ) || [];

    let customMetricsFormulaOutput = '';

    customMetricsFormulaList.forEach((part) => {
      if (part.startsWith("['") && part.endsWith("']")) {
        const [channel, metric] = part.slice(2, -2).split(':');

        const channelText = getChannelText(channel);
        const metricDisplayName = findDisplayNameByChannelMetricKey(`${channel}:${metric}`);

        customMetricsFormulaOutput += `(${channelText} ${metricDisplayName}) `;
      } else {
        customMetricsFormulaOutput += `${part} `; // operators
      }
    });

    return `${customMetric.name}\n\n${customMetric.description ? `${customMetric.description}\n\n` : ''}${customMetricsFormulaOutput}`;
  }

  const extractChannelsFromFormula = (formula) => {
    const captureChannelsRegex = /'([A-Z_]+):/g;
    const matches = [...formula.matchAll(captureChannelsRegex)];

    return [
      ...new Set(
        matches
          .map((match) => match[1])
          .filter((channel) => CUSTOM_METRIC_CHANNELS_FOR_CHANNEL_ICONS[channel])
          .map((channel) => snakeCase(CUSTOM_METRIC_CHANNELS_FOR_CHANNEL_ICONS[channel])),
      ),
    ];
  };

  function getFilteredBrandOptions(isCustomMetricsSelected) {
    const { dashboardIdentityBrands } = useDashboardsStore();
    return dashboardIdentityBrands
      .filter((brand) => !isCustomMetricsSelected || brandCanAccessCustomMetrics(brand))
      .map(({ id, name, avatarUrl }) => ({
        value: id,
        text: name,
        imageUrl: avatarUrl,
      }));
  }

  return {
    canAccessCustomMetrics,
    canManageCustomMetrics,
    canViewOrManageCustomMetrics,
    disableBrand,
    disabledBrandTooltip,
    formatCustomMetricTooltip,
    sanitizeFormulaForChip,
    getChannelConnectionData,
    getChannelConnectionStatuses,
    brandCanAccessCustomMetrics,
    getChannelIcon,
    getChannelText,
    getFilteredBrandOptions,
    generateCustomMetricTooltip,
    extractChannelsFromFormula,
    updateCustomMetricBuilder,
    updateChannelConnectionStatuses,
    setCustomMetricBrandChip,
    setCustomMetricsUsageChipColor,
    extractDisplayNameFromMetric,
  };
}
