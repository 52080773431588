<template>
  <p class="privacy-policy">
    By continuing, you {{ ageCopy }}agree to {{ appName }}'s
    <br v-if="lineBreak" />
    <span v-if="includeTermsOfService">
      <a
        class="tos-link"
        :href="termsOfServiceUrl"
        target="_blank"
        rel="noopener"
        @click="linkClicked({ url: termsOfServiceUrl, text: linkText.tos })"
      >
        {{ linkText.tos }}
      </a>
      and
    </span>
    <a
      class="privacy-policy-link"
      :href="privacyPolicyUrl"
      target="_blank"
      rel="noopener"
      @click="linkClicked({ url: privacyPolicyUrl, text: linkText.privacyPolicy })"
    >
      {{ linkText.privacyPolicy }}
    </a>
  </p>
</template>

<script>
import { defineComponent } from 'vue';
import { privacyPolicyUrl, termsOfServiceUrl, branding } from '@/config';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'DHTerms',
  props: {
    lineBreak: { type: Boolean, default: false },
    includeAgeCopy: { type: Boolean, default: false },
    includeTermsOfService: { type: Boolean, default: true },
  },
  emits: ['link-clicked'],
  data() {
    return {
      termsOfServiceUrl,
      privacyPolicyUrl,
      linkText: {
        privacyPolicy: 'Privacy Policy',
        tos: 'Terms of Service',
      },
      appName: branding.appName,
    };
  },
  computed: {
    ageCopy() {
      return this.includeAgeCopy ? "confirm that you're 16 or older and " : '';
    },
  },
  methods: {
    linkClicked(data) {
      this.$emit('link-clicked', data);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.privacy-policy {
  text-align: center;
  color: var(--text-secondary);
  margin-top: var(--space-24);
  font-size: var(--x12);
  font-weight: var(--font-normal);
}
</style>
