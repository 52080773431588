<script setup>
import Button from '@/components/foundation/Button.vue';
import Tabs from '@/components/Tabs.vue';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
    default: () => [],
  },
  buttonLabel: {
    type: String,
    default: 'Add',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  addButtonDisabled: {
    type: Boolean,
    default: false,
  },
  addButtonDisabledTooltip: {
    type: String,
    default: '',
  },
  selectedItems: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:tab', 'add-clicked']);

const updateTabView = (tab) => {
  emit('update:tab', tab);
};

const handleAddClicked = () => {
  emit('add-clicked');
};
</script>

<template>
  <div class="media-selector w-[100%]">
    <Tabs ref="tabs" :tabs="props.tabs" small @on-change="updateTabView" />
    <div class="right">
      <span v-if="props.selectedItems.length">
        <span class="blue-stats">
          {{ props.selectedItems.length }}
        </span>
        selected
      </span>
      <div v-tooltip="props.addButtonDisabledTooltip">
        <Button
          :disabled="props.addButtonDisabled"
          primary
          wide
          data-cy="select-media-popup-add-media-button"
          :loading="props.loading"
          @click="handleAddClicked"
        >
          {{ props.buttonLabel }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.media-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.blue-stats {
  color: #007bff;
  font-weight: bold;
  margin-right: 4px;
}
</style>
