<template>
  <div class="line-chart">
    <slot name="alert" />
    <canvas :id="chartId" ref="canvas" />
  </div>
</template>

<script>
import { defineComponent, markRaw } from 'vue';
import '@/components/foundation/charts/chartjs.defaults';
import merge from 'lodash/merge';
import { Chart } from 'chart.js';
import { colours } from '@/ux/colours';
import { formatDatePattern, formatDuration, scaleLabelFormatter } from '@/utils/formatters';
import { dataUnavailableMessage } from '@/config';
import HorizontalGridBars from '@/components/foundation/charts/chartjs-horizontal-grid-bars';
import VerticalHoverLine from '@/components/foundation/charts/chartjs-vertical-hover-line';
import { customTooltip, cleanupChartElements } from '@/components/foundation/charts/chartjs.utils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'LineChart',
  props: {
    chartId: { type: String, default: null },
    chartData: { type: Object, default: null },
    chartConfig: { type: Object, default: null },
    chartLineColor: { type: String, default: '#4990e2' },
    displayTicks: { type: Boolean, default: true },
    invalidDataColor: { type: String, default: colours.ERROR.ERROR_500 },
    forceInteger: { type: Boolean, default: false },
    tooltipDataName: { type: String, default: null },
    tooltipDataArray: { type: Array, default: null },
    showTooltipTitle: { type: Boolean, default: true },
    hidePoints: { type: Boolean, default: false },
    valueBeginAtZero: { type: Boolean, default: true },
    largeSizeChart: { type: Boolean, default: false },
    showChartValue: { type: Boolean, default: false },
    xAxisPattern: { type: String, default: null },
    // optional, if tooltip requires a footer or percent symbol
    tooltipFooterText: { type: String, default: null },
    showPercentage: { type: Boolean, default: false },
    suggestedMax: { type: Number, default: 1 },
    hoverLineColor: { type: String, default: colours.CHART.HOVER_LINE },
    isDuration: { type: Boolean, default: false },
  },
  emits: ['tooltipClicked', 'chart-destroyed', 'chart-created'],
  data() {
    return {
      defaultChartLineColor: colours.CHART.SET.CHART_SET_01,
      durationUnit: 'second',
    };
  },
  computed: {
    isReport() {
      return this.$route?.meta?.report;
    },
    hideNodes() {
      return this.hidePoints || (this.chartData.values && this.chartData.values.length > 96);
    },
    valueSource() {
      return this.chartData.values ? this.chartData.values : this.chartData.datasets[0];
    },
    refinedDatasets() {
      // If the data is null or undefined, display as Zero.
      return this.valueSource.map((d) => d || 0);
    },
    pointBackgroundColor() {
      return this.valueSource.map((v) => {
        /* eslint-disable eqeqeq */
        return this.validateValue(v) ? this.chartLineColor : this.invalidDataColor;
      });
    },
    chartOptions() {
      return merge({}, this.defaultChartOptions, this.chartConfig);
    },
    defaultChartOptions() {
      const ctx = this.$refs.canvas?.getContext('2d');
      if (!ctx) {
        return null;
      }

      const {
        valueSource,
        tooltipDataName,
        tooltipDataArray,
        tooltipFooterText,
        showPercentage,
        showTooltipTitle,
        validateValue,
        xAxisPattern,
      } = this;
      const gradientFill = ctx.createLinearGradient(0, 0, 0, 360);
      if (this.chartLineColor === colours.CHART.SET.CHART_SET_01) {
        gradientFill.addColorStop(0, colours.CHART.GRADIENT.CHART_GRADIENT_01);
      } else {
        gradientFill.addColorStop(0, colours.CHART.GRADIENT.CHART_GRADIENT_02);
      }
      gradientFill.addColorStop(1, colours.CHART.GRADIENT.CHART_GRADIENT_FADE);

      const parentChart = this;
      return {
        type: 'line',
        data: {
          labels: this.chartData.labels,
          datasets: [
            {
              data: this.refinedDatasets,
              borderColor: this.chartLineColor,
              pointRadius: this.hideNodes ? 0 : 5,
              pointBackgroundColor: this.pointBackgroundColor,
              pointHoverBorderColor: this.pointBackgroundColor,
              fill: true,
              backgroundColor: gradientFill,
            },
          ],
        },
        options: {
          animation: !this.isReport,
          layout: {
            padding: {
              top: this.largeSizeChart ? 32 : 4,
              left: this.largeSizeChart ? 2 : 5,
              bottom: this.largeSizeChart ? 2 : 5,
              right: 2,
            },
          },
          scales: {
            x: {
              offset: true,
              grid: {
                display: false,
                beginAtZero: false,
              },
              border: {
                display: false,
              },
              ticks: {
                display: this.displayTicks,
                font: {
                  size: this.largeSizeChart ? 12 : 10,
                },
                autoSkip: true,
                maxTicksLimit: 8,
                callback: (tickValue, index) => {
                  const label = this.chartData.labels[index];
                  return xAxisPattern ? formatDatePattern(label, xAxisPattern) : label;
                },
              },
            },
            y: {
              suggestedMax: this.suggestedMax,
              beginAtZero: this.valueBeginAtZero,
              grid: {
                display: false,
                drawTicks: false,
              },
              border: {
                display: false,
              },
              ticks: {
                display: this.displayTicks,
                font: {
                  size: this.largeSizeChart ? 12 : 10,
                },
                maxTicksLimit: 6,
                callback: this.scaleLabelFormatter,
                align: 'end',
                crossAlign: 'far',
                labelOffset: -2,
              },
              afterBuildTicks: this.adjustTicks,
            },
          },
          onClick(event) {
            const tooltipIndex = event?.chart?.tooltip?.dataPoints?.[0]?.dataIndex;
            if (tooltipIndex > -1) {
              let dataSelected;
              if (
                tooltipDataArray &&
                tooltipDataArray[tooltipIndex] &&
                'tooltip_label' in tooltipDataArray[tooltipIndex]
              ) {
                dataSelected = tooltipDataArray[tooltipIndex].tooltip_label;
              } else {
                dataSelected = this.data.labels[tooltipIndex];
              }
              parentChart.$emit('tooltipClicked', dataSelected);
            }
          },
          plugins: {
            verticalhoverline: {
              color: this.hoverLineColor,
            },
            horizontalgridbars: {},
            datalabels: {
              align: 'end',
              anchor: 'end',
              display: this.showChartValue,
              formatter: this.dataLabelFormatter,
            },
            tooltip: {
              // Disable the default tooltip
              enabled: false,
              intersect: false,
              callbacks: {
                title: (tooltipItems) => {
                  if (showTooltipTitle) {
                    return tooltipItems[0].label;
                  }
                  return null;
                },
              },
              external: customTooltip((context) => {
                const tooltipModel = context.tooltip;

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                const tooltipIndex = tooltipModel.dataPoints[0].dataIndex;

                const invalidPoint = !validateValue(valueSource[tooltipIndex]);

                let contentHtml = '';
                // Set Text
                if (tooltipModel.body) {
                  const bodyLines = tooltipModel.body.map(getBody);
                  contentHtml += '<dl class="pr-3.5">';
                  if (
                    tooltipDataArray &&
                    tooltipDataArray[tooltipIndex] &&
                    'tooltip_label' in tooltipDataArray[tooltipIndex]
                  ) {
                    contentHtml += `<dt>${tooltipDataArray[tooltipIndex].tooltip_label}</dt>`;
                  } else if (tooltipModel.title.length) {
                    contentHtml += `<dt>${context.chart.data.labels[tooltipIndex]}</dt>`;
                  }

                  if (invalidPoint) {
                    contentHtml += `<dd><span class="tooltip-unavailable">${dataUnavailableMessage}</span></dd>`;
                  } else if (tooltipDataName) {
                    contentHtml += `<dd>
                  <span class="tooltip-data-name">${tooltipDataName}</span><span class="tooltip-data">${bodyLines[0]}</span>`;
                    if (showPercentage) {
                      contentHtml += `<span class="tooltip-percent">%</span>`;
                    }
                    contentHtml += `</dd>`;
                  }

                  if (tooltipDataArray) {
                    Object.keys(tooltipDataArray[tooltipIndex])
                      .filter((key) => key !== 'tooltip_label')
                      .forEach((key) => {
                        contentHtml += `<dd>
                       <span class="tooltip-data-name">${key}</span>
                       <span class="tooltip-data">
                         ${tooltipDataArray[tooltipIndex][key].toLocaleString()}
                       </span>
                       </dd>`;
                      });
                  }

                  if (tooltipFooterText) {
                    contentHtml += `<dd id="tooltip-footer">${tooltipFooterText}</dd>`;
                  }

                  contentHtml += '</dl>';
                }

                return contentHtml;
              }),
            },
          },
        },
        plugins: [HorizontalGridBars, VerticalHoverLine],
      };
    },
  },
  watch: {
    chartData() {
      this.renderChart();
    },
  },
  beforeUnmount() {
    // Prevent tooltip from persisting on the screen when navigating to another page
    const tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl) {
      document.body.removeChild(tooltipEl);
    }
    this.destroyChart();
  },
  unmounted() {
    cleanupChartElements();
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      this.destroyChart();
      if (this.$refs.canvas) {
        this.chartObj = new Chart(this.$refs.canvas.getContext('2d'), this.chartOptions);
        this.$emit('chart-created', markRaw(this.chartObj));
      }
    },
    destroyChart() {
      if (this.chartObj) {
        this.chartObj.destroy();
        this.chartObj = null;
        this.$emit('chart-destroyed');
      }
    },
    validateValue(value) {
      return value !== null && value !== undefined;
    },
    refresh() {
      const myChart = this.chartObj;
      myChart.data.labels = this.chartData.labels;
      myChart.data.datasets.data = this.chartData.values;
      myChart.update();
      this.$forceUpdate();
    },
    scaleLabelFormatter(value, index, valueObjects) {
      const reduced = scaleLabelFormatter(value);
      if (this.forceInteger) {
        return Number.isInteger(value) ? `${reduced}` : '';
      }
      if (this.showPercentage) {
        return `${value}%`;
      }
      if (this.isDuration) {
        return formatDuration(value, 0, this.durationUnit);
      }

      const values = valueObjects.map((item) => item.value);
      const maxValue = Math.max(...values);
      const minValue = Math.min(...values);
      if (maxValue <= 1 && minValue > -1) {
        return `${value * 100}%`;
      }
      return `${reduced}`;
    },
    dataLabelFormatter(value, chartData) {
      const maxValue = Math.max(...chartData.dataset.data);
      const minValue = Math.min(...chartData.dataset.data);
      if (maxValue === 0 && minValue === 0) {
        return 0;
      }
      if (maxValue < 1 && minValue > -1) {
        return value.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 });
      }
      return value.toLocaleString('en', { maximumFractionDigits: 0 });
    },
    adjustTicks(scale) {
      if (this.isDuration) {
        let min = Math.min(...this.refinedDatasets);
        let max = Math.max(...this.refinedDatasets);
        const { ticks } = scale;
        const range = max - min;
        const numSteps = ticks.length - 1;

        if (range < 60 * numSteps) {
          // Display in seconds
          this.durationUnit = 'second';
        } else if (range < 3600 * numSteps) {
          // Display in minutes
          this.durationUnit = 'minute';
          min = Math.floor(min / 60);
          max /= 60;
          const step = Math.ceil((max - min) / numSteps);
          max = min + step * numSteps;

          scale.min = min * 60;
          scale.max = max * 60;
          scale.ticks = ticks.map((_, index) => ({ value: (min + step * index) * 60 }));
        } else {
          // Display in hours
          this.durationUnit = 'hour';
          min = Math.floor(min / 3600);
          max /= 3600;
          const step = Math.ceil((max - min) / numSteps);
          max = min + step * numSteps;

          scale.min = min * 3600;
          scale.max = max * 3600;
          scale.ticks = ticks.map((_, index) => ({ value: (min + step * index) * 3600 }));
        }
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.line-chart {
  width: 100%;
}
</style>
