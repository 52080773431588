<template>
  <section>
    <div class="media-popup">
      <MediaPopupV2
        v-if="media"
        :media-type="media.mediaType"
        :media-url="media.urls.original"
        :thumbnail-url="media.urls.ratio"
        :source-type="media.source"
        :carousel="media.carousel"
        :insights="insightsObject"
        :platform-icon="media.source"
        :name="media.sourceCreatorHandle"
        :user-name="media.sourceCreatorHandle"
        :message="media.caption"
        :date-posted="media.sourceCreatedAt"
        :icon-name="media.source"
        :avatar-url="media.sourceCreatorAvatar"
        :email="media.email"
        :tooltip="media.tooltip"
        :duration="media.videoDuration"
        :source-id="media.sourceId"
        :permalink="media?.permalink"
        :sentiment="media?.sentiment"
        :source-creator-id="media.sourceCreatorId"
        :carousel-media="getCarouselMedia(media?.extraUrls)"
        :is-loading="isLoading"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import MediaPopupV2 from '@/components/common/MediaPopupV2/MediaPopupV2.vue';
import { formatNumber, formatPercent } from '@/utils/formatters';
import { metricFormats, metricsMap } from '@/app/socialListening/constants';
import { useSocialListeningStore } from '@/stores/social-listening';
import { useMediaPopupStore } from '@/stores/media-popup';
import { usePublicMediaDrawer } from '@/composables/usePublicMediaDrawer';
import { useFlagStore } from '@/stores/flag';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PublicMediaPopup',
  components: {
    MediaPopupV2,
  },
  props: {
    id: { type: String, default: null },
    channel: { type: String, default: null },
    t: { type: String, default: null },
  },
  setup() {
    const { context, getMediaFromContextList } = usePublicMediaDrawer();
    return {
      context,
      getMediaFromContextList,
    };
  },
  data() {
    return {
      media: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapStores(useSocialListeningStore, useMediaPopupStore, useFlagStore),
    insightsObject() {
      return Object.values(metricsMap[this.media.source]).map((fieldData) => {
        if (fieldData.format === metricFormats.NUMBER)
          fieldData.value = formatNumber(this.media[fieldData.field]);
        else if (fieldData.format === metricFormats.PERCENT)
          fieldData.value = formatPercent(this.media[fieldData.field]);
        return {
          value: fieldData.value,
          label: fieldData.label,
          tooltip: fieldData.tooltip,
        };
      });
    },
    hasGdiHeadersAndFiltersFlag() {
      return this.flagStore.ready && this.flagStore.flags.gdiHeadersAndFilters;
    },
    updateMediaProps() {
      if (this.hasGdiHeadersAndFiltersFlag) {
        return {
          id: this.id,
          channel: this.channel?.toUpperCase?.(),
          t: this.t,
        };
      }
      return { ...(this.context ?? {}), channel: this.context?.channel?.toUpperCase() };
    },
  },
  watch: {
    updateMediaProps: {
      handler() {
        this.updateMedia();
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.context?.id) {
      this.context = {
        id: this.id ?? this.$route.params?.id,
        channel: this.channel ?? this.$route.params?.channel?.toUpperCase?.(),
        t: this.t ?? this.$route.query?.t,
      };
    }
  },
  beforeUnmount() {
    this.mediaPopupStore.clearMediaContextList();
  },
  methods: {
    async updateMedia() {
      this.isLoading = true;
      try {
        const { id, channel, t } = this.updateMediaProps;

        if (id !== this.media?.sourceId || channel !== this.media?.source) {
          const mediaFromContextList = this.getMediaFromContextList({ id, channel });
          if (mediaFromContextList) {
            this.media = mediaFromContextList;
          } else {
            const media = await this.socialListeningStore.getPostById(id, channel, t);
            this.media = media;
          }
        }
      } finally {
        this.isLoading = false;
      }
    },
    getCarouselMedia(mediaList) {
      const mediaItems = [];

      if (mediaList) {
        mediaList.forEach((media) => {
          mediaItems.push({
            mediaType: media.mediaType,
            mediaUrl: media.urls.full,
            sourceType: this.media.source,
          });
        });
      }

      return mediaItems;
    },
  },
});

export default comp;
</script>
