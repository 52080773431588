function getAsset(path) {
  return new URL(`./assets/${path}`, import.meta.url).href;
}

export const brandingDashSocial = {
  appName: 'Dash Social',
  abbreviated: 'Dash',
  isDashSocial: true,

  logoPrimary: getAsset('dashsocial/dash-social-logo-primary-black.svg'),
  logoPrimaryWhite: getAsset('dashsocial/dash-social-logo-primary-white.svg'),
  logoBlack: getAsset('dashsocial/dash-social-logo-black.svg'),
  logoWhite: getAsset('dashsocial/dash-social-logo-white.svg'),

  iconPrimary: getAsset('dashsocial/dash-social-icon-primary.svg'),
  iconBlack: getAsset('dashsocial/dash-social-icon-black.svg'),
  iconWhite: getAsset('dashsocial/dash-social-icon-white.svg'),
};

export const brandingDashHudson = {
  appName: 'Dash Hudson',
  abbreviated: 'DH',
  isDashSocial: false,

  logoPrimary: getAsset('img/logos/logo-dh-dark.svg'),
  // These do not exist in our old branding. Placeholders for now until switch to Dash Social
  logoPrimaryWhite: getAsset('img/logos/logo-dh-dark.svg'),
  logoBlack: getAsset('img/logos/logo-dh-dark.svg'),
  logoWhite: getAsset('img/logos/logo-dh-dark.svg'),

  iconPrimary: getAsset('img/logos/dh-logo.svg'),
  iconBlack: getAsset('img/logos/dh-logo.svg'),
  iconWhite: getAsset('img/logos/white-dh.png'),
};
