import { CHANNELS } from '@/models/dashboards/channels.enum';

export const AGGREGATION_TYPES = Object.freeze({
  AVERAGE: 'AVERAGE',
  COUNT: 'COUNT',
  CUSTOM: 'CUSTOM',
  GROWTH: 'GROWTH',
  LATEST: 'LATEST',
  LATEST_VALID: 'LATEST_VALID',
  SUM: 'SUM',
});

export const AGGREGATION_CURRENT_GRAPH_STATS = [
  AGGREGATION_TYPES.LATEST,
  AGGREGATION_TYPES.LATEST_VALID,
];
export const AGGREGATION_ONLY_AVG_GRAPH_STATS = [AGGREGATION_TYPES.GROWTH];
export const AGGREGATION_NO_GRAPH_STATS = [AGGREGATION_TYPES.AVERAGE];

export const DURATION_FORMATS = Object.freeze({
  SHORTENED_YEARS_DAYS_HOURS_MINUTES_SECONDS: 'yy:dd:hh:mm:ss',
  HOURS_MINUTES_SECONDS: 'hh:mm:ss',
  HOURS_MINUTES: 'hh:mm',
  HOURS: 'hh',
});

export const DEFAULT_PERCENTAGE_GRAPH_PRECISION = 4;

export const METRIC_FORMATS = Object.freeze({
  INTEGER: '0,0',
  INTEGER_ABBREVIATED: '0a',
  FLOAT: '0.0',
  FLOAT_TWO_DECIMAL: '0.[00]a',
  FLOAT_TWO_DECIMAL_WITH_COMMA: '0,0.[00]',
  PERCENT_FLOAT_NORMAl: '0.[00]a%',
  PERCENT_FLOAT_LONG: '0.00%',
  PERCENT_FLOAT_NORMAl_WITH_COMMA: '0,0.[00]a%',
  PERCENT_FLOAT_LONG_WITH_COMMA: '0,0.00%',
  MONETARY_NORMAL: '(0,0.00a)',
  MONETARY_LONG: '(0,0.00)',
});

export const DATA_TYPES = Object.freeze({
  INTEGER: 'INTEGER',
  FLOAT: 'FLOAT',
  PERCENTAGE: 'PERCENTAGE',
  SECONDS: 'SECONDS',
  MILLISECONDS: 'MILLISECONDS',
  MONETARY: 'MONETARY',
});

export const FORMAT_TYPES = Object.freeze({
  NORMAL: 'NORMAL',
  LONG: 'LONG',
});

export const DATA_TYPE_FORMATS = Object.freeze({
  [DATA_TYPES.INTEGER]: {
    [FORMAT_TYPES.NORMAL]: METRIC_FORMATS.INTEGER_ABBREVIATED,
    [FORMAT_TYPES.LONG]: METRIC_FORMATS.INTEGER,
  },
  [DATA_TYPES.FLOAT]: {
    [FORMAT_TYPES.NORMAL]: METRIC_FORMATS.FLOAT_TWO_DECIMAL,
    [FORMAT_TYPES.LONG]: METRIC_FORMATS.FLOAT_TWO_DECIMAL_WITH_COMMA,
  },
  [DATA_TYPES.PERCENTAGE]: {
    [FORMAT_TYPES.NORMAL]: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
    [FORMAT_TYPES.LONG]: METRIC_FORMATS.PERCENT_FLOAT_LONG,
  },
  [DATA_TYPES.SECONDS]: {
    [FORMAT_TYPES.NORMAL]: DURATION_FORMATS.HOURS_MINUTES_SECONDS,
    [FORMAT_TYPES.LONG]: DURATION_FORMATS.HOURS_MINUTES_SECONDS,
  },
  [DATA_TYPES.MILLISECONDS]: {
    [FORMAT_TYPES.NORMAL]: DURATION_FORMATS.SHORTENED_YEARS_DAYS_HOURS_MINUTES_SECONDS,
    [FORMAT_TYPES.LONG]: DURATION_FORMATS.SHORTENED_YEARS_DAYS_HOURS_MINUTES_SECONDS,
  },
  [DATA_TYPES.MONETARY]: {
    [FORMAT_TYPES.NORMAL]: METRIC_FORMATS.MONETARY_NORMAL,
    [FORMAT_TYPES.LONG]: METRIC_FORMATS.MONETARY_LONG,
  },
});

export const QUERY_TIMEFRAME = Object.freeze({
  ACTIVITY_DURING_TIMEFRAME: 'ACTIVITY_DURING_TIMEFRAME',
  POSTS_PUBLISHED_IN_TIMEFRAME: 'POSTS_PUBLISHED_IN_TIMEFRAME',
});

// TODO 104187: Remove this list
export const UPDATED_CONFIG_CHANNELS = [
  CHANNELS.FACEBOOK_COMPETITIVE.value,
  CHANNELS.FACEBOOK.value,
  CHANNELS.INSTAGRAM_BENCHMARKS.value,
  CHANNELS.INSTAGRAM_COMPETITIVE.value,
  CHANNELS.INSTAGRAM_STORIES.value,
  CHANNELS.INSTAGRAM_UGC.value,
  CHANNELS.INSTAGRAM.value,
  CHANNELS.LINKEDIN.value,
  CHANNELS.META_ADS.value,
  CHANNELS.PINTEREST.value,
  CHANNELS.TIKTOK_ADS.value,
  CHANNELS.TIKTOK_ADS.value,
  CHANNELS.TIKTOK.value,
  CHANNELS.TWITTER.value,
  CHANNELS.YOUTUBE.value,
  CHANNELS.THREADS.value,
];
