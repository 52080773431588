<script setup>
import { maxLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { computed, onMounted, ref, watch } from 'vue';
import { VISION_AI_SENDER } from '@/config';
import { KEYDOWN_EVENT_KEYS } from '@/utils/keyEvents';
import {
  INTENT_TYPES,
  CONVERSATION_TEXT_BOX_WARNING,
  TEXTBOX_PLACEHOLDERS,
  ACTION_TYPES,
} from '@/components/VisionAi/constants';
import { useVisionAiStore } from '@/stores/vision-ai';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import FormItem from '@/components/foundation/form/FormItem.vue';
import sphereAnimation from '@/assets/animations/lottie/vision-ai-sphere-blue.json';
import { useIdentityStore } from '@/stores/identity';
import WarningBubble from '@/components/VisionAi/WarningBubble.vue';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import { useVisionAiMessagesStore } from '@/stores/vision-ai-messages';

const INPUT_MAX_LENGTH = 500;
const INPUT_WARNING_LENGTH = 400;

const visionAiStore = useVisionAiStore();
const visionAiConversationsStore = useVisionAiConversationsStore();
const visionAiMessagesStore = useVisionAiMessagesStore();
const identityStore = useIdentityStore();

const userInput = ref('');
const textInputRef = ref(null);
const defaultTextAreaHeight = ref('2rem');
const textAreaFocused = ref(false);

const props = defineProps({
  isExpanded: {
    type: Boolean,
    default: false,
  },
});

const validations = {
  userInput: {
    required,
    maxLength: maxLength(INPUT_MAX_LENGTH),
  },
};

const v$ = useVuelidate(validations, { userInput });

const invalid = computed(() => {
  return v$.value.userInput.$invalid;
});

const inputLengthMessage = computed(() => {
  const userInputLength = userInput.value?.length;
  if (userInputLength >= INPUT_WARNING_LENGTH) {
    return `${userInputLength}/${INPUT_MAX_LENGTH} characters`;
  }
  return null;
});

const userHitMaxLength = computed(() => {
  return userInput.value?.length === INPUT_MAX_LENGTH;
});

const sphereAnimationProps = computed(() => {
  return {
    animationData: sphereAnimation,
    width: '1.25rem',
    height: '1.25rem',
    renderer: 'canvas',
  };
});

const placeholderText = computed(() => {
  if (visionAiConversationsStore.summariesSelected) {
    return TEXTBOX_PLACEHOLDERS.SUMMARIES;
  }
  return TEXTBOX_PLACEHOLDERS.DEFAULT;
});

const buttonTooltip = computed(() => {
  return visionAiStore.loadingMessageInCurrentConversation ? 'Response is being generated' : null;
});

const blockUserInput = computed(() => {
  return visionAiStore.loadingMessageInCurrentConversation;
});

async function handleSubmitUserInput(event) {
  event.preventDefault();

  if (invalid.value || blockUserInput.value) return;
  let conversationId = visionAiConversationsStore.currentConversationId;

  visionAiConversationsStore.addLoadingConversationIds(conversationId);
  if (visionAiConversationsStore.summariesSelected) {
    visionAiStore.startNewChat();
  }

  if (!conversationId) {
    const conversation = await visionAiConversationsStore.createConversation();
    conversationId = conversation?.id;
  }
  await visionAiMessagesStore.createMessage({
    conversationId,
    sender: VISION_AI_SENDER.USER,
    message: userInput.value,
    intentType: INTENT_TYPES.METRIC_ANALYTICS,
    intentQuery: { currentBrandId: identityStore.currentBrand?.id },
    actionType: ACTION_TYPES.QUESTION,
    usedPrompt: false,
  });
  userInput.value = '';
  v$.value.userInput.$touch();
}

const showWarningBubble = computed(
  () => Boolean(userInput.value?.length) && visionAiConversationsStore.summariesSelected,
);

function onInput(e) {
  const element = e.target;
  setTimeout(() => {
    if (element.scrollHeight <= 90) {
      element.style.overflowY = 'hidden';
      element.style.height = e.target.scrollHeight <= 44 ? defaultTextAreaHeight.value : 'auto';
    } else {
      // If the text length exceeds 3 lines.
      element.style.overflowY = 'auto';
    }
  }, 0);
}

function handleFocusTextArea() {
  textInputRef.value.focus();
  textInputRef.value.style.overflowY = 'hidden';
  textInputRef.value.style.height = defaultTextAreaHeight.value;
}

function handleArrowUp(event) {
  event.preventDefault();

  // Only update the user input if the conversation is open and the user hasn't typed anything yet
  if (visionAiConversationsStore.currentConversationId && !userInput.value) {
    userInput.value = visionAiMessagesStore.messages.findLast(
      (message) => message.sender === VISION_AI_SENDER.USER,
    ).message;
    v$.value.userInput.$touch();
  }
}

function handleKeydown(event) {
  if (event.shiftKey && event.key === KEYDOWN_EVENT_KEYS.ENTER) {
    event.stopPropagation();
  } else if (event.key === KEYDOWN_EVENT_KEYS.ENTER) {
    handleSubmitUserInput(event);
  } else if (event.key === KEYDOWN_EVENT_KEYS.ARROW_UP && userInput.value?.length === 0) {
    // Only handle arrow up if the user has not typed anything yet
    handleArrowUp(event);
  } else {
    onInput(event);
  }
}

watch(userInput, (to) => {
  if (!to) {
    textInputRef.value.style.height = defaultTextAreaHeight.value;
  }
});

watch(
  () => visionAiConversationsStore.currentConversationId,
  () => {
    handleFocusTextArea();
  },
);

onMounted(() => {
  // Focus the textArea and hide scrollbar on mount
  handleFocusTextArea();
});

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        handleSubmitUserInput,
        invalid,
        inputLengthMessage,
        showWarningBubble,
        userHitMaxLength,
        blockUserInput,
      }
    : {},
);
</script>

<template>
  <form>
    <WarningBubble
      v-if="showWarningBubble"
      :text="CONVERSATION_TEXT_BOX_WARNING"
      hide-resource-link
    />

    <FormItem
      class="form-item mt-0 rounded-3xl border border-solid bg-[--background-0]"
      :class="{
        'mt-4': showWarningBubble,
        'border-transparent': !textAreaFocused && !userHitMaxLength,
        'border-[--border] shadow-heavy': textAreaFocused && !userHitMaxLength,
        'border-[--error-500] shadow-heavy': userHitMaxLength,
      }"
    >
      <div class="flex items-center">
        <Vue3Lottie class="ml-3 h-[1.5rem] w-[1.5rem]" v-bind="sphereAnimationProps" />

        <textarea
          ref="textInputRef"
          v-model="userInput"
          data-cy="text-area"
          :placeholder="placeholderText"
          :maxlength="INPUT_MAX_LENGTH"
          class="min-h-10 resize-none border-none px-2 pt-[0.7rem]"
          @keydown="handleKeydown"
          @focus="() => (textAreaFocused = true)"
          @blur="() => (textAreaFocused = false)"
        />
        <Button
          v-tooltip="buttonTooltip"
          data-cy="submit-user-input"
          :class="[
            'ml-2 mr-1  h-[--space-30] w-[--space-34] cursor-pointer rounded-full bg-[--brand-primary] p-[--space-5]',
            { 'h-[--space-32] w-[--space-32]': props.isExpanded },
          ]"
          :loading="blockUserInput"
          :icon-name="'prompt-icon'"
          :icon-color="colours.BASIC.WHITE"
          :icon-hover-color="colours.BASIC.WHITE"
          icon-size="xxsmall"
          @click.prevent="handleSubmitUserInput"
        />
      </div>
    </FormItem>
    <p
      v-if="inputLengthMessage"
      class="text-small pl-3 pt-2.5"
      :class="{ 'text-[--error-500]': userHitMaxLength }"
    >
      {{ inputLengthMessage }}
    </p>
  </form>
</template>

<style lang="postcss" scoped>
textarea {
  &::placeholder {
    -webkit-text-fill-color: var(--text-secondary);
  }
}
</style>
