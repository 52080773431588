import { mediaTypeColors, platformColors, sentimentColors } from '@/config';
import { colours } from '@/ux/colours';
import { CHANNELS } from '@/models/dashboards/channels.enum';

export const PREVIEW_TIME_RANGE_IN_DAYS = 28; // last 4 weeks
export const MIN_SCALE_WIDTH = 35;
export const VISUAL_TRENDS_PREVIEW_MAX_POSTS = 5;
export const VISUAL_FILTER_SEARCH_MAX_MEDIA = 5;
export const OVERVIEW_TOP_POSTS_TO_FETCH = 8;
export const TRENDS_NUM_POSTS_TO_FETCH = 5;
export const WEB_RESULT_PREVIEW_MAX_POSTS = 4;
export const WEB_RESULT_PREVIEW_MAX_POSTS_GDI = 12;
export const WEB_RESULT_PAGE_INITIAL_MAX_POSTS = 50;
export const TOP_POSTS_INITIAL_MAX_POSTS = 25;
export const TOP_POSTS_MAX_POSTS_TOTAL = 1000;
export const SENTIMENT_LABEL = 'Sentiment - Caption';
export const metricFormats = {
  NUMBER: 'number',
  PERCENT: 'percent',
  STRING: 'string',
};
export const TOPIC_MAX_KEYWORD_COUNT = 100;

export const TRENDING_KEYWORDS_COUNT = {
  COLLAPSED: 10,
  EXPANDED: 25,
};

export const SHARE_OF_VOICE_COMPETITOR_COUNT = {
  COLLAPSED: 10,
  EXPANDED: 25,
};

export const HISTORICAL_IMPORT_MINIMUM_DATE = new Date(2022, 3, 1);

export const SOCIAL_LISTENING_ROUTE_NAMES = {
  OVERVIEW: 'listening.overview',
  QUICK_SEARCH: 'listening.quick-search',
  TRENDS: 'listening.trends',
  NO_ORG_ACCESS: 'listening.upgrade',
  NO_USER_ACCESS: 'listening.no-access',
  TOPICS: 'listening.topics',
  NO_TOPIC: 'listening.no-topic',
  TOPICS_TOP_POSTS: 'listening.topics.top-posts',
  TOP_POSTS: 'listening.top-posts',
  QUICK_SEARCH_TOP_POSTS: 'listening.quick-search.top-posts',
  VISUAL_TRENDS: 'listening.visual-trends',
  MANAGE_USAGE: 'listening.manage-usage',
  PUBLIC_MEDIA: 'listening.public-media',
  WEB_RESULTS: 'listening.web-results',
  TOPIC_MONITOR: 'listening.topics.monitor',
};

export const SOCIAL_LISTENING_ROUTES = {
  ROOT: {
    name: 'socialListening',
    path: '',
    label: 'Social Listening',
  },
  OVERVIEW: {
    name: SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW,
    path: 'overview',
    label: 'Overview',
  },
  TRENDS: {
    name: SOCIAL_LISTENING_ROUTE_NAMES.TRENDS,
    path: 'trends',
    label: 'Trends',
  },
  TOPICS: {
    name: SOCIAL_LISTENING_ROUTE_NAMES.TOPICS,
    path: 'topics/:id',
    label: 'Topics',
  },
};

export const SOCIAL_LISTENING_BREADCRUMBS = {
  OVERVIEW: [SOCIAL_LISTENING_ROUTES.ROOT, SOCIAL_LISTENING_ROUTES.OVERVIEW],
  TRENDS: [SOCIAL_LISTENING_ROUTES.ROOT, SOCIAL_LISTENING_ROUTES.TRENDS],
  TOPICS: [SOCIAL_LISTENING_ROUTES.ROOT, SOCIAL_LISTENING_ROUTES.TOPICS],
};

export const FILTER_TYPE = {
  CHANNELS: { value: 'channels', text: 'Channel' },
  SENTIMENTS: { value: 'sentiments', text: 'Sentiment' },
  MEDIA_TYPES: { value: 'mediaTypes', text: 'Media Type' },
  KEYWORD: { value: 'keyword', text: 'Keyword' },
  DATE_RANGE: { value: 'dateRange', text: 'Date Range' },
  SORT_BY: { value: 'sortBy', text: 'Sort By' },
  VISUAL: { value: 'visual', text: 'Visual' },
  KEYWORD_TYPE: { value: 'keywordTypes', text: 'Keyword Type' },
};

export const SOCIAL_LISTENING_CHANNEL_ICON_NAMES = {
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
};

export const SENTIMENT_COLORS = {
  POSITIVE: sentimentColors.positive,
  NEGATIVE: sentimentColors.negative,
  NEUTRAL: sentimentColors.neutral,
};

export const SOCIAL_LISTENING_CHANNEL_OPTIONS = {
  [CHANNELS.INSTAGRAM.value]: {
    title: 'Instagram',
    iconName: SOCIAL_LISTENING_CHANNEL_ICON_NAMES.INSTAGRAM,
    value: CHANNELS.INSTAGRAM.value,
  },
  [CHANNELS.TWITTER.value]: {
    title: 'X',
    iconName: SOCIAL_LISTENING_CHANNEL_ICON_NAMES.TWITTER,
    value: CHANNELS.TWITTER.value,
  },
  [CHANNELS.YOUTUBE.value]: {
    title: 'YouTube',
    iconName: SOCIAL_LISTENING_CHANNEL_ICON_NAMES.YOUTUBE,
    value: CHANNELS.YOUTUBE.value,
  },
};

export const SOCIAL_LISTENING_SENTIMENTS_OPTIONS = Object.freeze({
  POSITIVE: {
    value: 'POSITIVE',
    title: 'Positive',
    iconName: 'dot',
    iconColor: SENTIMENT_COLORS.POSITIVE,
    field: 'includePositive',
  },
  NEUTRAL: {
    value: 'NEUTRAL',
    title: 'Neutral',
    iconName: 'dot',
    iconColor: SENTIMENT_COLORS.NEUTRAL,
    field: 'includeNeutral',
  },
  NEGATIVE: {
    value: 'NEGATIVE',
    title: 'Negative',
    iconName: 'dot',
    iconColor: SENTIMENT_COLORS.NEGATIVE,
    field: 'includeNegative',
  },
});

export const SENTIMENT_OPTIONS = new Set(
  Object.values(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).map((sentimentOption) => {
    return sentimentOption.value;
  }),
);

export const SOCIAL_LISTENING_SENTIMENT_METRICS = {
  isPositive: {
    label: SENTIMENT_LABEL,
    value: 'Positive',
    iconColor: SENTIMENT_COLORS.POSITIVE,
  },
  isNeutral: {
    label: SENTIMENT_LABEL,
    value: 'Neutral',
    iconColor: SENTIMENT_COLORS.NEUTRAL,
  },
  isNegative: {
    label: SENTIMENT_LABEL,
    value: 'Negative',
    iconColor: SENTIMENT_COLORS.NEGATIVE,
  },
};

export const SOCIAL_LISTENING_TOP_POST_SENTIMENT_CHIP_PROPS = {
  isPositive: {
    label: 'Positive',
    size: 'sm',
    badge: { severity: 'positive' },
  },
  isNeutral: {
    label: 'Neutral',
    size: 'sm',
    badge: { severity: 'neutral' },
  },
  isNegative: {
    label: 'Negative',
    size: 'sm',
    badge: { severity: 'negative' },
  },
};

export const SOCIAL_LISTENING_METRIC_LABELS = {
  avgEngagements: 'Avg. Engagements',
  engagements: 'Engagements',
  fractionPositive: SOCIAL_LISTENING_SENTIMENTS_OPTIONS.POSITIVE.title,
  fractionNeutral: SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEUTRAL.title,
  fractionNegative: SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEGATIVE.title,
  isPositive: SOCIAL_LISTENING_SENTIMENT_METRICS.isPositive.label,
  isNeutral: SOCIAL_LISTENING_SENTIMENT_METRICS.isNeutral.label,
  isNegative: SOCIAL_LISTENING_SENTIMENT_METRICS.isNegative.label,
};

export const SOCIAL_LISTENING_METRIC_LABELS_GDI = {
  avgEngagements: 'Avg. Eng. Per Post',
  engagements: 'Engagements',
  fractionPositive: SOCIAL_LISTENING_SENTIMENTS_OPTIONS.POSITIVE.title,
  fractionNeutral: SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEUTRAL.title,
  fractionNegative: SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEGATIVE.title,
  isPositive: SOCIAL_LISTENING_SENTIMENT_METRICS.isPositive.label,
  isNeutral: SOCIAL_LISTENING_SENTIMENT_METRICS.isNeutral.label,
  isNegative: SOCIAL_LISTENING_SENTIMENT_METRICS.isNegative.label,
};

export const SOCIAL_LISTENING_METRICS = {
  avgEngagements: {
    label: SOCIAL_LISTENING_METRIC_LABELS.avgEngagements,
    format: metricFormats.NUMBER,
  },
  engagements: {
    label: SOCIAL_LISTENING_METRIC_LABELS.engagements,
    format: metricFormats.NUMBER,
  },
  fractionPositive: {
    label: SOCIAL_LISTENING_METRIC_LABELS.fractionPositive,
    iconColor: SENTIMENT_COLORS.POSITIVE,
    format: metricFormats.PERCENT,
  },
  fractionNegative: {
    label: SOCIAL_LISTENING_METRIC_LABELS.fractionNegative,
    iconColor: SENTIMENT_COLORS.NEGATIVE,
    format: metricFormats.PERCENT,
  },
};

export const SOCIAL_LISTENING_METRICS_GDI = {
  avgEngagements: {
    label: SOCIAL_LISTENING_METRIC_LABELS_GDI.avgEngagements,
    formatType: metricFormats.NUMBER,
    formatOptions: {
      notation: 'compact',
      compactDisplay: 'short',
      minAbbrNumber: 1000,
    },
  },
  fractionPositive: {
    label: SOCIAL_LISTENING_METRIC_LABELS_GDI.fractionPositive,
    formatType: metricFormats.PERCENT,
    dot: { severity: 'positive' },
  },
  fractionNegative: {
    label: SOCIAL_LISTENING_METRIC_LABELS_GDI.fractionNegative,
    formatType: metricFormats.PERCENT,
    dot: { severity: 'negative' },
  },
  fractionNeutral: {
    label: SOCIAL_LISTENING_METRIC_LABELS_GDI.fractionNeutral,
    formatType: metricFormats.PERCENT,
    dot: { severity: 'neutral' },
  },
};

export const SOCIAL_LISTENING_FILTERS = {
  FILTER_MEDIA_TYPE: 'listening_media_type',
  FILTER_CHANNEL: 'listening_channel',
  FILTER_SENTIMENT: 'listening_sentiment',
  FILTER_VISUAL: 'listening_visual',
  FILTER_KEYWORD: 'listening_keyword',
  FILTER_KEYWORD_TYPE: 'listening_keyword_type',
};

export const DATE_OPTIONS = {
  ALLTIME: 'ALL_TIME',
  LASTDAY: 'LAST_DAY',
  LAST7DAYS: 'LAST_7_DAYS',
  LAST30DAYS: 'LAST_30_DAYS',
  LAST3MONTHS: 'LAST_3_MONTHS',
  LAST6MONTHS: 'LAST_6_MONTHS',
  LAST365DAYS: 'LAST_365_DAYS',
  CUSTOM: 'CUSTOM',
};

export const GRAPH_SCALE = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  CUSTOM: 'CUSTOM',
};

// used when drilling down on post volume graph to specific hour/day/week/month on top posts page
export const CUSTOM_DATE = {
  label: 'Custom',
  value: 'CUSTOM',
};
export const SOCIAL_LISTENING_DATE_OPTIONS = Object.freeze({
  LAST_DAY: {
    label: 'Last 24 Hours',
    value: DATE_OPTIONS.LASTDAY,
    subtractUnit: 'day',
    subtractValue: 1,
    scale: GRAPH_SCALE.HOUR,
  },
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    value: DATE_OPTIONS.LAST7DAYS,
    subtractUnit: 'day',
    subtractValue: 6,
    scale: GRAPH_SCALE.DAY,
  },
  LAST_30_DAYS: {
    label: 'Last 30 Days',
    value: DATE_OPTIONS.LAST30DAYS,
    subtractUnit: 'day',
    subtractValue: 29,
    scale: GRAPH_SCALE.DAY,
  },
  LAST_3_MONTHS: {
    label: 'Last 3 Months',
    value: DATE_OPTIONS.LAST3MONTHS,
    subtractUnit: 'month',
    subtractValue: 3,
    scale: GRAPH_SCALE.WEEK,
  },
  LAST_6_MONTHS: {
    label: 'Last 6 Months',
    value: DATE_OPTIONS.LAST6MONTHS,
    subtractUnit: 'month',
    subtractValue: 6,
    scale: GRAPH_SCALE.MONTH,
  },
  LAST_365_DAYS: {
    label: 'Last 365 Days',
    value: DATE_OPTIONS.LAST365DAYS,
    subtractUnit: 'day',
    subtractValue: 364,
    scale: GRAPH_SCALE.MONTH,
  },
});

export const SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS = Object.freeze({
  IMAGE: {
    value: 'IMAGE',
    title: 'Photos',
    tooltip: 'Image',
  },
  VIDEO: {
    value: 'VIDEO',
    title: 'Videos',
    tooltip: 'Video',
  },
  LINK: {
    value: 'LINK',
    title: 'Links',
    tooltip: 'Link',
  },
  TEXT: {
    value: 'TEXT',
    title: 'Text',
    tooltip: 'Text',
  },
});

export const SOCIAL_LISTENING_KEYWORD_TYPE_OPTIONS = Object.freeze({
  KEYWORD: {
    value: 'KEYWORD',
    title: 'Keywords',
    tooltip: 'Keywords',
  },
  HASHTAG: {
    value: 'HASHTAG',
    title: 'Hashtags',
    tooltip: 'Hashtags',
  },
});

export const SOCIAL_LISTENING_OPTIONS_BY_FILTER = {
  [FILTER_TYPE.CHANNELS.value]: SOCIAL_LISTENING_CHANNEL_OPTIONS,
  [FILTER_TYPE.SENTIMENTS.value]: SOCIAL_LISTENING_SENTIMENTS_OPTIONS,
  [FILTER_TYPE.MEDIA_TYPES.value]: SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS,
  [FILTER_TYPE.KEYWORD_TYPE.value]: SOCIAL_LISTENING_KEYWORD_TYPE_OPTIONS,
};

export const POST_SORT_OPTIONS = Object.freeze({
  engagements: { label: 'Engagements', value: 'engagements' },
  date: {
    label: 'Published Date',
    value: 'date',
  },
});

export const TOP_PERFORMING_POST_DATE_OPTIONS = Object.freeze({
  [DATE_OPTIONS.LASTDAY]: {
    label: 'Last Day',
    value: DATE_OPTIONS.LASTDAY,
    subtractUnit: 'day',
    subtractValue: 1,
  },
  [DATE_OPTIONS.LAST7DAYS]: {
    label: 'Last 7 Days',
    value: DATE_OPTIONS.LAST7DAYS,
    subtractUnit: 'day',
    subtractValue: 7,
  },
  [DATE_OPTIONS.LAST30DAYS]: {
    label: 'Last 30 Days',
    value: DATE_OPTIONS.LAST30DAYS,
    subtractUnit: 'day',
    subtractValue: 30,
  },
  [DATE_OPTIONS.LAST3MONTHS]: {
    label: 'Last 3 Months',
    value: DATE_OPTIONS.LAST3MONTHS,
    subtractUnit: 'month',
    subtractValue: 3,
  },
  [DATE_OPTIONS.LAST6MONTHS]: {
    label: 'Last 6 Months',
    value: DATE_OPTIONS.LAST6MONTHS,
    subtractUnit: 'month',
    subtractValue: 6,
  },
  [DATE_OPTIONS.LAST365DAYS]: {
    label: 'Last 365 Days',
    value: DATE_OPTIONS.LAST365DAYS,
    subtractUnit: 'day',
    subtractValue: 365,
  },
});

export const formatChannelFilterOptions = (options) => {
  return options.map((channel) => {
    return {
      label: channel.title,
      formattedLabel: channel.title,
      icon: {
        channelIcon: true,
        name: channel.iconName,
      },
      value: channel.value,
    };
  });
};

export const SOCIAL_LISTENING_FILTER_WIDGETS = {
  [SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL]: {
    name: 'MediaFilterCheckboxWidget',
    label: FILTER_TYPE.CHANNELS.text,
    type: FILTER_TYPE.CHANNELS.value,
    noDuplicate: true,
    options: {
      filterOptions: formatChannelFilterOptions(Object.values(SOCIAL_LISTENING_CHANNEL_OPTIONS)),
    },
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT]: {
    name: 'MediaFilterCheckboxWidget',
    label: FILTER_TYPE.SENTIMENTS.text,
    type: FILTER_TYPE.SENTIMENTS.value,
    noDuplicate: true,
    disableDelete: false,
    options: {
      filterOptions: Object.values(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).map((sentiment) => {
        return {
          label: sentiment.title,
          icon: {
            name: sentiment.iconName,
            color: sentiment.iconColor,
          },
          value: sentiment.value,
        };
      }),
    },
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE]: {
    name: 'MediaFilterCheckboxWidget',
    label: FILTER_TYPE.MEDIA_TYPES.text,
    type: FILTER_TYPE.MEDIA_TYPES.value,
    icon: 'media',
    noDuplicate: true,
    disableDelete: false,
    options: {
      filterOptions: Object.values(SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS).map((mediaType) => {
        return {
          label: mediaType.title,
          value: mediaType.value,
        };
      }),
    },
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_KEYWORD]: {
    name: 'MediaFilterCheckboxWidget',
    label: FILTER_TYPE.KEYWORD.text,
    noDuplicate: true,
    disableDelete: false,
    type: FILTER_TYPE.KEYWORD.value,
    options: {},
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_KEYWORD_TYPE]: {
    name: 'MediaFilterCheckboxWidget',
    label: FILTER_TYPE.KEYWORD_TYPE.text,
    icon: 'key',
    noDuplicate: true,
    disableDelete: false,
    type: FILTER_TYPE.KEYWORD_TYPE.value,
    options: {
      filterOptions: Object.values(SOCIAL_LISTENING_KEYWORD_TYPE_OPTIONS).map((keywordType) => {
        return {
          label: keywordType.title,
          value: keywordType.value,
        };
      }),
    },
  },
};

export const SOCIAL_LISTENING_DEFAULT_FILTER = {
  [SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL]: {
    label: '',
    field: SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL,
    imgFilter: true,
    valueToIconMap: SOCIAL_LISTENING_CHANNEL_ICON_NAMES,
    emptyStates: false,
    type: FILTER_TYPE.CHANNELS.value,
    value: [],
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT]: {
    label: '',
    operator: 'is',
    field: SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT,
    emptyStates: false,
    type: FILTER_TYPE.SENTIMENTS.value,
    value: [],
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE]: {
    label: '',
    operator: 'is',
    field: SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE,
    emptyStates: false,
    type: FILTER_TYPE.MEDIA_TYPES.value,
    value: [],
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_KEYWORD_TYPE]: {
    label: '',
    operator: 'is',
    field: SOCIAL_LISTENING_FILTERS.FILTER_KEYWORD_TYPE,
    emptyStates: false,
    type: FILTER_TYPE.KEYWORD_TYPE.value,
    value: [],
  },
};

export const SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT = Object.keys(
  SOCIAL_LISTENING_CHANNEL_OPTIONS,
);

export const SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT = Object.values(
  SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS,
).map((type) => type.value);

export const SOCIAL_LISTENING_KEYWORDTYPE_FILTER_DEFAULT = Object.values(
  SOCIAL_LISTENING_KEYWORD_TYPE_OPTIONS,
).map((type) => type.value);

export const SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT = Object.values(
  SOCIAL_LISTENING_SENTIMENTS_OPTIONS,
).map((sentiment) => sentiment.value);

export const SOCIAL_LISTENING_DATERANGE_FILTER_DEFAULT = Object.values(
  SOCIAL_LISTENING_DATE_OPTIONS,
).map((date) => date.value);

export const SOCIAL_LISTENING_DEFAULT_FILTER_VALUES = Object.freeze({
  channels: SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
  sentiments: SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
  mediaTypes: SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
  dateRange: SOCIAL_LISTENING_DATERANGE_FILTER_DEFAULT[1],
  keyword: '',
  keywordTypes: SOCIAL_LISTENING_KEYWORDTYPE_FILTER_DEFAULT,
});

export const TOP_POSTS_DEFAULT_QUERY_PARAMS = Object.freeze({
  ...SOCIAL_LISTENING_DEFAULT_FILTER_VALUES,
  dateRange: SOCIAL_LISTENING_DATERANGE_FILTER_DEFAULT[1],
});

export const SOCIAL_LISTENING_TOPIC_DATE_RANGE_OPTIONS = [
  { label: 'Last 24 Hours', value: 1 },
  { label: 'Last 3 Days', value: 2 },
  { label: 'Last 7 Days', value: 6 },
  { label: 'Last 4 Weeks', value: 27 },
];

export const SOCIAL_LISTENING_AUDIENCE_TYPES = {
  PUBLIC: 'PUBLIC',
  COMPETITOR: 'COMPETITOR',
  INDUSTRY: 'INDUSTRY',
};

export const POST_VOLUME_API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const POST_VOLUME_API_CAMEL_CASE_FORMAT = 'YYYYMMDDHH:mm:ss';
export const POST_VOLUME_GRAPH_OPTIONS = Object.freeze({
  POSTS_BY_CHANNEL: {
    value: 'POSTS_BY_CHANNEL',
    label: 'Posts by Channel',
    tooltip: 'The number of published posts within Trends broken down by channel.',
    displayGraphLegend: true,
    topPostsFilterField: 'channels',
    primaryBreakdown: 'PLATFORM',
    secondaryBreakdown: 'SENTIMENT',
    chartConfig: {
      colour: {
        [CHANNELS.INSTAGRAM.value]: platformColors.instagram,
        [CHANNELS.TWITTER.value]: platformColors.twitter,
        [CHANNELS.YOUTUBE.value]: platformColors.youtube,
      },
      legendSort: {
        [CHANNELS.INSTAGRAM.value]: 1,
        [CHANNELS.TWITTER.value]: 2,
        [CHANNELS.YOUTUBE.value]: 3,
      },
      yAxisField: {
        name: 'totalPosts',
      },
      labelOverrides: {
        youtube: 'YouTube',
      },
    },
  },
  POSTS_BY_SENTIMENT: {
    value: 'POSTS_BY_SENTIMENT',
    label: 'Posts by Sentiment',
    tooltip: 'The breakdown of published posts within Trends by caption sentiment.',
    displayGraphLegend: true,
    topPostsFilterField: 'sentiments',
    primaryBreakdown: 'SENTIMENT',
    chartConfig: {
      colour: {
        [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.POSITIVE.value]: sentimentColors.positive,
        [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEUTRAL.value]: sentimentColors.neutral,
        [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEGATIVE.value]: sentimentColors.negative,
      },
      legendSort: {
        [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.POSITIVE.value]: 1,
        [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEUTRAL.value]: 2,
        [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEGATIVE.value]: 3,
      },
      yAxisField: {
        name: 'percentOfPosts',
        format: 'percent',
      },
      labelOverrides: {
        positive: 'Positive Sentiment',
        neutral: 'Neutral Sentiment',
        negative: 'Negative Sentiment',
        percentofposts: '% of Posts',
      },
      axis: {
        y: {
          ticks: {
            format: {
              style: 'percent',
            },
          },
        },
      },
    },
  },
  POSTS_BY_MEDIA_TYPE: {
    value: 'POSTS_BY_MEDIA_TYPE',
    label: 'Posts by Media Type',
    tooltip: 'The breakdown of media type included in published posts within Trends.',
    displayGraphLegend: true,
    topPostsFilterField: 'mediaTypes',
    primaryBreakdown: 'MEDIA_TYPE',
    secondaryBreakdown: 'SENTIMENT',
    chartConfig: {
      colour: {
        PHOTOS: mediaTypeColors.photo,
        VIDEOS: mediaTypeColors.video,
        TEXT: mediaTypeColors.text,
        LINKS: mediaTypeColors.link,
      },
      legendSort: {
        PHOTOS: 1,
        VIDEOS: 2,
        LINKS: 3,
        TEXT: 4,
      },
      yAxisField: {
        name: 'totalPosts',
      },
      labelOverrides: {
        image: 'Photos',
        video: 'Videos',
        link: 'Links',
      },
      filterOverrides: {
        PHOTOS: 'IMAGE',
        VIDEOS: 'VIDEO',
        LINKS: 'LINK',
      },
    },
  },
  TOTAL_POSTS: {
    value: 'TOTAL_POSTS',
    label: 'Total Posts',
    tooltip: 'The total number of posts within Trends published during the selected date range.',
    displayGraphLegend: false,
    primaryBreakdown: 'PLATFORM',
    chartConfig: {
      colour: {
        TOTALPOSTS: colours.ACTION.ACTION_500,
      },
      yAxisField: {
        name: 'totalPosts',
      },
      labelOverrides: {
        youtube: 'YouTube',
        anomalyTotalPosts: '',
        increaseOverPredictedVolume: '% Increase Over Predicted Volume',
      },
      disableTooltipLabelPrefix: true,
    },
  },
});

export const TOOLTIPS = {
  noUserAccessTitle: 'Looks Like You Don’t Have Permission To Use Social Listening',
  noUserAccessMessage:
    'Surface visual trends and competitors’ strategies, measure audience and UGC sentiment, and create easy-to-use reports. Contact your account admin to provide you access.',
};

export const SOCIAL_LISTENING_TOPIC_TOOLTIPS = {
  TOP_KEYWORDS: 'The most used keywords in post captions during the selected date range.',
  SHARE_OF_VOICE_INDUSTRY:
    'The industry breakdown of brand posts within your Topic during the selected date range.',
  SHARE_OF_VOICE_COMPETITOR:
    'The breakdown of competitor posts within your Topic during the selected date range.',
  TOP_POSTS: 'The most engaging posts within this Topic published during the reporting period.',
  WEB_RESULTS: "The most recent web results that match your Topic's search criteria.",
  VISUAL_TRENDS:
    'The most visually similar content segments, based on posts published during the selected date range.',
  TOP_LINE_METRICS: {
    TOTAL_POSTS: 'The total number of posts within your Topic during the selected date range.',
    UNIQUE_CONTRIBUTORS:
      'The unique number of accounts that have created posts within this Topic during the selected date range.',
    TOTAL_ENGAGEMENTS:
      'The total number of engagements on posts within this Topic during the selected date range.',
    AVERAGE_ENGAGEMENTS_PER_POST:
      'The average number of engagements per post within this Topic during the selected date range.',
  },
  POST_VOLUME: {
    POSTS_BY_CHANNEL: 'The number of published posts within your Topic, broken down by channel.',
    POSTS_BY_SENTIMENT: 'The breakdown of published posts within your Topic by caption sentiment.',
    POSTS_BY_MEDIA_TYPE:
      'The breakdown of media type included in published posts within your Topic.',
    TOTAL_POSTS:
      'The total number of posts within your Topic published during the selected date range.',
  },
  ALERT_POPUP: {
    ALERT_SENSITIVITY:
      "Determine the Alert sensitivity for this Topic. Choosing 'Less Sensitive' will result in fewer spike notifications, and 'More Sensitive' will result in a higher number of spike notifications.",
    ALERT_RECIPIENTS:
      'Select which teammates will be emailed when the volume of Topic mentions meet the above criteria.',
    SAVE_BUTTON_DISABLED: 'In order to save this alert, you must select a recipient.',
  },
};

export const SOCIAL_LISTENING_TOPIC_TOOLTIPS_GDI = {
  VISUAL_TRENDS:
    'The most visually similar content segments from posts published during the reporting period',
  WEB_RESULTS: "The most relevant and recent web results that match this Topic's search criteria",
  SHARE_OF_VOICE_COMPETITOR:
    'The distribution of competitor posts within this Topic during the reporting period',
  COMPETITOR_DISTRIBUTION: 'The number of competitor posts within this Topic over time',
};

export const TRENDING_KEYWORDS_DEFAULT_CONFIG = Object.freeze({
  title: 'Trending Keywords',
  tooltip: 'The top 25 trending keywords in post captions during the selected date range.',
  chartKeyLabel: 'Keywords',
  ChartValueLabel: 'Total Posts',
  legend: {
    [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.POSITIVE.value]: {
      value: true,
      label: 'Positive',
      color: SENTIMENT_COLORS.POSITIVE,
      disabled: false,
      canToggle: true,
    },
    [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEUTRAL.value]: {
      value: true,
      label: 'Neutral',
      color: SENTIMENT_COLORS.NEUTRAL,
      disabled: false,
      canToggle: true,
    },
    [SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEGATIVE.value]: {
      value: true,
      label: 'Negative',
      color: SENTIMENT_COLORS.NEGATIVE,
      disabled: false,
      canToggle: true,
    },
  },
});

export const SOCIAL_LISTENING_CONFIRMATION_DIALOG = {
  DELETE_TOPIC: {
    header: 'Delete Topic?',
    message:
      'Are you sure you want to proceed? This will permanently delete this saved Topic as well as associated data.',
    acceptLabel: 'Delete',
  },
};

export const SOCIAL_LISTENING_TOASTS = {
  DELETE_TOPIC: {
    ERROR: {
      severity: 'error',
      message: 'Topic failed to delete. Please refresh and try again.',
    },
  },
};

export const SOCIAL_LISTENING_TOPIC_DROPDOWN_FILTER_OPTIONS = [
  { label: 'Media Type', value: SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE },
  { label: 'Channel', value: SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL },
  { label: 'Sentiment', value: SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT },
];

export const SOCIAL_LISTENING_TOPIC_FILTER_VALUE_MAP = Object.freeze({
  [SOCIAL_LISTENING_FILTERS.FILTER_VISUAL]: {
    label: '',
    field: SOCIAL_LISTENING_FILTERS.FILTER_VISUAL,
    emptyOperator: true,
    value: null,
    props: {
      allowEmpty: true,
      minimalFilterStyle: true,
      disableOpenOnMountIfValueNull: true,
      disableOutsideClick: true,
    },
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE]: {
    label: SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT.join(', '),
    field: SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE,
    value: SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
    operator: 'is',
    props: {
      enforceMinimumCheckbox: true,
      disableOpenOnMountIfValueNull: true,
    },
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL]: {
    label: '',
    field: SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL,
    imgFilter: true,
    emptyOperator: true,
    valueToIconMap: Object.fromEntries(
      Object.values(SOCIAL_LISTENING_CHANNEL_OPTIONS).map((channel) => [
        channel.value,
        channel.iconName,
      ]),
    ),
    value: SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
    props: {
      enforceMinimumCheckbox: true,
      minimalFilterStyle: true,
      disableOpenOnMountIfValueNull: true,
    },
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT]: {
    label: '',
    field: SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT,
    value: SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
    operator: 'is',
    props: {
      disableOpenOnMountIfValueNull: true,
    },
  },
});

export const SOCIAL_LISTENING_TOPIC_FILTER_WIDGETS = Object.freeze({
  [SOCIAL_LISTENING_FILTERS.FILTER_VISUAL]: {
    name: 'VisualFilterSelectionWidget',
    label: 'Visual Filter',
    icon: 'eyeOpen',
    noDuplicate: true,
    disableDelete: true,
    showNumberBadge: false,
    type: FILTER_TYPE.VISUAL.value,
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE]: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    icon: 'media',
    noDuplicate: true,
    options: {
      filterOptions: Object.values(SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS).map((type) => {
        return {
          label: type.title,
          value: type.value,
        };
      }),
    },
    type: FILTER_TYPE.MEDIA_TYPES.value,
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL]: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Channel',
    noDuplicate: true,
    options: {
      filterOptions: formatChannelFilterOptions(Object.values(SOCIAL_LISTENING_CHANNEL_OPTIONS)),
    },
    type: FILTER_TYPE.CHANNELS.value,
  },
  [SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT]: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Sentiment',
    noDuplicate: true,
    options: {
      filterOptions: Object.values(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).map((sentiment) => {
        return {
          label: sentiment.title,
          icon: {
            name: sentiment.iconName,
            color: sentiment.iconColor,
          },
          value: sentiment.value,
        };
      }),
    },
    type: FILTER_TYPE.SENTIMENTS.value,
  },
});

export const SOCIAL_LISTENING_SEARCH_BODY_MAP = Object.freeze({
  [SOCIAL_LISTENING_FILTERS.FILTER_CHANNEL]: 'sources',
  [SOCIAL_LISTENING_FILTERS.FILTER_MEDIA_TYPE]: 'mediaTypes',
  [SOCIAL_LISTENING_FILTERS.FILTER_VISUAL]: 'visualData',
  [SOCIAL_LISTENING_FILTERS.FILTER_SENTIMENT]: 'sentiment',
});

export const TOPIC_BUILDER_SOURCE_OPTIONS = Object.freeze({
  PUBLIC: {
    type: 'Public',
    value: 'PUBLIC',
    description: 'Discover public posts across X, Instagram, and YouTube',
    tooltip:
      'Tip: Use this to discover how people are talking about your brand, product lines, or competitors.',
    totalStep: 4,
    keywordStep: 1,
  },
  COMPETITOR: {
    type: 'Competitor',
    value: 'COMPETITOR',
    description: 'Posts from the competitors you’ve selected in Dash Hudson',
    tooltip:
      "Tip: Use this to understand your competitors' share of voice and how they're going to market.",
    totalStep: 5,
    keywordStep: 2,
  },
  INDUSTRY: {
    type: 'Industry',
    value: 'INDUSTRY',
    description: 'Posts from all brands within the industries of your choice',
    tooltip:
      "Tip: Use this to understand the share of voice within industries and identify any trends in how they're going to market.",
    totalStep: 5,
    keywordStep: 2,
  },
});

export const TOPIC_BUILDER_STEP_DESCRIPTIONS = Object.freeze({
  AUDIENCE: 'Select which type of Topic you want to create',
  WEB_SOURCE: 'Would you like to include web results?',
  BRAND: {
    COMPETITOR: "Which of your brands' competitor lists do you want to choose from?",
    INDUSTRY: 'Which brand would you like to include in your Industry Topic?',
  },
  COMPETITOR: 'Select which competitors this Topic will be monitoring',
  INDUSTRY: 'Select which industries this Topic will be monitoring',
  KEYWORD:
    'Refine your Topic results by adding keywords, hashtags, and @mention combinations to listen to',
  NAME: 'Name your Topic',
});

export const TOPIC_BUILDER_TOAST_MESSAGES = {
  success: 'Topic saved.',
  error: 'Failed to save. Please try again.',
};

export const EDIT_TOPIC_KEYWORD_TOAST_MESSAGES = {
  SAVED: 'Successfully Saved.',
};

export const metricsMap = {
  INSTAGRAM: {
    engagementRate: {
      label: 'Engagement Rate',
      tooltip:
        'The percentage of followers that engaged with this post. Engagement Rate = Likes + Comments / Followers',
      field: 'engagementRate',
      format: metricFormats.PERCENT,
    },
    engagements: {
      label: 'Engagements',
      tooltip: 'Likes + Comments',
      field: 'engagements',
      format: metricFormats.NUMBER,
    },

    likes: {
      label: 'Likes',
      tooltip: 'The number of people that liked this post.',
      field: 'likes',
      format: metricFormats.NUMBER,
    },
    comments: {
      label: 'Comments',
      tooltip: 'The number of people that commented on this post.',
      field: 'comments',
      format: metricFormats.NUMBER,
    },
  },
  TWITTER: {
    publicEngagements: {
      label: 'Public Engagements',
      tooltip:
        'The number of public engagements on this post.' +
        '\n' +
        'Public Engagements = Replies + Likes + Total Reposts (Reposts + Quote Posts)',
      field: 'engagements',
      format: metricFormats.NUMBER,
    },
    replies: {
      label: 'Replies',
      field: 'replies',
      format: metricFormats.NUMBER,
    },
    totalRetweets: {
      label: 'Total Reposts',
      field: 'totalRetweets',
      format: metricFormats.NUMBER,
    },

    retweets: {
      label: 'Reposts',
      field: 'retweets',
      format: metricFormats.NUMBER,
    },
    quoteTweets: {
      label: 'Quote Posts',
      field: 'quoteTweets',
      format: metricFormats.NUMBER,
    },
    likes: {
      label: 'Likes',
      field: 'likes',
      format: metricFormats.NUMBER,
    },
    videoViews: {
      label: 'Video Views',
      field: 'videoViews',
      format: metricFormats.NUMBER,
    },
  },
  YOUTUBE: {
    videoViews: {
      label: 'Video Views',
      field: 'videoViews',
      format: metricFormats.NUMBER,
    },
    likes: {
      label: 'Likes',
      field: 'likes',
      format: metricFormats.NUMBER,
    },
    comments: {
      label: 'Comments',
      field: 'comments',
      format: metricFormats.NUMBER,
    },
  },
};

export const VISUAL_FILTER_PAGES = {
  TOP_PERFORMING: 'topPerforming',
  TRENDS: 'trends',
  LIBRARY: 'library',
};

export const VISUAL_DATA_TYPES = {
  UPLOAD: 'data',
  TRENDS: 'sourceAndId',
  LIBRARY: 'libraryMediaDetails',
};

export const VISUAL_FILTER_MAX_FILE_SIZE_MB = 30;

export const OPERATOR_MAP = {
  OR: 'OR',
  AND: 'AND',
  'AND NOT': 'AND~',
  NOT: 'NOT',
};

export const OPERATOR_LABEL_BY_TYPE = {
  [OPERATOR_MAP.OR]: 'Or',
  [OPERATOR_MAP.AND]: 'And',
  [OPERATOR_MAP['AND NOT']]: 'And',
  [OPERATOR_MAP.NOT]: 'Not',
};

export const KEYWORD_GROUP_TYPE = {
  INCLUDES: 'Includes',
  EXCLUDES: 'Excludes',
};

export const DEFAULT_OPERATOR_BY_GROUP_TYPE = {
  [KEYWORD_GROUP_TYPE.INCLUDES]: OPERATOR_MAP.AND,
  [KEYWORD_GROUP_TYPE.EXCLUDES]: OPERATOR_MAP.AND,
};

export const OPERATOR_GROUP_TYPE = {
  [OPERATOR_MAP.AND]: 'And',
  [OPERATOR_MAP.OR]: 'Or',
};

export const DUPLICATE_KEYWORD_ERROR_COPY = 'Keyword already added';
export const TOO_MANY_KEYWORD_ERROR_COPY = `Topics can not exceed ${TOPIC_MAX_KEYWORD_COUNT} keywords`;
export const MAX_ONE_SPACE_ERROR_COPY = 'Only one space allowed between words';
export const NO_SPECIAL_CHAR_ERROR_COPY = 'Special characters are not supported';
export const keywordLengthReachedError = (limit) => `Keywords can not exceed ${limit} characters`;

export const TOPIC_BUILDER_VALIDATION_ERROR_MESSAGES = {
  name: {
    required: 'This field is required',
    minLength: 'Topic name must contain at least 3 characters',
    nameExists: 'A Topic with this name already exists',
  },
};

export const SHARE_OF_VOICE_BREAKDOWN_BY = {
  [SOCIAL_LISTENING_AUDIENCE_TYPES.INDUSTRY]: 'INDUSTRY',
  [SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR]: 'CREATOR',
};

export const SOCIAL_LISTENING_EVENT_NAMES = {
  SOCIAL_LISTENING_REPORT_INTERACTION: 'Social Listening Report Interaction',
  SOCIAL_LISTENING_FILTERS: 'Social Listening Filters',
  UPGRADE_NOW_CLICKED: 'Upgrade Now Clicked',
  TRENDING_KEYWORD_SELECTED: 'Trending Keywords Selected',
  VISUAL_TRENDS_POST_CLICKED: 'Visual Trends Post Clicked',
  MEDIA_POPUP_OPENED: 'Media Pop-up Opened',
  TOPIC_CREATION_STARTED: 'Topic Creation Started',
  TOPIC_WEB_RESULTS_NEXT: 'Topic Web Results Next',
  TOPIC_BRAND_NEXT: 'Topic Brand Next',
  TOPIC_INDUSTRY_NEXT: 'Topic Industry Next',
  TOPIC_DATA_SOURCE_SELECTED: 'Topic Data Source Selected',
  TOPIC_COMPETITORS_ADDED: 'Topic Competitors Added',
  TOPIC_KEYWORDS_ADDED: 'Topic Keywords Added',
  TOPIC_CREATED: 'Topic Created',
  TOPIC_CREATION_FAILED: 'Topic Creation Failed',
  TOPIC_DELETED: 'Topic Deleted',
  TOPIC_KEYWORDS_EDITED: 'Topic Keywords Edited',
  TOPIC_SETTINGS_CHANGED: 'Topic Settings Changed',
  MANAGE_USE_CLICKED: 'Manage Use Clicked',
  TOPIC_VIEWED: 'Topic Viewed',
  SOCIAL_LISTENING_CARD_CLICKED: 'Social Listening Card Clicked',
  SOCIAL_LISTENING_VIEWED_REPORT_IN_APP: 'Social Listening - Viewed Report in App',
  MANAGE_USAGE_UPGRADE_LIMITS: 'Manage Use Clicked - Upgrade Limits',
  TOPIC_TEXT_EDITOR_SELECTED: 'Topic Text Editor Selected',
  SUGGESTED_TOPIC_HOVERED: 'Suggested Topic Hovered',
  TOPIC_QUICK_SEARCH_PREVIEW: 'Topic Quick Search Preview',
};

export const TOPIC_NAME_MAX_LENGTH = 50;

export const SOCIAL_LISTENING_REPORT_SECTIONS = {
  TOP_PERFORMING_POST: 'Top Performing Posts',
  TOP_KEYWORDS: 'Top Keywords',
  SHARE_OF_VOICE_INDUSTRY: 'Share of Voice by Industry',
  SHARE_OF_VOICE_COMPETITOR: 'Share of Voice by Competitor',
  SHARE_OF_VOICE: 'Share of Voice',
  TOTAL_POSTS: 'Total Posts Line Graph',
  VISUAL_TRENDS: 'Visual Trends',
  SOCIAL_TRENDS: 'Social Trends',
  TOPIC: 'Social Topic',
  WEB_RESULTS: 'Web Results',
  QUICK_SEARCH: 'Quick Search',
};

export const SOCIAL_LISTENING_VISUAL_FILTER_TYPES = {
  TRENDS: 'Trends',
  LIBRARY: 'Library',
  UPLOAD_MEDIA: 'Upload media',
};

export const SOCIAL_LISTENING_REPORT_INTERACTION_TYPES = {
  KEYWORD_CLICKED: 'Keyword Clicked',
  KEYWORD_FILTER_CHANGED: 'Keyword Filter Changed',
  DATA_POINT_CLICKED: 'Data Point Clicked',
  LEGEND_CHANGED: 'Changed Legend',
  GRAPH_METRIC_CHANGED: 'Changed Graph Metric',
  INDUSTRIES_CHANGED: 'Industries Changed',
  COMPETITORS_CHANGED: 'Competitors Changed',
};

export const TOPIC_EDIT_CONTEXT = {
  TOPIC_NAME: 'Topic name',
  KEYWORDS: 'Keywords',
  SOURCE: 'Source',
};

export const MANAGE_USAGE_POPUP_CLICK_LOCATION = {
  NAV_BAR: 'Navigation Bar',
  BANNER: 'Banner',
  DIRECT_LINK: 'Direct Link',
};

export const SOCIAL_LISTENING_CARD_TYPES = {
  TOP_POST: 'Top Post',
  VISUAL_TREND: 'Visual Trend',
  WEB_RESULT: 'Web Result',
};

export const FILTER_DETAILS = {
  [FILTER_TYPE.CHANNELS.value]: {
    filter: FILTER_TYPE.CHANNELS.text,
    action: 'changed channel',
    default: SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
  },
  [FILTER_TYPE.SENTIMENTS.value]: {
    filter: FILTER_TYPE.SENTIMENTS.text,
    action: 'changed sentiment',
    default: SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
  },
  [FILTER_TYPE.MEDIA_TYPES.value]: {
    filter: FILTER_TYPE.MEDIA_TYPES.text,
    action: 'changed media type',
    default: SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
  },
  [FILTER_TYPE.KEYWORD_TYPE.value]: {
    filter: FILTER_TYPE.KEYWORD_TYPE.text,
    action: 'changed keyword type',
    default: SOCIAL_LISTENING_KEYWORDTYPE_FILTER_DEFAULT,
  },
  [FILTER_TYPE.DATE_RANGE.value]: {
    filter: FILTER_TYPE.DATE_RANGE.text,
    action: 'changed date',
  },
  [FILTER_TYPE.SORT_BY.value]: {
    filter: FILTER_TYPE.SORT_BY.text,
    action: 'sorted',
  },
  [FILTER_TYPE.VISUAL.value]: {
    filter: FILTER_TYPE.VISUAL.text,
    action: 'changed visual',
  },
  [FILTER_TYPE.KEYWORD.value]: {
    filter: FILTER_TYPE.KEYWORD.text,
    action: 'changed keyword',
  },
};

export const SOCIAL_LISTENING_TOPIC_CREATION_FLOW = {
  CREATE_TOPIC: 'Create Topic',
  SUGGESTED_TOPIC: 'Suggested Topic',
  QUICK_SEARCH: 'Quick Search',
};

export const NO_DATA_FOUND = 'No posts found during this reporting period.';
export const NO_DATA_FOUND_INDUSTRY = 'There were no Industry posts during this time period.';
export const NO_KEYWORDS_FOUND = 'No top keywords during this reporting period.';
export const NO_WEB_RESULTS = 'No web results found for your Topic.';

export const NULL_USERNAME = 'User';

export const TOPIC_BUILDER_FORM_TYPES = {
  BRAND: 'BRAND',
  COMPETITOR: 'COMPETITOR',
};

export const SOCIAL_LISTENING_OVERVIEW_SOV_CHART_OPTIONS = {
  title: 'Share of Voice By Industry',
  tooltip:
    'The breakdown of industry handles that used the top 25 keywords and hashtags within the selected date range.',
  breakdown: SHARE_OF_VOICE_BREAKDOWN_BY[SOCIAL_LISTENING_AUDIENCE_TYPES.INDUSTRY],
};

export const MENTION_LIMIT_EXCEEDED_TOOLTIP =
  'Mention limit exceeded. Listening has been paused on this Topic.';

// p{L} matches any kind of letter from any language
// p{N} matches any kind of numeric character in any script
// p{M} matches a character intended to be combined with another character (e.g. accents, umlauts, enclosing boxes, etc.)
export const KEYWORD_VALIDATION_REGEX = /[^\p{L}\p{N} _#@.'\p{M}]/mu;

export const WEB_SEARCH_SOURCES = Object.freeze({
  WEB: null,
  REDDIT: 'reddit.com',
  LINKEDIN: 'linkedin.com/posts',
});

export const WEB_SEARCH_SOURCE_OPTIONS_WITHOUT_LINKEDIN = Object.freeze({
  [WEB_SEARCH_SOURCES.WEB]: {
    text: 'Web',
    value: WEB_SEARCH_SOURCES.WEB,
    iconName: 'web',
  },
  [WEB_SEARCH_SOURCES.REDDIT]: {
    text: 'Reddit',
    value: WEB_SEARCH_SOURCES.REDDIT,
    iconName: 'reddit',
  },
});

export const WEB_SEARCH_SOURCE_OPTIONS = Object.freeze({
  [WEB_SEARCH_SOURCES.WEB]: {
    text: 'Web',
    value: WEB_SEARCH_SOURCES.WEB,
    iconName: 'web',
  },
  [WEB_SEARCH_SOURCES.LINKEDIN]: {
    text: 'LinkedIn',
    value: WEB_SEARCH_SOURCES.LINKEDIN,
    iconName: 'linkedin',
  },
  [WEB_SEARCH_SOURCES.REDDIT]: {
    text: 'Reddit',
    value: WEB_SEARCH_SOURCES.REDDIT,
    iconName: 'reddit',
  },
});

export const WEB_SEARCH_SOURCE_OPTIONS_GDI = Object.freeze({
  [WEB_SEARCH_SOURCES.WEB]: {
    text: 'Web',
    value: WEB_SEARCH_SOURCES.WEB,
    iconName: 'www',
  },
  [WEB_SEARCH_SOURCES.LINKEDIN]: {
    text: 'LinkedIn',
    value: WEB_SEARCH_SOURCES.LINKEDIN,
    iconName: 'linkedin',
  },
  [WEB_SEARCH_SOURCES.REDDIT]: {
    text: 'Reddit',
    value: WEB_SEARCH_SOURCES.REDDIT,
    iconName: 'reddit',
  },
});

export const SOV_GRAPH_METRIC_OPTIONS = Object.freeze({
  POST_VOLUME: {
    value: 'POST_VOLUME',
    label: 'Total Posts',
    aggregationMetric: null,
    property: 'postVolume',
    postfix: 'Posts',
  },
  ENGAGEMENTS: {
    value: 'ENGAGEMENTS',
    label: 'Total Engagements',
    aggregationMetric: 'ENGAGEMENTS',
    property: 'postEngagement',
    postfix: 'Engagements',
  },
});

export const SOV_GRAPH_METRIC_OPTIONS_GDI = Object.freeze({
  MENTIONS: {
    value: 'MENTIONS',
    label: 'Mentions',
  },
  ACTIVITY: {
    value: 'ACTIVITY',
    label: 'Activity',
  },
});

export const SOV_GRAPH_METRICS_GDI_MAP = Object.freeze({
  MENTIONS: 'POST_VOLUME',
  ACTIVITY: 'ENGAGEMENTS',
});

export const DEFAULT_KEYWORD_GROUPS = Object.freeze([
  {
    level: 0,
    keywords: [],
    operators: ['or'],
  },
]);

export const SHOW_ALL_BREAKDOWN_THRESHOLD = 0.005;

export const SOCIAL_LISTENING_TOUR_URL =
  'https://www.dashhudson.com/interactive-demo/social-listening-product-demo?utm_medium=referral&utm_source=dh_platform&utm_campaign=sl_upgrade';

export const ALERT_SENSITIVITY = {
  MORE_SENSITIVE: 'MORE_SENSITIVE',
  BALANCED: 'BALANCED',
  LESS_SENSITIVE: 'LESS_SENSITIVE',
};

export const ALERT_SENSITIVITY_THRESHOLDS = {
  // don't change order
  [ALERT_SENSITIVITY.MORE_SENSITIVE]: 90,
  [ALERT_SENSITIVITY.BALANCED]: 95,
  [ALERT_SENSITIVITY.LESS_SENSITIVE]: 99,
};

export const ALERT_SENSITIVITY_LABEL_BY_NUMBER = Object.values(ALERT_SENSITIVITY_THRESHOLDS).reduce(
  (acc, cur, idx) => {
    return {
      ...acc,
      [cur]: Object.keys(ALERT_SENSITIVITY_THRESHOLDS)[idx],
    };
  },
  {},
);

export const ALERT_CHART_FOOTER_MESSAGES = {
  [ALERT_SENSITIVITY.LESS_SENSITIVE]:
    'When "Less Sensitive" is selected, Dash Hudson will detect fewer spikes in mention volume. Graphic is for illustrative purposes only.',
  [ALERT_SENSITIVITY.MORE_SENSITIVE]:
    'When "More Sensitive" is selected, Dash Hudson will detect more spikes in mention volume. Graphic is for illustrative purposes only.',
  [ALERT_SENSITIVITY.BALANCED]:
    'When "Balanced" is selected, Dash Hudson will detect a moderate number of spikes in mention volume. Graphic is for illustrative purposes only.',
};

export const ALERT_HEADER_TOPIC_CREATED =
  'Creating your Topic. Results may take up to 24 hours to populate.';
export const ALERT_HEADER_TOPIC_UPDATED =
  'Updating your Topic. Results may take up to 24 hours to populate.';

export const SOCIAL_LISTENING_LOADED_SOURCE = {
  APP: 'APP',
  ALERTS_EMAIL: 'ALERTS_EMAIL',
  UPDATES_EMAIL: 'UPDATES_EMAIL',
  VISION_AI: 'VISION_AI',
};

export const ALERT_EVENT_NAMES = {
  SOCIAL_LISTENING_ALERT_SETTINGS_CHANGED: 'Social Listening Alert Settings Changed',
  SOCIAL_LISTENING_TOPIC_ALERT_ACTIVATED: 'Social Listening Topic Alert Activated',
  SOCIAL_LISTENING_TOPIC_ALERT_CREATED: 'Social Listening Topic Alert Created',
  SOCIAL_LISTENING_TOPIC_ALERT_DEACTIVATED: 'Social Listening Topic Alert Deactivated',
};

export const KEYWORD_WEIGHTS = {
  SINGLE_KEYWORD: '400',
  MULTI_KEYWORD: '500',
  HOVER_ANY_KEYWORD: '700',
};

export const TRUNCATED_KEYWORD_LENGTH = {
  KEYWORDS: 30,
};

export const KEYWORD_CHIP_TRUNCATE_WIDTH = {
  PIXELS: '320',
};

export const SOCIAL_LISTENING_PAGES = {
  VIEW_YOUR_TOPIC: 'Social Listening - View Your Topic',
  TRENDS: 'Social Listening - Overview',
  CREATE_TOPIC_POPUP: 'Social Listening - Create Topic Popup',
  TEAM_TOPICS: 'Social Listening - Team Topics',
};

export const TOPICS_QUERY_PARAM_MAP = {
  SOURCES: 'sources',
  MEDIA_TYPES: 'mediaTypes',
  POSITIVE_SENTIMENT: 'includePositive',
  NEGATIVE_SENTIMENT: 'includeNegative',
  NEUTRAL_SENTIMENT: 'includeNeutral',
  VISUAL_DATA_INCLUDE: 'includeVisualDataSourceAndId',
  VISUAL_DATA_EXCLUDE: 'excludeVisualDataSourceAndId',
};

export const MAX_TOPIC_KEYWORD_LENGTH = 100;

export const KEYWORD_TEXT_EDITOR_ERRORS = {
  MISSING_STARTING_BRACKET: 'Missing starting bracket',
  MISSING_CLOSING_BRACKET: 'Missing closing bracket',
  UNEXPECTED_CLOSING_BRACKET: 'Unexpected closing bracket',
  UNSUPPORTED_CHARACTER: 'Unsupported special character(s)',
  MAX_LENGTH_EXCEEDED: 'Topic keywords can not exceed 100 characters',
  MULTIPLE_OPERATORS: 'Unsupported combination of operators ("AND", "OR" or "NOT")',
  MULTIPLE_NOT_CLAUSES: 'Only a single "NOT" clause is supported',
};

export const nestingDepthExceededError = (keyword) => {
  const keywordSnippet = keyword ? ` found near "${keyword}"` : '';
  return `Too many nested conditions${keywordSnippet}. Simplify by reducing the number of nested conditions`;
};

export const notClauseContainsAndError = (keyword) => {
  const keywordSnippet = keyword ? ` Found near "${keyword}".` : '';
  return `"NOT" conditions may only contain the "OR" operator.${keywordSnippet}`;
};

export const keywordDuplicateError = (keyword) => `Keyword "${keyword}" already exists`;

export const SOCIAL_LISTENING_OVERVIEW_TOOLTIPS = {
  QUICK_SEARCH:
    'Create a Quick Search using a combination of keywords and capitalized operators (AND, OR, NOT) to view posts from the last 4 weeks. Quick Searches can be saved as Topics.',
  SUGGESTED_TOPICS: "Vision AI-suggested Topics are based on your brand's most recent content.",
};

export const SOCIAL_LISTENING_QUICK_SEARCH_TOOLTIPS = {
  SEARCH_RESULT_CHANNELS: 'Instagram, X and YouTube results are part of the search results below',
  TOP_POSTS: 'The most engaging posts matching your search criteria during the reporting period.',
  POST_VOLUME: 'A sample of posts matching your search criteria to analyze popularity over time.',
  TOP_LINE_METRICS: {
    TOTAL_POSTS:
      'The estimated number of posts matching your search criteria during the reporting period.',
    UNIQUE_CONTRIBUTORS:
      'The unique number of accounts that have created posts within this Topic during the selected date range.',
    AVERAGE_ENGAGEMENTS_PER_POST:
      'The average number of engagements on posts matching your search criteria during the reporting period.',
    AVERAGE_POSTS_PER_DAY:
      'The estimated daily posts matching your search criteria during the reporting period.',
  },
};

export const BOOLEAN_EXPRESSION_CODE_EDITOR_PLACEHOLDER =
  'Enter your keywords, hashtags or @mentions combinations. Eg. Bedroom AND (blankets OR pillows)';

export const OVERVIEW_SUGGESTED_TOPICS_EMPTY_STATE = {
  HEADER: 'Start posting to generate Suggested Topics',
  DESCRIPTION:
    'You need a minimum of 5 published posts on your account for suggested topics to appear.',
};

export const TOPIC_BUILDER_FORM_STATE_FILTERS = {
  webSourceIncluded: false,
  webSource: null,
  brandIds: [],
  competitorIds: [],
  industryIds: [],
  keywords: [
    {
      keywords: [],
      level: 0,
      operators: ['or'],
    },
  ],
  keywordBooleanExpression: '',
};

export const QUICKSEARCH_POST_TREND_PREVIEW_OPTIONS = {
  label: 'Post Trend Preview',
};

export const KEYWORD_EDITOR_CHAR_TO_CLASS = {
  '(': '.paren-left',
  ')': '.paren-right',
  AND: '.operator-and',
  OR: '.operator-or',
  NOT: '.operator-not',
};

export const SOCIAL_LISTENING_TOP_LINE_STATS = {
  MENTIONS: {
    label: 'Mentions',
    tooltip: 'The number of published posts within this Topic during the reporting period',
    type: 'Total',
  },
  ENGAGEMENTS: {
    label: 'Engagements',
    tooltip:
      'The total number of engagements on posts within this Topic during the reporting period',
    type: 'Total',
  },
  AVG_ENGAGEMENTS_PER_POST: {
    label: 'Avg. engagements per post',
    tooltip:
      'The average number of engagements per post within this Topic during the reporting period',
    type: 'Average',
  },
};

export const SENTIMENT_TREND_CHART_OPTIONS = Object.freeze({
  legendSort: {
    POSITIVE: 1,
    NEUTRAL: 2,
    NEGATIVE: 3,
  },
  sentimentColors: {
    POSITIVE: '#24a677',
    NEUTRAL: '#4299e9',
    NEGATIVE: '#f46565',
  },
});

export const SOCIAL_LISTENING_DRAWERS = {
  TOP_POST: 'socialListeningTopPerformingPost',
  TOPIC_CREATOR: 'socialListeningTopicCreator',
  TRENDING_KEYWORD: 'socialListeningTrendingKeyword',
  VISUAL_TRENDS: 'socialListeningVisualTrends',
  VISUAL_TREND: 'socialListeningVisualTrend',
  TOPIC_ALERT: 'socialListeningTopicAlert',
  VISUAL_FILTERS: 'socialListeningVisualFilters',
  MEDIA_POST: 'socialListeningMediaPost',
};
