import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';
import { useMediaPopupStore } from '@/stores/media-popup';
import { useFlagStore } from '@/stores/flag';
import { formatChannelForPublicMediaRoute } from '@/utils/formatters';
import { useDrawer } from '@dashhudson/dashing-ui';

const propsListIndex = ref();
const context = ref({});

export function usePublicMediaDrawer() {
  const router = useRouter();

  const mediaPopupStore = useMediaPopupStore();
  const flagStore = useFlagStore();
  const { launchDrawer } = useDrawer();

  const hasGdiHeadersAndFiltersFlag = computed(
    () => flagStore.ready && flagStore.flags.gdiHeadersAndFilters,
  );

  const propsList = computed(() =>
    (mediaPopupStore.contextList ?? [])?.map((contextListItem) => {
      const id = contextListItem.sourceId;
      const channel = formatChannelForPublicMediaRoute(contextListItem.source);

      return {
        id,
        channel,
        replaceModal: true,
      };
    }),
  );

  async function openPublicMediaPopup({ id, channel, t, replaceModal }) {
    const params = {
      id,
      channel,
    };

    const query = {};
    if (t) {
      query.t = t;
    }
    if (replaceModal) {
      query.replaceModal = !!replaceModal;
    }

    const payload = {
      name: SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA,
      params,
    };

    if (Object.keys(query).length > 0) {
      payload.query = query;
    }

    await router.push(payload);
  }

  async function openPublicMedia({ id, channel, t, replaceModal }) {
    context.value = { id, channel, t };

    if (hasGdiHeadersAndFiltersFlag.value) {
      launchDrawer({
        name: 'publicMedia',
        props: {
          id,
          channel,
          t,
        },
        propsList: propsList.value,
      });
    } else {
      openPublicMediaPopup({ id, channel, t, replaceModal });
    }
  }

  function updatePropsListIndex(media) {
    if (!media || !propsList.value) {
      propsListIndex.value = null;
      return;
    }

    propsListIndex.value = propsList.value.findIndex((item) => {
      return item?.sourceId === media?.sourceId;
    });
  }

  const hasPreviousMedia = computed(() => {
    if (propsList.value?.length > 0) {
      return propsListIndex.value > 0;
    }
    return false;
  });

  const hasNextMedia = computed(() => {
    if (propsList.value?.length > 0) {
      return propsListIndex.value < propsList.value.length - 1;
    }
    return false;
  });

  function loadPreviousPublicMedia() {
    if (!hasPreviousMedia.value) {
      return null;
    }

    propsListIndex.value -= 1;
    const previousProps = propsList.value[propsListIndex.value];

    openPublicMedia(previousProps);

    return previousProps;
  }

  function loadNextPublicMedia() {
    if (!hasNextMedia.value) {
      return null;
    }

    propsListIndex.value += 1;
    const nextProps = propsList.value[propsListIndex.value];

    openPublicMedia(nextProps);

    return nextProps;
  }

  function getMediaFromContextList({ id, channel }) {
    return propsList.value.find(
      (media) => id === media?.sourceId && channel?.toUpperCase?.() === media?.source,
    );
  }

  return {
    context,
    openPublicMedia,
    hasPreviousMedia,
    hasNextMedia,
    loadPreviousPublicMedia,
    loadNextPublicMedia,
    updatePropsListIndex,
    getMediaFromContextList,
  };
}
