<template>
  <section
    class="mx-auto my-2 flex w-full flex-col rounded-[--rounded-corner] bg-[--background-300] p-6 text-[length:--x14] text-[color:--text-primary]"
  >
    <InsightsDropdown
      :has-insights-access="accessFbInsight"
      data-cy="FacebookInsights"
      :header-title="headerTitle"
      :platform-icon="platformIcon"
      :header-tooltip="headerTooltip"
      :has-negative-organic-metric="hasNegativeOrganicMetric"
    >
      <template #dropdownContents>
        <div class="insights-list">
          <div v-if="!insightsExist" class="alert-message">
            <p>Facebook source data is currently unavailable for this post.</p>
          </div>
          <div v-if="!isEmpty(callToActionBanner)" class="pt-4">
            <Banner v-bind="callToActionBanner" small @click-action="callToActionBanner.actionTo">
              {{ callToActionBanner.message }}
            </Banner>
          </div>
          <div v-if="hasNegativeOrganicMetric" class="alert-message">
            <p>
              This promoted post is reporting negative organic values for some metrics due to a
              known issue with the Meta Marketing API.
            </p>
          </div>
          <div>
            <ul class="insights-stats">
              <template v-if="isReel">
                <li
                  v-for="(item, key) in sortedReelInsights"
                  :key="key"
                  :class="[
                    !accessFbInsight ? 'disabled' : undefined,
                    item.level ? `level${item.level}` : undefined,
                  ]"
                >
                  <span class="stat-name">
                    {{ item.name || startCase(key) }}
                    <InfoTooltip
                      v-if="item.tooltip || tooltips[key]"
                      :tooltip="item.tooltip || tooltips[key]"
                    />
                  </span>
                  <span class="stat">
                    <template v-if="item.format === 'percent'">
                      {{ formatPercent(item.value) }}
                    </template>
                    <template v-else-if="item.format === 'time'">
                      {{ formatDynamicDuration(item.value, TIME_UNIT_OPTIONS.MILLISECOND) }}
                    </template>
                    <template v-else>
                      {{ formatValue(item.format, item.value) }}
                    </template>
                  </span>
                </li>
              </template>
              <template v-else>
                <li
                  v-for="(item, key) in insights"
                  :key="key"
                  :class="[
                    !accessFbInsight ? 'disabled' : undefined,
                    item.level ? `level${item.level}` : undefined,
                  ]"
                  :data-cy="`fb-insight-${key}`"
                >
                  <span class="stat-name">
                    {{ item.name || startCase(key) }}
                    <InfoTooltip
                      v-if="item.tooltip || tooltips[key]"
                      :tooltip="item.tooltip || tooltips[key]"
                    />
                  </span>
                  <span class="stat">
                    <template v-if="item.percent">
                      {{ formatPercent(item.value) }}
                    </template>
                    <template v-else-if="item.monetary">
                      {{ formatMonetary(item.value) }}
                    </template>
                    <template v-else>
                      {{ formatValue(item.format, item.value) }}
                    </template>
                  </span>
                </li>
              </template>
            </ul>
            <div :class="['disable-message', { hide: accessFbInsight }]">
              <Icon name="lock-1" height="40" width="40" />
              <p>
                Looks like this feature isn't included in your plan.
                <a @click="contactUsClicked">Message us</a>
                on chat or send us an email to try it out!
              </p>
            </div>
          </div>
        </div>
        <CustomMetricsInsights v-if="showCustomMetricsInsights" :media-item="mediaItem" />
      </template>
    </InsightsDropdown>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import startCase from 'lodash/startCase';
import numeral from 'numeral';
import { useAuthStore } from '@/stores/auth';
import { toolTips } from '@/config';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import CustomMetricsInsights from '@/app/library/components/MediaPopup/CustomMetricsInsights.vue';
import { getMediaTypeFromDetail } from '@/utils/media';
import { usePlatformStore } from '@/stores/platform';
import { useSupportChatStore } from '@/stores/support-chat';
import { useFlagStore } from '@/stores/flag';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import {
  formatDynamicDuration,
  formatPercent,
  formatValueByFormatType,
  TIME_UNIT_OPTIONS,
} from '@/utils/formatters';
import enumTypes from '@/app/library/constants';
import { METRIC_FORMATS } from '@/models/dashboards/metrics.constants';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import Banner from '@/components/foundation/feedback/Banner.vue';
import isEmpty from 'lodash/isEmpty';
import {
  facebookPromotedMediaPopupMetrics,
  promotedMediaPopupTooltips,
} from '@/app/facebook/constants';
import InsightsDropdown from './InsightsDropdown.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FacebookInsights',
  components: { Banner, Icon, InfoTooltip, InsightsDropdown, CustomMetricsInsights },
  props: { mediaItem: { type: Object, default: null } },
  setup() {
    const { canAccessCustomMetrics } = useCustomMetrics();
    return {
      canAccessCustomMetrics,
    };
  },
  computed: {
    TIME_UNIT_OPTIONS() {
      return TIME_UNIT_OPTIONS;
    },
    ...mapStores(usePlatformStore, useSupportChatStore, useFlagStore),
    ...mapPiniaState(useAuthStore, ['brand_can']),
    isReel() {
      return this.mediaItem?.isReel;
    },
    isBoosted() {
      return this.mediaItem?.isBoosted;
    },
    isMetaAdsConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    hasConnectedMetaAdsPreviously() {
      return !this.platformStore.isPlatformNeverConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    hasPromotedInsightsFeature() {
      return (
        // changes are made to all media types except reels
        this.flagStore?.ready && this.flagStore?.flags?.metaPromotedPostsInsights && !this.isReel
      );
    },
    shouldShowPromotedInsights() {
      // show promoted metrics for boosted posts if Meta Ads has been connected previously
      return (
        this.isBoosted && this.hasPromotedInsightsFeature && this.hasConnectedMetaAdsPreviously
      );
    },
    hasNegativeOrganicMetric() {
      const organicMetrics = [
        this.mediaItem?.insights?.organicComments,
        this.mediaItem?.insights?.organicReactions,
        this.mediaItem?.insights?.organicShares,
        this.mediaItem?.insights?.organicLinkClicks,
        this.mediaItem?.insights?.organicOtherClicks,
        this.mediaItem?.insights?.organicPostClicks,
        this.mediaItem?.insights?.organicPhotoViewClicks,
      ];
      return organicMetrics.some((metric) => metric < 0);
    },
    headerTitle() {
      return this.isReel ? 'Facebook Reels Insights' : 'Facebook Insights';
    },
    headerTooltip() {
      return this.shouldShowPromotedInsights
        ? 'Promoted metrics displayed are from posts that were boosted through Ads Manager only'
        : '';
    },
    platformIcon() {
      if (this.shouldShowPromotedInsights) return 'boosted';
      return this.isReel ? 'instagram-reels' : 'facebook';
    },
    accessFbInsight() {
      return this.brand_can('facebook', 'can_access_fb_insights');
    },
    showCustomMetricsInsights() {
      return this.canAccessCustomMetrics && this.isOwned && this.insightsExist;
    },
    isOwned() {
      return this.mediaItem.sourceType.startsWith(enumTypes.FACEBOOK_OWNED);
    },
    insightsExist() {
      return Object.keys(this.mediaItem?.insights || {}).length > 0;
    },
    isVideo() {
      return getMediaTypeFromDetail(this.mediaItem) === 'video';
    },
    isCompetitive() {
      return enumTypes.FACEBOOK_COMPETITIVE_SOURCE_LIST.includes(this.mediaItem.sourceType);
    },
    tooltips() {
      const baseTooltips = {
        ...toolTips.facebookReelsInsights,
        ...(this.isCompetitive ? toolTips.facebookCompetitiveInsights : toolTips.facebookInsights),
      };
      // use updated tooltips for promoted insights feature
      return this.hasPromotedInsightsFeature
        ? { ...baseTooltips, ...promotedMediaPopupTooltips }
        : baseTooltips;
    },
    sortedReelInsights() {
      let insightsData;
      if (this.isReel) {
        if (this.isBoosted) {
          insightsData = this.getPromotedAndOrganicInsights(this.mediaItem?.insights);
        } else {
          insightsData = this.getOwnedInsights(this.mediaItem?.insights);
        }
      }
      const reelInsights = this.reelInsights;
      const combinedInsights = { ...insightsData, ...reelInsights };

      const insightsArray = Object.entries(combinedInsights).map(([key, value]) => ({
        key,
        ...value,
      }));

      // Assuming function definitions to determine parent or child
      const parents = insightsArray.filter((item) => !item.parentKey);
      const children = insightsArray.filter((item) => item.parentKey);
      parents.sort((a, b) => (a.name || a.label || '').localeCompare(b.label || b.name || ''));

      // Organize children under parents
      const sortedInsights = [];
      parents.forEach((parent) => {
        sortedInsights.push(parent);
        const parentChildren = children.filter((child) => child.parentKey === parent.key);
        sortedInsights.push(...parentChildren);
      });

      return sortedInsights;
    },
    insights() {
      const insights = this.accessFbInsight ? this.mediaItem?.insights : undefined;
      if (this.shouldShowPromotedInsights) {
        return this.getPromotedAndOrganicInsights(insights);
      }
      if (this.isCompetitive) {
        return this.getCompetitiveInsights(insights);
      }
      return this.getOwnedInsights(insights);
    },
    reelInsights() {
      const insights = this.accessFbInsight ? this.mediaItem?.reelInsights : undefined;

      return {
        plays: {
          name: 'Plays',
          level: 2,
          value: insights?.plays,
          tooltip: this.tooltips.plays,
        },
        replays: {
          name: 'Replays',
          level: 2,
          value: insights?.replays,
          tooltip: this.tooltips.replays,
        },
        totalPlays: {
          name: 'Total Plays',
          level: 2,
          value: insights?.totalPlays,
          tooltip: this.tooltips.totalPlays,
        },
        uniqueImpressions: {
          name: 'Unique Impressions',
          level: 2,
          value: insights?.uniqueImpressions,
          tooltip: this.tooltips.uniqueImpressions,
        },
        avgTimeWatched: {
          name: 'Avg. Time Watched',
          level: 2,
          value: insights?.avgTimeWatched,
          format: 'time',
          tooltip: this.tooltips.avgTimeWatched,
        },
        totalTimeWatched: {
          name: 'Total Time Watched',
          level: 2,
          value: insights?.totalTimeWatched,
          format: 'time',
          tooltip: this.tooltips.totalTimeWatched,
        },
        follows: {
          name: 'Follows',
          level: 2,
          value: insights?.follows,
          tooltip: this.tooltips.follows,
        },
        effectivenessReels: {
          name: 'Effectiveness - Reels',
          level: 2,
          value: insights?.effectivenessReels,
          format: 'percent',
          tooltip: this.tooltips.effectivenessReels,
        },
        // TODO add this for https://app.shortcut.com/dashhudson/story/115859/create-graphed-percentage-viewed-component
        // percentageViewed: {
        //   level: 2,
        //   value: insights?.percentageViewed,
        //   format: 'percent',
        // },
      };
    },
    callToActionBanner() {
      // Meta Ads was connected but now isn't
      if (
        this.hasPromotedInsightsFeature &&
        this.hasConnectedMetaAdsPreviously &&
        !this.isMetaAdsConnected
      ) {
        return {
          customIcon: 'alertCircle',
          message: 'Meta Ads not connected, data may not be current',
          actionText: 'Reconnect in Settings',
          alertType: 'warning',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      // has never connected Meta Ads
      if (this.hasPromotedInsightsFeature && !this.hasConnectedMetaAdsPreviously) {
        return {
          customIcon: 'boosted',
          message: 'Inform your social strategy with promoted post insights!',
          actionText: 'Connect Meta Ads Account',
          alertType: 'white',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      return {};
    },
    currency() {
      if (this.shouldShowPromotedInsights) {
        const metaAdsAccounts = this.platformStore.getAccount(PLATFORM_CONNECTION.FACEBOOK_ADS);
        return metaAdsAccounts?.[0]?.currency;
      }
      return undefined;
    },
  },
  methods: {
    isEmpty,
    formatDynamicDuration,
    startCase,
    contactUsClicked() {
      this.supportChatStore.show();
    },
    dash(value, force = false) {
      if (force) {
        return '-';
      }
      return value !== null && value !== undefined ? value : '-';
    },
    formatValue(format = '0,0', ...args) {
      return numeral(this.dash(...args)).format(format);
    },
    formatPercent(...args) {
      return formatPercent(this.dash(...args));
    },
    formatMonetary(value) {
      const formattedValue = formatValueByFormatType(value, METRIC_FORMATS.MONETARY_LONG, '-');
      return this.currency ? `${formattedValue} ${this.currency}` : formattedValue;
    },
    getCompetitiveInsights(insights) {
      return {
        comments: { level: 2, value: insights?.totalComments },
        engagements: { value: insights?.totalEngagements },
        engagementRate: { level: 2, percent: true, value: insights?.engagementRate },
        reactions: { level: 2, value: insights?.totalReactions },
        shares: { level: 2, value: insights?.totalShares },
      };
    },
    getOwnedInsights(insights) {
      return {
        comments: {
          name: 'Comments',
          key: 'comments',
          level: 2,
          value: insights?.comments,
          tooltip: this.tooltips.comments,
        },
        effectiveness: {
          name: 'Effectiveness',
          percent: true,
          value: insights?.effectiveness,
          tooltip: this.tooltips.effectiveness,
        },
        engagementRate: {
          name: 'Engagement Rate',
          percent: true,
          value: insights?.engagementRate,
          tooltip: this.tooltips.engagementRate,
        },
        impressions: {
          name: 'Impressions',
          value: insights?.impressions,
          tooltip: this.tooltips.impressions,
        },
        linkClicks: {
          name: 'Link Clicks',
          parentKey: 'postClicks',
          level: 3,
          value: insights?.linkClicks,
          tooltip: this.tooltips.linkClicks,
        },
        otherClicks: {
          name: 'Other Clicks',
          parentKey: 'postClicks',
          level: 3,
          value: insights?.otherClicks,
          tooltip: this.tooltips.otherClicks,
        },
        photoViewClicks: {
          name: 'Photo View Clicks',
          parentKey: 'postClicks',
          level: 3,
          value: insights?.photoViewClicks,
          tooltip: this.tooltips.photoViewClicks,
        },
        postClicks: {
          name: 'Post Clicks',
          level: 2,
          value: insights?.postClicks,
          tooltip: this.tooltips.postClicks,
        },
        reach: {
          name: 'Reach',
          value: insights?.reach,
          tooltip: this.tooltips.reach,
        },
        reactions: {
          name: 'Reactions',
          key: 'reactions',
          level: 2,
          value: insights?.reactions,
          tooltip: this.tooltips.reactions,
        },
        shares: {
          name: 'Shares',
          key: 'shares',
          level: 2,
          value: insights?.shares,
          tooltip: this.tooltips.shares,
        },
        totalEngagements: {
          name: 'Total Engagements',
          key: 'totalEngagements',
          value: insights?.totalEngagements,
          level: 2,
          tooltip: this.tooltips.totalEngagements,
        },
        ...(this.isVideo
          ? {
              videoCompleteViews: {
                name: 'Video Complete Views',
                value: insights.videoCompleteViews,
                tooltip: this.tooltips.videoCompleteViews,
              },
              videoViews: {
                name: 'Video Views',
                value: insights.videoViews,
                tooltip: this.tooltips.videoViews,
              },
            }
          : {}),
      };
    },
    getPromotedAndOrganicInsights(insights) {
      return facebookPromotedMediaPopupMetrics
        .filter((metric) => this.isVideo === metric.videoMetric || metric.videoMetric === undefined)
        .reduce((acc, metric) => {
          acc[metric.key] = {
            value: (insights?.[metric.key] || insights?.[metric.alternativeKey]) ?? 0,
            name: metric.label,
            level: metric.nested ? 3 : 2,
            tooltip: metric.tooltip,
            percent: metric.percent,
            monetary: metric.monetary,
            parentKey: metric.parentKey,
          };
          return acc;
        }, {});
    },
    async showMetaAdsConnectionPopup() {
      await this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.insights-list .insights-stats {
  .stat-name {
    padding-right: var(--space-8);
    font-weight: var(--font-normal);
  }

  li {
    &.level2 {
      .stat-name {
        font-weight: var(--font-normal);
      }
    }

    &.level3 {
      margin-left: var(--space-24);

      .stat-name {
        font-weight: var(--font-normal);
        opacity: 0.7;
      }
    }
  }
}
</style>
