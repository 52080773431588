<template>
  <component
    :is="containerComponent"
    :key="sourceId"
    :close="close"
    type="xlarge"
    close-on-route-change
    data-cy="media-popup"
    size="large"
  >
    <template v-if="!hasGdiHeadersAndFiltersFlag">
      <Arrow
        v-if="showPrevArrow"
        :show-previous-arrow="true"
        @click-previous-arrow="previousArrowClicked"
      />
      <Arrow v-if="showNextArrow" :show-next-arrow="true" @click-next-arrow="nextArrowClicked" />
    </template>
    <main>
      <template v-if="hasGdiHeadersAndFiltersFlag">
        <DrawerHeader
          :show-back-button="hasHistory"
          :previous-loading="previousLoading"
          :next-loading="nextLoading"
          :disabled="isLoading"
          @previous="previousLoading = true"
          @next="nextLoading = true"
          @back="backDrawer"
        />
      </template>
      <div
        v-if="!loading"
        :class="[
          'media-main',
          {
            'gdi-styling': hasGdiHeadersAndFiltersFlag,
          },
        ]"
        data-cy="media-popup-content"
      >
        <div class="left-container">
          <div class="media-item-container">
            <!-- Carousel media popup -->
            <Carousel
              v-if="isCarousel"
              ref="carousel"
              :per-page="1"
              :pagination-color="paginationColor"
              :pagination-active-color="paginationActiveColor"
              :navigate-to="[0, false]"
              :navigation-enabled="true"
              :speed="700"
              :navigation-next-label="''"
              :navigation-prev-label="''"
              class="media-carousel"
            >
              <Slide
                v-for="(media, index) in carouselMedia"
                :key="index"
                :title="`Media ${index + 1}`"
              >
                <MediaItem
                  :id="`media-${index}`"
                  :media-type="media.mediaType"
                  :media-url="media.mediaUrl"
                  :cover="cover"
                  :source-type="media.sourceType"
                  carousel
                  @close="close"
                />
              </Slide>
            </Carousel>
            <TwitterTextPreview
              v-else-if="isTextPreview"
              :post-type="mediaType"
              :post-url="permalink"
            />

            <EmbedPreviewMediaView
              v-else-if="isYouTube"
              :key="sourceId"
              class="embed-preview-media-view"
              :src-url="srcUrl"
              :iframe-attributes="iframeAttributes"
              :styles="styles"
              @close="close"
            />

            <!-- Single media popup -->
            <MediaItem
              v-else
              id="media-0"
              :media-type="mediaType"
              :media-url="mediaUrl"
              :cover="cover"
              :thumbnail-url="thumbnailUrl"
              :source-type="sourceType"
              :duration="duration"
              @close="close"
            />
          </div>
        </div>
        <div class="right-container">
          <UserSection
            class="user-info"
            :source-type="sourceType"
            :name="name"
            :user-name="userName"
            :message="message"
            :date-posted="datePosted"
            :platform-home-page-url="sourceId"
            :icon-name="sourceType"
            :avatar-url="avatarUrl"
            :email="email"
            :tooltip="tooltip"
            :post-type="sourceType"
            :source-id="sourceId"
            :permalink="permalink"
            :source-creator-id="sourceCreatorId"
          />
          <Sentiment v-if="showSentiment" :sentiment="sentiment" />
          <Insights class="insights-dropdown" :insights="insights" :platform-icon="sourceType" />
        </div>
      </div>
      <CircularLoader v-else class="loading" />
    </main>
  </component>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { mapStores } from 'pinia';
import { Carousel, Slide } from '@/vendor/vue-carousel';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import globalModalsMixin from '@/mixins/globalModalsMixin';
import enumTypes, { MEDIA_TYPE_OPTIONS } from '@/app/library/constants';
import Popup from '@/components/Popup.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { usePlatformStore } from '@/stores/platform';
import { useMediaLinksStore } from '@/stores/media-links';
import { useSearchStore } from '@/stores/search';
import { useMediaPopupStore } from '@/stores/media-popup';
import { useTiktokStore } from '@/stores/tiktok';
import { useMediaDetailStore } from '@/stores/media-detail';
import UserSection from '@/components/common/MediaPopupV2/UserSection.vue';
import Arrow from '@/components/foundation/Arrow.vue';
import MediaItem from '@/components/common/MediaPopupV2/MediaItem.vue';
import Insights from '@/components/common/MediaPopupV2/Insights.vue';
import Sentiment from '@/components/common/MediaPopupV2/Sentiment.vue';
import { useSocialListeningStore } from '@/stores/social-listening';
import { stringifyQuery } from '@/utils/query';
import EmbedPreviewMediaView from '@/app/library/components/MediaPopup/EmbedPreviewMediaView.vue';
import {
  SOCIAL_LISTENING_CARD_TYPES,
  SOCIAL_LISTENING_ROUTE_NAMES,
} from '@/app/socialListening/constants';

import { colours } from '@/ux/colours';
import TwitterTextPreview from '@/components/common/MediaPopupV2/TwitterTextPreview.vue';
import { socialListeningCardClickedEvent } from '@/app/socialListening/utils/mixpanel-events';
import { useFlagStore } from '@/stores/flag';
import { usePublicMediaDrawer } from '@/composables/usePublicMediaDrawer';
import { DrawerContent, DrawerHeader, useDrawer } from '@dashhudson/dashing-ui';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaPopupV2',
  components: {
    MediaItem,
    Arrow,
    Insights,
    CircularLoader,
    Popup,
    UserSection,
    EmbedPreviewMediaView,
    Sentiment,
    Carousel,
    Slide,
    TwitterTextPreview,
    DrawerContent,
    DrawerHeader,
  },
  mixins: [globalModalsMixin],
  props: {
    carousel: { type: Boolean, default: false },
    mediaType: { type: String, default: null },
    mediaUrl: { type: String, default: null },
    cover: { type: Boolean, default: false },
    thumbnailUrl: { type: String, default: null },
    sourceType: { type: String, required: true },
    duration: { type: Number, default: null },
    insights: { type: Array, default: () => [] },
    userName: { type: String, default: null },
    name: { type: String, default: null },
    datePosted: { type: String, default: null },
    message: { type: String, default: null },
    avatarUrl: { type: String, default: null },
    invitationStatus: { type: String, default: null },
    email: { type: String, default: null },
    tooltip: { type: String, default: null },
    sourceId: { type: String, default: null },
    permalink: { type: String, default: null },
    sentiment: { type: Object, default: () => {} },
    carouselMedia: { type: Array, default: () => [] },
    sourceCreatorId: { type: String, default: null },
    isLoading: { type: Boolean, default: undefined },
  },
  setup(props) {
    const {
      updatePropsListIndex,
      hasPreviousMedia,
      hasNextMedia,
      loadPreviousPublicMedia,
      loadNextPublicMedia,
    } = usePublicMediaDrawer();
    const { hasHistory, backDrawer } = useDrawer();

    const nextLoading = ref(false);
    const previousLoading = ref(false);

    watch(
      () => props.isLoading,
      () => {
        if (props.isLoading === false) {
          nextLoading.value = false;
          previousLoading.value = false;
        }
      },
    );

    return {
      updatePropsListIndex,
      hasPreviousMedia,
      hasNextMedia,
      loadPreviousPublicMedia,
      loadNextPublicMedia,
      hasHistory,
      backDrawer,
      previousLoading,
      nextLoading,
    };
  },
  data() {
    return {
      isRenderingFinished: false,
      paginationColor: colours.TEXT.TEXT_SECONDARY,
      paginationActiveColor: colours.BASIC.WHITE,
      iframeAttributes: {
        allowFullscreen: true,
      },
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useNotificationStore,
      usePlatformStore,
      useMediaLinksStore,
      useSearchStore,
      useMediaPopupStore,
      useMediaDetailStore,
      useTiktokStore,
      useSocialListeningStore,
      useFlagStore,
    ),
    isTextPreview() {
      return (
        this.isTwitter &&
        (this.mediaType === MEDIA_TYPE_OPTIONS.LINK.value ||
          this.mediaType === MEDIA_TYPE_OPTIONS.TEXT.value)
      );
    },
    showPrevArrow() {
      return this.hasPreviousMedia;
    },
    showNextArrow() {
      return this.hasNextMedia;
    },
    isCarousel() {
      return this.carouselMedia?.length > 1;
    },
    isYouTube() {
      return this.sourceType === enumTypes.YOUTUBE;
    },
    isTwitter() {
      return this.sourceType === enumTypes.TWITTER;
    },
    loading() {
      return !this.mediaType;
    },
    srcUrl() {
      const queryParams = stringifyQuery({
        // do not show related videos on pause
        rel: 0,
        // do not show YouTube logo in control bar
        modestbranding: 1,
        // set the color used in the video progress bar to white
        color: 'white',
        // use light UI theme
        theme: 'light',
      });
      return `https://www.youtube.com/embed/${this.sourceId}?${queryParams}`;
    },
    styles() {
      return {
        width: '600px',
        height: '400px',
      };
    },
    showSentiment() {
      const show = this.flagStore.ready && this.flagStore.flags.youtubeCompliance;
      return !this.isYouTube || show;
    },
    hasGdiHeadersAndFiltersFlag() {
      return this.flagStore.ready && this.flagStore.flags.gdiHeadersAndFilters;
    },
    containerComponent() {
      return this.hasGdiHeadersAndFiltersFlag ? DrawerContent : Popup;
    },
  },
  watch: {
    sourceId() {
      this.updatePropsListIndex({ sourceId: this.sourceId });
    },
    hasGdiHeadersAndFiltersFlag: {
      async handler() {
        // using new media popup as drawer
        if (this.hasGdiHeadersAndFiltersFlag) {
          // on old media popup path
          if (this.$route.name === SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA) {
            const brandLabel = this.$route.params.brandLabel;
            const channel = this.$route.params.channel;
            const id = this.$route.params.id;

            const { generateStateKey, launchDrawer } = useDrawer();
            const d = generateStateKey('publicMedia', {
              id,
              channel,
              brandLabel,
            });

            // redirect to library index with drawer query param
            await this.$router.push({
              name: SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW,
              params: {
                brandLabel,
              },
              query: {
                ...this.$route.query,
                d,
              },
            });

            // force drawer to open
            launchDrawer({ ...this.$route.query, stateKey: d });
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.updatePropsListIndex({ sourceId: this.sourceId });
    socialListeningCardClickedEvent({
      cardType: SOCIAL_LISTENING_CARD_TYPES.TOP_POST,
      pageOpenedFrom: this.$route?.name,
      cardDetails: {
        mediaOpened: this.sourceId,
        mediaType: this.mediaType,
        sourceType: this.sourceType,
        sentiment: this.sentiment,
      },
      path: this.$router.history?.pending?.path,
    });
  },
  mounted() {
    window.addEventListener('keydown', this.arrowKeySwitchHandler);
  },
  unmounted() {
    this.mediaDetailStore.clearMediaDetail();
    this.searchStore.clearMediaSourceList();
    window.removeEventListener('keydown', this.arrowKeySwitchHandler);
  },
  methods: {
    close() {
      if (this.mediaDetailStore.showMediaPopup) {
        this.mediaDetailStore.closeMediaPopup();
      } else if (this.route) {
        this.$router.push({ name: this.route });
      } else {
        this.$router.back();
      }
    },
    async previousArrowClicked() {
      this.loadedFromPreviousArrow = true;
      const previousMediaItem = this.loadPreviousPublicMedia();

      socialListeningCardClickedEvent({
        cardType: SOCIAL_LISTENING_CARD_TYPES.TOP_POST,
        pageOpenedFrom: this.$route?.name,
        cardDetails: {
          mediaOpened: previousMediaItem.sourceId,
          mediaType: previousMediaItem.mediaType,
          sourceType: previousMediaItem.source,
          sentiment: previousMediaItem?.sentiment,
        },
        path: this.$router.history?.pending?.path,
      });
    },
    async nextArrowClicked() {
      this.loadedFromNextArrow = true;
      const nextMediaItem = this.loadNextPublicMedia();

      socialListeningCardClickedEvent({
        cardType: SOCIAL_LISTENING_CARD_TYPES.TOP_POST,
        pageOpenedFrom: this.$route?.name,
        cardDetails: {
          mediaOpened: nextMediaItem.sourceId,
          mediaType: nextMediaItem.mediaType,
          sourceType: nextMediaItem.source,
          sentiment: nextMediaItem?.sentiment,
        },
        path: this.$router.history?.pending?.path,
      });
    },
    arrowKeySwitchHandler(e) {
      const key = e.which || e.keyCode;
      const LEFT_ARROW_KEYCODE = 37;
      const RIGHT_ARROW_KEYCODE = 39;
      if (
        ['TEXTAREA', 'INPUT'].indexOf(document.activeElement.tagName) > -1 ||
        window.location.href.includes('scheduler')
      ) {
        return;
      }
      if (key === LEFT_ARROW_KEYCODE && this.showPrevArrow) {
        this.previousArrowClicked();
      } else if (key === RIGHT_ARROW_KEYCODE && this.showNextArrow) {
        this.nextArrowClicked();
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.nav-arrow {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: var(--space-24);
  cursor: pointer;
}

.prev-arrow {
  position: absolute;
  left: -60px;
}

.next-arrow {
  position: absolute;
  right: -60px;
}

main {
  position: relative;
  min-height: 600px;
}

.media-main {
  display: flex;

  &.gdi-styling {
    .left-container {
      background-color: white;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .right-container {
      padding-top: 0;
      padding-right: 0;
    }
  }

  .left-container {
    width: 55%;
    border-radius: var(--round-corner) 0 0 var(--round-corner);
    text-align: center;
    background: var(--background-300);
    padding: var(--space-40);

    .info-text {
      float: left;
      font-size: var(--x12);
      margin-bottom: 30px;

      .info-icon {
        margin-right: 10px;
        margin-bottom: -3px;
      }
    }

    .similar-media {
      text-align: left;

      label {
        color: var(--text-primary);
        font-size: var(--x18);
      }
    }
  }

  .right-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: var(--space-40);

    :deep(.information-box) {
      margin-bottom: var(--space-16);
    }

    .user-info,
    .content-rights {
      width: 100%;
      background: var(--background-300);
      border-radius: var(--round-corner-small);
    }

    .user-info {
      padding: var(--space-16);
      margin: 0 auto var(--space-8);
    }

    .content-rights {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: var(--space-8) auto;
      padding: 0 var(--space-16);
    }

    .insights {
      background: var(--background-300);
      border-radius: var(--round-corner);
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: var(--space-24);
      margin: var(--space-8) auto;
      font-size: var(--x14);
      color: var(--text-primary);
    }
  }
}

.loading {
  padding: 10rem 0;
}

.small.loading {
  padding: 0 var(--space-24);
}

:deep(.chart-title) {
  padding-bottom: 0 !important;
}

:deep(.selection-display) {
  text-align: left;
}

:deep(.chart-container) {
  background: var(--background-300);
  box-shadow: none;
}

.media-item-container {
  margin-bottom: var(--space-32);
}

.media-carousel {
  .VueCarousel-navigation-button.VueCarousel-navigation-next,
  .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    top: 50%;
  }

  .VueCarousel-pagination {
    z-index: var(--z-index-sticky);
    position: absolute;
    bottom: var(--space-18);
    padding-left: var(--space-24);
    padding-right: var(--space-24);

    ul {
      height: 5rem;
    }

    button {
      margin-top: 0 !important;
    }

    button:focus {
      outline: none;
    }
  }
}

.embed-preview-media-view {
  :deep(.embedded-content-container) {
    width: 580px;
    height: 1226px;
    border-radius: var(--round-corner-large);
  }
}

.youtube-media-view {
  margin-bottom: var(--space-40);
}

:deep(#embedded-content-iframe) {
  height: 1000px;
}
</style>
