import { defineAsyncComponent } from 'vue';
import { useFlagStore } from '@/stores/flag';
import { useAuthStore } from '@/stores/auth';
import { BRAND, ORGANIZATION_USER } from '@/models/auth/permissions.enum';
import { useIdentityStore } from '@/stores/identity';

const App = () => import('@/app/settings/App.vue');
const Profile = () => import('@/app/settings/pages/Profile.vue');
const ScheduledEmails = () => import('@/app/settings/pages/ScheduledEmails.vue');
const SocialPlatform = () => import('@/app/settings/pages/SocialPlatform.vue');
const TeamAndPermissions = () => import('@/app/settings/pages/TeamAndPermissions.vue');
const EmailNotificationPreferences = () =>
  import('@/app/settings/pages/EmailNotificationPreferences.vue');
const CompetitorManagement = () => import('@/app/settings/pages/CompetitorManagement.vue');
const ContentTags = () => import('@/app/settings/pages/ContentTags.vue');
const ContentAutomation = () => import('@/app/settings/pages/ContentAutomation.vue');
const BrandManagement = () => import('@/app/settings/pages/BrandManagement.vue');
const Developer = () => import('@/app/settings/pages/Developers.vue');
const Integrations = () => import('@/app/settings/pages/Integrations.vue');
const Billing = () => import('@/app/settings/pages/Billing.vue');
const UtmManagement = () => import('@/app/settings/pages/UtmManagement.vue');
const CreatorEmailConnection = () => import('@/app/settings/pages/CreatorEmailConnection.vue');
const SocialListeningUpgradeModal = defineAsyncComponent(
  () => import('@/app/settings/components/SocialListeningUpgradeModal.vue'),
);
const CustomMetrics = () => import('@/app/settings/pages/CustomMetrics.vue');

const PAGE_LEVEL_TOP = `Settings`;
const authStore = useAuthStore();

export default {
  path: 'settings',
  component: App,
  meta: {
    requiresAuth: true,
    allowsAds: true,
    allowWhenNoConnections: true,
    hideVisionAi: true,
  },
  children: [
    {
      name: 'settings',
      path: '',
      redirect: { name: 'settings.profile' },
    },
    {
      name: 'settings.profile',
      path: 'profile',
      component: Profile,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Profile`,
      },
    },
    {
      name: 'settings.scheduledEmails',
      path: 'scheduled-emails',
      component: ScheduledEmails,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Scheduled Emails`,
      },
    },
    {
      name: 'settings.socialPlatform',
      path: 'social_platform',
      component: SocialPlatform,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Social Platform Connections`,
      },
    },
    {
      name: 'settings.team.user',
      path: 'team/:id',
      redirect: { name: 'settings.notifications.email' },
    },
    {
      name: 'settings.permissions',
      path: 'permissions',
      component: TeamAndPermissions,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Team and Permissions`,
      },
    },
    {
      name: 'settings.notifications.email',
      path: 'notifications/email',
      component: EmailNotificationPreferences,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Email Preferences`,
      },
    },
    {
      name: 'settings.competitors',
      path: 'competitors',
      component: CompetitorManagement,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Competitors`,
      },
    },
    {
      name: 'settings.contentTags',
      path: 'content-tags',
      component: ContentTags,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Content Tags`,
      },
    },
    {
      name: 'settings.contentAutomation',
      path: 'content-automation',
      component: ContentAutomation,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Content Automation`,
      },
      beforeEnter: (to, from, next) => {
        const flagStore = useFlagStore();
        const canAccess =
          authStore.checkIfBrandPermissionsExist(
            [BRAND.SETTINGS.CAN_ACCESS_CONTENT_AUTOMATION],
            [authStore.currentBrand],
          ) &&
          flagStore.ready &&
          (flagStore.flags?.contentAutomationGalleries ||
            flagStore.flags?.contentAutomationContentTags ||
            flagStore.flags?.contentAutomationCampaigns ||
            flagStore.flags?.contentAutomationBoards);
        if (!canAccess) {
          next('/not_found');
        } else {
          next();
        }
      },
    },
    {
      name: 'settings.utm',
      path: 'utm-management',
      component: UtmManagement,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - UTM Management`,
      },
    },
    {
      name: 'settings.creatorEmail',
      path: 'creator-email-connections',
      component: CreatorEmailConnection,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Creator Email Connections`,
      },
    },
    {
      name: 'settings.brands',
      path: 'brands',
      component: BrandManagement,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Brands`,
      },
    },
    {
      name: 'settings.developer',
      path: 'developer_page',
      component: Developer,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Developer`,
      },
    },
    {
      name: 'settings.integrations',
      path: 'integrations',
      component: Integrations,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Integrations`,
      },
    },
    {
      name: 'settings.billing',
      path: 'billing',
      component: Billing,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Billing & Plan`,
      },
    },
    {
      name: 'settings.socialListeningUpgrade',
      path: 'social-listening-upgrade',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Social Listening Upgrade`,
        modal: {
          component: SocialListeningUpgradeModal,
        },
      },
    },
    {
      name: 'settings.customMetrics',
      path: 'custom-metrics',
      component: CustomMetrics,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Custom Metrics`,
      },
    },
    {
      name: 'settings.customMetrics',
      path: 'custom-metrics',
      component: CustomMetrics,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Custom Metrics`,
      },
      beforeEnter: (to, from, next) => {
        const flagStore = useFlagStore();
        const identityStore = useIdentityStore();
        const canAccess =
          (authStore.checkIfBrandPermissionsExist(
            [BRAND.SETTINGS.CAN_ACCESS_CUSTOM_METRICS],
            [authStore.currentBrand],
          ) ||
            identityStore.guard(ORGANIZATION_USER.SETTINGS.CAN_MANAGE_CUSTOM_METRICS)) &&
          flagStore.ready;
        if (!canAccess) {
          next({
            name: 'settings.profile',
            params: {
              brandLabel: identityStore.currentBrand?.label,
            },
          });
        } else {
          next();
        }
      },
    },
  ],
};
