import App from '@/App.vue';
import { setupVueCompat } from '@/compat-config';
import DragMove from '@/directives/drag-move';
import DragMove2 from '@/directives/drag-move-2';
import DragSelect from '@/directives/drag-select';
import InfiniteScroll from '@/directives/infinite-scroll';
import LineClampDirective from '@/directives/line-clamp';
import SlideSelect from '@/directives/slide-select';
import { env } from '@/env';
import FloatingVuePlugin from '@/plugins/floating-vue';
import SanitizeHtmlPlugin from '@/plugins/sanitize-html';
import router from '@/router-lifecycle';
import pinia from '@/stores/pinia';
import setupDayJsPlugins from '@/utils/dayjsExtensions';
import DashingUI from '@dashhudson/dashing-ui';
import { createGtm } from '@gtm-support/vue-gtm';
import { PiniaColada } from '@pinia/colada';
import { createHead } from '@unhead/vue';
import { createApp } from 'vue';
import Vue3Lottie from 'vue3-lottie';

setupVueCompat();
setupDayJsPlugins();

const app = createApp(App);
app.use(pinia);
app.use(PiniaColada);
app.use(router);
app.use(FloatingVuePlugin);
app.use(SanitizeHtmlPlugin);
app.use(Vue3Lottie);
app.use(DashingUI);
app.directive('drag-move', DragMove);
app.directive('drag-move-2', DragMove2);
app.directive('drag-select', DragSelect);
app.directive('slide-select', SlideSelect);
app.directive('line-clamp', LineClampDirective);
app.directive('infinite-scroll', InfiniteScroll);

app.use(
  createGtm({
    id: env.gtmId,
    vueRouter: router,
    enabled: env.gtmEnabled,
  }),
);

const head = createHead();
app.use(head);

router.isReady().then(() => app.mount('#app'));
