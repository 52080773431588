<script setup>
import { computed } from 'vue';
import Insights from '@/components/common/MediaPopupV2/Insights.vue';
import { formatNumber } from '@/utils/formatters';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { toolTips } from '@/config';

const props = defineProps({
  mediaItem: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const source = CHANNELS.THREADS.value;
const metricFormats = {
  NUMBER: 'number',
  PERCENT: 'percent',
};
const metricConfig = {
  views: {
    label: 'Views',
    tooltip: toolTips.threadsInsights.views,
    field: 'views',
    format: metricFormats.NUMBER,
    isIndented: false,
  },
  likes: {
    label: 'Likes',
    tooltip: toolTips.threadsInsights.likes,
    field: 'likes',
    format: metricFormats.NUMBER,
    isIndented: false,
  },
  replies: {
    label: 'Replies',
    tooltip: toolTips.threadsInsights.replies,
    field: 'replies',
    format: metricFormats.NUMBER,
    isIndented: false,
  },
  repostsAndQuotes: {
    label: 'Total Reposts',
    tooltip: toolTips.threadsInsights.repostsAndQuotes,
    field: 'repostsAndQuotes',
    format: metricFormats.NUMBER,
    isIndented: false,
  },
  reposts: {
    label: 'Reposts',
    tooltip: toolTips.threadsInsights.reposts,
    field: 'reposts',
    format: metricFormats.NUMBER,
    isIndented: true,
  },
  quotes: {
    label: 'Quotes',
    tooltip: toolTips.threadsInsights.quotes,
    field: 'quotes',
    format: metricFormats.NUMBER,
    isIndented: true,
  },
  engagements: {
    label: 'Engagements',
    tooltip: toolTips.threadsInsights.engagements,
    field: 'engagements',
    format: metricFormats.NUMBER,
    isIndented: false,
  },
  engagementRate: {
    label: 'Engagement Rate',
    tooltip: toolTips.threadsInsights.engagementRate,
    field: 'engagementRate',
    format: metricFormats.PERCENT,
    isIndented: false,
  },
};

const insights = computed(() => {
  return Object.values(metricConfig).reduce((acc, fieldData) => {
    if (fieldData?.requiredMediaType && props.mediaItem.mediaType !== fieldData.requiredMediaType) {
      return acc;
    }
    const rawValue = props.mediaItem.insights[fieldData.field];
    fieldData.value = formatNumber(rawValue, fieldData.format === metricFormats.PERCENT);
    acc.push({
      value: fieldData.value,
      label: fieldData.label,
      tooltip: fieldData.tooltip,
      isIndented: fieldData.isIndented,
    });
    return acc;
  }, []);
});

defineExpose({ insights });

defineOptions({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
});
</script>

<template>
  <Insights :insights="insights" :platform-icon="source" />
</template>
