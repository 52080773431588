<template>
  <section class="all-insights-container">
    <HighlightedInsights v-if="!isUgc" :insights="highlightedInsights" />
    <section class="main-insights-container">
      <InsightsDropdown
        :has-insights-access="accessTikTokInsights"
        header-title="TikTok Insights"
        platform-icon="tiktok"
        :header-tooltip="headerTooltip"
        @toggle-insights="sendToggleEvent"
      >
        <template #dropdownContents>
          <div class="insights-list">
            <div v-if="!insightsExist" class="alert-message">
              <p>TikTok source data is currently unavailable for this video.</p>
            </div>
            <div v-else>
              <ul class="insights-stats">
                <template v-for="(value, key) in filteredMediaItem.insights" :key="key">
                  <li :class="{ disabled: !accessTikTokInsights }">
                    <span class="stat-name" :class="{ indented: isIndented(key) }">
                      {{ formatKey(key) }}
                      <InfoTooltip v-if="metricsTooltip(key)" :tooltip="metricsTooltip(key)" />
                    </span>
                    <span class="stat">{{ formatValue(key, value) }}</span>
                  </li>
                </template>
              </ul>
              <div :class="['disable-message', { hide: accessTikTokInsights }]">
                <Icon name="lock-1" height="40" width="40" />
                <p>
                  Looks like this feature isn't included in your plan.
                  <a @click="contactUsClicked">Message us</a>
                  on chat or send us an email to try it out!
                </p>
              </div>
            </div>
          </div>
          <CustomMetricsInsights v-if="showCustomMetricsInsights" :media-item="mediaItem" />
        </template>
      </InsightsDropdown>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import { useAuthStore } from '@/stores/auth';
import { toolTips } from '@/config';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import CustomMetricsInsights from '@/app/library/components/MediaPopup/CustomMetricsInsights.vue';
import { formatDynamicDuration } from '@/utils/formatters';
import HighlightedInsights from '@/app/library/components/MediaPopup/HighlightedInsights.vue';
import { useFlagStore } from '@/stores/flag';
import { useSupportChatStore } from '@/stores/support-chat';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import { Formatters } from '@dashhudson/dashing-ui';
import InsightsDropdown from './InsightsDropdown.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TikTokInsights',
  components: { HighlightedInsights, Icon, InfoTooltip, InsightsDropdown, CustomMetricsInsights },
  props: { mediaItem: { type: Object, default: null } },
  emits: ['toggleInsights'],
  setup() {
    const { canAccessCustomMetrics } = useCustomMetrics();
    return {
      canAccessCustomMetrics,
    };
  },
  data() {
    return {
      tooltips: toolTips.tiktokInsights,
      trafficSourceRates: [
        'forYouPageViews',
        'followingPageViews',
        'hashtagViews',
        'soundViews',
        'profileViews',
        'searchViews',
        'entertainmentScore',
      ],
    };
  },
  computed: {
    ...mapStores(useFlagStore, useSupportChatStore),
    ...mapPiniaState(useAuthStore, ['user_can']),
    accessTikTokInsights() {
      return this.user_can('tiktok', 'can_access_tiktok_insights');
    },
    insightsExist() {
      return Object.keys(this.mediaItem?.insights || {}).length > 0;
    },
    likeshopClicks() {
      const { tiktokLikeshopClicks } = this.mediaItem;
      if (Number.isInteger(tiktokLikeshopClicks)) {
        return tiktokLikeshopClicks;
      }
      return '-';
    },
    insightsToInclude() {
      if (this.isUgc) {
        return ['totalEngagements', 'likes', 'comments', 'shares', 'reach', 'videoViews', 'emv'];
      }
      return null;
    },
    filteredMediaItem() {
      const filteredMediaItem = cloneDeep(this.mediaItem);
      const insightsToDelete = [...this.trafficSourceRates];
      insightsToDelete.forEach((insight) => {
        delete filteredMediaItem?.insights?.[insight];
      });
      if (filteredMediaItem?.insights) {
        filteredMediaItem.insights.likeshopClicks = this.likeshopClicks;
      }
      if (this.insightsToInclude) {
        Object.keys(filteredMediaItem?.insights).forEach((key) => {
          if (!this.insightsToInclude.includes(key)) {
            delete filteredMediaItem?.insights?.[key];
          }
        });
      }
      return filteredMediaItem;
    },
    highlightedInsights() {
      const value = this.mediaItem?.insights?.entertainmentScore;
      const formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      let formattedValue;
      if (value == null) {
        formattedValue = '-';
      } else {
        formattedValue = value.toLocaleString('en', formatOptions);
      }
      return [
        {
          name: 'Entertainment Score',
          value: formattedValue,
          iconName: 'leisurePartyPopper',
          tooltip: toolTips.tiktokInsights.entertainmentScore,
        },
      ];
    },
    showCustomMetricsInsights() {
      return this.canAccessCustomMetrics && this.isOwned && this.insightsExist;
    },
    isOwned() {
      return this.mediaItem?.sourceType.startsWith('TIKTOK_OWNED');
    },
    isUgc() {
      return this.mediaItem?.sourceType === 'TIKTOK_UGC';
    },
    headerTooltip() {
      return this.flagStore.flags.tiktokUgc && this.isUgc
        ? 'TikTok UGC metrics may take up to 48 hours to update'
        : null;
    },
  },
  methods: {
    contactUsClicked() {
      this.supportChatStore.show();
    },
    formatKey(key) {
      if (key === 'likeshopClicks') {
        return 'LikeShop Clicks';
      }
      if (key === 'avgTimeWatched') {
        return 'Avg. Time Watched';
      }
      if (key === 'totalEngagements' && this.isUgc) {
        return 'Engagements';
      }
      if (this.trafficSourceRates.includes(key)) {
        return `${startCase(key)} %`;
      }
      if (key === 'emv') {
        return 'EMV';
      }
      return startCase(key);
    },
    formatValue(key, value) {
      if (value === null || value === undefined) {
        return '-';
      }
      if (
        [
          'effectiveness',
          'retentionRate',
          'completionRate',
          'engagementRate',
          ...this.trafficSourceRates,
        ].includes(key)
      ) {
        return value.toLocaleString('en', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      if (['totalTimeWatched', 'videoDuration', 'avgTimeWatched'].includes(key)) {
        return formatDynamicDuration(value);
      }
      // TODO: Remove this if statement when removing reels entertainment score feature flag
      if (key === 'entertainmentScore') {
        return value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      if (key === 'emv') {
        return Formatters.formatCurrency(value, { maximumFractionDigits: 0 });
      }
      return value.toLocaleString();
    },
    isIndented(key) {
      return ['likes', 'comments', 'shares'].includes(key);
    },
    metricsTooltip(key) {
      const tiktokTooltips = this.tooltips;

      if (this.isUgc && key === 'totalEngagements') {
        return tiktokTooltips.ugcTotalEngagements;
      }

      return tiktokTooltips[key];
    },
    sendToggleEvent(isOpen) {
      const actionName = isOpen ? 'Show' : 'Hide';
      this.$emit('toggleInsights', 'Insights', `${actionName} insights`);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.all-insights-container {
  width: 100%;

  .main-insights-container {
    background: var(--background-300);
    border-radius: var(--round-corner);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-24);
    margin: var(--space-8) auto;
    font-size: var(--x14);
    color: var(--text-primary);
  }
}

.insights-list {
  .stat-interval {
    display: flex;
    align-items: center;
    margin: var(--space-16) 0 0 var(--space-32);
    padding-left: var(--space-4);

    .select-control {
      border: 1px solid var(--border);
      border-radius: var(--button-border-radius);
      height: var(--space-32);
      background: var(--background-0);
      align-items: center;
      margin: 0 0 0 auto;

      :deep(.picker) {
        padding-bottom: var(--space-12);
        background-position-y: 0.3rem;
        font-size: var(--x12);
      }
    }

    p {
      color: var(--text-secondary);
      font-size: var(--x12);
    }
  }

  .warning-message {
    color: var(--error-500);
  }

  .stat-name {
    margin-right: var(--space-12);

    &.indented {
      padding-left: var(--space-30);
    }
  }
}
</style>
