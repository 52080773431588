// Vue
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
// APIs
import * as AuthAPI from '@/apis/auth';
// Stores
import { useAuthStore } from '@/stores/auth';
import { useIdentityStore } from '@/stores/identity';
import { useNotificationStore } from '@/stores/notification';
// Constants
import { SOCIAL_LISTENING_CUSTOM_TIER, SOCIAL_LISTENING_TIERS } from '@/models/auth/addons.enum';
import { CUSTOMER_STAGE, isSameCustomerStage } from '@/models/auth/customer-stage.enum';
import {
  CUSTOMER_PLAN,
  isAdvancePlan,
  isCustomerPlanLegacy,
  isEnterprisePlan,
  isPremiumPlan,
  isSameCustomerPlan,
  isPlusPlan,
  isProPlan,
  isStarterPlan,
  isCustomPlan,
  PRODUCT_MAP,
  START_TRIAL,
  removeAddOnFromPlan,
  BRAND_PRODUCT_LABEL_MAP,
} from '@/models/auth/customer-plan.enum';
import { isSameProspectJourney, PROSPECT_JOURNEY } from '@/models/auth/prospect-journey.enum';
import { ORGANIZATION, ORGANIZATION_USER } from '@/models/auth/permissions.enum';
import { SUBSCRIPTION_LABELS_MAP } from '@/app/settings/constants';
import dayjs from 'dayjs';

export const useCustomerJourneyStore = defineStore('customerJourney', () => {
  const authStore = useAuthStore();
  const identityStore = useIdentityStore();
  const notificationStore = useNotificationStore();

  const pending = ref({
    purchaseSubscription: false,
  });

  /*
   *   Popup system
   * */

  const popups = ref({
    startTrial: {
      product: null,
      show: false,
    },
    successTrial: {
      product: null,
      show: false,
    },
    featureAccess: {
      product: null,
      show: false,
    },
  });

  /**
   * @param {string} popupName
   * @param {boolean} show
   * @param {string|null} product
   */
  function togglePopup(popupName, show, product = null) {
    if (!popups.value[popupName]) {
      return;
    }
    popups.value[popupName].product = product;
    popups.value[popupName].show = show;
  }

  /*
   *   Current Brand Details
   * */
  const currentBrand = computed(() => authStore.currentBrand);
  const currentBrandPlan = computed(() => currentBrand.value?.plan);
  const currentBrandPlanType = computed(() => currentBrandPlan.value?.plan_type);
  const customerStage = computed(() => currentBrandPlan.value?.customer_stage);
  const prospectJourney = computed(() => currentBrandPlan.value?.prospect_journey);

  /*
   *   Organization Details
   * */
  const organizationPermissions = computed(
    () =>
      authStore.identity?.organization_permissions?.[currentBrand.value.organizationId]
        ?.social_listening,
  );

  /*
   *   Customer Stages
   * */
  const isStageOutreach = computed(() =>
    isSameCustomerStage(CUSTOMER_STAGE.OUTREACH, customerStage),
  );
  const isStageTrial = computed(
    () =>
      isSameCustomerStage(CUSTOMER_STAGE.TRIAL, customerStage) ||
      isSameCustomerStage(CUSTOMER_STAGE.SELF_SERVE_TRIAL, customerStage),
  );
  const isStageCustomer = computed(() =>
    isSameCustomerStage(CUSTOMER_STAGE.CUSTOMER, customerStage),
  );
  const isStageChurn = computed(() => isSameCustomerStage(CUSTOMER_STAGE.CHURN, customerStage));

  /*
   *   Prospect Journey
   * */
  const isProductLed = computed(() =>
    isSameProspectJourney(PROSPECT_JOURNEY.PRODUCT_LED, prospectJourney),
  );

  /*
   *   Brand Plans
   * */
  const hasPlanGrow = computed(() => isSameCustomerPlan(CUSTOMER_PLAN.GROW, currentBrandPlanType));
  const hasPlanEngage = computed(() =>
    isSameCustomerPlan(CUSTOMER_PLAN.ENGAGE, currentBrandPlanType),
  );
  const hasPlanOrSubscriptionAdvance = computed(
    () =>
      isAdvancePlan(currentBrandPlanType) ||
      identityStore.currentSubscriptions.some(
        (subscription) =>
          isAdvancePlan(subscription.dhProduct.label) &&
          (subscription.trialEndDate == null || new Date(subscription.trialEndDate) > new Date()),
      ),
  );

  const hasPlanOrSubscriptionEnterprise = computed(
    () =>
      isEnterprisePlan(currentBrandPlanType) ||
      identityStore.currentSubscriptions.some(
        (subscription) =>
          isEnterprisePlan(subscription.dhProduct.label) &&
          (subscription.trialEndDate == null || new Date(subscription.trialEndDate) > new Date()),
      ),
  );

  const hasSubscriptionPro = computed(() =>
    identityStore.currentSubscriptions.some(
      (subscription) =>
        isProPlan(subscription.dhProduct.label) &&
        (subscription.trialEndDate === null || new Date(subscription.trialEndDate) > new Date()),
    ),
  );

  const hasSubscriptionPlus = computed(() =>
    identityStore.currentSubscriptions.some(
      (subscription) =>
        isPlusPlan(subscription.dhProduct.label) &&
        (subscription.trialEndDate === null || new Date(subscription.trialEndDate) > new Date()),
    ),
  );

  const hasSubscriptionStarter = computed(() =>
    identityStore.currentSubscriptions.some(
      (subscription) =>
        isStarterPlan(subscription.dhProduct.label) &&
        (subscription.trialEndDate === null || new Date(subscription.trialEndDate) > new Date()),
    ),
  );

  const hasSubscriptionCustom = computed(() =>
    identityStore.currentSubscriptions.some(
      (subscription) =>
        isCustomPlan(subscription.dhProduct.label) &&
        (subscription.trialEndDate === null || new Date(subscription.trialEndDate) > new Date()),
    ),
  );

  const isTrialCreatorManagement = computed(() =>
    identityStore.currentSubscriptions.some((subscription) => {
      const { dhProduct } = subscription;
      const isTrial =
        subscription.isTrial &&
        subscription.trialEndDate !== null &&
        new Date(subscription.trialEndDate) > new Date();
      return (
        (isStarterPlan(dhProduct.label) ||
          isPlusPlan(dhProduct.label) ||
          isCustomPlan(dhProduct.label) ||
          isProPlan(dhProduct.label)) &&
        isTrial
      );
    }),
  );

  const hasPlanLegacy = computed(() => isCustomerPlanLegacy(currentBrandPlanType));

  /*
   *   Add-Ons
   * */
  const hasAddOnPremium = computed(() => isPremiumPlan(currentBrandPlanType));
  const hasAddOnSocialListening = computed(() => {
    return (
      organizationPermissions.value?.can_access_social_listening === true &&
      authStore.guard(ORGANIZATION.LISTENING.CAN_ACCESS_SOCIAL_LISTENING) &&
      authStore.guard(ORGANIZATION_USER.LISTENING.CAN_ACCESS_SOCIAL_LISTENING)
    );
  });

  const addOnCreatorManagementTrial = computed(() => {
    return identityStore.currentBrandSubscriptions?.find(
      (subscription) =>
        [
          BRAND_PRODUCT_LABEL_MAP.CREATOR_STARTER,
          BRAND_PRODUCT_LABEL_MAP.CREATOR_PLUS,
          BRAND_PRODUCT_LABEL_MAP.CREATOR_PRO,
          BRAND_PRODUCT_LABEL_MAP.CREATOR_CUSTOM,
        ].includes(subscription.dhProduct.label) && subscription.isTrial,
    );
  });

  const addOnCreatorSubscription = computed(() => {
    return identityStore.currentBrandSubscriptions?.find(
      (subscription) =>
        [
          BRAND_PRODUCT_LABEL_MAP.CREATOR_STARTER,
          BRAND_PRODUCT_LABEL_MAP.CREATOR_PLUS,
          BRAND_PRODUCT_LABEL_MAP.CREATOR_PRO,
          BRAND_PRODUCT_LABEL_MAP.CREATOR_CUSTOM,
        ].includes(subscription.dhProduct.label) &&
        (!subscription.isTrial ||
          !subscription.trialEndDate ||
          dayjs(subscription?.trialEndDate) > dayjs()),
    );
  });
  /*
   *   PLG Helpers
   * */
  const isGrowTrial = computed(() => hasPlanGrow.value && isStageTrial.value);
  const isProductLedGrow = computed(() => isProductLed.value && hasPlanGrow.value);
  const isProductLedGrowTrial = computed(() => isProductLedGrow.value && isStageTrial.value);
  const hasNoTrialStartDate = computed(() => currentBrandPlan.value?.trial_start_date === null);
  const isEligibleForPremiumAnalytics = computed(
    () => hasPlanOrSubscriptionAdvance.value || hasPlanOrSubscriptionEnterprise.value,
  );
  const isEligibleForSocialListening = computed(
    () => isAdvancePlan(currentBrandPlanType) || isEnterprisePlan(currentBrandPlanType),
  );
  /*
   *   Plan Trials
   * */
  const basePlanTrial = computed(() => {
    const basePlan = identityStore.currentBrandSubscriptions?.filter(
      (subscription) =>
        subscription.isTrial &&
        subscription.dhProduct.category === 'plan' &&
        subscription.dhProduct.label === removeAddOnFromPlan(currentBrandPlanType.value),
    );
    return basePlan?.[0];
  });
  const additionalPlanTrial = computed(() => {
    const planTrials = identityStore.currentBrandSubscriptions?.filter(
      (subscription) =>
        subscription.isTrial &&
        subscription.dhProduct.category === 'plan' &&
        subscription.dhProduct.label !== currentBrandPlanType.value &&
        subscription.dhProduct.label !== removeAddOnFromPlan(currentBrandPlanType.value),
    );
    return planTrials?.[0];
  });
  const additionalPlanTrialActive = computed(() => {
    return (
      additionalPlanTrial.value &&
      (!additionalPlanTrial.value?.trialEndDate ||
        dayjs(additionalPlanTrial.value?.trialEndDate) > dayjs())
    );
  });

  /*
   *   Add-on Trials
   * */
  const additionalPremiumAnalyticsTrial = computed(() => {
    if (hasAddOnPremium.value) {
      return null;
    }
    const brandAddOnPATrials = identityStore.currentBrandSubscriptions?.filter(
      (subscription) =>
        subscription.isTrial &&
        subscription.dhProduct.label === BRAND_PRODUCT_LABEL_MAP.PREMIUM_ANALYTICS,
    );
    return brandAddOnPATrials?.[0];
  });
  const additionalPremiumAnalyticsTrialActive = computed(() => {
    return (
      additionalPremiumAnalyticsTrial.value &&
      (!additionalPremiumAnalyticsTrial.value?.trialEndDate ||
        dayjs(additionalPremiumAnalyticsTrial.value?.trialEndDate) > dayjs())
    );
  });
  const socialListeningTrial = computed(() => {
    const organizationAddOnTrials = identityStore.currentOrganizationSubscriptions?.filter(
      (subscription) =>
        subscription.isTrial &&
        subscription.dhProduct.label === 'social_listening_tier_trial' &&
        subscription.trialEndDate,
    );
    return organizationAddOnTrials?.[0];
  });
  /*
   *   Actions
   * */
  async function setTrialDates() {
    try {
      await AuthAPI.setBrandTrialDates({
        brandId: currentBrand.value?.id,
        dhProductSubscriptionId: basePlanTrial.value?.id,
      });
    } catch (error) {
      // catch the error to avoid breakage
    }
  }

  function getSocialListeningTier() {
    if (!hasAddOnSocialListening.value) {
      return null;
    }

    const { social_listening_topic_limit: topics, social_listening_mention_limit: mentions } =
      organizationPermissions.value;

    if (!topics || !mentions) {
      return null;
    }

    const tier = Object.values(SOCIAL_LISTENING_TIERS).find(
      (value) => value.data.topics === topics && value.data.mentions === mentions,
    );
    return tier || { ...SOCIAL_LISTENING_CUSTOM_TIER, data: { topics, mentions } };
  }

  async function purchaseSubscription(priceLookupKey) {
    try {
      pending.value.purchaseSubscription = true;
      const res = await AuthAPI.createBillingCheckoutSession({
        brandId: currentBrand.value.id,
        priceLookupKey,
        redirectDomain: window.location.href,
      });
      window.location.href = res.data.url;
    } catch (err) {
      pending.value.purchaseSubscription = false;
      notificationStore.setToast({
        message:
          'An error occurred while trying to complete your subscription purchase. Please contact us for assistance.',
        type: 'error',
      });
    }
  }

  function getSocialListeningTierDetails() {
    const socialListeningTier = getSocialListeningTier();

    let insightDetail;
    let price;

    if (!socialListeningTier) {
      insightDetail = '20 Topics and 50,000 Mentions';
      price = '999';
    } else if (socialListeningTier?.value === 'trial') {
      insightDetail = '20 Topics and 50,000 Mentions';
      price = '999';
    } else if (socialListeningTier.value === 'custom') {
      insightDetail = '50+ Topics and 1,000,000 Mentions';
      price = 'Custom Pricing';
    } else {
      insightDetail = `${
        socialListeningTier.data.topics
      } Topics and ${socialListeningTier.data.mentions.toLocaleString()} mentions`;
      price = String(socialListeningTier.price);
    }
    return {
      insightDetail,
      price,
    };
  }

  function getNextTier() {
    const socialListeningTier = getSocialListeningTier();

    switch (socialListeningTier?.value) {
      case 'tier_one':
        return 'tier_three';
      case 'tier_two':
        return 'tier_three';
      case 'tier_three':
        return 'custom';
      case 'custom':
        return 'custom';
      default:
        return 'tier_three';
    }
  }

  function getPlanStatus() {
    if (authStore.canOnlyAccessInactiveBrand) {
      return SUBSCRIPTION_LABELS_MAP.inactive.value;
    }
    if (isStageTrial.value) {
      return SUBSCRIPTION_LABELS_MAP.trial.value;
    }
    return SUBSCRIPTION_LABELS_MAP.active.value;
  }

  async function createTrialSubscription(dhProduct) {
    const payload =
      dhProduct === PRODUCT_MAP.SOCIAL_LISTENING
        ? {
            organizationId: currentBrand.value?.organization.id,
            dhProductLabel: START_TRIAL[dhProduct].product_label,
          }
        : {
            brandId: currentBrand.value?.id,
            dhProductLabel: START_TRIAL[dhProduct].product_label,
          };
    await AuthAPI.createTrialSubscription(payload);
  }

  return {
    // State
    pending,
    popups,

    // Getters
    currentBrandPlanType,
    customerStage,
    isStageOutreach,
    isStageTrial,
    isStageCustomer,
    isStageChurn,
    isProductLed,
    hasPlanGrow,
    hasPlanEngage,
    hasPlanOrSubscriptionAdvance,
    hasPlanOrSubscriptionEnterprise,
    hasSubscriptionPro,
    hasSubscriptionPlus,
    hasSubscriptionStarter,
    hasSubscriptionCustom,
    hasPlanLegacy,
    hasAddOnPremium,
    hasAddOnSocialListening,
    isGrowTrial,
    isProductLedGrow,
    isProductLedGrowTrial,
    isEligibleForSocialListening,
    isEligibleForPremiumAnalytics,
    isTrialCreatorManagement,
    hasNoTrialStartDate,
    basePlanTrial,
    additionalPlanTrial,
    additionalPremiumAnalyticsTrial,
    additionalPremiumAnalyticsTrialActive,
    socialListeningTrial,
    additionalPlanTrialActive,
    addOnCreatorSubscription,
    addOnCreatorManagementTrial,

    // Actions
    setTrialDates,
    getSocialListeningTier,
    getSocialListeningTierDetails,
    getNextTier,
    purchaseSubscription,
    getPlanStatus,
    createTrialSubscription,
    togglePopup,
  };
});
