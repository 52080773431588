/**
 * Check if a string contains emojis
 * Source: https://dev.to/melvin2016/how-to-check-if-a-string-contains-emojis-in-javascript-31pe
 * @param {string} text The text to check for emojis
 * @return {boolean} Whether the string contains emojis
 */
export const containsEmojis = (text) =>
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
    text,
  );

// Regex to identify any hashtags with #xx format, then removing any duplicate hashtags
// Example: "some text#one#two #three some text" will be counted as 3 hashtags.
export const extractHashtags = (text) => {
  const regex = /#([a-zA-Z\d_]+)/g;

  const matches = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text))) {
    matches.push(match[1]);
  }
  return [...new Set(matches)];
};
