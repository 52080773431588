<template>
  <section
    class="mx-auto my-2 flex w-full flex-col rounded-[--rounded-corner] bg-[--background-300] p-6 text-[length:--x14] text-[color:--text-primary]"
  >
    <InsightsDropdown
      :has-insights-access="true"
      header-title="X Insights"
      platform-icon="twitter"
      :has-paid-metrics="hasTwitterPaidMetrics"
    >
      <template #dropdownContents>
        <div class="insights-list">
          <div v-if="!insightsExist" class="alert-message">
            <p>X source data is currently unavailable for this post.</p>
          </div>

          <div v-else>
            <div v-if="hasTwitterPaidMetrics">
              <PromotedInsightsDropdown
                v-for="(insight, key) in promotedTwitterInsights"
                :key="key"
                :insight="insight"
              ></PromotedInsightsDropdown>
            </div>
            <ul v-else class="insights-stats">
              <li v-for="(value, key) in twitterInsights" :key="key">
                <span class="stat-name" :class="{ indented: isIndented(key) }">
                  {{ formatKey(key) }}
                  <InfoTooltip v-if="metricsTooltip(key)" :tooltip="metricsTooltip(key)" />
                </span>
                <span class="stat">{{ formatValue(key, value) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <CustomMetricsInsights v-if="showCustomMetricsInsights" :media-item="mediaItem" />
      </template>
    </InsightsDropdown>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import startCase from 'lodash/startCase';
import { toolTips } from '@/config';
import { getMediaTypeFromDetail } from '@/utils/media';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { usePlatformStore } from '@/stores/platform';
import { useCustomMetrics } from '@/app/settings/composables/customMetrics';
import CustomMetricsInsights from '@/app/library/components/MediaPopup/CustomMetricsInsights.vue';
import InsightsDropdown from './InsightsDropdown.vue';
import PromotedInsightsDropdown from './PromotedInsightsDropdown.vue';

const OVERRIDE_LABELS = {
  totalRetweets: 'Total Reposts',
  retweets: 'Reposts',
  quoteTweets: 'Quote Posts',
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TwitterInsights',
  components: { PromotedInsightsDropdown, InfoTooltip, InsightsDropdown, CustomMetricsInsights },
  props: { mediaItem: { type: Object, default: null } },
  setup() {
    const { canAccessCustomMetrics } = useCustomMetrics();
    return {
      canAccessCustomMetrics,
    };
  },
  computed: {
    ...mapStores(usePlatformStore),
    showCustomMetricsInsights() {
      return this.canAccessCustomMetrics && this.isOwned && this.insightsExist;
    },
    isVideo() {
      return getMediaTypeFromDetail(this.mediaItem) === 'video';
    },
    isOwned() {
      return this.mediaItem.sourceType.startsWith('TWITTER_OWNED');
    },
    insightsExist() {
      return Object.keys(this.mediaItem?.insights || {}).length > 0;
    },
    promotedTwitterInsights() {
      const { insights } = this.mediaItem;
      let formattedPromotedInsights = {
        totalEngagements: {
          subMetrics: {
            organicTotalEngagements: { format: 'number', value: insights.engagementsOrganic },
            promotedTotalEngagements: { format: 'number', value: insights.engagementsPromoted },
          },
          format: 'number',
          value: insights.engagementsTotal,
        },
        engagementRate: {
          subMetrics: {
            organicEngagementRate: { format: 'percent', value: insights.engagementRateOrganic },
            promotedEngagementRate: {
              format: 'percent',
              value: insights.engagementRatePromoted,
            },
          },
          format: 'percent',
          value: insights.engagementRateTotal,
        },
        replies: {
          subMetrics: {
            organicReplies: { format: 'number', value: insights.repliesOrganic },
            promotedReplies: { format: 'number', value: insights.repliesPromoted },
          },
          format: 'number',
          value: insights.repliesTotal,
        },
        totalRetweets: {
          subMetrics: {
            organicRetweets: { format: 'number', value: insights.retweetsOrganic },
            promotedRetweets: { format: 'number', value: insights.retweetsPromoted },
            quoteTweets: { format: 'number', value: insights.quoteTweets },
          },
          value: insights.totalRetweets,
        },
        likes: {
          subMetrics: {
            organicLikes: { format: 'number', value: insights.likesOrganic },
            promotedLikes: { format: 'number', value: insights.likesPromoted },
          },
          value: insights.likesTotal,
        },
        impressions: {
          subMetrics: {
            organicImpressions: { format: 'number', value: insights.impressionsOrganic },
            promotedImpressions: { format: 'number', value: insights.impressionsPromoted },
          },
          value: insights.impressionsTotal,
        },
        videoViews: {
          subMetrics: {
            organicVideoViews: { format: 'number', value: insights.videoViewsOrganic },
            promotedVideoViews: { format: 'number', value: insights.videoViewsPromoted },
          },
          value: insights.videoViewsTotal,
        },
        linkClicks: {
          subMetrics: {
            organicLinkClicks: { format: 'number', value: insights.urlClicksOrganic },
            promotedLinkClicks: { format: 'number', value: insights.urlClicksPromoted },
          },
          value: insights.urlClicksTotal,
        },
        profileClicks: {
          subMetrics: {
            organicProfileClicks: { format: 'number', value: insights.userProfileClicksOrganic },
            promotedProfileClicks: {
              format: 'number',
              value: insights.userProfileClicksPromoted,
            },
          },
          format: 'number',
          value: insights.userProfileClicksTotal,
        },
        userFollows: { format: 'number', value: insights.followsTotal },
      };
      if (!this.isVideo) {
        delete formattedPromotedInsights.videoViews;
      }

      Object.keys(formattedPromotedInsights).forEach((key) => {
        formattedPromotedInsights[key].label = this.formatKey(key);
        formattedPromotedInsights[key].tooltip = this.metricsTooltip(key);
        if (formattedPromotedInsights[key].subMetrics) {
          Object.keys(formattedPromotedInsights[key].subMetrics).forEach((subMetricKey) => {
            formattedPromotedInsights[key].subMetrics[subMetricKey].label =
              this.formatKey(subMetricKey);
            formattedPromotedInsights[key].subMetrics[subMetricKey].tooltip =
              this.metricsTooltip(subMetricKey);
          });
          formattedPromotedInsights[key].subMetrics = Object.values(
            formattedPromotedInsights[key].subMetrics,
          );
        }
      });
      formattedPromotedInsights = Object.values(formattedPromotedInsights);
      return formattedPromotedInsights;
    },
    twitterInsights() {
      const { insights } = this.mediaItem;
      const formattedInsights = {};
      if (this.isOwned) {
        formattedInsights.publicEngagements =
          insights.replies + insights.likes + insights.totalRetweets;
        formattedInsights.totalEngagements = insights.engagements;
        formattedInsights.engagementRate = insights.engagementRate;
        formattedInsights.replies = insights.replies;
        formattedInsights.totalRetweets = insights.totalRetweets;
        formattedInsights.retweets = insights.retweets;
        formattedInsights.quoteTweets = insights.quoteTweets;
        formattedInsights.likes = insights.likes;
        formattedInsights.impressions = insights.impressions;
        formattedInsights.linkClicks = insights.urlClicks;
        formattedInsights.profileClicks = insights.userProfileClicks;
        formattedInsights.userFollows = insights.follows;
      } else {
        formattedInsights.publicEngagements = insights.totalPublicEngagements;
        formattedInsights.replies = insights.replies;
        formattedInsights.totalRetweets = insights.totalRetweets;
        formattedInsights.retweets = insights.retweets;
        formattedInsights.quoteTweets = insights.quoteTweets;
        formattedInsights.likes = insights.likes;
      }
      if (this.isVideo) {
        if (this.isOwned) {
          formattedInsights.videoViews = insights.videoViews;
        } else {
          formattedInsights.videoViews = insights.videoViewsTotal;
        }
      }
      return formattedInsights;
    },
    hasTwitterPaidMetrics() {
      return this.mediaItem.isPromoted;
    },
    tooltips() {
      if (this.isOwned) {
        return toolTips.twitterInsights;
      }
      return toolTips.twitterCompetitiveInsights;
    },
  },
  methods: {
    isIndented(key) {
      return ['retweets', 'quoteTweets'].includes(key);
    },
    formatKey(key) {
      return OVERRIDE_LABELS[key] ?? startCase(key);
    },
    formatValue(key, value) {
      if (!value) {
        return '0';
      }
      if (key === 'engagementRate') {
        return value.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 });
      }
      return value.toLocaleString();
    },
    metricsTooltip(key) {
      return this.tooltips[key];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.indented {
  padding-left: var(--space-30);
}
</style>
