<template>
  <div class="message-chips-container flex flex-wrap">
    <template v-for="(stat, index) in stats" :key="index">
      <SentimentChip
        v-if="isSentimentChip(stat)"
        :interaction="interaction"
        :stat="stat"
        :light="light"
        class="my-0.5 mr-1"
      />
      <Chip
        v-else-if="stat.iconOnly"
        :prepend-img="stat.prependIcon"
        :tooltip="stat.tooltip"
        xxsmall
        class="message-chip my-0.5 mr-1"
      />
      <Chip
        v-else
        :prepend-icon="stat.iconName"
        :prepend-img="stat.prependImg"
        :append-icon="stat.appendIcon"
        :prepend-icon-color="iconColor(stat)"
        :tooltip="stat.tooltip"
        xxsmall
        :light="light"
        class="message-chip my-0.5 mr-1"
      >
        {{ stat.value }}{{ stat.suffix }} {{ stat.title }}
      </Chip>
    </template>
    <TicketChips :tickets="tickets" :light="light" :interaction="interaction" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import Chip from '@/components/foundation/Chip.vue';
import { colours } from '@/ux/colours';
import { useFlagStore } from '@/stores/flag';
import TicketChips from '@/app/community/components/ticket/TicketChips.vue';
import SentimentChip from '@/app/community/components/SentimentChip.vue';
import EnabledCommunityInteractionTypes from '@/app/community/mixins/enabledCommunityInteractionTypes.vue';
import { SENTIMENT_LABEL_BY_TYPE } from '@/app/community/constants';

const comp = defineComponent({
  name: 'MessageChips',
  components: {
    Chip,
    TicketChips,
    SentimentChip,
  },
  mixins: [EnabledCommunityInteractionTypes],
  props: {
    stats: {
      type: Array,
      default: () => [],
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    interaction: {
      type: Object,
      default: null,
    },
    light: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useFlagStore),
  },
  methods: {
    iconColor(stat) {
      return stat?.iconColor ?? colours.ICON.ICON_SECONDARY;
    },
    isSentimentChip(stat) {
      // looking for pure text sentiment chip that supports sentiment update,
      // not include aggregated sentiment chip, i.e. 10% Positive
      const sentimentTitles = Object.values(SENTIMENT_LABEL_BY_TYPE).map((i) => i.title);
      return sentimentTitles.indexOf(stat?.title) > -1 && !stat?.suffix;
    },
  },
});
export default comp;
</script>
