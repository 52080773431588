<script setup>
import { watch, computed } from 'vue';
import dayjs from 'dayjs';

import DatePickerBase from '@/components/foundation/pickers/DatePickerBase.vue';

const { disabled, masks, keepVisibleOnInput } = defineProps({
  disabled: { type: Boolean },
  masks: { type: Object, default: () => null },
  keepVisibleOnInput: { type: Boolean },
});

const modelValue = defineModel({ type: [Object, Date], default: () => null });

const emit = defineEmits(['show', 'hide', 'page']);

const open = defineModel('open', { type: Boolean, default: false });
function togglePopover() {
  open.value = !open.value;
}

watch(
  modelValue,
  () => {
    if (!keepVisibleOnInput) {
      open.value = false;
    }
  },
  { flush: 'sync' },
);

const dateFormat = computed(() =>
  masks?.input && Array.isArray(masks.input) ? masks.input[0] : masks?.input,
);

const inputValue = computed(() =>
  modelValue.value && dateFormat.value
    ? dayjs(modelValue.value).format(dateFormat.value)
    : modelValue.value,
);
</script>

<template>
  <div :class="['flex flex-col', { 'cursor-default opacity-30': disabled }]">
    <VDropdown
      v-model:shown="open"
      placement="bottom-start"
      :triggers="[]"
      theme="dh-dropdown-full"
      @apply-show="emit('show')"
      @apply-hide="emit('hide')"
    >
      <slot v-bind="{ inputValue, togglePopover, open }" />

      <template #popper>
        <DatePickerBase
          v-bind="$attrs"
          v-model="modelValue"
          :disabled="disabled"
          @page="(page) => emit('page', page)"
        >
          <template #header-prev-button>
            <slot name="header-prev-button" />
          </template>
          <template #header-next-button>
            <slot name="header-next-button" />
          </template>
          <template #bottom>
            <slot name="bottom" />
          </template>
        </DatePickerBase>
      </template>
    </VDropdown>
  </div>
</template>
